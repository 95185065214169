import { AppContext } from "@/App.context";
import config from "@/config";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NOT_FOUND_ERROR,
  ERROR_STATUS_CODES,
} from "@/lib/errors/error-constants";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

const dummy = {
    "snapshots": [
        {
            "title": "Cost Anomaly by Service",
            "anomaly_id":"123",
            "userName":"SACHIN SINGH NEGI",
            "userId":"sachin.negi@cloudeq.com",
            "anomilies": 6,
            "lastOccurred": "17/02/25",
            "EvaluationPeriod": 60,
            "threshold": 75,
            "filter": "Service : EC2",
        },
        {
            "title": "Cost Anomaly by Service",
            "anomaly_id":"236",
            "userName":"SACHIN SINGH NEGI",
            "userId":"sachin.negi@cloudeq.com",
            "anomilies": 6,
            "lastOccurred": "17/02/25",
            "EvaluationPeriod": 60,
            "threshold": 75,
            "filter": "Service : EC2",
        },
        {
            "title": "Cost Anomaly by Service",
            "anomaly_id":"098",
            "userName":"SACHIN SINGH NEGI",
            "userId":"sachin.negi@cloudeq.com",
            "anomilies": 6,
            "lastOccurred": "17/02/25",
            "EvaluationPeriod": 60,
            "threshold": 75,
            "filter": "Service : EC2",
        },
    ],
    "total": 26
  }
/**
 * @typedef AllTableStatistics
 * @property {AlltableStatics[]} snapshots
 *
 */
/**
 * @typedef AlltableStatics
 * @property {String} title
 * @property {String} anomilies
 * @property {String} lastOccurred
 * @property {String} EvaluationPeriod
 * @property {String} threshold
 * @property {Number} filter
 */

/**
 * @typedef AllBodyStatisticsRes
 * @property {AllTableStatistics} statistics
 */

/**
 * @typedef GetStatisticsCostAnomalyReq
 * @property {'aws' | 'azure' |'gcp'  } service
 * @property {number} page
 * @property {number} recordsPerPage
 */

/**
 * @param {GetStatisticsCostAnomalyReq} req
 * @returns {Promise<AllBodyStatisticsRes>}
 */

export const getStatisticsCostAnomaly = (token) => {
  return async ({ service, page, recordsPerPage }) => {
    // return dummy
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/cost-anomaly/list?page=${page}&recordsPerPage=${recordsPerPage}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!owners.ok) {
      if (ERROR_STATUS_CODES.includes(owners.status)) {
        throw new Error(API_LOADING_ERROR);
      }
      if (owners.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR);
      }
      throw new Error((await owners.json()).message);
    }
    const res = await owners.json();
    if (res.data.length === 0) {
      throw new Error(API_NO_DATA_FOUND_ERROR);
    }

    return res;
  };
};

/**
 * @param {GetStatisticsCostAnomalyReq} req
 * @param {any[]=} deps
 */
export const useCostANomalyStats = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getStatisticsCostAnomaly", req, ...deps],
    queryFn: () => getStatisticsCostAnomaly(appContext.idToken)(req),
    retry:false
  });
  return query;
};

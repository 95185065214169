import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  ReferenceDot,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
  ZAxis,
} from "recharts";
import styles from "../anomaly.module.css";
import style from "@/page-components/cost-explorer/cost.explorer.module.css";
import CurrencyFormatter from "@/lib/value-converison";
import threshold from "@/assets/img/thresholds.svg";
import { useContext } from "react";
import { AnomalyContext } from "../cost-anomaly.context";

const StackedBarGraph = ({
  data,
  colorMap,
  showLegend,
  height,
  service,
  // dimensionData,
}) => {
  const context = useContext(AnomalyContext);
  const scatterData = data
    .filter((item) => item.anomaly === 1)
    .map((item) => {
      const totalCostForDate = data.reduce((sum, curr) => {
        if (curr.date === item.date) {
          return sum + curr.cost;
        }
        return sum;
      }, 0);
      return {
        x: item.date,
        y: totalCostForDate,
      };
    });
  const transformDataForChart = (data) => {
    const groupedData = {};

    data?.forEach((item) => {
      if (!groupedData[item.date]) {
        groupedData[item.date] = { label: item.date };
      }

      // If item.service exists, use it as the key; otherwise, use "cost"
      const key = item.service || "cost";
      groupedData[item.date][key] = item.cost;
    });

    return Object.values(groupedData);
  };

  const barChartData = transformDataForChart(data);

  const uniqueKeys = Array.isArray(barChartData)
    ? Array.from(
        new Set(
          barChartData.flatMap((data) =>
            Object.keys(data).filter((key) => key !== "label")
          )
        )
      )
    : [];

  return (
    <ResponsiveContainer height={height} width="100%">
      <BarChart
        data={barChartData}
        className={styles.legends_Style}
        margin={{ top: 0, right: 3, left: 0, bottom: 0 }}
        stackOffset="sign"
      >
        <XAxis
          dataKey="label"
          type="category"
          tickLine={false}
          axisLine={false}
          fontSize={10}
          fontWeight={400}
          stroke="#A3A3A3"
          fontFamily="Inter"
          textAnchor="middle"
          tickMargin={7}
        />
        <YAxis
          label={{ fill: "#A3A3A3" }}
          type="number"
          height={400}
          tickLine={false}
          axisLine={false}
          fontSize={10}
          fontWeight={400}
          stroke="#A3A3A3"
          fontFamily="Inter"
          domain={["auto", "dataMax + 4"]}
        />
        {/* <ZAxis dataKey="y" range={[5, 5]} /> */}

        <CartesianGrid strokeDasharray="0" stroke="#F0F0F0" />
        <Tooltip
          content={
            <CustomTooltip
              context={context}
              colorMap={colorMap}
              service={service}
              dimensionData={context?.SelectedFilter}
            />
          }
          cursor={{ fill: "transparent" }}
        />
        {showLegend && (
          <Legend
            content={<CustomLegend dimensionData={context?.SelectedFilter} />}
          />
        )}

        {uniqueKeys.map((key) => (
          <Bar
            key={key}
            dataKey={key}
            stackId="a"
            fill={colorMap[key]}
            barSize={25}
          />
        ))}

        {/* <ReferenceLine
          y={context?.SelectedThreshold}
          stroke="orange"
          strokeDasharray="3 3"
          strokeWidth={2}
          alwaysShow
        /> */}
        {scatterData.map((point, index) => (
          <ReferenceDot
            key={index}
            x={point.x}
            y={point.y + point.y * 0.05}
            fill="red"
            stroke="white"
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export const CustomTooltip = ({
  active,
  payload,
  label,
  service,
  dimensionData,
  colorMap,
  context,
}) => {
  const filteredPayload = payload.sort((a, b) => a.value - b.value);

  return (
    <div className={style.custom_tooltip}>
      <div className="tooltip_date">
        <div className="tooltip_date_css">{label}</div>
      </div>
      <table>
        <thead>
          <tr>
            <th className={style.cloud_cell}>
              {" "}
              {[
                ...(context?.SelectedFilter?.tags || []),
                ...(context?.SelectedFilter?.dimensions || []),
              ].map((item) => (
                <div style={{ display: "flex", gap: "2px" }} key={item?.name?.name}>
                  {item?.name?.name.replace(/([a-z])([A-Z])/g, "$1 $2").replace(/\b\w/g, (char) => char.toUpperCase())}
                </div>
              ))}
            </th>
            <th className={style.cost_cell}>Costs</th>
          </tr>
        </thead>
        <tbody>
          {filteredPayload.map((entry, index) => (
            <tr
              key={`tooltip-row-${index}`}
              style={{ borderBottom: "1px solid #373737" }}
            >
              <td className={style.cloud_cell}>
                <div
                  style={{ display: "flex", gap: "8px", alignItems: "center" }}
                >
                  <span
                    style={{
                      background: colorMap?.[entry.dataKey],
                      display: "flex",
                      width: "12px",
                      height: "12px",
                      borderRadius: "2px",
                    }}
                  ></span>

                  {[
                    ...(dimensionData?.tags || []),
                    ...(dimensionData?.dimensions || []),
                  ].map((item) => (
                    <>
                      {(item?.values || []).map((i) => (
                        <span
                          key={i}
                          style={{
                            overflowX: "hidden",
                            textOverflow: "ellipsis",
                            width: "120px",
                          }}
                        >
                          {i?.name}
                        </span>
                      ))}
                    </>
                  ))}

                  {/* {entry.datakey !== "Null" ? entry.dataKey || "NA" : "NA"} */}
                </div>
              </td>
              <td className={style.cost_cell}>
                <CurrencyFormatter value={entry.value} />
              </td>
            </tr>
          ))}
          {/* <tr style={{ borderBottom: "" }}>
            <td className={style.cloud_cell}>
              <div
                style={{ display: "flex", gap: "8px", alignItems: "center" }}
              >
                <img
                  src={threshold}
                  alt="Threshold"
                  style={{
                    width: 14,
                    height: 14,
                  }}
                />
                Threshold
              </div>
            </td>
            <td className={style.cost_cell}>
              {context?.SelectedThreshold
                ? context?.SelectedThreshold + "%"
                : "--"}
            </td>
          </tr> */}
        </tbody>
      </table>
    </div>
  );
};

export const CustomLegend = ({ payload, dimensionData }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        margin: "10px 0",
        gap: "8px 10px",
      }}
    >
      {payload.map((bar, barIndex) => (
        <div
          key={barIndex}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: 14,
              height: 14,
              backgroundColor: bar.color,
              marginRight: 8,
              borderRadius: "2px",
            }}
          ></div>
          <span
            style={{
              fontSize: 12,
              fontWeight: 400,
              fontFamily: "Inter, sans-serif",
              color: "#000000",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {[
              ...(dimensionData?.tags || []),
              ...(dimensionData?.dimensions || []),
            ].map((item) => (
              <div style={{ display: "flex", gap: "2px" }} key={item?.name}>
                {/* <span>{item?.name}</span>: */}
                {(item?.values || []).map((i) => (
                  <span key={i}>{i?.name}</span>
                ))}
              </div>
            ))}
          </span>
        </div>
      ))}
      {/* <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "4px",
        }}
      >
        <img
          src={threshold}
          alt="Threshold"
          style={{
            width: 14,
            height: 14,
          }}
        />
        <span
          style={{
            fontSize: 12,
            fontWeight: 400,
            fontFamily: "Inter, sans-serif",
            color: "#000000",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            maxWidth: "150px",
          }}
          title="Threshold"
        >
          Threshold
        </span>
      </div> */}
    </div>
  );
};

export default StackedBarGraph;

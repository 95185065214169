import React, { useState, useRef, useMemo, useContext } from "react";
import menuicon from "@/assets/img/menu.svg";
import download from "@/assets/img/download.svg";
import barcharticon from "@/assets/img/graphCharticon.svg";
import linecharticon from "@/assets/img/lineCharticon.svg";
import Dropdown from "react-bootstrap/Dropdown";
import { CSVLink } from "react-csv";
import styles from "@/page-components/idle-resource/idle.module.css";
import { useStackedStatistics } from "@/services/IdleResources/getStackedStatistics.repo";
import BarGraphErrorStates from "@/components/States/BarGraphErrorStates";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR,
  API_NOT_FOUND_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
} from "@/lib/errors/error-constants";
import { parse, compareAsc } from "date-fns";
import SnapHistoryBArChart from "./snapHistoryBarChart.part";
import LineChartSnapshot from "./historyLineChart.part";
import { SnapshotContext } from "../../snap.contex";

const HistoryChartSection = ({
  data,
  historChartLoading,
  historChartListError,
}) => {
  const context = useContext(SnapshotContext)
  const [chartType, setchartType] = useState("bar");
  const sortedData = data?.data?.data?.activities.sort((a, b) => {
    const [aMonth, aYear] = a.month.split(" ");
    const [bMonth, bYear] = b.month.split(" ");

    const aDate = new Date(`${aMonth} 1, ${aYear}`);
    const bDate = new Date(`${bMonth} 1, ${bYear}`);

    return compareAsc(aDate, bDate);
  });
  const maxLength = sortedData?.length || 0;
  const csvLinkRef = useRef(null);
  const handleDownloadCsv = () => {
    const headers = [
      "Month",
      ...Object.keys(data?.data?.data?.activities[0]).filter(
        (key) => key !== "formatted_month" || key !== "month"
      ),
    ];

    const csvData = [
      headers,
      ...data?.data?.data?.activities.map((item) => [
        // item.month,
        ...headers.slice(1).map((header) => item[header] || "-"),
      ]),
    ];

    if (csvLinkRef.current) {
      csvLinkRef.current.link.click();
    }
  };
  return (
    <>
          {historChartLoading && <BarGraphErrorStates />}
      <div
        style={{
          display: "flex",
          alignItems: "start",
          flexDirection: "column",
          gap: "20px",
          padding: "24px 16px 0px 40px",
          width: "100%",
          // marginRight: "10px",
        }}
        >
        <>
          {historChartListError && (
            <ScenariosErrorState
              error={historChartListError.message}
              messageConfig={{
                [API_NO_DATA_FOUND_FOR_FILTERS_ERROR]: {
                  message: "No Data Available for Selected Filters",
                  additionalMessage:
                    "It looks like there are no data matching your filter criteria. Please try adjusting your filters to explore other options.",
                },
                [API_NO_DATA_FOUND_ERROR]: {
                  message: "No Data Available",
                  additionalMessage:
                    "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
                },
                [API_LOADING_ERROR]: {
                  message: "Oops!",
                  additionalMessage:
                    "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
                },
                [API_NOT_FOUND_ERROR]: {
                  message: "404 Error",
                  additionalMessage:
                    "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
                },
              }}
            />
          )}
          {!historChartLoading && !historChartListError && data && (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    gap: "8px",
                    alignItems: "center",
                  }}
                >
                  <div className={styles.all_cloud_text}>
                    Snapshots Activity
                  </div>
                  <div className={styles.last_days_name}>
                    {/* Last {maxLength} months
                     */}
                     {context?.TitlePeriod}
                  </div>
                </div>
                <div className={styles.menu_icon_css1}>
                  <Dropdown style={{ marginBottom: 2 }}>
                    <Dropdown.Toggle
                      variant="light"
                      id={styles.custom_dropdown_toggle}
                      className={styles.custom_dropdown_toggle}
                      style={{ background: "transparent", border: "none" }}
                    >
                      <img src={menuicon} alt="Menu" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className={styles.dropdown_menu}>
                      <Dropdown.Item
                        className={styles.dropdown_item}
                        eventKey="bar"
                        onClick={() => setchartType("bar")}
                      >
                        <img
                          src={barcharticon}
                          alt=""
                          style={{ marginRight: "10px" }}
                        ></img>
                        Bar Chart
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={styles.dropdown_item}
                        eventKey="line"
                        onClick={() => setchartType("line")}
                      >
                        <img
                          src={linecharticon}
                          alt=""
                          style={{ marginRight: "10px" }}
                        ></img>
                        Line Chart
                      </Dropdown.Item>
                      <Dropdown.Divider />

                      <Dropdown.Item
                        className={styles.dropdown_item}
                        eventKey="Download as CSV"
                        onClick={handleDownloadCsv}
                      >
                        <img
                          src={download}
                          alt=""
                          style={{ marginRight: "10px" }}
                        />
                        Download as CSV
                      </Dropdown.Item>
                      <CSVLink
                        data={data?.data?.data?.activities}
                        filename={"HistoryActivities.csv"}
                        target="_blank"
                        ref={csvLinkRef}
                        style={{ display: "none" }}
                      >
                        Download
                      </CSVLink>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>

              <div style={{ width: "100%" }}>
                {data && chartType === "bar" ? (
                  <SnapHistoryBArChart data={sortedData} />
                ) : (
                  <LineChartSnapshot lineChartData={sortedData} />
                )}
              </div>
            </>
          )}
        </>
      </div>
    </>
  );
};

export default HistoryChartSection;

import { AppContext } from "@/App.context";
import config from "@/config";
import {
  API_LOADING_ERROR,
  API_LOADING_ERROR_CUSTOM_WIDGET,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET,
  API_NOT_FOUND_ERROR,
  API_NOT_FOUND_ERROR_CUSTOM_WIDGET,
  ERROR_STATUS_CODES,
} from "@/lib/errors/error-constants";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

/**
 * @typedef AllHeaderStatistics
 * @property {AllStatics} Statics
 * @property {AllStaticsCloud} snapshotByClouds
 */

/**
 * @typedef AllStatics
 * @property {Number} totalSnapshotCount
 * @property {Number} totalSnapshotCost
 * @property {Number} unTaggedCount
 * @property {Number} orphanedCount
 * @property {Number} orphanedSavings
 * @property {Btw10-15}
 * @property {Btw15-30}
 * @property {more_than_thirty}
 *
 */

/**
 * @typedef Btw10-15
 * @property {Number} count
 */

/**
 * @typedef Btw15-30
 * @property {Number} count
 */

/**
 * @typedef more_than_thirty
 * @property {Number} count
 */

/**
 * @typedef AllStaticsCloud
 * @property {Number} AwsSnapshotCount
 * @property {Number} AwsSnapshotCost
 * @property {Number} AzureSnapshotCount
 * @property {Number} AzureSnapshotCost
 */
/**
 * @typedef GetSnapshotsFilters
 * @property {string[]} accounts
 * @property {string[]} regions
 * @property {string[]} diskIds
 * @property {string[]} snapshotAge
 * @property {string[]} snapshottags
 */

/**
 * @typedef AllHeaderStatisticsRes
 * @property {AllHeaderStatistics} statistics
 * @property {GetSnapshotsFilters} filters
 */

/**
 * @typedef GetStatisticsReq
 * @property {'aws' | 'azure' | 'gcp' | 'all-cloud' } service
 */

export const getHeaderStatistics = (token) => {
  /**
   * @param {GetStatisticsReq} req
   * @returns {Promise<AllHeaderStatisticsRes>}
   */
  return async ({ status, service, filters }, signal) => {
    const statusMap = {
      Active: "active",
      "In Progress": "in_progress",
      Retained: "retained",
    };
    const mappedStatus = statusMap[status] || status;

    const adjustedFilters = { ...filters };
    if (status === "Retained") {
      delete adjustedFilters.maxAge;
    }

    const url =
      `${config.apiHost}/v1/${service}/snapshots/stats` +
      (status === "Retained" ? `?status=${mappedStatus}` : "");

    const HeaderStats = await fetch(
      url,

      {
        method: service === "all-cloud" ? "GET" : "POST",
        body:
          service !== "all-cloud"
            ? JSON.stringify({
                filters: adjustedFilters,
              })
            : undefined,
        signal,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!HeaderStats.ok) {
      const errorResponse = await HeaderStats.json();

      if (ERROR_STATUS_CODES.includes(HeaderStats.status)) {
        throw new Error(API_LOADING_ERROR_CUSTOM_WIDGET);
      }
      if (HeaderStats?.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR_CUSTOM_WIDGET);
      }
      throw new Error(errorResponse.message);
    }
    const res = await HeaderStats.json();
    if (res.stats.totalCount === 0) {
      throw new Error(API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET);
    }
    return res;
  };
};

/**
 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useHeaderStatistics = (req, deps = [],enabled=true) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getHeaderStatistics", req, ...deps],
    queryFn: () => getHeaderStatistics(appContext.idToken)(req),
    retry: false,
    enabled
  });
  return query;
};

import React, { useContext, useMemo } from "react";
import style from "@/page-components/Cost-Anomaly/anomaly.module.css";
import crossModal from "@/assets/img/modal_cross.svg";
import LineChartGraph from "@/page-components/cost-explorer/all-clouds/charts-tables/line-chart-graph.part";
import LineChartSection from "./line-chart.part";
import TablePart from "./table.part";
import { RiutilizationContext } from "../../utilization.context";
import { useRiModalData } from "@/services/ri-utilization/get-ri-modal-data.repo";
import CurrencyFormatter from "@/lib/value-converison";
import { Spinner } from "react-bootstrap";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";

const ModalSection = ({ setisChartModal, service }) => {
  const context = useContext(RiutilizationContext);
  const query = useMemo(() => {
    return {
      service,
      reservation_id: context?.SelectedPopupAccount,
    };
  }, [service, context?.SelectedPopupAccount]);

  const { data, isLoading, error } = useRiModalData(query);

  return (
    <>
      {isLoading && (
        <div
          style={{display: "flex", alignItems: "center",justifyContent:"center",width:"100%",height:"100vh" }}
        >
          <Spinner />
        </div>
      )}
      {!isLoading && error && (
        <ScenariosErrorState
          error={error.message}
          messageConfig={{
            [API_LOADING_ERROR]: {
              message: "Oops!",
              additionalMessage:
                "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
            },
            [API_NOT_FOUND_ERROR]: {
              message: "404 Error",
              additionalMessage:
                "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
            },
          }}
        />
      )}
      {data && !error && !isLoading && (
        <div
          className={style.chartModalMain}
          style={{ width: "100%", height: "100%" }}
        >
          <div
            className={style.chartModalHeader}
            style={{ paddingLeft: "0px" }}
          >
            <span className={style.chartModalHeadertext}>
              Reservation Utilization
            </span>

            <img
              src={crossModal}
              alt=""
              style={{ cursor: "pointer" }}
              onClick={() => {
                setisChartModal(false);
              }}
            />
          </div>

          <div style={{ padding: "20px 20px 0px 0px" }}>
            <div
              style={{
                display: "flex",
                gap: "40px",
                flexDirection: "column",
              }}
            >
              <div
                className={style.chartStats}
                style={{ justifyContent: "space-between" }}
              >
                <div className={style.chartStats}>
                  <span className={style.gap}>
                    <h1 className={style.statsHeadings}>
                      Effective Reservation Cost
                    </h1>
                    <p className={style.chartStatsValue}>
                      <CurrencyFormatter
                        value={data?.effectiveReservationCost || 0}
                      />
                    </p>
                  </span>
                  <span className={style.gap}>
                    <h1 className={style.statsHeadings}>
                      On-Demand Cost Equivalent
                    </h1>
                    <p className={style.chartStatsValue}>
                      <CurrencyFormatter value={data?.onDemandCost} />
                      {/* {context?.SelectedAnomaliesCount || "--"} */}
                    </p>
                  </span>
                  <span className={style.gap}>
                    <h1 className={style.statsHeadings}>Total Net savings</h1>
                    <p
                      className={style.chartStatsValue}
                      style={{ color: "#359446" }}
                    >
                      <CurrencyFormatter value={data?.totalNetSavings} />
                      {/* {context?.SelectedThreshold
                    ? context?.SelectedThreshold + "%"
                    : "--"} */}
                    </p>
                  </span>
                  <span className={style.gap}>
                    <h1 className={style.statsHeadings}>
                      Total Potential Savings
                    </h1>
                    <p className={style.chartStatsValue}>
                      <CurrencyFormatter value={data?.potentialSavings} />

                      {/* {context?.SelectedThreshold
                    ? context?.SelectedThreshold + "%"
                    : "--"} */}
                    </p>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginTop: "32px" }}>
            <span
              style={{ fontSize: "14px", fontWeight: "600", color: "#212529" }}
            >
              Reservation Utilization Over Time
            </span>
            <LineChartSection data={data?.utilizationOverTime} />
          </div>

          <div style={{ marginTop: "32px" }}>
            <span
              style={{ fontSize: "14px", fontWeight: "600", color: "#212529" }}
            >
              Reserved Instance Details
            </span>
            <TablePart data={data} />
          </div>
        </div>
      )}
    </>
  );
};

export default ModalSection;

import React from "react";
import styles from "@/page-components/budgets/components/budgets.module.css";
import clsx from "clsx";

const IndividualTableAllBudget = ({ data }) => {
  return (
    <div style={{ width: "100%" }}>
      <table
        className={styles.table}
        style={{ minWidth: "30%", overflowX: "auto", width: "100%" }}
      >
        <thead>
          <tr>
            <th className={styles.table_header}>Budgets</th>
            <th className={clsx(styles.table_header,styles.Text_End)}>Actual Spent</th>
            <th className={clsx(styles.table_header,styles.Text_End)}>Total Budget</th>
            <th className={clsx(styles.table_header,styles.Text_End)}>% Spent</th>
            <th className={clsx(styles.table_header,styles.Text_End)} style={{textAlign:"end"}}>Forecasted Amount</th>
            <th className={clsx(styles.table_header,styles.Text_End)}>Period</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td className={styles.table_body} title={item?.budget_name}>
                {item.budget_name||"--"}
              </td>
              <td className={clsx(styles.table_body, styles.td_center_align,styles.Text_End)}>
                {item.actual_spent!==null?"$"+Number(item.actual_spent).toFixed(2):"--"}
              </td>
              <td className={clsx(styles.table_body, styles.td_center_align,styles.Text_End)}>
                {item.total_budget!=null?"$"+Number(item.total_budget).toFixed(2):"--"}
              </td>
              <td className={clsx(styles.table_body, styles.td_center_align,styles.Text_End)}>
                {item.spent_percentage !==null ?Number(item.spent_percentage).toFixed(2)+"%":"--"}
              </td>
              <td className={clsx(styles.table_body, styles.td_right_align)}>
                {item.forcasted_amount !==null?"$"+Number(item.forcasted_amount).toFixed(2): "--"}
              </td>
              <td className={clsx(styles.table_body, styles.td_center_align,styles.Text_End)}>
                {item.period || "--"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default IndividualTableAllBudget;

import React from "react";
import style from "@/page-components/cost-explorer/cost.explorer.module.css";

import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import CurrencyFormatter from "@/lib/value-converison";

const LineChartSection = ({ service, data }) => {
  return (
    <div style={{ marginTop: "20px" ,marginBottom:"-32px"}}>
      <ResponsiveContainer width="100%" height={300}  >
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="0" stroke="#F0F0F0" />

          <XAxis
            dataKey="date"
            tickLine={false}
            axisLine={false}
            fontSize={10}
            fontFamily="Inter"
            fontWeight={400}
            stroke="#A3A3A3"
          />
          <YAxis
            label={{ fill: "#A3A3A3" }}
            tickLine={false}
            axisLine={false}
            fontSize={10}
            fontFamily="Inter"
            fontWeight={400}
            stroke="#A3A3A3"
          />
          <Tooltip
            content={
              <CustomTooltip
                //  colorMap={colorMap}
                service={service}
                dimensionData={data}
              />
            }
            cursor={{ fill: "transparent" }}
          />
          <Line
            type="monotone"
            dataKey="utilization"
            stroke="#8884d8"
            activeDot={{ r: 5 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export const CustomTooltip = ({ active, payload, label }) => {
  if (!active || !payload || payload.length === 0) {
    return null;
  }

  return (
    <div className={style.custom_tooltip}>
      <div className="tooltip_date">
        <div className="tooltip_date_css">{label}</div>
      </div>
      <table>
        <thead>
          <tr>
            <th className={style.cloud_cell}>Services</th>
            <th className={style.cost_cell}>Costs</th>
          </tr>
        </thead>
        <tbody>
          {payload.map((entry, index) => (
            <tr
              key={`tooltip-row-${index}`}
            >
              <td className={style.cloud_cell}>
                <div
                  style={{ display: "flex", gap: "8px", alignItems: "center" }}
                >
                  {entry?.name}
                </div>
              </td>
              <td className={style.cost_cell}>
                <CurrencyFormatter value={entry?.value} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};


export default LineChartSection;

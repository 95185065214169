import React, { useContext, useEffect, useMemo, useState } from "react";
import styles from "@/page-components/RightSizing/rightSizing.module.css";
import style from "@/page-components/cost-Recommendation/cost-Recommendation.module.css";
import copy from "@/assets/img/copy-06 1.svg";
import stylesMain from "@/page-components/snapshot/snapshots.module.css";

import clsx from "clsx";
import Pagination from "@/components/pagination/pagination.component";
import { Offcanvas, OverlayTrigger, Tooltip } from "react-bootstrap";
import downlaodVM from "@/assets/img/downloadVM.svg";
import FilterSection from "./filter-section/filter-section";
import { RiutilizationContext } from "../utilization.context";
import ModalSection from "./modal/modal.part";
import { useRiUtlizationDetails } from "@/services/ri-utilization/get-table-ri-details.repo";
import { useSearchParams } from "react-router-dom";
import TableErrorState from "@/components/States/TableErrorState";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";
import { UseRiTableDownload } from "@/services/ri-utilization/download-ri-table-data.repo";

const TableSection = ({ service, name,HEaderLoading }) => {
  const context = useContext(RiutilizationContext);
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const [copiedResourceId, setCopiedResourceId] = useState(null);
  const [showCopyMessage, setShowCopyMessage] = useState(false);
  const [isChartModal, setisChartModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page");
  const itemsPerPage = searchParams.get("itemsPerPage");

  const handleMouseEnter = (index) => {
    setHoveredRowIndex(index);
  };
  const handleMouseLeave = () => {
    setHoveredRowIndex(null);
  };
  const handleCopyClick = (snapshotId) => {
    const textarea = document.createElement("textarea");
    textarea.value = snapshotId;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    setCopiedResourceId(snapshotId);
    setCopiedResourceId(snapshotId);
    setShowCopyMessage(true);

    setTimeout(() => {
      setShowCopyMessage(false);
    }, 5000);
  };

  const query = useMemo(() => {
    const parsedPage = !Number.isNaN(parseInt(page)) ? parseInt(page) : 1;
    const parsedItemsPerPage = !Number.isNaN(parseInt(itemsPerPage))
      ? parseInt(itemsPerPage)
      : 10;
    return {
      service,
      period: context?.selectMonthRange.toLowerCase(),
      start_date: context?.fromDate,
      end_date: context?.toDate,
      page: parsedPage || 1,
      recordsPerPage: parsedItemsPerPage || 20,
      filters: {
        accounts: context?.selectedAccounts?.length
          ? context.selectedAccounts.map((e) => e.id)
          : undefined,
        rg: context?.selectedResourceGroup?.length
          ? context.selectedResourceGroup.map((e) => e.id)
          : undefined,
        regions: context?.selectedRegions?.length
          ? context.selectedRegions.map((e) => e.id)
          : undefined,
        term: context?.selectedTerm?.length
          ? context.selectedTerm.map((e) => e.id)
          : undefined,
      },
    };
  }, [
    page,
    itemsPerPage,
    service,
    context?.selectMonthRange,
    context?.fromDate,
    context?.toDate,
    context.selectedAccounts,
    context.selectedResourceGroup,
    context.selectedRegions,
    context.selectedTerm,
  ]);
  const { data, isLoading, error } = useRiUtlizationDetails(query);
  // ----------------------------download query--------------------------------------------------
  const downloadQuery = useMemo(() => {
    const parsedPage = !Number.isNaN(parseInt(page)) ? parseInt(page) : 1;
    const parsedItemsPerPage = !Number.isNaN(parseInt(itemsPerPage))
      ? parseInt(itemsPerPage)
      : 10;
    return {
      service,
      period: context?.selectMonthRange.toLowerCase(),
      start_date: context?.fromDate,
      end_date: context?.toDate,
      page: parsedPage || 1,
      recordsPerPage: parsedItemsPerPage || 20,
      filters: {
        accounts: context?.selectedAccounts?.length
          ? context.selectedAccounts.map((e) => e.id)
          : undefined,
        rg: context?.selectedResourceGroup?.length
          ? context.selectedResourceGroup.map((e) => e.id)
          : undefined,
        regions: context?.selectedRegions?.length
          ? context.selectedRegions.map((e) => e.id)
          : undefined,
        term: context?.selectedTerm?.length
          ? context.selectedTerm.map((e) => e.id)
          : undefined,
      },
    };
  }, [
    page,
    itemsPerPage,
    service,
    context?.selectMonthRange,
    context?.fromDate,
    context?.toDate,
    context.selectedAccounts,
    context.selectedResourceGroup,
    context.selectedRegions,
    context.selectedTerm,
  ]);

  const { mutate: downloadMutate } = UseRiTableDownload(downloadQuery);

  const handleDownloadClick = () => {
    downloadMutate(downloadQuery);
  };
  console.log(data);

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.container}
        style={{
          padding: "0px 40px 24px 40px",
          gap: "10px",
        }}
      >
        <div
          style={{
            width: "84%",
            display: "flex",
            flexDirection: "column",
            gap: "24px",
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", gap: "22.5px" }}
          >
            <span
              className={styles.recommendationsLabel}
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {" "}
              {((!isLoading && data) || error) && "RI Utilization Details"}
              {data && (
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip id="tooltip">Download as CSV</Tooltip>}
                >
                  <span
                    className={styles.downloadButton}
                    onClick={handleDownloadClick}
                  >
                    <img src={downlaodVM} alt="" />
                  </span>
                </OverlayTrigger>
              )}
            </span>
            {!data && !error && isLoading && <TableErrorState />}{" "}
            {!isLoading && error && (
              <ScenariosErrorState
                error={error.message}
                messageConfig={{
                  [API_NO_DATA_FOUND_FOR_FILTERS_ERROR]: {
                    message: "No Data Available for Selected Filters",
                    additionalMessage:
                      "It looks like there is no matching your filter criteria. Please try adjusting your filters to explore other options.",
                  },
                  [API_LOADING_ERROR]: {
                    message: "Oops!",
                    additionalMessage:
                      "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
                  },
                  [API_NOT_FOUND_ERROR]: {
                    message: "404 Error",
                    additionalMessage:
                      "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
                  },
                  [API_NO_DATA_FOUND_ERROR]: {
                    message: "No  Data Available",
                    additionalMessage:
                      "We couldn’t retrieve idle resource data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
                  },
                }}
              />
            )}
            {!isLoading && !error && data?.count?.total_data > 0 && (
              <>
                <div style={{ overflowX: "scroll" }}>
                  <table
                    style={{ width: "100%" }}
                    id="customers"
                    className={clsx(styles.scrollable, "table-hover")}
                  >
                    <thead>
                      <tr>
                        <th className={styles.tableHead}>
                          {service === "aws" ? "Account" : "Subscription"}
                        </th>
                        <th className={styles.tableHead}>
                          Instance Type & Count
                        </th>
                        <th className={styles.tableHead}>Reservation ID</th>
                        <th className={styles.tableHead}>Region</th>
                        <th className={styles.tableHead}>Term</th>
                        <th className={styles.tableHead}>Expiry Date</th>
                        <th className={styles.tableHead}>Utilization %</th>
                        <th className={styles.tableHead}>RI Cost</th>
                        <th className={styles.tableHead}>RI Savings</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(data?.data || []).map((item, i) => (
                        <tr
                          id={styles.table_body_tr}
                          className={clsx(styles.accountCol, {
                            [styles.hovered]: i === hoveredRowIndex,
                          })}
                          key={item.resourceId + "-" + i}
                          onMouseEnter={() => handleMouseEnter(i)}
                          onMouseLeave={handleMouseLeave}
                        >
                          <td
                            className={clsx(
                              styles.accountCol,
                              styles.my_white_space
                            )}
                            style={{
                              color: "#056EE5",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              context?.setSelectedPopupAccount(
                                item?.reservation_id
                              );
                              setisChartModal(true);
                            }}
                          >
                            {item?.account !== null
                              ? item?.account || "--"
                              : "--"}
                          </td>
                          <td
                            className={clsx(
                              styles.accountCol,
                              styles.my_white_space
                            )}
                            style={{
                              borderBottom:"0px",
                            }}
                          >
                            <div style={{display:"flex",justifyContent:"space-between",gap:"10px"}}>

                            <span>
                              {item?.instance_type !== null
                                ? item?.instance_type || "--"
                                : "--"}
                            </span>
                            <span>
                              {item?.count !== null
                                ? item?.count || "--"
                                : "--"}
                            </span>
                            </div>
                          </td>
                          <td
                            className={clsx(
                              styles.accountCol,
                              styles.my_white_space,
                              stylesMain.snap_table_data__Class
                            )}
                            title={item?.reservation_id}
                            style={{ marginRight: "8px" }}
                          >
                            <span
                              style={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                maxWidth: "150px",
                                display: "inline-block",
                                lineHeight: "1.2",
                              }}
                            >
                              {item?.reservation_id !== null
                                ? item?.reservation_id || "--"
                                : "--"}
                            </span>
                            <span
                              className={`${stylesMain.snap_copy_icon} ${
                                copiedResourceId === item?.reservation_id
                                  ? "visible"
                                  : ""
                              }`}
                              onClick={() =>
                                handleCopyClick(item?.reservation_id)
                              }
                            >
                              <img src={copy} alt="" />
                            </span>
                          </td>
                          <td
                            className={clsx(
                              styles.accountCol,
                              styles.my_white_space
                            )}
                          >
                            {item?.region !== null
                              ? item?.region || "--"
                              : "--"}
                          </td>
                          <td
                            className={clsx(
                              styles.accountCol,
                              styles.my_white_space
                            )}
                          >
                            {item?.term !== null ? item?.term || "--" : "--"}
                          </td>
                          <td
                            className={clsx(
                              styles.accountCol,
                              styles.my_white_space
                            )}
                          >
                     {new Date(item?.expiryDate !== null
                              ? item?.expiryDate || "--"
                              : "--").toISOString().split("T")[0].split("-").reverse().join("-")}
                          </td>
                          <td
                            className={clsx(
                              styles.accountCol,
                              styles.my_white_space
                            )}
                          >
                            {item?.utilization !== null
                              ? Number(item?.utilization || 0).toFixed(2) + "%" || "--"
                              : "--"}
                          </td>
                          <td
                            className={clsx(
                              styles.accountCol,
                              styles.my_white_space
                            )}
                          >
                            {item?.ricost !== null
                              ? "$" + Number(item?.ricost || 0).toFixed(2) || "--"
                              : "--"}
                          </td>
                          <td
                            className={clsx(
                              styles.accountCol,
                              styles.my_white_space
                            )}
                          >
                            {item?.ri_saving !== null
                              ? "$" + Number(item?.ri_saving || 0).toFixed(2) || "--"
                              : "--"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <Pagination totalItems={data?.count?.total_data} />
              </>
            )}
          </div>
        </div>
        <FilterSection service={service} name={name} HEaderLoading={HEaderLoading} />
      </div>

      {showCopyMessage && (
        <div className={styles.copyMessage}>Copy to Clipboard!</div>
      )}
      <Offcanvas
        placement="end"
        show={isChartModal}
        onHide={() => {
          setisChartModal(false);
        }}
        style={{
          width: "85%",
        }}
      >
        <Offcanvas.Body
          className={styles.modal_body}
          style={{
            paddingTop: 16,
          }}
        >
          <ModalSection service={service} setisChartModal={setisChartModal} />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default TableSection;

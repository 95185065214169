import React, { useMemo } from "react";
import IdleIndividualBarGarph from "../idle-individual-bar-graph.part";
import vms from "@/assets/img/tooltipVM.svg";
import storage from "@/assets/img/tooltipDisk.svg";
import loadbalancer from "@/assets/img/tooltipLoadbalancer.svg";
import IP from "@/assets/img/tooltipIP.svg";
import { useSavingStatistics } from "@/services/IdleResources/getSavingStatistics.repo";
import { useSearchParams } from "react-router-dom";
import styles from "@/page-components/idle-resource/idle.module.css";
import clsx from "clsx";
import VerticalBarGraph from "@/components/States/verticalBarErrorState";
import CurrencyFormatter from "@/lib/value-converison";
import config from "@/config";

const AllCloudIndividualCloudCost = ({ service }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const query = useMemo(() => {
    return {
      page: searchParams.get("page") || 1,
      resourceType: searchParams.get("resourceType") || "all",
      recordsPerPage: searchParams.get("recordsPerPage") || 20,
      idleDays: searchParams.get("idleDays") || 31,
      service,
    };
  }, [searchParams]);

  const { data, isLoading } = useSavingStatistics(query);

  const graphData = useMemo(() => {
    if (!data) return [];

    return data
      .filter((item) =>
        config.enabledClouds.has(item?.Resource_Type?.toLowerCase())
      )
      .sort((a, b) => {
        const order = Array.from(config.enabledClouds);
        return (
          order.indexOf(a.Resource_Type.toLowerCase()) -
          order.indexOf(b.Resource_Type.toLowerCase())
        );
      })
      .map((item) => {
        const resourceType = item?.Resource_Type?.toLowerCase();
        const isAWS = resourceType === "aws";
        const isAzure = resourceType === "azure";
        const isGCP = resourceType === "gcp";

        return {
          serviceType: resourceType,
          title: `${item?.Resource_Type} Idle Resource`,
          costValue: <CurrencyFormatter value={item?.saving} />,
          data1: [
            {
              name: isAWS
                ? "Elastic IPs"
                : isAzure
                ? "Public IPs"
                : isGCP
                ? "Static IPs"
                : null,
              type: "ip",
              Image: IP,
              uv: Math.round(Number(item?.ip) * 100) / 100 || 0,
              fill: "#E7C067",
            },
            {
              name: isAWS
                ? "EC2"
                : isAzure
                ? "VMs"
                : isGCP
                ? "Compute Engine"
                : null,
              type: "vm",
              Image: vms,
              uv: Math.round(Number(item?.vm) * 100) / 100 || 0,
              fill: "#DF8367",
            },
            {
              name: isAWS
                ? "EBS"
                : isAzure
                ? "Disks"
                : isGCP
                ? "Disks"
                : null,
              type: "disk",
              Image: storage,
              uv: Math.round(Number(item?.disk) * 100) / 100 || 0,
              fill: "#7FBAFF",
            },
            {
              name: isAWS
                ? "Load Balancer"
                : isAzure
                ? "Load Balancer"
                : isGCP
                ? "Load Balancer"
                : null,
              type: "lb",
              Image: loadbalancer,
              uv: Math.round(Number(item?.lb) * 100) / 100 || 0,
              fill: "#9D626E",
            },
          ].filter((d) => d.name !== null), 
        };
      });
  }, [data, config.enabledClouds]);

  console.log("gcp", graphData, data)

  return (
    <>
      {isLoading && (
        <div
          className="container-fluid"
          style={{
            marginBottom: "7%",
            marginRight: "-12px",
            display: "flex",
            justifyContent: "start",
            gap: "20px",
            top: 16,
          }}
        >
          {Array.from(config.enabledClouds).map((cloud, index) => (
            <VerticalBarGraph key={index} cloud={cloud} />
          ))}
        </div>
      )}
      {data && (
        <div
          className="container-fluid"
          style={{ marginBottom: "20px", marginRight: "-12px" }}
        >
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                gap: "8px",
                alignItems: "center",
                marginBottom: "16px",
                paddingRight: "20px",
                marginLeft: -10,
              }}
            >
              <div className={styles.all_cloud_text}>
                Individual Cloud Idle Resources Count
              </div>
              {/* <div className="last_days_name">Last 30 Days</div> */}
            </div>
            <div className={clsx("row", styles.graph_main_div)}>
              {graphData.map((graph, index) => (
                <IdleIndividualBarGarph
                  key={index}
                  data={graph}
                  color={graph.data1.fill}
                  serviceType={graph.serviceType}
                />
              ))}
            </div>
          </>
        </div>
      )}
    </>
  );
};

export default AllCloudIndividualCloudCost;

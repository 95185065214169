import React from "react";
import NoDataImage from "@/assets/img/noDataImg.svg";
import styles from "./states.module.css";

const SearchNoDataState = () => {
  return (
    <div>
      <img src={NoDataImage} alt=""></img>
      <div className={styles.sec_container}>
        <div className={styles.text_css}>Oops, No Matches!</div>
        <div className={styles.sec_text_css}>
          It looks like we couldn't find what you're looking for. How about
          trying again with service categories, specific services, or resource
          names?
        </div>
      </div>
    </div>
  );
};

export default SearchNoDataState;

import React from "react";
import styless from "@/page-components/cost-explorer/cost.explorer.module.css";

export const CustomTooltip = ({ active, payload, month, service }) => {
  console.log("object");
  if (!active) {
    return null;
  }
  return (
    <div className={styless.custom_tooltip}>
      <div className="tooltip_date">
        <div className="tooltip_date_css">
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "211px",
              whiteSpace:"nowrap"
            }}
          >
            {payload?.[0]?.payload?.month ||
              payload?.[0]?.payload?.name ||
              payload?.[0]?.payload?.idle_days ||
              payload?.[0]?.payload?.date}
          </span>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th className={styless.cloud_cell}>Amount Types</th>
            <th className={styless.cost_cell}>Costs</th>
          </tr>
        </thead>
        <tbody>
          {(payload || []).map((entry, index) => (
            <tr key={`tooltip-row-${index}`}>
              <td className={styless.cloud_cell}>{entry.dataKey}</td>
              <td className={styless.cost_cell}>
                ${Number(entry.value)?.toFixed(2)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export const CustomXAxisTick = ({ x, y, payload }) => {
  return (
    <foreignObject x={x - 30} y={y} width="100" height="20">
      <div
        style={{
          width: "100px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          fontSize: "10px",
          fontWeight: "500",
          textAlign: "center",
          color: "#495057",
        }}
        title={payload.value} 
      >
        {payload.value}
      </div>
    </foreignObject>
  );
};

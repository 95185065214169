import React, { useMemo } from "react";
import TabsContainer from "./tabs-container.component";
import CostExplorerHeader from "./cost-explorer-header.component";
import { useAllStatistics } from "@/services/costExplorer/getStatistics.repo";

const CostExplorerContainer = ({
  selectedCustomTabs,
  setSelectedCustomTabs,
  selectedForecastTab,
  setSelectedForecastTab,
  service,
  range,
  setRange,
  toDate,
  fromDate,
  daysDifference,
}) => {

    const query = useMemo(() => {
      return {
        service: service,
        fromDate,
        toDate,
      };
    }, [fromDate, service, toDate]);
  
    const { data, isLoading, error } = useAllStatistics(query);
  return (
    <div className={(!isLoading && data) || error ? "HeaderMainDiv" : ""}>
      <CostExplorerHeader
        service={service}
        range={range}
        setRange={setRange}
        toDate={toDate}
        fromDate={fromDate}
        daysDifference={daysDifference}
        data={data}
        isLoading={isLoading}
        error={error}
      />

      <TabsContainer
        service={service}
        selectedCustomTabs={selectedCustomTabs}
        setSelectedCustomTabs={setSelectedCustomTabs}
        selectedForecastTab={selectedForecastTab}
        setSelectedForecastTab={setSelectedForecastTab}
        data={data}
        isLoading={isLoading}
        error={error}
      />
    </div>
  );
};

export default CostExplorerContainer;

import React, { useContext } from 'react'
import styles from "@/page-components/budgets/components/budgets.module.css"
import { AnomalyContext } from '../cost-anomaly.context'

const DeleteConfirmationSection = ({name ,DeleteData,setConfirmationmodal,deleteLoading}) => {
    const context = useContext(AnomalyContext)
    const Conformation = (id)=>{
        context?.setSelectedAnomalyID(id);
    }
  return (
    <div className={styles.dialogContainer}>
    <div>
      <div className={styles.dialogTitle}>Delete {name}</div>
      <span className={styles.dialogBody}>
        Are you sure you want to delete this {name}?
        <div>This action cannot be undone.</div>
      </span>
    </div>
    <div className={styles.buttonContainer}>
      <button className={styles.deleteButton} 
      onClick={()=>Conformation(DeleteData)}
      >
        {deleteLoading ? "Deleting...":"Delete"}
      </button>
      <button className={styles.cancleButton} 
      onClick={()=>setConfirmationmodal(false)}
      >
        Cancel
      </button>
    </div>
  </div>
  )
}

export default DeleteConfirmationSection

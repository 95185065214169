import React, { useState, useContext, useMemo, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import menuicon from "@/assets/img/menu.svg";
import styles from "../idle.module.css";
import copyicon from "@/assets/img/copyicon.svg";
import { DropdownButton } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { IdleContext } from "../idle-context-file.part";
import { useSavingTableStatistics } from "@/services/IdleResources/getArchiveStatistics.repo";
import Pagination from "@/components/pagination/pagination.component";
import clsx from "clsx";
import TableErrorState from "@/components/States/TableErrorState";
import ToastComponent from "@/page-components/budgets/components/toast";
import sorting from "@/assets/img/sorting.svg";
import { useGetHistory } from "@/services/IdleResources/getAcceptDismissHistory.repo";
import DoneIcon from "@/assets/img/doneStateChange.svg";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_QUERY_RESOURCE_FILTERS,
  API_NO_DATA_FOUND_FOR_QUERY_IDLE_FILTERS,
} from "@/lib/errors/error-constants";
import { UseSavingDetailsDownload } from "@/services/IdleResources/savingDetailsDownload.repo";
import downlaodVM from "@/assets/img/downloadVM.svg";
import { OverlayTrigger, Tooltip as ReactTooltip } from "react-bootstrap";
import { ToolTipComponent } from "@/page-components/cost-explorer/components/cost-explorer-header.component";
import CurrencyFormatter from "@/lib/value-converison";
import { Form } from "react-bootstrap";
import jiraIcon from "@/assets/img/jiraIcon.svg";
import jiraAbledIcon from "@/assets/img/jiraAbleIcon.svg";
import retainAbled from "@/assets/img/retainAbled.svg";
import retainedDisabled from "@/assets/img/retainDisable.svg";
import AcceptViaJira from "@/components/state-change/accept-via-jira";
import jiraHover from "@/assets/img/jiraHover.svg";
import ViewAcceptData from "@/components/state-change/view-accept-data";
import RetainModal from "@/components/state-change/retain-modal";
import { useIdleUpdateStatus } from "@/services/IdleResources/putUpdateStatusChanges.repo";
import { useIdleTicketStatus } from "@/services/IdleResources/getIdleTicketStatus.repo";
import config from "@/config";

const IdleAWSResourceTablePart = ({
  service,
  name,
  refreshHeaders,
  archiveHeaderRefeth,
  ipName,
  vmName,
  diskName,
}) => {
  const [selectedResourceType, setSelectedResourceType] = useState("All");
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page");
  const itemsPerPage = searchParams.get("itemsPerPage");
  const desc = searchParams.get("desc");
  const [copiedResourceId, setCopiedResourceId] = useState(null);
  const IdleDays = searchParams.get("idleDays");
  const [showCopyMessage, setShowCopyMessage] = useState(false);
  const [toast, setToast] = useState(false);
  const [message, setMessage] = useState();
  const [orderBy, setOrderBy] = useState("saving");
  const [resourceId, setResourceId] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const context = useContext(IdleContext);
  const formattedItsmType = String(
    config?.itsmTool ? config?.itsmTool : "JIRA"
  ).toLowerCase();
  const Itsm_type =
    formattedItsmType === "snow"
      ? "SNOW"
      : formattedItsmType.replace(/^./, (char) => char.toUpperCase());

  const modalName = "Idle Resource";

  const selectedIdleDays = useMemo(() => {
    let showIdleDays;

    switch (IdleDays) {
      case "7":
        showIdleDays = "0-7 Days";
        break;
      case "15":
        showIdleDays = "8-15 Days";
        break;
      case "30":
        showIdleDays = "16-30 Days";
        break;
      case "31":
        showIdleDays = "More than 31 Days";
        break;
      default:
        showIdleDays = "All";
    }

    return showIdleDays;
  }, [IdleDays]);

  const query = useMemo(() => {
    const parsedPage = !Number.isNaN(parseInt(page)) ? parseInt(page) : 1;
    const parsedItemsPerPage = !Number.isNaN(parseInt(itemsPerPage))
      ? parseInt(itemsPerPage)
      : 10;
    const tags = (context?.selectedTags || []).filter(
      (e) => e.filterOn.length > 0 && e.values.length > 0
    );

    return {
      service,
      resourceType: searchParams.get("resourceType") || "all",
      idleDays: searchParams.get("idleDays") || "all",
      page: parsedPage || 1,
      orderBy: orderBy || "saving",
      desc: searchParams.get("desc") || "true",
      status: context?.selectedStatus || "active",
      recordsPerPage: parsedItemsPerPage || 20,
      untagged: context.showUntaggedOnly,
      filters: {
        accounts: context?.selectAccounts?.length
          ? context.selectAccounts.map((e) => e.id)
          : undefined,
        rg: context?.selectedResourceGroup?.length
          ? context.selectedResourceGroup.map((e) => e.id)
          : undefined,
        regions: context?.selectRegions?.length
          ? context.selectRegions.map((e) => e.id)
          : undefined,
        tags: tags?.length ? tags : undefined,
      },
    };
  }, [
    context.selectAccounts,
    context.selectRegions,
    context.selectedResourceGroup,
    context?.selectedStatus,
    context?.selectedTags,
    context.showUntaggedOnly,
    itemsPerPage,
    orderBy,
    page,
    searchParams,
    service,
  ]);

  const {
    data: tableData,
    isLoading,
    error,
    refetch: refetchActiveTable,
  } = useSavingTableStatistics(query);
  // ------------------------------------------------------update status(via jira)--------------------------------------------

  const {
    data: updatedStatus,
    mutate: acceptUpdatedMutate,
    isError: isErrorUpdateStatus,
    error: errorUpdateStatus,
    isPending: updateStatusPending,
  } = useIdleUpdateStatus();

  const ticketStatusQuery = useMemo(() => {
    if (!context?.selectedResourceId) return null;
    return {
      service,
      resource_id: context?.selectedResourceId,
    };
  }, [context?.selectedResourceId, service]);

  const {
    data: ticketStatusData,
    isLoading: ticketStatusLoading,
    error: ticketStatusError,
  } = useIdleTicketStatus(ticketStatusQuery);

  useEffect(() => {
    setSelectedResourceType("All");
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);
      updatedParams.set("page", "1");
      updatedParams.set("resourceType", "all");
      updatedParams.set("idleDays", "all");
      return updatedParams;
    });
  }, [
    updatedStatus,
    context.selectAccounts,
    context.selectRegions,
    context.selectedTags,
    context?.selectedStatus,
  ]);

  // -------------------------------------------------------------------------------------------------------------------------

  const handleCopyClick = (resource_id) => {
    const textarea = document.createElement("textarea");
    textarea.value = resource_id;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);

    setCopiedResourceId(resource_id);
    setShowCopyMessage(true);

    setTimeout(() => {
      setShowCopyMessage(false);
    }, 5000);
  };

  const handleResourceTypeSelect = (selectedType) => {
    let resourceType;

    switch (selectedType) {
      case "All":
        resourceType = "all";
        break;
      case "VMs":
      case "EC2":
      case "Compute Engine":
        resourceType = "vm";
        break;
      case "Elastic IPs":
      case "Public IPs":
      case "Static IPs":
        resourceType = "ip";
        break;
      case "Disks":
      case "EBS":
        resourceType = "disk";
        break;
      case "Load Balancer":
        resourceType = "lb";
        break;
      default:
        resourceType = selectedType.toLowerCase();
    }

    setSelectedResourceType(selectedType);

    const updatedSearchParams = new URLSearchParams(searchParams);
    updatedSearchParams.set("resourceType", resourceType);
    updatedSearchParams.set("page", "1");
    setSearchParams(updatedSearchParams);
  };
  // ---------------------------------------------orderBY--------------------------------------------

  const handleOrderByClick = (columnName) => {
    setOrderBy(orderBy === columnName ? columnName : columnName);
    const newBoolVal = desc === "true" ? "false" : "true";

    const updatedSearchParams = new URLSearchParams(searchParams);
    updatedSearchParams.set("orderBy", orderBy);
    updatedSearchParams.set("desc", newBoolVal);
    updatedSearchParams.set("page", "1");
    setSearchParams(updatedSearchParams);
  };
  //  ----------------------------------------filter idledays-------------------------------------

  const handleIdleDaysSelect = (selectedDays) => {
    let upperLimit;
    if (selectedDays === "All") {
      upperLimit = "all";
    } else if (selectedDays === "More than 31 Days") {
      upperLimit = 31;
    } else {
      upperLimit = parseInt(selectedDays.split("-")[1].trim());
    }

    const updatedSearchParams = new URLSearchParams(searchParams);
    updatedSearchParams.set("idleDays", upperLimit.toString());
    updatedSearchParams.set("page", "1");
    setSearchParams(updatedSearchParams);
  };

  useEffect(() => {
    if (updatedStatus) {
      refetchActiveTable();
      refreshHeaders();
      archiveHeaderRefeth();
      Object.values(context?.semiCardRefetch || {}).forEach((refetchFn) => {
        if (typeof refetchFn === "function") {
          refetchFn();
        }
      });
      context?.setShowRetainModal(false);
      context?.setShowJiraModal(false);
    }
  }, [
    archiveHeaderRefeth,
    refetchActiveTable,
    refreshHeaders,
    updatedStatus,
    context?.semiCardRefetch,
  ]);

  useEffect(() => {
    if (errorUpdateStatus) {
      context?.setShowRetainModal(false);
      context?.setShowJiraModal(false);
      setMessage({
        message: errorUpdateStatus?.message,
        type: "Anomaly",
      });
      setToast(true);
    }
  }, [errorUpdateStatus, setMessage, isErrorUpdateStatus]);

  const invalidStatus = (status) => {
    switch (status) {
      case "done":
      case "archived":
      case "active":
      case "in_progress":
      case "accepted":
      case "discarded":
        return false;
      default:
        return true;
    }
  };

  const downloadQuery = useMemo(() => {
    const parsedPage = !Number.isNaN(parseInt(page)) ? parseInt(page) : 1;
    const parsedItemsPerPage = !Number.isNaN(parseInt(itemsPerPage))
      ? parseInt(itemsPerPage)
      : 10;
    const tags = (context?.selectedTags || []).filter(
      (e) => e.filterOn.length > 0 && e.values.length > 0
    );
    return {
      service,
      tableName: "saving-details",
      resourceType: searchParams.get("resourceType") || "all",
      idleDays: searchParams.get("idleDays") || "all",
      page: parsedPage || 1,
      orderBy: orderBy || "saving",
      desc: searchParams.get("desc") || "true",
      status: context?.selectedStatus || "active",
      recordsPerPage: parsedItemsPerPage || 20,
      untagged: context.showUntaggedOnly,
      filters: {
        accounts: context?.selectAccounts?.length
          ? context.selectAccounts.map((e) => e.id)
          : undefined,
        rg: context?.selectedResourceGroup?.length
          ? context.selectedResourceGroup.map((e) => e.id)
          : undefined,
        regions: context?.selectRegions?.length
          ? context.selectRegions.map((e) => e.id)
          : undefined,
        tags: tags?.length ? tags : undefined,
      },
    };
  }, [
    context.selectAccounts,
    context.selectRegions,
    context.selectedResourceGroup,
    context?.selectedStatus,
    context?.selectedTags,
    context.showUntaggedOnly,
    itemsPerPage,
    orderBy,
    page,
    searchParams,
    service,
  ]);
  const { mutate: downloadMutate } = UseSavingDetailsDownload(downloadQuery);

  const handleDownloadClick = () => {
    downloadMutate(downloadQuery);
  };

  useEffect(() => {
    if (
      updatedStatus &&
      tableData?.data?.length === 1 &&
      error?.message === "API_NO_DATA_FOUND_ERROR"
    ) {
      setSearchParams((prevParams) => {
        const updatedParams = new URLSearchParams(prevParams);
        updatedParams.set("page", "1");
        return updatedParams;
      });

      refetchActiveTable();
    }
  }, [
    updatedStatus,
    tableData?.data?.length,
    refetchActiveTable,
    error?.message,
  ]);

  const handleCheckboxChange = (item) => {
    const isSelected = selectedRows.some(
      (row) => row.resource_id === item.resource_id
    );

    if (isSelected) {
      setSelectedRows(
        selectedRows.filter((row) => row.resource_id !== item.resource_id)
      );
    } else {
      if (selectedRows.length >= 20) {
        setMessage({
          message: "You can select up to 20 idle resources at a time.",
          type: "Info",
        });
        setToast(true);
      } else {
        setSelectedRows([...selectedRows, item]);
      }
    }
  };

  // ------------------------handle select status ---------------------------------------------------------

  const handleSelectStatus = (status) => {
    context?.setSelectedStatus(status);
    // const updatedSearchParams = new URLSearchParams(window.location.search);

    // updatedSearchParams.set("status", status);
    // updatedSearchParams.set("page", "1");
    // setSearchParams(updatedSearchParams);
  };

  const handleShowAcceptJira = () => context?.setShowJiraModal(true);
  // -----------------------------------accept via jira by dropdown---------------------------------------------------
  const handleShowJiraModal = (item) => {
    context?.setShowJiraModal(true);
    setSelectedRows([item]);
  };

  const handleCloseJiraModal = (item) => {
    context?.setShowJiraModal(false);
    setSelectedRows([]);
  };
  // --------------------------retain by dropdown--------------------------------------------------------------------
  const handleRetainModalMultiselect = (item) => {
    context?.setShowRetainModal(true);

    setSelectedRows([item]);
  };

  const handleShowRetainModal = () => context?.setShowRetainModal(true);

  const handleCloseRetainModal = () => {
    context?.setShowRetainModal(false);
    setSelectedRows([]);
  };

  const handleShowacceptmodal = (ticket_id, resource_id) => {
    context?.setSelectedResourceId(ticket_id);
    setResourceId(resource_id);
    context?.setAcceptViaJira(true);
  };
  const handleClosecceptmodal = () => {
    context?.setAcceptViaJira(false);
  };

  const disablebtn =
    context?.selectedStatus === "in_progress" || selectedRows.length === 0;

  const STATUS_MAPPING = {
    active: "Active",
    in_progress: "In Progress",
    deleted: "Deleted",
  };

  useEffect(() => {
    setSelectedRows([]);
  }, [context?.selectedStatus, updatedStatus]);

  return (
    <>
      {toast && (
        <ToastComponent
          message={message}
          toast={toast}
          setToast={setToast}
          timeout={5000}
        />
      )}

      <div
        className={styles.service_table__container}
        style={{ paddingRight: "0px", paddingBottom: "32px" }}
      >
        {((!isLoading && tableData) || error) && (
          <div className={styles.saving_container}>
            <div className={styles.saving_name_text}>Saving Details</div>
          </div>
        )}

        <div className={styles.saving_down_space}>
          <div className={styles.main_resource_container}>
            {!isLoading && (tableData || error) && (
              // error.message === API_NO_DATA_FOUND_FOR_FILTERS_ERROR ||
              // error.message ===
              //   API_NO_DATA_FOUND_FOR_QUERY_RESOURCE_FILTERS ||
              // error.message === API_NO_DATA_FOUND_FOR_QUERY_IDLE_FILTERS) && (
              <div className={styles.resource_container}>
                <div className={styles.res_type}>
                  <div className={styles.res_type_text}>Resource Type</div>

                  <div>
                    <DropdownButton
                      id={styles.dropdown_item_button}
                      title={selectedResourceType}
                      style={{}}
                    >
                      <Dropdown.Item
                        className={styles.dropdown_item}
                        as="button"
                        onClick={() => handleResourceTypeSelect("All")}
                      >
                        All
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={styles.dropdown_item}
                        as="button"
                        onClick={() => handleResourceTypeSelect(ipName)}
                      >
                        {ipName}
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={styles.dropdown_item}
                        as="button"
                        onClick={() => handleResourceTypeSelect(diskName)}
                      >
                        {diskName}
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={styles.dropdown_item}
                        as="button"
                        onClick={() => handleResourceTypeSelect(vmName)}
                      >
                        {vmName}
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={styles.dropdown_item}
                        as="button"
                        onClick={() =>
                          handleResourceTypeSelect("Load Balancer")
                        }
                      >
                        Load Balancer
                      </Dropdown.Item>
                    </DropdownButton>
                  </div>
                </div>
                <div className={styles.res_type}>
                  <div className={styles.res_type_text}>Idle Days</div>

                  <div className="dropdown">
                    <div>
                      <DropdownButton
                        id={styles.dropdown_item_button}
                        title={selectedIdleDays}
                        style={{}}
                      >
                        <Dropdown.Item
                          className={styles.dropdown_item}
                          as="button"
                          onClick={() => handleIdleDaysSelect("All")}
                        >
                          All
                        </Dropdown.Item>
                        <Dropdown.Item
                          className={styles.dropdown_item}
                          as="button"
                          onClick={() => handleIdleDaysSelect("0-7 Days")}
                        >
                          0-7 Days
                        </Dropdown.Item>
                        <Dropdown.Item
                          className={styles.dropdown_item}
                          as="button"
                          onClick={() => handleIdleDaysSelect("8-15 Days")}
                        >
                          8-15 Days
                        </Dropdown.Item>
                        <Dropdown.Item
                          className={styles.dropdown_item}
                          as="button"
                          onClick={() => handleIdleDaysSelect("16-30 Days")}
                        >
                          16-30 Days
                        </Dropdown.Item>
                        <Dropdown.Item
                          className={styles.dropdown_item}
                          as="button"
                          onClick={() =>
                            handleIdleDaysSelect("More than 31 Days")
                          }
                        >
                          More than 31 Days
                        </Dropdown.Item>
                      </DropdownButton>
                    </div>
                  </div>
                </div>
                <div className={styles.res_type}>
                  <div className={styles.res_type_text}>Status</div>

                  <div>
                    <DropdownButton
                      id={styles.dropdown_item_button}
                      title={STATUS_MAPPING[context?.selectedStatus]}
                      style={{}}
                    >
                      <Dropdown.Item
                        className={styles.dropdown_item}
                        as="button"
                        onClick={() => handleSelectStatus("active")}
                      >
                        Active
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={styles.dropdown_item}
                        as="button"
                        onClick={() => handleSelectStatus("in_progress")}
                      >
                        In Progress
                      </Dropdown.Item>
                    </DropdownButton>
                  </div>
                </div>
              </div>
            )}
          </div>
          {!isLoading && !error && tableData && (
            <div className={styles.selected_box_option}>
              <div className={styles.checkbox_seleted_count}>
                <span
                  className={styles.sselected}
                  style={{ color: disablebtn ? "#ADB5BD" : "" }}
                >
                  Selected
                </span>
                <span
                  className={styles.selected_number}
                  style={{ color: disablebtn ? "#ADB5BD" : disablebtn }}
                >
                  ({selectedRows.length})
                </span>
              </div>
              {/* )} */}
              <button
                className={styles.accept_jira_button}
                style={{ color: disablebtn ? "" : "#056EE5" }}
                disabled={disablebtn}
                onClick={handleShowAcceptJira}
              >
                {Itsm_type === "jira" && (
                  <img src={disablebtn ? jiraIcon : jiraAbledIcon} alt=""></img>
                )}
                Accept via {Itsm_type}
              </button>

              <button
                className={styles.accept_jira_button}
                style={{
                  color: disablebtn ? "" : "#343A40",
                }}
                disabled={disablebtn}
                onClick={handleShowRetainModal}
              >
                <img
                  src={disablebtn ? retainedDisabled : retainAbled}
                  alt=""
                  style={{ marginRight: "4px" }}
                ></img>
                Retain
              </button>

              <div className={styles.menu_icon_css1}>
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <ReactTooltip id="tooltip">Download as CSV</ReactTooltip>
                  }
                >
                  <span
                    className={styles.downloadButton}
                    onClick={handleDownloadClick}
                  >
                    <img src={downlaodVM} alt="" />
                  </span>
                </OverlayTrigger>
              </div>
            </div>
          )}
        </div>

        {!tableData && !error && isLoading && <TableErrorState />}
        {error && (
          <ScenariosErrorState
            error={error.message}
            messageConfig={{
              [API_NO_DATA_FOUND_FOR_FILTERS_ERROR]: {
                message: "No Data Available for Selected Filters",
                additionalMessage:
                  "It looks like there is no matching your filter criteria. Please try adjusting your filters to explore other options.",
              },
              [API_LOADING_ERROR]: {
                message: "Oops!",
                additionalMessage:
                  "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
              },
              [API_NOT_FOUND_ERROR]: {
                message: "404 Error",
                additionalMessage:
                  "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
              },
              [API_NO_DATA_FOUND_ERROR]: {
                message: "No Idle Resource Data Available",
                additionalMessage:
                  "We couldn’t retrieve idle resource data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
              },
              [API_NO_DATA_FOUND_FOR_QUERY_RESOURCE_FILTERS]: {
                message: "No Data Available for Selected Resource Type",
                additionalMessage:
                  "It looks like there is no matching your filter criteria. Please try adjusting your filters to explore other options.",
              },
              [API_NO_DATA_FOUND_FOR_QUERY_IDLE_FILTERS]: {
                message: "No Data Available for Selected Idle Days",
                additionalMessage:
                  "It looks like there is no matching your filter criteria. Please try adjusting your filters to explore other options.",
              },
            }}
          />
        )}
        {!isLoading && !error && tableData?.count?.total_data > 0 && (
          <div className="container-fluid  p-0">
            <div style={{ minWidth: "30%", overflowX: "auto" }}>
              <table
                className="table table-hover "
                id={styles.customers}
                style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <th
                      className={clsx(styles.th_class)}
                      style={{ width: "0%" }}
                    >
                      {context?.selectedStatus === "active" && (
                        <Form.Check
                          className={styles.input_checkbox_form}
                          type="checkbox"
                          checked={tableData?.data.every((item) =>
                            selectedRows.some(
                              (row) => row.resource_id === item.resource_id
                            )
                          )}
                          onChange={(e) => {
                            if (e.target.checked) {
                              const pageRows =
                                tableData?.data.slice(0, 20) || [];
                              const remainingLimit = 20 - selectedRows.length;
                              const rowsToAdd = pageRows
                                .filter(
                                  (item) =>
                                    !selectedRows.some(
                                      (row) =>
                                        row.resource_id === item.resource_id
                                    )
                                )
                                .slice(0, remainingLimit);

                              if (selectedRows.length >= 20) {
                                setMessage({
                                  message:
                                    "You can select up to 20 idle resources at a time.",
                                  type: "Info",
                                });
                                setToast(true);
                                return;
                              }

                              if (rowsToAdd.length > 0) {
                                setSelectedRows((prev) => [
                                  ...prev,
                                  ...rowsToAdd,
                                ]);
                              }
                            } else {
                              const pageRows =
                                tableData?.data.slice(0, 20) || [];
                              setSelectedRows((prev) =>
                                prev.filter(
                                  (row) =>
                                    !pageRows.some(
                                      (item) =>
                                        item.resource_id === row.resource_id
                                    )
                                )
                              );
                            }
                          }}
                          style={{
                            opacity: selectedRows.length >= 20 ? 0.4 : 1,
                          }}
                        />
                      )}
                    </th>

                    <th
                      className={clsx(styles.th_class, styles.table_td_width)}
                    >
                      {name}{" "}
                      <img
                        src={sorting}
                        alt=""
                        className={styles.sorting_icon}
                        onClick={() => handleOrderByClick("account_name")}
                      ></img>
                    </th>
                    <th
                      className={clsx(styles.th_class, styles.table_td_width)}
                    >
                      Resource Name{" "}
                      <img
                        src={sorting}
                        alt=""
                        className={styles.sorting_icon}
                        onClick={() => handleOrderByClick("name")}
                      ></img>{" "}
                    </th>
                    {service === "azure" && (
                      <th
                        className={clsx(styles.th_class, styles.table_td_width)}
                      >
                        Resource Group
                        <img
                          src={sorting}
                          alt=""
                          className={styles.sorting_icon}
                          onClick={() =>
                            handleOrderByClick("resource_group_name")
                          }
                        ></img>{" "}
                      </th>
                    )}
                    <th
                      className={clsx(styles.th_class, styles.table_td_width)}
                    >
                      Resource ID{" "}
                      <img
                        src={sorting}
                        alt=""
                        className={styles.sorting_icon}
                        onClick={() => handleOrderByClick("resource_id")}
                      ></img>
                    </th>
                    <th
                      className={clsx(styles.th_class, styles.table_td_width)}
                    >
                      Resource Type{" "}
                      <img
                        src={sorting}
                        alt=""
                        className={styles.sorting_icon}
                        onClick={() => handleOrderByClick("resource_type")}
                      ></img>
                    </th>
                    <th
                      className={clsx(styles.th_class, styles.table_td_width)}
                    >
                      Region{" "}
                      <img
                        src={sorting}
                        alt=""
                        className={styles.sorting_icon}
                        onClick={() => handleOrderByClick("region")}
                      ></img>
                    </th>
                    <th
                      className={clsx(styles.th_class, styles.table_td_width)}
                      style={{ textAlign: "center" }}
                    >
                      Idle Days &nbsp; &nbsp;
                      <img
                        src={sorting}
                        alt=""
                        className={styles.sorting_icon}
                        onClick={() => handleOrderByClick("idle_days")}
                      ></img>
                    </th>
                    <th
                      className={clsx(styles.th_class, styles.table_td_width)}
                      style={{ textAlign: "right" }}
                    >
                      {/* <span style={{ paddingRight: "12px" }}> */}
                      Cost {/* </span> */}
                    </th>

                    <th
                      className={clsx(styles.th_class, styles.table_td_width)}
                      style={{ textAlign: "right" }}
                    >
                      <span style={{ paddingRight: "12px" }}>
                        Projected Monthly Savings{" "}
                      </span>
                      <img
                        src={sorting}
                        alt=""
                        className={styles.sorting_icon}
                        onClick={() => handleOrderByClick("saving")}
                      ></img>
                    </th>
                    <th className={clsx(styles.th_class)}>Status</th>
                    {context?.selectedStatus === "in_progress" && (
                      <th className={clsx(styles.th_class)}>
                        {Itsm_type} Status
                      </th>
                    )}
                    {context?.selectedStatus !== "in_progress" &&
                      disablebtn && <th className={styles.menu_head}></th>}
                  </tr>
                </thead>

                <tbody>
                  {(tableData?.data || []).map((item, index) => (
                    <tr
                      className={clsx(
                        styles.table_row,
                        selectedRows.some(
                          (row) => row.resource_id === item.resource_id
                        ) && styles.highlighted_row,
                        "p-0"
                      )}
                    >
                      <td className={styles.table_data__Class}>
                        {context?.selectedStatus === "active" ? (
                          <Form.Check
                            className={styles.input_checkbox_form}
                            type="checkbox"
                            style={{
                              opacity:
                                selectedRows.length >= 20 &&
                                !selectedRows.some(
                                  (row) => row.resource_id === item.resource_id
                                )
                                  ? 0.4
                                  : 1,
                            }}
                            checked={selectedRows.some(
                              (row) => row.resource_id === item.resource_id
                            )}
                            onChange={() => handleCheckboxChange(item)}
                          />
                        ) : (
                          <img
                            width="14px"
                            src={jiraHover}
                            alt=""
                            onClick={() =>
                              handleShowacceptmodal(
                                item.ticket_id,
                                item.resource_id
                              )
                            }
                            style={{ cursor: "pointer" }}
                          />
                        )}
                      </td>

                      <td
                        className={styles.table_data__Class}
                        title={item?.account_name}
                      >
                        {item?.account_name || "NA"}
                      </td>
                      <td
                        className={styles.table_data__Class}
                        title={item?.idle_resource_name || item?.name}
                      >
                        {item?.idle_resource_name || item?.name || "NA"}
                      </td>
                      {service === "azure" && (
                        <td
                          className={styles.table_data__Class}
                          title={item.resource_group_name}
                        >
                          {item?.resource_group_name || "NA"}
                        </td>
                      )}
                      <td className={styles.table_data__Class}>
                        <span
                          style={{ paddingRight: "20px" }}
                          title={item.resource_id}
                        >
                          {item?.resource_id || "NA"}
                        </span>
                        <button
                          className={`${styles.copy_icon} ${
                            copiedResourceId === item?.resource_id
                              ? "visible"
                              : ""
                          }`}
                          onClick={() => handleCopyClick(item?.resource_id)}
                        >
                          <img
                            src={copyicon}
                            alt=""
                            style={{ marginRight: "-15px" }}
                          />
                        </button>
                      </td>

                      <td className={styles.table_data__Class}>
                        {item?.resource_type || "NA"}
                      </td>
                      <td
                        className={styles.table_data__Class}
                        title={item.region}
                      >
                        {item?.region || "NA"}
                      </td>
                      <td
                        className={styles.table_data__Class}
                        style={{ textAlign: "center" }}
                      >
                        {item?.idle_days || 0}
                      </td>
                      <td className={styles.table_cost_paid}>
                        <ToolTipComponent data={item?.cost_paid || 0}>
                          <CurrencyFormatter value={item?.cost_paid || 0} />
                        </ToolTipComponent>
                      </td>
                      <td
                        className={styles.table_cost_paid}
                        style={{
                          paddingRight: "20px",
                        }}
                      >
                        <ToolTipComponent data={item?.saving || 0}>
                          {item?.saving < 0.1 && "< $0.1"}
                          {item?.saving > 0.1 && (
                            <CurrencyFormatter value={item?.saving || 0} />
                          )}
                        </ToolTipComponent>
                      </td>
                      <td
                        className={styles.status_table}
                        style={{ backgroundColor: "transparent" }}
                      >
                        {item?.status == "active" && (
                          <span className={styles.badgeName}> Active</span>
                        )}
                        {item?.status == "accepted" && (
                          <span className={styles.badgeNamePending}>
                            Pending Approval
                          </span>
                        )}
                        {item?.status == "in_progress" && (
                          <span className={styles.badgeNameProgress}>
                            In Progress
                          </span>
                        )}
                        {item?.status == "done" && (
                          <span className={styles.badgeNameDone}>
                            {" "}
                            <img src={DoneIcon} alt=""></img> Done
                          </span>
                        )}
                        {invalidStatus(item?.status) && (
                          <span className={styles.badgeName}>
                            {item?.status || "--"}
                          </span>
                        )}
                      </td>

                      {context?.selectedStatus === "in_progress" && (
                        <td
                          className={styles.status_table}
                          style={{ textAlign: "left" }}
                        >
                          {" "}
                          <span className={styles.ticket_status}>
                            {" "}
                            {item?.ticket_status || "--"}
                          </span>
                        </td>
                      )}
                      {context?.selectedStatus !== "in_progress" &&
                        disablebtn && (
                          <td
                            style={{
                              padding: "3px",
                              textAlign: "center",
                              paddingTop: "2px",
                              background: "transparent",
                            }}
                            className={styles.menu_icon_accept}
                          >
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="none"
                                id={styles.dropdown_basic_check}
                                className={styles.custom_dropdown_toggle}
                                style={{
                                  border: "none",
                                  textAlign: "start",
                                  paddingTop: "0px",
                                  paddingBottom: "4px",
                                }}
                              >
                                <img src={menuicon} alt=""></img>
                              </Dropdown.Toggle>

                              <Dropdown.Menu className={styles.dropdown_menu}>
                                <>
                                  <Dropdown.Item
                                    className={styles.dropdown_item}
                                    onClick={() => handleShowJiraModal(item)}
                                  >
                                    <span className={styles.dropdown_values}>
                                      Accept via {Itsm_type}
                                    </span>
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className={styles.dropdown_item}
                                    onClick={() =>
                                      handleRetainModalMultiselect(item)
                                    }
                                  >
                                    <span className={styles.dropdown_values}>
                                      Retain
                                    </span>
                                  </Dropdown.Item>
                                </>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <Pagination totalItems={tableData?.count?.total_data || 0} />
          </div>
        )}
      </div>
      <AcceptViaJira
        Itsm_type={Itsm_type}
        show={context?.showJiraModal}
        handleClose={handleCloseJiraModal}
        name={modalName}
        table_account={name}
        selectedRows={selectedRows}
        updateStatusPending={updateStatusPending}
        onSubmit={(data) => {
          acceptUpdatedMutate({
            service,
            status: "accept",
            comment: data.comment,
            resources: data.selectedRows,
          });
        }}
      />
      <RetainModal
        show={context?.showRetainModal}
        handleClose={handleCloseRetainModal}
        name={modalName}
        table_account={name}
        selectedRows={selectedRows}
        updateStatusPending={updateStatusPending}
        onSubmit={(data) => {
          acceptUpdatedMutate({
            service,
            status: "retained",
            comment: "",
            resources: data.selectedRows,
          });
        }}
      />
      <ViewAcceptData
        Itsm_type={Itsm_type}
        show={context?.acceptViaJira}
        handleClose={handleClosecceptmodal}
        name={modalName}
        table_account={name}
        selectedRows={ticketStatusData?.data}
        isLoading={ticketStatusLoading}
        error={ticketStatusError}
        selectedResourceId={resourceId}
      />

      {showCopyMessage && (
        <div className={styles.copyMessage}>Copy to Clipboard!</div>
      )}
    </>
  );
};

export default IdleAWSResourceTablePart;

import React from "react";
import styles from "@/page-components/admin/user profile/admin.profile.module.css";
import clsx from "clsx";
import { Spinner } from "react-bootstrap";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";

export default function Overview({ data, type, isLoading, error }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        marginTop: "24px",
      }}
    >
      {isLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner />
        </div>
      )}
      {error && (
        <ScenariosErrorState
          error={error.message}
          messageConfig={{
            [API_LOADING_ERROR]: {
              message: "Oops!",
              additionalMessage:
                "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
            },
            [API_NOT_FOUND_ERROR]: {
              message: "404 Error",
              additionalMessage:
                "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
            },
          }}
        />
      )}
      {!error && data && (
        <>
          <div className={styles.userAccountStatusDiv}>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "4px" }}
            >
              <span className={styles.userAccountStatusText}>
                {type} Account Status
              </span>
              <span
                className={styles.activeDisabledText}
                style={{
                  color: data?.userProfile?.accountEnabled
                    ? "#127E24"
                    : "#D01A1A",
                }}
              >
                {data?.userProfile?.accountEnabled ? "Active" : "Disabled"}{" "}
              </span>
            </div>
          </div>

          <div className={styles.userProfileOverviewDiv}>
            <span className={styles.userProfileOverviewText}>
              User Profile Overview
            </span>
            <div className={styles.userDetailsDiv}>
              <div className={styles.userDetailsInnerDivs}>
                <span
                  className={styles.userAccountStatusText}
                  style={{ color: "#797B80" }}
                >
                  Country/City
                </span>
                <span className={styles.detailValues}>
                  {data?.userProfile?.country || "--"}
                </span>
              </div>

              <div className={styles.userDetailsInnerDivs}>
                <span
                  className={styles.userAccountStatusText}
                  style={{ color: "#797B80" }}
                >
                  {" "}
                  Email
                </span>
                <span className={styles.detailValues}>
                  {data?.user?.email || "--"}
                </span>
              </div>

              <div className={styles.userDetailsInnerDivs}>
                <span
                  className={styles.userAccountStatusText}
                  style={{ color: "#797B80" }}
                >
                  User_preferred_language
                </span>
                <span className={styles.detailValues}>
                eg- en-US, fr-FR
                  {/* {data?.userProfile?.preferredLanguage || "--"} */}
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

import React from "react";
import { useSavingStatistics } from "@/services/IdleResources/getSavingStatistics.repo";
import { useSearchParams } from "react-router-dom";
import { useMemo } from "react";
import styles from "../idle.module.css";
import azureIcon from "@/assets/img/azurelogo.svg";
import awsIcon from "@/assets/img/aws_logo2.svg";
import googleIcon from "@/assets/img/gcp_logo.svg";
import clsx from "clsx";
import TableErrorState from "@/components/States/TableErrorState";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR,
  API_NOT_FOUND_ERROR,
  API_NO_DATA_FOUND_ERROR,
} from "@/lib/errors/error-constants";
import CurrencyFormatter from "@/lib/value-converison";
import config from "@/config";

const IdleResourceTablePart = ({ service }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const query = useMemo(() => {
    return {
      page: searchParams.get("page") || 1,
      resourceType: searchParams.get("resourceType") || "all",
      recordsPerPage: searchParams.get("recordsPerPage") || 20,
      idleDays: searchParams.get("idleDays") || 31,
      service,
    };
  }, [searchParams, service]);

  const { data, isLoading, error } = useSavingStatistics(query);
  const tableFilteredData = useMemo(() => {
    if (!data) return [];

    return [...data]
      .filter((item) =>
        config.enabledClouds.has(item?.Resource_Type?.toLowerCase())
      )
      .sort((a, b) => {
        const order = Array.from(config.enabledClouds);
        return (
          order.indexOf(a.Resource_Type.toLowerCase()) -
          order.indexOf(b.Resource_Type.toLowerCase())
        );
      });
  }, [data, config.enabledClouds]);

  return (
    <>
      {isLoading && <TableErrorState />}
      {error && (
        <ScenariosErrorState
          error={error.message}
          messageConfig={{
            [API_LOADING_ERROR]: {
              message: "Oops!",
              additionalMessage:
                "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
            },
            [API_NOT_FOUND_ERROR]: {
              message: "404 Error",
              additionalMessage:
                "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
            },
            [API_NO_DATA_FOUND_ERROR]: {
              message: "No Idle Resource Data Available",
              additionalMessage:
                "We couldn’t retrieve idle resource data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
            },
          }}
        />
      )}
      {data && (
        <div
          className={clsx(
            "container-fluid p-0",
            styles.table_main_container,
            "main_cont_padding"
          )}
          style={{ marginLeft: "14px" }}
        >
          <table className="table" id={styles.customers}>
            <thead>
              <tr>
                <th className={styles.th_data} style={{ textAlign: "left" }}>
                  Cloud
                </th>
                <th className={styles.th_data}>Savings</th>
                <th className={styles.th_data}>IPs</th>
                <th className={styles.th_data}>VMs</th>
                <th className={styles.th_data}>Disks</th>
                <th className={styles.th_data}>Load Balancer</th>
              </tr>
            </thead>
            <tbody>
              {(tableFilteredData?.map)((item, index) => (
                <tr key={index}>
                  <td className={styles.table_data__Class1}>
                    {item?.Resource_Type === "AZURE" && (
                      <img
                        src={azureIcon}
                        alt=""
                        style={{ width: "20px", marginRight: "10px" }}
                      />
                    )}
                    {item?.Resource_Type === "AWS" && (
                      <img
                        src={awsIcon}
                        alt=""
                        style={{ width: "20px", marginRight: "10px" }}
                      />
                    )}
                    {item?.Resource_Type === "GCP" && (
                      <img
                        src={googleIcon}
                        alt=""
                        style={{ width: "20px", marginRight: "10px" }}
                      />
                    )}

                    {item?.Resource_Type}
                  </td>
                  <td className={styles.table_data}>
                    <CurrencyFormatter
                      value={item?.saving || 0}
                      hideDollar={true}
                    />
                  </td>
                  <td className={styles.table_data}>
                    <CurrencyFormatter
                      value={item?.ip || 0}
                      hideDollar={true}
                    />
                  </td>
                  <td className={styles.table_data}>
                    <CurrencyFormatter
                      value={item?.vm || 0}
                      hideDollar={true}
                    />
                  </td>
                  <td className={styles.table_data}>
                    <CurrencyFormatter
                      value={item?.disk || 0}
                      hideDollar={true}
                    />
                  </td>
                  <td className={styles.table_data}>
                    <CurrencyFormatter
                      value={item?.lb || 0}
                      hideDollar={true}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td className={styles.total_Costs1}>
                  <b>Total Savings</b>
                </td>
                <td className={styles.tfooter_class}>
                  <CurrencyFormatter
                    value={data.reduce(
                      (total, item) => total + (item?.saving || 0),
                      0
                    )}
                    hideDollar={true}
                  />
                </td>
                <td className={styles.tfooter_class}>
                  <CurrencyFormatter
                    value={data.reduce(
                      (total, item) => total + (item?.ip || 0),
                      0
                    )}
                    hideDollar={true}
                  />
                </td>
                <td className={styles.tfooter_class}>
                  <CurrencyFormatter
                    value={data.reduce(
                      (total, item) => total + (item?.vm || 0),
                      0
                    )}
                    hideDollar={true}
                  />
                </td>
                <td className={styles.tfooter_class}>
                  <CurrencyFormatter
                    value={data.reduce(
                      (total, item) => total + (item?.disk || 0),
                      0
                    )}
                    hideDollar={true}
                  />
                </td>
                <td className={styles.tfooter_class}>
                  <CurrencyFormatter
                    value={data.reduce(
                      (total, item) => total + (item?.lb || 0),
                      0
                    )}
                    hideDollar={true}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="6" className={styles.table_info_css}>
                  *Figures in USD
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      )}
    </>
  );
};

export default IdleResourceTablePart;

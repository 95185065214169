import React from "react";
import styles from "@/page-components/cost-explorer/cost.explorer.module.css";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import NoDataAvailable from "@/components/States/noDataAvailable";
import tooltipStyles from "@/page-components/idle-resource/idle.module.css";
import creditStyles from "@/page-components/billing-credit/billing-credit.module.css";
import clsx from "clsx";
import CurrencyFormatter from "@/lib/value-converison";

const UsageBarChart = ({ bardata, serviceName }) => {
  const data = (bardata || []).map((item) => ({
    name: item.month_date,
    [serviceName]: item.cost,
  }));

  return (
    <div className={styles.bar_main}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "4px",
          alignItems: "center",
        }}
      >
        <span className={styles.cost_trend}>Costs Trend</span>
        <span className={styles.last_month}>Last 6 Months</span>
      </div>
      {bardata?.length === 0 && (
        <NoDataAvailable
          message="No Data Available for Selected Date Range or Filters"
          addOnMsg="It looks like there is no matching your filter criteria. Please try adjusting your filters to explore other options."
        />
      )}
      {bardata?.length > 0 && (
        <div>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={data}>
              <XAxis
                dataKey="name"
                tickLine={false}
                axisLine={false}
                fontSize={10}
                fontWeight={500}
                stroke="var(--Neutral-D3, #495057)"
              />
              <YAxis
                label={{ fill: "#A3A3A3" }}
                tickLine={false}
                axisLine={false}
                fontSize={10}
                fontWeight={400}
                stroke="var(--Neutral-D3, #495057)"
              />
              <CartesianGrid strokeDasharray="0" stroke="#F0F0F0" />
              <Tooltip
                content={customTooltip}
                cursor={{ fill: "transparent" }}
              />

              <Legend
                align="center"
                verticalAlign="bottom"
                layout="horizontal"
                iconSize={12}
                iconType="Square"
                fontSize={12}
                fontWeight={400}
                fontStyle="normal"
              />
              <Bar dataKey={serviceName} fill="#4A7B9A"barSize={100}/>
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
};

export default UsageBarChart;

const customTooltip = ({ active, payload, label, colorMap, fill }) => {
  if (active && payload && payload.length) {
    return (
      <div className={tooltipStyles.custom_tooltip} style={{ width: "275px" }}>
        <div
          className={tooltipStyles.tooltip_date}
          style={{ paddingLeft: "-4px" }}
        >
          <div className={tooltipStyles.tooltip_date_css}>{label}</div>
        </div>
        <div style={{ width: "92%", paddingLeft: "12px" }}>
          <table style={{ width: "100%", paddingRight: "24px" }}>
            <tbody>
              {payload.map((entry, index) => (
                <tr
                  key={`${tooltipStyles.tooltip_row} ${index}`}
                  className={creditStyles.horizontalline_tool}
                  style={{
                    position: "relative",
                    borderBottom: "1px solid #373737",
                    paddingLeft: "12px",
                  }}
                >
                  <td
                    className={tooltipStyles.cloud_cell}
                    style={{
                      whiteSpace: "nowrap",
                      display: "flex",
                      paddingLeft: "0px",
                    }}
                  >
                    {/* <div
                      className={clsx(
                        creditStyles.tooltipbox,
                        tooltipStyles.cloud_logo
                      )}
                      style={{ background: entry.fill }}
                    ></div> */}
                    <span
                      style={{
                        textOverflow: "ellipsis",
                        width: "115px",
                        textWrap: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {entry.dataKey}
                    </span>
                  </td>
                  <td
                    className={tooltipStyles.cost_cell}
                    style={{ paddingRight: "24px", paddingLeft: "0px" }}
                  >
                    <CurrencyFormatter value={entry?.value || 0} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  return null;
};

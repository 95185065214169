import React, { useContext, useEffect, useMemo } from "react";
import "@/assets/css/customwidget.css";
import { useDimensionValues } from "@/services/costExplorer/getDimensionValues.repo";
import styles from "@/page-components/cost-explorer/components/edit-widget/edit-widget.module.css";
import { FilterSearch } from "@/components/filter-search/filter-search";
import clsx from "clsx";
import includeIcon from "@/assets/img/include.svg";
import excludeIcon from "@/assets/img/exclude.svg";

import { endOfDay, startOfDay, sub, format } from "date-fns";
import { useDimensionValuesCost } from "@/services/cost-anomaly/get-dimension-value.repo";
import { AnomalyContext } from "../cost-anomaly.context";

const FilterItem = ({
  watch,
  setValue,
  errors,
  index,
  dimensionData,
  service,
}) => {
   const context = useContext(AnomalyContext);
  const watchInterval = "days";
  const watchShowPrevious = watch(`showPrevious`);
  const watchIsInclude = watch(`filters.${index}.isInclude`);
  const watchDimension = watch(`filters.${index}.dimension`);
  const watchValues = watch(`filters.${index}.values`);

  useEffect(() => {
    setValue("filter", [{ dimension: "", isInclude: true, values: [] }]);
    setValue("tags", [
      {
        filterOn: "",
        filterType: "include",
        values: [],
      },
    ]);
  }, [setValue]);

  const watchFilters = watch("filters");
  const { fromDate, toDate } = useMemo(() => {
    return {
      toDate: endOfDay(new Date()).toISOString(),
      fromDate: startOfDay(
        sub(new Date(), {
          [watchInterval]: watchShowPrevious,
        })
      ).toISOString(),
    };
  }, [watchInterval, watchShowPrevious]);

  const { data: dimensionValues, isLoading } = useServiceDimensionValues(
    service,
    watchDimension,
    fromDate,
    toDate
  );

  const filteredValues = useMemo(() => {
    if (!watchValues || !Array.isArray(watchValues)) return [];
  
    return watchValues.map((val) => {
      const match = (dimensionValues?.data || []).find((d) => d.id === val || d.name === val);
      return match || { id: val, name: val }; 
    });
  }, [dimensionValues, watchValues]);
  useEffect(() => {
   
    const newId = filteredValues.length > 0 ? filteredValues[0].id : "";
  
 
    if (newId !== context?.selectedID) {
      context?.setSelectedID(newId);
    }
  }, [filteredValues, context]);
  
  


  // const dimensionLabels = useMemo(() => {
  //   switch () {
  //     case "aws":
  //       return AWS_DIMENSIONS;
  //     case "azure":
  //       return AZURE_DIMENSIONS;
  //     case "gcp":
  //       return GCP_DIMENSIONS;
  //     default:
  //       return {};
  //   }
  // }, []);
  const dimensionLabels = useMemo(() => {
    return (dimensionData?.dimensions || [])
      .filter(({ id }) => id !== "tags")
      .map(({ id, name }) => ({
        id,
        name,
      }));
  }, [dimensionData]);

  const selectedFilterDimensions = useMemo(() => {
    return watchFilters.map((e) => e.dimension);
  }, [watchFilters]);

  const filterOptions = useMemo(() => {
    return dimensionLabels.filter(
      ({ id }) => !selectedFilterDimensions.includes(id)
    );
  }, [dimensionLabels, selectedFilterDimensions]);

  return (
    <>
      <div className={styles.filter_select_div}>
        <div className={styles.filter_select_css}>
          <div className="w-100">
            <FilterSearch
              options={filterOptions}
              displayValue="name"
              selectedValues={[watchDimension]}
              searchFieldPlaceholder={"Filter Grouping"}
              onSelect={(selectedItem) => {
                setValue(`filters.${index}.dimension`, selectedItem.id);
                setValue(`filters.${index}.values`, []);
              }}
              toggle={
                <div
                  style={{
                    background: "filter_dimension_toggle",
                    color: "#797B80",
                    backgroundColor:
                      filteredValues.length >= 1 ? "" : "#F8F9FA",
                  }}
                  className={clsx(styles.filter_dimension_toggle, {
                    [styles.filter_dimension_toggle_selected]:
                      !!dimensionLabels.find((d) => d.id === watchDimension),
                  })}
                >
                  {dimensionLabels.find((d) => d.id === watchDimension)?.name ||
                    "Add Filter"}
                </div>
              }
            />
          </div>
        </div>

        <div className="d-block w-100">
          <FilterSearch
            // multiselect
            searchFieldPlaceholder="Filter Service"
            options={(dimensionValues?.data || []).map((e) => ({
              id: e?.id || "null",
              name: e.name || "null",
            }))}
            displayValue="name"
            loading={isLoading}
            selectedValues={filteredValues}
            onSelect={(selectedItem) => {
              setValue(`filters.${index}.values`, [selectedItem.name]);
            }}
            disabled={!watchDimension || !watchDimension.length}
            toggle={
              <div className="hstack gap-1 mt-1">
                {filteredValues.length === 0 && (
                  <span
                    className={clsx(styles.placeholder_text_filter)}
                    style={{ paddingLeft: "8px", color: "#797B80" }}
                  >
                    Choose Values
                  </span>
                )}
                {filteredValues.length >= 1 && (
                  <span className={clsx(styles.chip, "w-100 gap-2 d-flex")}>
                    <span
                      style={{
                        overflow: "hidden",
                        maxWidth: "300px",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                      title={filteredValues.map((e) => e.name)}
                    >
                      {filteredValues
                        .slice(0, 1)
                        .map((e) => e.name || null)
                        .join(", ")}
                    </span>
                    {filteredValues.length > 1 && (
                      <span>+{filteredValues.length - 1}</span>
                    )}
                  </span>
                )}
              </div>
            }
          />
        </div>
        {/* {errors.filters && errors.filters[index].values && (
          <small className="error-message text-left mb-1 mx-4">
            * {errors.filters[index].values.message}
          </small>
        )}
        {errors.filters && errors.filters[index].dimension && (
          <small className="error-message text-left mb-1 mx-4">
            * {errors.filters[index].dimension.message}
          </small>
        )} */}
      </div>
    </>
  );
};

/**
 * @typedef {import('@/services/costExplorer/getDimensionValues.repo').AWS_DIMENSIONS} AWS_DIMENSIONS
 */

/**
 * @typedef {import('@/services/costExplorer/getDimensionValues.repo').AZURE_DIMENSIONS} AZURE_DIMENSIONS
 */

/**
 *
 * @param {'aws' | 'azure' | 'gcp'} service
 * @param {AWS_DIMENSIONS | AZURE_DIMENSIONS} columnName
 * @param {string} fromDate
 * @param {string} toDate
 * @returns
 */
export const useServiceDimensionValues = (
  service,
  columnName,
  fromDate,
  toDate,
) => {
  const { data: values, isLoading } = useDimensionValuesCost({
    service,
    columnName,
    fromDate: format(new Date(fromDate), "dd-MM-yyyy"),
    toDate: format(new Date(toDate), "dd-MM-yyyy"),
  });
  // useEffect(() => {
  //   if (columnName?.length > 0) {
  //     fetchValues();
  //   }
  // }, [columnName?.length, fetchValues, service]);
  return { data: values || [], isLoading };
};

export default FilterItem;

export const API_LOADING_ERROR = "API_LOADING_ERROR";
export const API_LOADING_ERROR_SNAPSHOT = "API_LOADING_ERROR_SNAPSHOT";
export const API_LOADING_ERROR_CUSTOM_WIDGET =
  "API_LOADING_ERROR_CUSTOM_WIDGET";
export const API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET =
  "API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET";
export const API_NOT_FOUND_ERROR_CUSTOM_WIDGET =
  "API_NOT_FOUND_ERROR_CUSTOM_WIDGET";
export const API_NOT_FOUND_ERROR = "API_NOT_FOUND_ERROR";
export const API_NO_DATA_FOUND_ERROR = "API_NO_DATA_FOUND_ERROR";
export const API_NO_DATA_FOUND_FOR_FILTERS_ERROR_CUSTOM_WIDGET =
  "API_NO_DATA_FOUND_FOR_FILTERS_ERROR_CUSTOM_WIDGET";
export const API_NO_DATA_FOUND_FOR_FILTERS_ERROR =
  "API_NO_DATA_FOUND_FOR_FILTERS_ERROR";
export const API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR =
  "API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR";
export const ERROR_STATUS_CODES = [400, 401, 403, 500, 503, 504, 502];
export const API_NO_DATA_FOUND_FOR_QUERY_RESOURCE_FILTERS =
  "API_NO_DATA_FOUND_FOR_QUERY_RESOURCE_FILTERS";
export const API_NO_DATA_FOUND_FOR_QUERY_IDLE_FILTERS =
  "API_NO_DATA_FOUND_FOR_QUERY_IDLE_FILTERS";
export const API_NO_DATA_FOUND_FOR_QUERY_RESOURCE_AND_IDLE_FILTERS =
  "API_NO_DATA_FOUND_FOR_QUERY_RESOURCE_AND_IDLE_FILTERS";

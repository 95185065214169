import React, { useMemo, useContext } from "react";
import AwsRightSizingHeader from "../components/AllPagesHeaders";
import RightSizingTable from "../components/rightChart/RightSizingTable";
import styles from "@/page-components/RightSizing/rightSizing.module.css";
import FilterSection from "@/page-components/RightSizing/components/filter_section/Filter";
import {
  RightSizingContext,
  RightSizingProvider,
} from "../right-sizing.context";
import clsx from "clsx";
import ArchivedRightSizingTable from "../components/rightChart/ArchivedRightSizingTable";
import AllPagesArchivedHeaders from "../components/AllPagesArchivedHeaders";
import { useStatistics } from "@/services/right-sizing/getStatistics.repo";
import AllPageLoading from "@/components/States/AllPageLoading";
import DataLoading from "@/components/States/DataLoading";
import { useArchivedHeaderStatistics } from "@/services/right-sizing/getArchivedHeader.repo";
import DiscardedTable from "../components/rightChart/discardedTable.part";
import downlaodVM from "@/assets/img/downloadVM.svg";
import { useRecommendationsListDownload } from "@/services/right-sizing/getRecommendationListDownload.repo";
import { useDiscardedTableDownload } from "@/services/right-sizing/getDiscardTable.repo";
import { useArchivedRecommendationsListDownload } from "@/services/right-sizing/getArchivedRecommendationListDownload.repo";

const AWSRightSizing = ({ service = "aws" }) => {
  return (
    <RightSizingProvider>
      <AWSRecommendationPage service={service} />
    </RightSizingProvider>
  );
};

const AWSRecommendationPage = ({ service = "aws" }) => {
  const context = useContext(RightSizingContext);
  
  const query = useMemo(() => {
    const tags = (context?.selectedTags || []).filter(
      (e) => e.filterOn.length > 0 && e.values.length > 0
    );
    return {
      service,
      filters: {
        accounts: context?.selectedAccounts?.length
          ? context.selectedAccounts.map((e) => e.id)
          : undefined,
        regions: context?.selectedRegions?.length
          ? context.selectedRegions.map((e) => e.id)
          : undefined,
        tags: tags.length ? tags : undefined,
      },
    };
  }, [
    context.selectedAccounts,
    context.selectedRegions,
    context?.selectedTags,
    service,
  ]);

  const {
    data,
    isLoading,
    isError,
    refetch: refetchStatistics,
  } = useStatistics(query);

  const { data: data2, refetch: refetchArchivedStatistics } =
    useArchivedHeaderStatistics(query);

  // ------------for download------------------//

  const downloadQuery = useMemo(() => {
    return {
      service,
      recommendationType: context?.recommendationType || "downsize",
    };
  }, [context?.recommendationType, service]);

  const downloadMutatediscard = useMemo(() => {
    return {
      service,
    };
  }, [service]);

  const downloadQueryArchive = useMemo(() => {
    return {
      service,
      recommendationType: context?.recommendationType || "downsize",
    };
  }, [context?.recommendationType, service]);

  const { mutate: downloadMutate } =
    useRecommendationsListDownload(downloadQuery);
  const { mutate: downloadMutateDiscard } = useDiscardedTableDownload(
    downloadMutatediscard
  );
  const { mutate: downloadMutateArchive } =
    useArchivedRecommendationsListDownload(downloadQueryArchive);

  const handleDownloadClick = () => {
    downloadMutate(downloadQuery);
  };
  const handleDownloadClickdiscard = () => {
    downloadMutateDiscard(downloadMutatediscard);
  };
  const handleDownloadClickarchive = () => {
    downloadMutateArchive(downloadQueryArchive);
  };

  return (
    <>
      {isLoading && <AllPageLoading />}
      {isError && <DataLoading />}
      {data && (
        <RightSizingContext.Consumer
          children={(v) => (
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12 p-0">
                  {v.viewType === "Active" && (
                    <AwsRightSizingHeader
                      data={data}
                      names="VM Recommendations"
                      savingFor="PROJECTED SAVINGS"
                      tilldate="Saved till date"
                      active="active"
                      error={isError}
                      service="aws"
                    />
                  )}
                  {v.viewType === "Archive" && (
                    <AllPagesArchivedHeaders
                      data={data2}
                      names="Archived VM Recommendations"
                      savingFor="OPPORTUNITY MISSED"
                      tilldate="Recommendations Active"
                      active="archive"
                      error={isError}
                    />
                  )}
                  <div className={styles.tablesFilterDiv}>
                    <div
                      className={clsx(
                        v.viewType === "Active" && styles.tableDiv1,
                        v.viewType === "Archive" && styles.tableDiv
                      )}
                    >
                      <span
                        className={styles.recommendationsLabel}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {v.viewType === "Active" && "VM Recommendations"}
                        {v.viewType === "Archive" &&
                          "Dismissed VM Recommendations"}

                        {v.viewType === "Active" &&
                          (context?.recommendationType === "downsize" ||
                            context?.recommendationType === "upsize") && (
                            <span
                              className={styles.downloadButton}
                              onClick={handleDownloadClick}
                            >
                              <img src={downlaodVM} alt="" />
                            </span>
                          )}
                        {v.viewType === "Archive" &&
                          (context?.recommendationType === "downsize" ||
                            context?.recommendationType === "upsize") && (
                            <span
                              className={styles.downloadButton}
                              onClick={handleDownloadClickarchive}
                            >
                              <img src={downlaodVM} alt="" />
                            </span>
                          )}
                        {v.viewType === "Active" &&
                          context?.recommendationType === "discarded" && (
                            <span
                              className={styles.downloadButton}
                              onClick={handleDownloadClickdiscard}
                            >
                              <img src={downlaodVM} alt="" />
                            </span>
                          )}
                      </span>
                      <p className={styles.recommendationsText}>
                        Explore In-Depth Recommendations for VM Right Sizing and
                        Insights into Resource Utilization. Unlock EconomiQ
                        Potential by Aligning Infrastructure with Actual
                        Workload Demands. Delve into Actionable Data for
                        Informed Decision-Making and Efficient Resource
                        Allocation.
                      </p>
                      <div style={{ display: "flex" }}>
                        {v.viewType === "Active" &&
                          context.recommendationType !== "discarded" && (
                            <RightSizingTable
                              service={service}
                              name="Account Name"
                              resource="Resource ID"
                              refreshHeaders={() => {
                                refetchStatistics();
                                refetchArchivedStatistics();
                              }}
                            />
                          )}
                        {context.recommendationType === "discarded" && (
                          <div className="col-lg-12 p-0">
                            <DiscardedTable
                              service="aws"
                              name="Account Name"
                              resource="ResourceId"
                              refreshHeaders={() => {
                                refetchStatistics();
                                refetchArchivedStatistics();
                              }}
                            />
                          </div>
                        )}
                        {v.viewType === "Archive" && (
                          <ArchivedRightSizingTable
                            service={service}
                            name="Account Name"
                            refreshHeaders={() => {
                              refetchStatistics();
                              refetchArchivedStatistics();
                            }}
                          />
                        )}
                      </div>
                    </div>

                    {v.viewType === "Active" &&
                      context.recommendationType !== "discarded" && (
                        <FilterSection service={service} name="Account Name" />
                      )}
                  </div>
                </div>
              </div>
            </div>
          )}
        />
      )}
    </>
  );
};

export default AWSRightSizing;

import React, { useMemo, useContext, useCallback } from "react";
import { differenceInDays, format, parse } from "date-fns";
import styles from "./savings.module.css";
import clsx from "clsx";
import { subMonths } from "date-fns";
import {
  formatCount,
  ToolTipComponent,
} from "@/page-components/cost-explorer/components/cost-explorer-header.component";
import { useStatisticsHeaderPost } from "@/services/saving/getAzureHeaderV2.repo";
import { SavingContext } from "@/page-components/savings/components/saving-context-file.part";
import SavingCalendar from "./react-calendar/react-calendar";
import HeaderState from "@/components/States/HeaderState";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR_CUSTOM_WIDGET,
  API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET,
  API_NOT_FOUND_ERROR_CUSTOM_WIDGET,
} from "@/lib/errors/error-constants";

const SavingHeader = ({
  service,
  toDate,
  fromDate,
  range,
  setRange,
  data,
  isLoading,
  error,
}) => {
  const date2 = parse(toDate, "yyyy-MM-dd", new Date());
  const date1 = parse(fromDate, "yyyy-MM-dd", new Date());
  const daysDifference = differenceInDays(date2, date1) + 1;
  const formatedDate = subMonths(new Date(), 1);

  const days = daysDifference > 1 ? "days" : "day";

  const monthAbbreviation = format(formatedDate, "LLL");
  const year = format(formatedDate, "yy");
  const previousMonth = `${monthAbbreviation}'${year}`;

  const context = useContext(SavingContext);
  const generateQuery = useCallback(
    (type) => {
      const tags = (context?.selectedTags || []).filter(
        (e) => e.filterOn.length > 0 && e.values.length > 0
      );
      return {
        service,
        type,
        toDate,
        fromDate,
        filters: {
          accounts: context?.selectAccounts?.length
            ? context.selectAccounts.map((e) => e.id)
            : undefined,
          rg: context?.selectRg?.length
            ? context?.selectRg.map((e) => e.id)
            : undefined,
          regions: context?.selectRegions?.length
            ? context.selectRegions.map((e) => e.id)
            : undefined,
          tags: tags.length ? tags : undefined,
        },
      };
    },
    [
      context.selectAccounts,
      context.selectRegions,
      context?.selectRg,
      context?.selectedTags,
      fromDate,
      service,
      toDate,
    ]
  );

  const previousMonthQuery = useMemo(
    () => generateQuery("month"),
    [generateQuery]
  );

  const thisYearQuery = useMemo(() => generateQuery("year"), [generateQuery]);

  const { data: previousData } = useStatisticsHeaderPost(previousMonthQuery);
  const { data: yearData } = useStatisticsHeaderPost(thisYearQuery);
  const formattedTotalSavingCurrent = formatCount(
    data?.data?.total_savings?.cost || 0
  );
  const formattedTotalSavingsData = formatCount(
    previousData?.data?.total_savings?.cost || 0
  );

  const formattedTotalSavingThisYear = formatCount(
    yearData?.data?.total_savings?.cost || 0
  );

  const formattedCurrentIdleSavings = formatCount(
    data?.data?.idle_resources_saving?.cost || 0
  );

  const formattedCurrentRightSizingSavings = formatCount(
    data?.data?.right_sizing_saving?.cost || 0
  );

  const formattedSnapshotSavings = formatCount(
    data?.data?.snapshot_saving?.cost || 0
  );

  const formattedTotalProjectedSavings = formatCount(
    data?.data?.total_projected_savings?.cost || 0
  );

  const formattedSavedTillDate = formatCount(data?.data?.saving_till_date || 0);

  return (
    <>
      <div className={styles.heading}>
        {((!isLoading && data) || error) && (
          <div className={styles.headingh4}>
            <div className={styles.CEDIV} style={{ opacity: "50%" }}>
              Savings
            </div>
            <div className={styles.calmaindiv}>
              <div className={styles.calheading}>Month</div>

              <SavingCalendar range={range} setRange={setRange} />
            </div>
          </div>
        )}
        {isLoading && <HeaderState />}
        {error && (
          <ScenariosErrorState
            error={error.message}
            headerItem="header"
            messageConfig={{
              [API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET]: {
                message: "No Data Available",
                additionalMessage:
                  "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
              },
              [API_LOADING_ERROR_CUSTOM_WIDGET]: {
                message: "Oops!",
                additionalMessage:
                  "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
              },
              [API_NOT_FOUND_ERROR_CUSTOM_WIDGET]: {
                message: "404 Error",
                additionalMessage:
                  "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
              },
            }}
          />
        )}
        {!error && data && (
          <div className={clsx(styles.ComboMainDIv1, "row")}>
            {" "}
            {/* main div of all combo div */}
            {/* -------------------------------------------------------------------------------- */}
            {/* first div of combo div*/}
            <div className=" col-lg-3 col-md-12 col-sm-12 p-0">
              <div className={styles.totalSaving}>
                <div className={styles.totalSavingsBox}>
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      alignItems: "center",
                    }}
                  >
                    <span className={styles.TPComboDiv}>Total Savings</span>
                    <span className={styles.LDaysDiv}>
                      {" "}
                      {/* Last {daysDifference || "No Data"} {days} */}
                      {context?.showSelectDate}
                    </span>
                  </div>

                  <div className={styles.ComboSECDiv}>
                    <div className={styles.id_span_value}>
                      <ToolTipComponent
                        data={data?.data?.total_savings?.cost || 0}
                      >
                        {formattedTotalSavingCurrent}
                      </ToolTipComponent>
                    </div>

                    {/* <div
                    style={{
                      width: "100%",
                      paddingBottom: 7,
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-end",
                      gap: 10,
                      display: "inline-flex",
                    }}
                  >
                    <div className={styles.upto_percentage}>up to 12%</div>
                  </div> */}
                  </div>
                </div>

                <div className={styles.ComboSECONDDiv1}>
                  <div className={styles.ComboSECONDInnerDIv1}>
                    <div className={styles.period_cost1}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "3px",
                        }}
                      >
                        <span className={styles.preceding_header}>
                          Previous Month
                        </span>
                        <span className={styles.preceding_text}>
                          {previousMonth}
                        </span>
                      </div>
                      <span className={styles.preceding_cost}>
                        <ToolTipComponent
                          data={previousData?.data?.total_savings?.cost || 0}
                        >
                          {formattedTotalSavingsData}
                        </ToolTipComponent>
                      </span>
                    </div>
                    <div className={styles.period_cost1}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "3px",
                        }}
                      >
                        <div className={styles.preceding_header}>This Year</div>
                        <div className={styles.preceding_text}>
                          1 Jan - Today
                        </div>
                      </div>
                      <div className={styles.preceding_cost}>
                        <ToolTipComponent
                          data={yearData?.data?.total_savings?.cost || 0}
                        >
                          {formattedTotalSavingThisYear}
                        </ToolTipComponent>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* -----------------------second part-------------------------------------------- */}
            <div className="col-lg-6 col-md-12 col-sm-12 p-0">
              <div className={styles.second_part}>
                <div className={styles.IdleResource_div}>
                  <div className={styles.id_first_div}>
                    <span className={styles.name_part_css}>Idle Resources</span>
                    <div className={styles.value_with_inc}>
                      <span className={styles.id_span_value}>
                        <ToolTipComponent
                          data={data?.data?.idle_resources_saving?.cost || 0}
                        >
                          {formattedCurrentIdleSavings}
                        </ToolTipComponent>
                      </span>
                      {/* <span className={styles.upto_percentage}>up by 18.6%</span> */}
                    </div>
                  </div>
                  <div className={styles.id_second_div}>
                    <div className={styles.acted_upon}>Acted Upon</div>
                    <div className={styles.acted_upon_div}>
                      <span className={styles.id_value_css}>
                        {data?.data?.idle_resources_saving?.count || 0}
                      </span>
                      <span className={styles.idle_res_name}>
                        Idle Resources
                      </span>
                    </div>
                  </div>
                </div>
                {/* -----------------------hide recommendation----------------------------------------- */}
                {/* <div className={styles.IdleResource_div}>
                <div className={styles.id_first_div}>
                  <span className={styles.name_part_css}>Rightsizing</span>
                  <div className={styles.value_with_inc}>
                    <span className={styles.id_span_value}>
                      <ToolTipComponent
                        data={data?.data?.right_sizing_saving?.cost || 0}
                      >
                        {formattedCurrentRightSizingSavings}
                      </ToolTipComponent>
                    </span>
                  </div>
                </div>
                <div className={styles.id_second_div}>
                  <div className={styles.acted_upon}>Acted Upon</div>
                  <div className={styles.acted_upon_div}>
                    <span className={styles.id_value_css}>
                      {data?.data?.right_sizing_saving?.count || 0}
                    </span>
                    <span className={styles.idle_res_name}>
                      Recommendations
                    </span>
                  </div>
                </div>
              </div> */}
                {/* ------------------------------------------------------------------------------------ */}
                <div className={styles.IdleResource_div}>
                  <div className={styles.id_first_div}>
                    <span className={styles.name_part_css}>Snapshots</span>
                    <div className={styles.value_with_inc}>
                      <span className={styles.id_span_value}>
                        <ToolTipComponent
                          data={data?.data?.snapshot_saving?.cost || 0}
                        >
                          {formattedSnapshotSavings}
                        </ToolTipComponent>
                      </span>
                      {/* <span className={styles.upto_percentage}>up by 15.6%</span> */}
                    </div>
                  </div>
                  <div className={styles.id_second_div}>
                    <div className={styles.acted_upon}>Acted Upon</div>
                    <div className={styles.acted_upon_div}>
                      <span className={styles.id_value_css}>
                        {data?.data?.snapshot_saving?.count || 0}
                      </span>
                      <span className={styles.idle_res_name}>Snapshots</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* third div -------------------------------------------------------------------- */}
            <div
              className={clsx(
                "container col-lg-3 col-sm-12",
                styles.second_div_third_container
              )}
            >
              <div
                className={clsx("container-fluid ", styles.innerContainer - 1)}
                style={{ paddingTop: 6 }}
              >
                <div
                  className={clsx("container p-2", styles.innerContainer_div)}
                >
                  <div
                    className={clsx("container", styles.innerContainer_text)}
                  >
                    <div
                      className={clsx(
                        "container",
                        styles.innerContainer_text_1
                      )}
                    >
                      PROJECTED SAVINGS
                    </div>
                    <div
                      className={clsx(
                        "container",
                        styles.innerContainer_text_2
                      )}
                    >
                      <ToolTipComponent
                        data={data?.data?.total_projected_savings?.cost || 0}
                      >
                        {formattedTotalProjectedSavings}
                      </ToolTipComponent>
                    </div>
                  </div>
                </div>
              </div>
              <div className={clsx("container-fluid", styles.innerContainer_2)}>
                <div
                  className={clsx(
                    "container-fluid",
                    styles.innerSecondContainer
                  )}
                >
                  <div
                    className={clsx(
                      "container",
                      styles.innerSecondContainer_text
                    )}
                  >
                    Saved till date
                  </div>
                  <div
                    className={clsx(
                      "container",
                      styles.innerSecondContainer_num
                    )}
                  >
                    <ToolTipComponent data={data?.data?.saving_till_date || 0}>
                      {formattedSavedTillDate}
                    </ToolTipComponent>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SavingHeader;

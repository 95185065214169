import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  CostRecommendationContext,
  CostRecommendationProvider,
} from "../costContext";
import style from "@/page-components/cost-Recommendation/cost-Recommendation.module.css";
import styles from "@/page-components/RightSizing/rightSizing.module.css";
import clsx from "clsx";
import ActiveCostHeader from "@/page-components/cost-Recommendation/component/activeCostHeader";
import ActiveCostTable from "../component/activeCostTable";
import FilterSection from "../component/filter_section/Filter";
import { useCostStatistics } from "@/services/cost-recommendation/getTotalStats.repo";
import downlaodVM from "@/assets/img/downloadVM.svg";
import { useCostRecommendationsListDownlaod } from "@/services/cost-recommendation/getTableDownload";
import {
  Dropdown,
  DropdownButton,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Navigate, useSearchParams } from "react-router-dom";
import AllPageLoading from "@/components/States/AllPageLoading";
import RetainedBodySection from "../component/retainedTableSection";
function AwsCostPage({ service = "aws" }) {
  return (
    <CostRecommendationProvider>
      <AwsCostRecommendation service={service} />
    </CostRecommendationProvider>
  );
}

const AwsCostRecommendation = ({ service = "aws" }) => {
  const context = useContext(CostRecommendationContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedOption, setSelectedOption] = useState(context?.viewType);

  const query = useMemo(() => {
    const tags = (context?.selectedTags || []).filter(
      (e) => e.filterOn.length > 0 && e.values.length > 0
    );
    return {
      service: "aws",
      accounts: context?.selectedAccounts?.length
        ? context.selectedAccounts.map((e) => e.id)
        : undefined,
      regions: context?.selectedRegions?.length
        ? context.selectedRegions.map((e) => e.id)
        : undefined,
      resource_type: context?.selectedResourceType?.length
        ? context.selectedResourceType.map((e) => e.id)
        : undefined,
      tags: tags.length ? tags : undefined,
      status: context?.viewType,
    };
  }, [
    context.selectedAccounts,
    context.selectedRegions,
    context.selectedResourceType,
    context?.selectedTags,
    context?.viewType,
  ]);

  const { data: headerData, isLoading, error,refetch } = useCostStatistics(query);

  const downloadMutate = useMemo(() => {
    const tags = (context?.selectedTags || []).filter(
      (e) => e.filterOn.length > 0 && e.values.length > 0
    );
    return {
      page: 1,
      recordsPerPage: 10,
      orderBy: context?.orderBy,
      desc: searchParams.get("desc") || "true",
      service,
      accounts: context?.selectedAccounts?.length
        ? context.selectedAccounts.map((e) => e.id)
        : undefined,
      // resourceGroupName:
      //   context?.selectedResourceGroup?.length > 0 &&
      //   context?.selectedResourceGroup?.length
      //     ? context.selectedResourceGroup.map((e) => e.id)
      //     : undefined,
      regions: context?.selectedRegions?.length
        ? context.selectedRegions.map((e) => e.id)
        : undefined,
      resource_type: context?.selectedResourceType?.length
        ? context.selectedResourceType.map((e) => e.id)
        : undefined,
      tags: tags.length ? tags : undefined,
    };
  }, [
    context?.selectedTags,
    context?.orderBy,
    context.selectedAccounts,
    context.selectedRegions,
    context.selectedResourceType,
    searchParams,
    service,
  ]);

  const { mutate: downloadMutateDiscard } =
    useCostRecommendationsListDownlaod(downloadMutate);

  const handleDownloadClicked = () => {
    downloadMutateDiscard(query);
  };

  useEffect(() => {
    searchParams.delete("recommendation_id");
  });
    useEffect(() => {
      const eventKey = searchParams.get("eventKey") || "Active";
      setSelectedOption(eventKey);
      // setSelectedOptionStatus(eventKey);
      context?.setViewType(eventKey);
    }, [searchParams, context]);
  
  const handleDropdownChange = (eventKey) => {
    context?.setViewType(eventKey);
    setSelectedOption(eventKey);
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("eventKey", eventKey);
    setSearchParams(newSearchParams);
  };
  const handleTagSelection = () => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("page", "1");
    Navigate(`?${searchParams.toString()}`);
  };
  return (
    <>
      <CostRecommendationContext.Consumer
        children={(v) => (
          <>
            {isLoading && <AllPageLoading />}
            {!isLoading && (
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12 p-0">
                    <div className={clsx(styles.rec_inner_first_container)}>
                      <div
                        className="container-fluid main-container"
                        style={{ padding: "16px 50px 20px 40px" }}
                      >
                        <div
                          className=" Inner_main_container"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="inner_first_container_text">
                            Recommendations
                          </div>
                          <div
                            style={{
                              display: "flex",
                              gap: "8px",
                              alignItems: "center",
                            }}
                          >
                            <DropdownButton
                              id={style.snap_dropdown_header}
                              title={selectedOption}
                              onSelect={handleDropdownChange}
                            >
                              <Dropdown.Item
                                id={style.dropdown_item}
                                as="button"
                                eventKey="Active"
                                // onClick={handleTagSelection}
                              >
                                Active
                              </Dropdown.Item>
                              <Dropdown.Item
                                id={style.dropdown_item}
                                as="button"
                                eventKey="Retained"
                                // onClick={handleTagSelection}
                              >
                                Retained
                              </Dropdown.Item>
                            </DropdownButton>
                          </div>
                        </div>
                        {context?.viewType === "Active" && (
                          <ActiveCostHeader
                            data={headerData}
                            names="Recommendations"
                            savingFor="PROJECTED SAVINGS"
                            tilldate="Saved till date"
                            active="active"
                            error={error}
                            isLoading={isLoading}
                            service="aws"
                            heading="TOTAL RECOMMENDATIONS"
                          />
                        )}
                        {context?.viewType === "Retained" && (
                          <ActiveCostHeader
                            data={headerData}
                            names="Recommendations"
                            savingFor="OPPORTUNITY MISSED"
                            tilldate="Saved till date"
                            active="Retained"
                            error={error}
                            isLoading={isLoading}
                            service="aws"
                            heading="TOTAL RECOMMENDATIONS"
                          />
                        )}
                        <div
                          className={clsx(
                            "container-fluid",
                            styles.Rec_inner_contaier_text
                          )}
                        >
                          Updated Today at 7:05AM
                        </div>
                      </div>
                    </div>

                    {context?.viewType === "Active" && (
                      <ActiveCostTable
                        service="aws"
                        name="Account Name"
                        resource="Resource ID"
                        refetchheader={refetch}
                      />
                    )}
                    {context?.viewType === "Retained" && (
                      <RetainedBodySection
                        service="aws"
                        name="Account Name"
                        resource="Resource ID"
                        refetchheader={refetch}

                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      />
    </>
  );
};

export default AwsCostPage;

import React, { useMemo, useContext, useState, useEffect } from "react";
import { useFilterOptions } from "@/services/right-sizing/filter.repo";
import styles from "@/page-components/RightSizing/rightSizing.module.css";
import {
  FilterSearch,
  FilterSearchToggle,
} from "@/components/filter-search/filter-search";
import { useNavigate } from "react-router-dom";
import { RiutilizationContext } from "../../utilization.context";
import { useRiUtilizations } from "@/services/ri-utilization/get-ri-filtersection.repo";
import FilterState from "@/components/States/FilterState";

const FilterSection = ({ service, name ,HEaderLoading}) => {
  const context = useContext(RiutilizationContext);
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true);
  const selectedAccountIds = (context?.selectedAccounts || []).map((e) => e.id);
  const [Type, setType] = useState("accounts");
  // type = "accounts" | "terms" | "regions"

  const res = useMemo(() => {
    return {
      service,
      type: Type,
    };
  }, [Type, service]);
  const { data: dropdown, isLoading, error } = useRiUtilizations(res);

  return (
    <>
      {HEaderLoading && <FilterState />}
      {!HEaderLoading &&(

        <div className={styles.filter_main_div} style={{ minWidth: 0 }}>
          <Filter
            isLoading={isLoading}
            setType={setType}
            tittle={name}
            data={dropdown?.data}
            filterType="accounts"
            selectedValues={context?.selectedAccounts}
            onSelect={(list) => {
              context?.setSelectedAccounts(list);
            }}
            onRemove={(list) => {
              context?.setSelectedAccounts(list);
            }}
          />

          {/* {service === "azure" && context?.selectedAccounts?.length > 0 && (
            <Filter
              isLoading={isLoading}
              setType={setType}
              tittle={"Resource Group"}
              data={dropdown?.data?.resourceGroupName}
              filterType="resourcegroupname"
              selectedValues={context?.selectedResourceGroup}
              onSelect={(list) => {
                context?.setSelectedResourceGroup(list);
              }}
              onRemove={(list) => {
                context?.setSelectedResourceGroup(list);
              }}
            />
          )} */}

          <Filter
            isLoading={isLoading}
            setType={setType}
            tittle={"Region"}
            data={dropdown?.data}
            filterType="regions"
            selectedValues={context?.selectedRegions}
            onSelect={(list) => {
              context?.setSelectedRegions(list);
            }}
            onRemove={(list) => {
              context?.setSelectedRegions(list);
            }}
          />

          <Filter
            isLoading={isLoading}
            setType={setType}
            tittle={"Term"}
            data={dropdown?.data}
            filterType="terms"
            selectedValues={context?.selectedTerm}
            onSelect={(list) => {
              context?.setSelectedTerm(list);
            }}
            onRemove={(list) => {
              context?.setSelectedTerm(list);
            }}
          />
        </div>
      )}
    </>
  );
};

const Filter = ({
  isLoading,
  setType,
  tittle,
  data,
  setSelectedValues,

  filterType,
  selectedValues,
  onSelect,
  onRemove,
}) => {
  const context = useContext(RiutilizationContext);

  const handleSelect = (selectedList) => {
    if (filterType === "accounts") {
      context.setSelectedAccounts(selectedList);
    }
    if (filterType === "resourcegroupname") {
      context.setSelectedResourceGroup(selectedList);
    }
    if (filterType === "regions") {
      context.setSelectedRegions(selectedList);
    }
    if (filterType === "terms") {
      context.setSelectedTerm(selectedList);
    }
  };

  const handleRemove = (selectedList) => {
    handleSelect(selectedList);
  };

  // const handleTittle = (title) => {
  //   if (title === "Subscription Name" || title === "Account Name") {
  //     title = "Accounts";
  //   }
  //   if (title === "Resource Type") {
  //     title = "resource_type";
  //   }
  //   setType(title?.toLowerCase());
  // };
  const handleTittle = (title) => {
    let type = "";
    if (title === "Accounts") type = "accounts";
    if (title === "Region") type = "regions";
    if (title === "Term") type = "terms";

    if (type) setType(type); // Only set valid types
  };

  const navigate = useNavigate();
  const handleTagSelection = () => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("page", "1");
    navigate(`?${searchParams.toString()}`);
  };

  return (
    <>
      <div className={styles.filter_padding_div}>
        <div className={styles.dimension_div}>
          <div className={styles.dimension_name_css}>{tittle}</div>
          <div
            onClick={() => handleTittle(tittle)}
            className={styles.filter_group_name_css}
          >
            <FilterSearch
              loading={isLoading}
              multiselect={true}
              options={(data || []).map((e) => {
                return {
                  id: e.term || e.accountId || e.region,
                  name: e.termName || e.accountName || e.regionName,
                };
              })}
              selectedValues={selectedValues || []}
              onSelect={(list) => {
                handleTagSelection();
                handleRemove(list);
              }}
              onRemove={(list) => {
                handleRemove(list);
              }}
              displayValue="name"
              placeholder="--Select--"
              toggle={
                <>
                  <FilterSearchToggle placeholder={tittle} />
                </>
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterSection;

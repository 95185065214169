import React from "react";

const CurrencyFormatter = ({ value, hideDollar, fontValue }) => {
  if (value === null || value === undefined) return 0;

  const showNegativeSign = value < 0 && Math.abs(value) >= 0.0001;

  const formattedValue = new Intl.NumberFormat("en-US", {
    style: hideDollar ? "decimal" : "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(Number(Math.abs(value).toFixed(2)));

  return (
    <span
      style={{
        fontWeight: fontValue ? 600 : "",
        fontSize: fontValue ? "13px" : "",
      }}
    >
      {showNegativeSign ? `-${formattedValue}` : formattedValue}
    </span>
  );
};

export default CurrencyFormatter;


import React, { useContext } from "react";
import styles from "@/page-components/idle-resource/idle.module.css";
import Dropdown from "react-bootstrap/Dropdown";
import { IdleContext } from "../idle-context-file.part";
import { useSearchParams } from "react-router-dom";

const ActiveRetainDropdown = () => {
  const context = useContext(IdleContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleDropdownChange = (option) => {
    context?.setSelectedOption(option);

    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("option", option);
    setSearchParams(newSearchParams);
  };
  return (
    <div>
      {context?.showDropdown && (
        <div className={styles.header_dropdown}>
          <Dropdown onSelect={handleDropdownChange}>
            <Dropdown.Toggle
              variant="none"
              id={styles.dropdown_header}
              style={{ color: "white" }}
            >
              {context?.selectedOption}
            </Dropdown.Toggle>

            <Dropdown.Menu className={styles.dropdown_menu}>
              <Dropdown.Item className={styles.dropdown_item} eventKey="Active">
                Active
              </Dropdown.Item>
              <Dropdown.Item
                className={styles.dropdown_item}
                eventKey="Retained"
              >
                Retained
              </Dropdown.Item>
              <Dropdown.Item
                className={styles.dropdown_item}
                eventKey="History"
              >
                History
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
    </div>
  );
};

export default ActiveRetainDropdown;

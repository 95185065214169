/** @type {import('./global')} */
import { useRemoveRecommendationFromArchives } from "@/services/right-sizing/removeRecommendationFromArchives";
import React, { createContext, useState, useEffect } from "react";

// export const RECOMMENDATION_TYPES = {
//   upsize: "upsize",
//   downsize: "downsize",
//   discarded: "discarded",
// };

/**
 * @typedef {import('@/services/right-sizing/removeRecommendationFromArchives').RemoveRecommendationFromArchivesRes} RemoveRecommendationFromArchivesRes
 */

/**
 * @typedef {import('@/services/right-sizing/removeRecommendationFromArchives').RemoveRecommendationFromArchivesReq} RemoveRecommendationFromArchivesReq
 */

/**
 * @typedef Cost_RecommendationType
 * @property {keyof typeof VIEW_TYPES} viewType
 * @property {React.Dispatch<React.SetStateAction<keyof typeof VIEW_TYPES>>} setViewType
 * @property {string} recommendationType
 * @property {React.Dispatch<React.SetStateAction<string>>} setRecommendationType
 * @property {string[]} selectedAccounts
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSelectedAccounts
 * @property {string[]} selectedResourceGroup
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSelectedResourceGroup
 * @property {string[]} selectedRegions
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSelectedRegions
 * @property {string[]} selectedTags
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSelectedTags
 */

/**
 * @type {React.Context<Cost_RecommendationType>}
 */
export const CostRecommendationContext = createContext();

/**
 * @returns {Cost_RecommendationType}
 */
export const useCost_Recommendation = () => {
  const [viewType, setViewType] = useState("Active");

  /** @type {UseState<string[]>} */
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  /** @type {UseState<string[]>} */
  const [showRetainModal, setShowRetainModal] = useState(false);
    /** @type {UseState<string[]>} */
    const [selectedResourceGroup, setSelectedResourceGroup] = useState([]);

  /** @type {UseState<string[]>} */
  const [selectedRegions, setSelectedRegions] = useState([]);

  /** @type {UseState<string[]>} */
  const [selectedImpact, setSelectedImpact] = useState([]);
    /** @type {UseState<string[]>} */
    const [acceptViaJira, setAcceptViaJira] = useState(false);

  /** @type {UseState<string[]>} */
  const [selectedResourceType, setSelectedResourceType] = useState([]);
    /** @type {UseState<string[]>} */
    const [showJiraModal, setShowJiraModal] = useState(false);


    /** @type {UseState<string>} */
  const [orderBy, setOrderBy] = useState("monthly_cost");
    /** @type {UseState<string[]>} */
    const [selectedItems, setSelectedItems] = useState([]);
    console.log("selectedItems",selectedItems)
    const [selectedResourcetype, setSelectedResourcetype] = useState("Active");
    const [selectedJiraticket, setselectedJiraticket] = useState("");
  /** @type {UseState<string[]>} */
  const [selectedTags, setSelectedTags] = useState([
    {
      filterOn: "",
      filterType: 'include',
      values: [],
    }
  ]);

  useEffect(() => {
    setSelectedResourceGroup([]);
}, [selectedAccounts]);

  return {
    acceptViaJira,
    setAcceptViaJira,
    showRetainModal,
    setShowRetainModal,
    showJiraModal,
    setShowJiraModal,
    selectedJiraticket,
    setselectedJiraticket,
    selectedResourcetype,
    setSelectedResourcetype,
    selectedItems,
    setSelectedItems,
    selectedResourceType,
    setSelectedResourceType,
    selectedImpact,
    setSelectedImpact,
    orderBy,
    setOrderBy,
    viewType,
    setViewType,
    selectedAccounts,
    setSelectedAccounts,
    selectedRegions,
    setSelectedRegions,
    selectedTags,
    setSelectedTags,
    selectedResourceGroup,
    setSelectedResourceGroup,
  };
};

export const CostRecommendationProvider = ({ children }) => {
  const context = useCost_Recommendation();
  return (
    <>
      <CostRecommendationContext.Provider value={context}>
        {children}
      </CostRecommendationContext.Provider>
    </>
  );
};

import React, { useContext, useMemo, useState } from "react";
import style from "../anomaly.module.css";
import addWidget from "@/assets/img/addWidget.svg";
import CreateAnomalyForm from "../components/cost-anomaly-form";
import { AnomalyProvider, AnomalyContext } from "../cost-anomaly.context";
import TableSection from "../components/table-section";
import { useAllDimensions } from "@/services/cost-anomaly/get-dimension.repo";
import { useCostANomalyStats } from "@/services/cost-anomaly/total-stats-repo";
import { useSearchParams } from "react-router-dom";
import { useCostANomalyStatsDelete } from "@/services/cost-anomaly/delete-anomaly.repo";
import DeleteConfirmationSection from "../components/delete-confirmation";
import styles from "@/page-components/budgets/components/budgets.module.css";

const AzureAnomalyCost = () => {
  return (
    <AnomalyProvider>
      <AzureCostAnomaly />
    </AnomalyProvider>
  );
};

export default AzureAnomalyCost;

const AzureCostAnomaly = () => {
  const context = useContext(AnomalyContext);
  const service = "azure";

  const handleForm = () => {
    context?.setIsLayoverOpen(true);
  };
  const dimensoinQuery = useMemo(() => {
    return {
      service: service,
    };
  }, []);
  const { data: dimensionData } = useAllDimensions(dimensoinQuery);
  const [searchParams, setSearchParams] = useSearchParams();
  const [confirmationmodal, setConfirmationmodal] = useState(false);
  const [DeleteData, setDeleteData] = useState();
  const page = searchParams.get("page");
  const itemsPerPage = searchParams.get("itemsPerPage");

  const parsedPage = !Number.isNaN(parseInt(page)) ? parseInt(page) : 1;
  const parsedItemsPerPage = !Number.isNaN(parseInt(itemsPerPage))
    ? parseInt(itemsPerPage)
    : 10;

  const query = useMemo(
    () => ({
      service: service,
      page: parsedPage || 1,
      recordsPerPage: parsedItemsPerPage || 10,
    }),
    [parsedItemsPerPage, parsedPage]
  );
  const { data, isLoading, error, refetch } = useCostANomalyStats(query);
  const DeleteQuery = useMemo(
    () => ({
      service: service,
      id: context?.SelectedAnomalyID,
    }),
    [context?.SelectedAnomalyID]
  );

  const {
    data: deleteData,
    isLoading: deleteLoading,
    error: deleteError,
    isSuccess: deleteSuccess,
  } = useCostANomalyStatsDelete(DeleteQuery, [], !!context?.SelectedAnomalyID);

  return (
    <>
      <div className={style.mainPage}>
        <div className={style.headerSectionTextP}>
          <div className={style.headerSection}>
            <span className={style.costText}>Cost Anomalies ({data?.total_count || 0})</span>
            <span className={style.costTextp}>
              Create customized anomaly detection policies to monitor cloud
              spending, detect unusual patterns, and receive real-time alerts.
              Configure thresholds, select services, and automate notifications
              to ensure proactive cost management in your FinOps workflow.
            </span>
          </div>
          <div
            className=" btn add_new_widgets"
            onClick={() => {
              context?.setIsLayoverOpen(true);
            }}
          >
            <img className="add_widgte_svg" src={addWidget} alt=" " />
            <span className="add_widget_text" style={{ width: "92px" }}>
              Create Anomaly
            </span>
          </div>
        </div>
        <div className={style.headerSection} style={{ height: "100vh" }}>
          <TableSection
            service={service}
            data={data}
            isLoading={isLoading}
            error={error}
            refetch={refetch}
            deleteError={deleteError}
            deleteSuccess={deleteSuccess}
            deleteLoading={deleteLoading}
            deleteData={deleteData}
            setConfirmationmodal={setConfirmationmodal}
            setDeleteData={setDeleteData}
          />
        </div>
        <div className={style.headerSection}></div>
      </div>
      <CreateAnomalyForm
        setConfirmationmodal={setConfirmationmodal}
        service={service}
        dimensionData={dimensionData}
        refetch={refetch}
        deleteError={deleteError}
        deleteSuccess={deleteSuccess}
        deleteLoading={deleteLoading}
        deleteData={deleteData}
      />
      {confirmationmodal && (
        <div className={styles.customeOverlay}>
          <div className={styles.conformationBox}>
            <DeleteConfirmationSection
              deleteLoading={deleteLoading}
              setConfirmationmodal={setConfirmationmodal}
              DeleteData={DeleteData}
              name="Anomaly"
            />
          </div>
        </div>
      )}
    </>
  );
};


import React, { useContext } from "react";
import styles from "@/page-components/snapshot/snapshots.module.css";
import crossIcon from "@/assets/img/closecross.svg";
import checked from "@/assets/img/checkedGrey.svg";
import clsx from "clsx";
import Viajira from "@/assets/img/viajira.svg";
import { SnapshotContext } from "../../snap.contex";
import style from "@/components/state-change/state.module.css";


const ModalTable = ({name}) => {
    const context = useContext(SnapshotContext);
  return (
    <div className={styles.snap_table_section} style={{display:"flex",flexDirection:"column",marginTop:"20px"}}>
      <span className={style.selected_resource_table} style={{    borderBottom:"1px solid #E9ECEF",marginBottom:"12px"}}>
        Selected Resources ({context?.selectedItems?.length || 0})
      </span>
            <div
              className={clsx(styles.tableContainer, styles.table_container)}
              style={{ width: "100%", overflowY: "scroll", maxHeight: "350px" }}
            >
              <table
                className="table "
                style={{ margin: "0px", width: "100%" }}
              >
                <thead className={styles.stickyHeader}>
                  <tr
                    style={{
                      background: "#F6F6F6",
                      borderBottom: "1px solid #F6F6F6",
                    }}
                  >
                    <th
                      className={clsx(
                        styles.snap_th_classmodal,
                        styles.textAlignStart
                      )}
                      style={{ textAlign: "start" }}
                    >
                      {name}
                    </th>
                    <th
                      className={clsx(
                        styles.snap_th_classmodal,
                        styles.textAlignStart
                      )}
                      style={{ textAlign: "start" }}
                    >
                      Snapshot Name
                    </th>
                    <th
                      className={clsx(
                        styles.snap_th_classmodal,
                        styles.textAlignend
                      )}
                      style={{ textAlign: "end" }}
                    >
                      Projected Savings
                    </th>
                  </tr>
                </thead>
                <tbody
                  className={styles.scrollableBody}
                  style={{
                    borderRight: "white",
                    borderLeft: "white",
                  }}
                >
                  {(context?.selectedItems || []).map((item, index) => (
                    <tr key={index} className={clsx(styles.for_copy_hover)}>
                      <td
                        className={clsx(
                          styles.snap_table_data__Class,
                          "elipese"
                        )}
                      >
                        {item?.accountName !== null ? item?.accountName : "--"}
                      </td>
                      <td
                        className={clsx(
                          styles.snap_table_data__Class,
                          "elipese"
                        )}
                      >
                        {item?.snapshotName !== null
                          ? item?.snapshotName
                          : "--"}
                      </td>
                      <td
                      style={{color:"#127E24",fontWeight:"600",fontSize:"13px"}}
                        className={clsx(
                          styles.snap_table_data__Class,
                          "elipese",
                          styles?.textAlignend
                        )}
                      >
                        {item?.remaining_saving !== null
                          ? "$" + Number(item?.remaining_saving || 0).toFixed(2)
                          : "--"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
  )
}

export default ModalTable

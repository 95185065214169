import React from "react";
import SnapChartSection from "./snap.chart.part";
import CloudTable from "./cloudTable.part";
import styles from "@/page-components/snapshot/snapshots.module.css";
import clsx from "clsx";

// import FilterSection from '@/components/idle_resource/allCloud_idleResource/AllCloud_Filter_Section/Filter_Section'

const SnapshotBodySection = ({ data, isLoading, error, EnableCloud }) => {
  const services = ["aws", "azure", "GCP"];
  return (
    <div className={styles.body_section}>
      <div className={styles.allCloud_Chart_table_section}>
        <div className={styles.snap_chart_section}>
          <span className={styles.heading_text}>
            Individual Cloud Snapshots Cost
          </span>

          <div className={styles.chart_containers}>
            <>
              {EnableCloud.has("aws") && (
                <div style={{ width: "50%", border: "1px solid #e9ecef" }}>
                  <SnapChartSection service={"aws"} name={"AWS Snapshots"} />
                </div>
              )}
              {EnableCloud.has("azure") && (
                <div style={{ width: "50%", border: "1px solid #e9ecef" }}>
                  <SnapChartSection
                    service={"azure"}
                    name={"Azure Snapshots"}
                  />
                </div>
              )}
              {EnableCloud.has("gcp") && (
                <div style={{ width: "50%", border: "1px solid #e9ecef" }}>
                  <SnapChartSection service={"gcp"} name={"GCP Snapshots"} />
                </div>
              )}
            </>
          </div>
          <div className={styles.snap_table_section}>
            <CloudTable EnableCloud={EnableCloud} data={data} isLoading={isLoading} error={error} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SnapshotBodySection;

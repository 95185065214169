import React, { useContext } from "react";
import { Fragment, useState } from "react";
import { Table } from "react-bootstrap";
import styles from "@/page-components/cost-explorer/cost.explorer.module.css";
import serviceData from "./../cloud.service";
import RemoveIcon from "@/assets/img/filter-delete.svg";
import collapseIcon from "@/assets/img/collapseIcon.svg";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import clsx from "clsx";
import DataAbsense from "@/components/States/DataAbsense";
import NoDataAvailable from "@/components/States/noDataAvailable";
import { FilterContext } from "../../filter-section/filter-section.component";
import CurrencyFormatter from "@/lib/value-converison";

function CollapsibleRow({
  data,
  title,
  account,
  region,
  quantity,
  unit,
  unitPrice,
  cost,
  showRemoveIcon = false,
  defaultIsOpen = false,
  children,
  level = 0,
}) {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);

  const formattedCost =
    cost < 0
      ? `-${new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(Math.abs(cost))}`
      : cost < 0.1
      ? "< $0.1"
      : new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(cost);

  return (
    <Fragment>
      <tr className={styles.collapse_tr}>
        {/* <td onClick={() => setIsOpen(!isOpen)}> */}
        <td
          onClick={() => setIsOpen(!isOpen)}
          style={
            showRemoveIcon
              ? {
                  cursor: "pointer",
                  paddingLeft: level > 0 ? `${level * 24}px` : "8px",
                }
              : { paddingLeft: level > 0 ? `${level * 24}px` : "8px" }
          }
        >
          {showRemoveIcon && (
            <img
              src={isOpen ? RemoveIcon : collapseIcon}
              alt=""
              style={{
                marginRight: "8px",
                transform: "translate(0px, -1px)",
              }}
            />
          )}
          <span>{data}</span>
        </td>
        <td>{account}</td>
        <td>{region}</td>
        <td>
                  {quantity && (
            <ToolTipComponent data={quantity || 0} prefix="">
              {quantity < 0.1 ? (
                "< 0.1"
              ) : (
                <CurrencyFormatter value={quantity} hideDollar={true} />
              )}{" "}
              *{" "}
            </ToolTipComponent>
          )}
          {unitPrice && (
            <ToolTipComponent data={unitPrice || 0}>
              {quantity < 0.1 ? (
                "< 0.1"
              ) : (
                <CurrencyFormatter value={unitPrice} hideDollar={false} />
              )}{" "}
              *{" "}
            </ToolTipComponent>
          )}
          {unit ? " / " + unit : ""}
        </td>

        <td className={styles.side_align}>
          {cost !== undefined && cost !== null && (
            <ToolTipComponent data={cost || 0}>
              {formattedCost}
            </ToolTipComponent>
          )}
        </td>
      </tr>

      {isOpen &&
        children?.map((child, index) => (
          <CollapsibleRow
            key={index}
            data={child.title}
            account={child.account}
            region={child.region}
            quantity={child.quantity}
            unit={child.unit}
            unitPrice={child.unitPrice}
            cost={child.cost}
            showRemoveIcon={child.children?.length > 0}
            level={level + 1}
            children={child.children}
          />
        ))}
    </Fragment>
  );
}

export default function CollapsibleTable({ serviceData }) {
  const filterContext = useContext(FilterContext);

  return (
    <div>
      {serviceData?.length === 0 && (
        <NoDataAvailable
          message="No Data Available for Selected Date Range or Filters"
          addOnMsg="It looks like there is no matching your filter criteria. Please try adjusting your filters to explore other options."
        />
      )}
      {serviceData?.length > 0 && (
        <Table responsive>
          <thead className={styles.collapsibleTableHead}>
            <tr className={styles.tr_head_css}>
              <td className={styles.col_header}>Description</td>
              <td className={styles.col_header}>
                {filterContext?.selectedCloud === "azure"
                  ? "Subscription"
                  : "Account"}
              </td>
              <td className={styles.col_header}>Region</td>
              <td className={styles.col_header}>Usage Quantity</td>
              <td className={clsx(styles.col_header, styles.side_align)}>
                Amount in USD
              </td>
            </tr>
          </thead>
          <tbody>
            {(serviceData || []).map((tableData) => {
              return (
                <Fragment key={tableData?.title}>
                  <tr className={styles.collapse_tr}>
                    <td colSpan={4} className={styles.table_head_css}>
                      {tableData.title}
                    </td>
                    <td
                      className={clsx(styles.table_head_css, styles.side_align)}
                    >
                      <ToolTipComponent data={tableData?.cost || 0}>
                        <CurrencyFormatter value={tableData?.cost || 0} />
                      </ToolTipComponent>
                    </td>
                  </tr>
                  {tableData.children.map((data, index) => (
                    <CollapsibleRow
                      key={index}
                      data={data.title}
                      account={data.account}
                      region={data.region}
                      quantity={data.quantity}
                      unit={data.unit}
                      unitPrice={data.unitPrice}
                      cost={data.cost}
                      showRemoveIcon={data.children?.length > 0}
                      level={0}
                      children={data.children}
                    />
                  ))}
                </Fragment>
              );
            })}
          </tbody>
        </Table>
      )}
    </div>
  );
}

export const ToolTipComponent = ({ data, children, prefix = "$" }) => {
  const isNegative = data < 0;
  const formattedData =
    (isNegative ? "-" : "") +
    prefix +
    new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 8,
      maximumFractionDigits: 8,
    }).format(Math.abs(data) || 0);

  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip id="tooltip" style={{ cursor: "pointer" }}>
          <strong>{formattedData}</strong>
        </Tooltip>
      }
    >
      <span>{children}</span>
    </OverlayTrigger>
  );
};

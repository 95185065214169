import React, { useContext, useMemo, useState } from "react";
import styles from "@/page-components/snapshot/snapshots.module.css";
import clsx from "clsx";
import AWSHeaderSectionUtilization from "../component/header-section.part";
import SnapDropdownHistory from "@/page-components/snapshot/components/historySection/SnapshotDropDown.part";
import TableSection from "../component/table-section.part";
import UtilizationDropdownHistory from "../utilization-dropdown.part";
import { RiUtilizationProvider } from "../utilization.context";
import { useRiTotalStats } from "@/services/ri-utilization/get-total-stats.repo";
import { RiutilizationContext } from "../utilization.context";
import HeaderState from "@/components/States/HeaderState";

const AWSUtilizationSection = () => {
  return (
    <RiUtilizationProvider>
      <AwsUtilization />
    </RiUtilizationProvider>
  );
};

export default AWSUtilizationSection;
const AwsUtilization = () => {
  const context = useContext(RiutilizationContext);
  const [customCalendar, setcustomCalendar] = useState(false);
  const service = "aws";

  const query = useMemo(() => {
    return {
      service: service,
      period: context?.selectMonthRange.toLowerCase(),
      start_date: context?.fromDate,
      end_date: context?.toDate,
      filters: {
        accounts: context?.selectedAccounts?.length
          ? context.selectedAccounts.map((e) => e.id)
          : undefined,
        rg: context?.selectedResourceGroup?.length
          ? context.selectedResourceGroup.map((e) => e.id)
          : undefined,
        regions: context?.selectedRegions?.length
          ? context.selectedRegions.map((e) => e.id)
          : undefined,
        term: context?.selectedTerm?.length
          ? context.selectedTerm.map((e) => e.id)
          : undefined,
      },
    };
  }, [
    context?.selectMonthRange,
    context?.fromDate,
    context?.toDate,
    context.selectedAccounts,
    context.selectedResourceGroup,
    context.selectedRegions,
    context.selectedTerm,
    service,
  ]);

  const {
    data,
    isLoading,
    error,
  } = useRiTotalStats(query);
  return (
    <>
      <div
        className={
          (!isLoading && data) || error ? "container-fluid main-container" : ""
        }
        style={{ paddingBottom: "0px" }}
      >
        <div
          className=" Inner_main_container"
          style={{ paddingBottom: "16px" }}
        >
          {((!isLoading && data) || error) && (
            <div
              className={styles.inner_first_container}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div className={styles.inner_first_container_text}>
                RI Utilization
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                }}
              ></div>
              {!customCalendar && <UtilizationDropdownHistory />}
            </div>
          )}
          {isLoading && <HeaderState />}

          <AWSHeaderSectionUtilization
            service={service}
            data={data}
            error={error}
          />
          {((!isLoading && data) || error) && (
            <div
              className={clsx(
                "contnainer-fluid ",
                styles.inner_third_container
              )}
            >
              {/* {selectedOption == "Active" && <TabsComponent />} */}
              <div
                className={clsx("container-fluid", styles.inner_contaier_text)}
              >
                Updated Today at 7:05AM
              </div>
            </div>
          )}
        </div>
      </div>

      <TableSection service={service} name="Accounts" HEaderLoading = {isLoading} />
    </>
  );
};

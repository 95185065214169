import { AppContext } from "@/App.context";
import config from "@/config";
import { API_LOADING_ERROR, API_LOADING_ERROR_CUSTOM_WIDGET, API_NO_DATA_FOUND_ERROR, API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET, API_NO_DATA_FOUND_FOR_FILTERS_ERROR_CUSTOM_WIDGET, API_NOT_FOUND_ERROR, API_NOT_FOUND_ERROR_CUSTOM_WIDGET, ERROR_STATUS_CODES } from "@/lib/errors/error-constants";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

/**
 * @typedef GetStatistics
 * @property {number | null} cost_recommendations_total
 * @property {number | null} current_cost
 * @property {number | null} projected_cost
 * @property {number | null} projected_savings
 * @property {number | null} saveings_till_data
 * @property {number | null} updated_time_and_Date
 */

/**
 * @typedef GetStatisticsRes
 * @property {GetStatistics} statistics
 * @property {GetIdleFilters} filters
 */

export const getStatistics = (token) => {

  /**
   * @param {import('./getRecommendationsList.repo').GetRecommendationsListReq} req
   * @param {AbortSignal} signal
   * @returns {Promise<GetStatisticsRes>}
   */
  return async (req, signal) => {
    const statusMap = {
      Active: "active",
      "In Progress": "in_progress",
      Retained: "retained",
    };
    const mappedStatus = statusMap[req.status] || req.status;
    const url =
    `${config.apiHost}/v1/${req.service}/cost-recommendations/total-stats` +
    (req.service !== "all-cloud" ? `?status=${mappedStatus}` : "");

    const owners = await fetch(url,
      // `${config.apiHost}/v1/${req.service}/cost-recommendations/total-stats?status=${mappedStatus}`,
      {
        method: "POST",
        body: JSON.stringify({
          accounts: req.accounts || [],
          regions: req.regions || [],
          resource_type:req.resource_type||[],
          impact:req.impact||[]
        }),
        signal,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!owners.ok) {
      if (ERROR_STATUS_CODES.includes(owners.status)) {
        throw new Error(API_LOADING_ERROR_CUSTOM_WIDGET);
      }
      if (owners.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR_CUSTOM_WIDGET);
      }
      throw new Error((await owners.json()).message);
    }
    const res = await owners.json();
    if (Object.keys(res || {}).length === 0) {
      throw new Error(API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET);
    }
    else if(res?.cost_recommendations_total === 0){
      throw new Error(API_NO_DATA_FOUND_FOR_FILTERS_ERROR_CUSTOM_WIDGET);
    }
    return res;
  };
};

/**
 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useCostStatistics = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getStatistics", appContext.idToken, req, ...deps],
    queryFn: ({ signal }) => getStatistics(appContext.idToken)(req, signal),
    retry:false
  });
  return query;
};

import React, { useContext, useEffect, useMemo } from "react";
// import styles from "@/page-components/billing-credit/billing-credit.module.css";
import styles from "@/page-components/budgets/components/budgets.module.css";
import Dropdown from "react-bootstrap/Dropdown";
import { BillingContext } from "@/page-components/billing-credit/billing-context.part";
import { ToolTipComponent } from "@/page-components/cost-explorer/components/cost-explorer-header.component";
import { useStatsHeaderStatistics } from "@/services/billing-credit/getStatisticsHeader.repo";
import clsx from "clsx";
import HeaderState from "@/components/States/HeaderState";
import { formatCount } from "@/page-components/cost-explorer/components/cost-explorer-header.component";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR_CUSTOM_WIDGET,
  API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET,
  API_NOT_FOUND_ERROR_CUSTOM_WIDGET,
} from "@/lib/errors/error-constants";
import zoom_in from "@/assets/img/zoom-in-white.svg";
import { BudgetContext } from "../../budget.context";

const AllCloudHeader = ({ data,isloading,isError }) => {

  const formattedTotalCharge = formatCount(data?.total_budget_amount || 0);
  const formattedBilledAmount = formatCount(
    data?.total_spent_amount?.amount || 0
  );
  const formattedCurrentBalance = formatCount(
    data?.total_forcasted_amount?.amount || 0
  );

  return (
    <>
      <div

        className={styles.main_containers}
      >

          <span className={styles.billing_name}>
            Budgets
          </span>
        {isloading && <HeaderState />}
        {isError && (
          <ScenariosErrorState
            error={isError.message}
            headerItem="header"
            messageConfig={{
              [API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET]: {
                message: "No Data Available",
                additionalMessage:
                  "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
              },
              [API_LOADING_ERROR_CUSTOM_WIDGET]: {
                message: "Oops!",
                additionalMessage:
                  "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
              },
              [API_NOT_FOUND_ERROR_CUSTOM_WIDGET]: {
                message: "404 Error",
                additionalMessage:
                  "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
              },
            }}
          />
        )}
        {!isError && data && (
        <div className={styles.component_css}>
          <div className={styles.data_bars_azure}>
            <span className={styles.billing_charge}>TOTAL BUDGETED AMOUNT</span>

         
            <span
              style={{ cursor: "context-menu" }}
              className={styles.total_charge}
            >
              <ToolTipComponent data={data?.total_budget_amount}>
                {formattedTotalCharge}
              </ToolTipComponent>
            </span>
            <span className={styles.include_budgets}>(Including all Budgets)</span>
          </div>
          {/* ------------------------------- */}
          <div className={styles.billing_component}>
            <div className={styles.billed_box}>
              <span className={styles.billing_charge}>TOTAL SPENT</span>

              <span
                style={{ cursor: "context-menu" }}
                className={styles.total_charge}
              >
                <ToolTipComponent data={data?.total_spent_amount?.amount}>
                  {formattedBilledAmount}
                </ToolTipComponent>
              </span>
              <span className={styles.budget_percentage}>{Number(data?.total_spent_amount?.percentage).toFixed(2) || "--"}% of Budget</span>
            </div>
          </div>
          {/* ---------------------------------------------------- */}

          <div className={styles.third_boxed}>
            <span className={styles.billing_charge}>
              TOTAL FORECASTED AMOUNT{" "}
            </span>

              <span
                style={{ cursor: "context-menu" }}
                className={styles.total_charge}
              >
                <ToolTipComponent data={data?.total_forcasted_amount?.amount}>
                  {formattedCurrentBalance}
                </ToolTipComponent>
              </span>
              <span className={styles.budget_percentage}>{Number(data?.total_forcasted_amount?.percentage).toFixed(2) || "--"}% of Budget</span>
              
            <div>
            </div>
          </div>
        </div>
        )}

        {/* -------------------------------------------------------- */}
        <div className={styles.updated_box}>
          <span className={styles.updated_date}>Updated Today at 7:05AM</span>
        </div>
      </div>
    </>
  );
};

export default AllCloudHeader;

import { AppContext } from "@/App.context";
import config from "@/config";
import {
  API_NO_DATA_FOUND_ERROR,
} from "@/lib/errors/error-constants";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";


export const getStatisticsCostAnomaly = (token) => {
  return async ({ service,id }) => {
    // return dummy
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/cost-anomaly/delete?anomalyId=${id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!owners.ok) {
      throw new Error((await owners.json()).message);
    }
    const res = await owners.json();
    return res;
  };
};

/**
 * @param {GetStatisticsCostAnomalyReq} req
 * @param {any[]=} deps
 */
export const useCostANomalyStatsDelete = (req, deps = [],enabled=true) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getStatisticsCostAnomaly", req, ...deps],
    queryFn: () => getStatisticsCostAnomaly(appContext.idToken)(req),
    retry:false,
    enabled
  });
  return query;
};

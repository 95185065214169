import React, { useMemo, useContext, useEffect } from "react";
import BodySection from "../components/bodysection.part";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import styles from "../snapshots.module.css";
import { useState } from "react";
import AWSHeaderSection from "../components/awsHeader.part";
import { SnapshotProvider } from "../snap.contex";
import AchieveBodySection from "../components/acchieveBodysection.part";
import clsx from "clsx";
import { useHeaderStatistics } from "@/services/snapshots/getAllCloudHeader.repo";
import { SnapshotContext } from "../snap.contex";
import { useNavigate, useSearchParams } from "react-router-dom";
import AllPageLoading from "@/components/States/AllPageLoading";
import HistrotSection from "../components/historySection/historybodySection.part";
import subDays from "date-fns/subDays";
import { format } from "date-fns";
import AWSHeaderSectionHistory from "../components/historySection/awsHeaderHistory.part";
import { useStatisticsHistory } from "@/services/snapshots/getHistoryStats.repo";
import SnapDropdownHistory from "../components/historySection/SnapshotDropDown.part";

const SnapshotPageGCP = ({ service = "gcp" }) => {
  return (
    <SnapshotProvider>
      <GCPSnapshotPage service={service} />
    </SnapshotProvider>
  );
};

const GCPSnapshotPage = ({ service = "gcp" }) => {
  const context = useContext(SnapshotContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedOption, setSelectedOption] = useState("Active");
  const [selectedOptionStatus, setSelectedOptionStatus] = useState(searchParams.get("eventKey") || "Active");
  const [customCalendar, setcustomCalendar] = useState(false);
  // const [loadingchanges, setloadingchanges] = useState(false);
  const [selectedOptionmonth, setSelectedOptionmonth] =
    useState("Last 3 months");
  const [range, setRange] = useState([
    {
      startDate: subDays(new Date(), 6),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  let fromDate = format(range[0].startDate, "yyyy-MM-dd");
  let toDate = format(range[0].endDate, "yyyy-MM-dd");
  const query = useMemo(() => {
    const tags = (context?.selectedSnapshotTags || []).filter(
      (e) => e.filterOn.length > 0 && e.values.length > 0
    );
    const filters = {
      accounts: context?.selectedAccounts?.length
        ? context.selectedAccounts.map((e) => e.id)
        : undefined,
      regions: context?.selectedRegions?.length
        ? context.selectedRegions.map((e) => e.id)
        : undefined,
      diskIds: context?.selectedDisk?.length
        ? context.selectedDisk.map((e) => e.id)
        : undefined,
      snapshotAge: context?.selectedSnapshotAge?.length
        ? context.selectedSnapshotAge.map((e) => e.id)
        : undefined,
      tags: tags.length ? tags : undefined,
      minAge: context?.value?.length ? context?.value[0] : undefined,
      maxAge: context?.value?.length ? context?.value[1] : undefined,
      resourceGroupNames: context?.selectedResourceGroup?.length
        ? context?.selectedResourceGroup.map((e) => e.id)
        : undefined,
    };

    if (context?.showUntaggedOnly) {
      filters.filterUntagged = context.showUntaggedOnly;
    }

    if (context?.showOrphanedOnly) {
      filters.filterOrphaned = context.showOrphanedOnly;
    }
    if (context?.legallyHeldSnapshot) {
      filters.filterLegallyHeld = context?.legallyHeldSnapshot;
    }
    return {
      service,
      status: selectedOptionStatus,
      toDate,
      fromDate,
      filters,
    };
  }, [
    context?.selectedSnapshotTags,
    context.selectedAccounts,
    context.selectedRegions,
    context.selectedDisk,
    context.selectedSnapshotAge,
    context?.value,
    context?.selectedResourceGroup,
    context.showUntaggedOnly,
    context.showOrphanedOnly,
    context?.legallyHeldSnapshot,
    service,
    selectedOptionStatus,
    toDate,
    fromDate,
  ]);

  const Historyquery = useMemo(() => {
    const accounts = context?.selectedAccounts?.length
      ? context.selectedAccounts.map((e) => e.id)
      : undefined;

    const regions = context?.selectedRegions?.length
      ? context.selectedRegions.map((e) => e.id)
      : undefined;

    const diskIds = context?.selectedDisk?.length
      ? context.selectedDisk.map((e) => e.id)
      : undefined;

    return {
      accounts,
      regions,
      diskIds,
      service,
      toDate: context?.toDate,
      fromDate: context?.fromDate,
      period: context?.selectMonthRange.toLowerCase(),
    };
  }, [
    context.selectedAccounts,
    context.selectedRegions,
    context.selectedDisk,
    context?.toDate,
    context?.fromDate,
    context?.selectMonthRange,
    service,
  ]);

  const navigate = useNavigate();

  const handleTagSelection = () => {
    setcustomCalendar(false);
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("page", "1");
    navigate(`?${searchParams.toString()}`);
  };
  const {
    data,
    isLoading,
    error,
    refetch: refetchStatistics,
  } = useHeaderStatistics(query,[],selectedOptionStatus !== "History");

  const {
    data: historyStatsData,
    refetch: refetchHistoryHeader,
    isLoading: historyStatsLoading,
    error: historyStatsError,
  } = useStatisticsHistory(Historyquery,[],selectedOptionStatus === "History");

  useEffect(() => {
    const eventKey = searchParams.get("eventKey") || "Active";
    setSelectedOption(eventKey);
    setSelectedOptionStatus(eventKey);
    context?.setEventkey(eventKey);
  }, [searchParams, context]);

  const handleDropdownChange = (eventKey) => {
    context?.setEventkey(eventKey);
    setSelectedOption(eventKey);
    setSelectedOptionStatus(eventKey);

    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("eventKey", eventKey);
    setSearchParams(newSearchParams);
  };
  const handleDropdownChangeMonth = (eventKey) => {
    setSelectedOptionmonth(eventKey);
  };
  useEffect(() => {
    context?.setSelectedAccounts([]);
    context?.setSelectedResourceGroup([]);
    context?.setSelectedDisk([]);
    context?.setSelectedRegions([]);
  }, [context?.eventkey]);

  return (
    <>
      {(isLoading || historyStatsLoading) && (
        <AllPageLoading />
      )}
      {!(isLoading || historyStatsLoading) && (
        <>
          <div>
            <div
              className="container-fluid main-container"
              style={{ paddingBottom: "0px" }}
            >
              <div
                className=" Inner_main_container"
                style={{ paddingBottom: "16px" }}
              >
                <div
                  className={styles.inner_first_container}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className={styles.inner_first_container_text}>
                    Snapshots
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      alignItems: "center",
                    }}
                  >
                    {selectedOption === "History" && !customCalendar && (
                      <SnapDropdownHistory />
                    )}

                    <DropdownButton
                      id={styles.snap_dropdown_header}
                      title={selectedOption}
                      onSelect={handleDropdownChange}
                    >
                      <Dropdown.Item
                        id={styles.dropdown_item}
                        as="button"
                        eventKey="Active"
                        onClick={handleTagSelection}
                      >
                        Active
                      </Dropdown.Item>
                      <Dropdown.Item
                        id={styles.dropdown_item}
                        as="button"
                        eventKey="Retained"
                        onClick={handleTagSelection}
                      >
                        Retained
                      </Dropdown.Item>
                      <Dropdown.Item
                        id={styles.dropdown_item}
                        as="button"
                        eventKey="History"
                        onClick={handleTagSelection}
                      >
                        History
                      </Dropdown.Item>
                    </DropdownButton>
                  </div>
                </div>
                {selectedOption === "Active" && (
                  <AWSHeaderSection
                    active="active"
                    savingFor="PROJECTED MONTHLY SAVINGS"
                    service={service}
                    data={data}
                    selectedOption={selectedOption}
                    error={error}
                    isLoading={isLoading}
                  />
                )}
                {selectedOption === "Retained" && (
                  <AWSHeaderSection
                    savingFor="OPPORTUNITY MISSED"
                    active="retain"
                    service={service}
                    data={data}
                    selectedOption={selectedOption}
                    error={error}
                    isLoading={isLoading}
                  />
                )}

                {selectedOption === "History" && (
                  <AWSHeaderSectionHistory
                    service={service}
                    selectedOption={selectedOption}
                    data={historyStatsData}
                    error={historyStatsError}
                    isLoading={historyStatsLoading}
                  />
                )}

                <div
                  className={clsx(
                    "contnainer-fluid ",
                    styles.inner_third_container
                  )}
                >
                  {/* {selectedOption == "Active" && <TabsComponent />} */}
                  <div
                    className={clsx(
                      "container-fluid",
                      styles.inner_contaier_text
                    )}
                  >
                    Updated Today at 7:05AM
                  </div>
                </div>
              </div>
            </div>
          </div>
          {selectedOption === "Active" && (
            <BodySection
              showFilterSection={true}
              setSelectedOptionStatus={setSelectedOptionStatus}
              service="gcp"
              name="Project"
              // headerData={data}
              refreshHeaders={() => {
                refetchStatistics();  
                refetchHistoryHeader();
              }}
            />
          )}
          {selectedOption === "Retained" && (
            <AchieveBodySection
              name="Project"
              service="gcp"
              width="100%"
              refreshHeaders={() => {
                refetchHistoryHeader();
                refetchStatistics();
              }}
            />
          )}
          {selectedOption === "History" && (
            <HistrotSection
              showFilterSection={true}
              service="gcp"
              name="Project"
              refreshHeaders={() => {
                refetchStatistics();
                refetchHistoryHeader();
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export default SnapshotPageGCP;

import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import styles from "../../cost.explorer.module.css";
import awsLogo from "@/assets/img/aws_logo.svg";
import azureLogo from "@/assets/img/azurelogo.svg";
import gcpLogo from "@/assets/img/gcp_logo.svg";
import CurrencyFormatter from "@/lib/value-converison";

const LineChartGraph = ({ lineChartData, colorMap }) => {
  const customTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div className="custom-tooltip">
          <div className="tooltip_date">
            <div className="tooltip_date_css">{label}</div>
          </div>

          <table>
            <thead>
              <tr>
                <th>Cloud</th>
                <th style={{ textAlign: "right" }}>Costs</th>
              </tr>
            </thead>
            <tbody>
              {payload.map((entry, index) => (
                <tr
                  key={`tooltip-row-${index}`}
                  className={styles.horizontal_line}
                >
                  <td className={styles.cloud_cell}>
                    {entry.dataKey === "AWS" && (
                      <img src={awsLogo} alt=" " className="cloud-logo" />
                    )}
                    {entry.dataKey === "Azure" && (
                      <img src={azureLogo} alt=" " className="cloud-logo" />
                    )}
                    {entry.dataKey === "GCP" && (
                      <img src={gcpLogo} alt=" " className="cloud-logo" />
                    )}
                    {entry.dataKey}
                  </td>
                  <td className={styles.cost_cell}>
                    <CurrencyFormatter value={entry.value} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }

    return null;
  };
  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart
        data={lineChartData}
        margin={{ top: 0, right: 13, left: -13, bottom: 0 }}
      >
        <CartesianGrid strokeDasharray="0" vertical={false} stroke="#F0F0F0" />
        <XAxis
          dataKey="label"
          tickLine={false}
          axisLine={false}
          fontSize={10}
          fontFamily="Inter"
          fontWeight={400}
          stroke="#A3A3A3"
        />
        <YAxis
          tickLine={false}
          axisLine={false}
          fontSize={10}
          fontFamily="Inter"
          fontWeight={400}
          stroke="#A3A3A3"
        />
        <Tooltip content={customTooltip} cursor={{ fill: "transparent" }} />

        <Legend
          align="center"
          verticalAlign="bottom"
          layout="horizontal"
          iconSize={12}
          iconType="Square"
          wrapperStyle={{}}
          fontSize={12}
          fontWeight={400}
          fontFamily="Inter"
          fontStyle="normal"
        />
        {Object.keys(lineChartData[0] || []).map((key) => {
          if (key === "label") return null;
          return (
            <Line
              type="straight"
              dataKey={key}
              stackId="a"
              stroke={colorMap[key]}
            />
          );
        })}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LineChartGraph;

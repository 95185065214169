import { useContext } from "react";
import config from "../../config";
import { useQuery } from "@tanstack/react-query";
import { AppContext } from "@/App.context";

export const getFilterOptions = (token) => {
  
  return async ({ service, type }) => {
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/ri-utilization/filters?type=${type}`,
      {
        method: "GET",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    if (!owners.ok) {
      throw new Error((await owners.json()).message);
    }
    return await owners.json();
  };
};

/**
 * @param {GetFilterOptionsReq} req
 * @param {any[]=} deps
 */
export const useRiUtilizations = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getFilterOptions", appContext.idToken, req, ...deps],
    queryFn: ({ signal }) => getFilterOptions(appContext.idToken)(req, signal),
  });
  return query;
};

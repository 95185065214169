import { useContext } from "react";
import config from "../../config";
import { useMutation } from "@tanstack/react-query";
import { AppContext } from "@/App.context";

const setCostAnomaly = (token) => {
  return async ({ body, service,type }) => {
    const endpoint = type === "edit" ? "update" : "create";
    const setCostAnomaly = await fetch(
      `${config.apiHost}/v1/${service}/cost-anomaly/${endpoint}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );

    if (!setCostAnomaly.ok) {
      const errorResponse = await setCostAnomaly.json();
      throw new Error(errorResponse.message);
    }
    const data = await setCostAnomaly.json();

    return data;
  };
};

export const useCreateCostANomaly = (type) => {
  const appContext = useContext(AppContext);

  const mutation = useMutation({
    mutationKey: ["setCostAnomaly", type], // Ensure type is used correctly
    mutationFn: (body) => setCostAnomaly(appContext.idToken)({ ...body, type }),
  });

  return mutation;
};

import React, { useMemo } from "react";
import styles from "@/page-components/budgets/components/budgets.module.css";
import { ProgressBar, Card, Spinner } from "react-bootstrap";
import { useSpendingChartSection } from "@/services/budget/getAllCloudSpendingStart.repo";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR,
  API_LOADING_ERROR_CUSTOM_WIDGET,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET,
  API_NOT_FOUND_ERROR,
  API_NOT_FOUND_ERROR_CUSTOM_WIDGET,
} from "@/lib/errors/error-constants";
import VerticalBarGraph from "@/components/States/verticalBarErrorState";

const BudgetSpendingOverview = () => {
    const query = useMemo(()=>{
      const service="all-cloud"
      return{
        service
      }
  
    },[])
  const {
    data: spendingData,
    isLoading,
    error: iserror,
  } = useSpendingChartSection(query);

  // const budgetData = [
  //   { label: "AWS", crossed: 11, within: 15 },
  //   { label: "Azure", crossed: 5, within: 17 },
  // ];

  const budgetData = Object.entries(spendingData?.data || {}).map(
    ([key, value]) => ({
      label: key.toUpperCase() === "AWS" ? "AWS" : "Azure",
      crossed: value.crossed_threshold_count,
      within: value.total_threshold,
    })
  );
  return (
    <div className={styles.spending_box}>
      <span className={styles.spending_name}>Budget Spending Overview</span>
      {isLoading && 
      <div style={{display:"flex",justifyContent:"center",alignItems:"center",height:"100%"}}>

        <Spinner />
      </div>}
      {iserror && (
        <ScenariosErrorState
          error={iserror.message}
          headerItem="header"
          messageConfig={{
            [API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET]: {
              message: "No Data Available",
              additionalMessage:
                "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
            },
            [API_LOADING_ERROR_CUSTOM_WIDGET]: {
              message: "Oops!",
              additionalMessage:
                "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
            },
            [API_NOT_FOUND_ERROR_CUSTOM_WIDGET]: {
              message: "404 Error",
              additionalMessage:
                "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
            },
          }}
        />
      )}
      {!iserror && budgetData && (
        <div>
          {budgetData.map((data, index) => (
            <BudgetProgressBar
              key={index}
              label={data.label}
              crossed={data.crossed}
              within={data.within}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default BudgetSpendingOverview;

const BudgetProgressBar = ({ label, crossed, within }) => {

  const total = within;
  const crossedPercentage = (crossed) * 100;
  const withinPercentage = (total-crossed) * 100;

  return (
    <>
      <div className={styles.progress_box}>
        <span className={styles.service_label}>{label}</span>
        <span className={styles.budget_total}>Total Budgets</span>
        <span className={styles.add_filter_names}>{total}</span>
        <ProgressBar style={{ height: "12px" }}>
          <ProgressBar now={crossedPercentage} variant="danger" key={1} />
          <ProgressBar now={withinPercentage} variant="success" key={2} />
        </ProgressBar>
        <div className={styles.threshold_div}>
          <div className={styles.justified_div}>
            <div className={styles.crossed_item}></div>
            <span className={styles.budget_total}>Crossed Threshold</span>
            <span className={styles.total_threshold}> {crossed}</span>
          </div>
          <div className={styles.justified_div}>
            <div className={styles.within_item}></div>
            <span className={styles.budget_total}>Within Threshold</span>
            <span className={styles.total_threshold}>{total-crossed}</span>
          </div>
        </div>
      </div>
    </>
  );
};

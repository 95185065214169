import copy from "@/assets/img/copy-06 1.svg";
import clsx from "clsx";
import { useState, useMemo, useEffect } from "react";
import Pagination from "@/components/pagination/pagination.component";
import uploadicon from "@/assets/img/uploadicon.svg";
import { useAchiveSnapTableStatistics } from "@/services/snapshots/getArchieveTableStatics.repo";
import Spinner from "react-bootstrap/Spinner";
import { useSearchParams } from "react-router-dom";
import styles from "@/page-components/snapshot/snapshots.module.css";
import TableErrorState from "@/components/States/TableErrorState";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import unarchieve from "@/assets/img/unarchieve.svg";
import { useGetHistory } from "@/services/snapshots/getAcceptDismissHistory.repo";
import ToastComponent from "@/page-components/budgets/components/toast";
import UnarchiveModal from "./state_change/unarchiveModal";
import Tooltip2 from "@/components/tooltip/tooltip";
import DoneIcon from "@/assets/img/doneStateChange.svg";
import { useUpdateAcceptRecommendation } from "@/services/snapshots/addAcceptFields.repo";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import download from "@/assets/img/download.svg";
import menuicon from "@/assets/img/menu.svg";
import { UseSnapshotsDownload } from "@/services/snapshots/getSnapshotDownload.repo";
import DropdownComponent from "@/common/DropdownComponent";
import downlaodVM from "@/assets/img/downloadVM.svg";
import { ToolTipComponent } from "@/page-components/cost-explorer/components/cost-explorer-header.component";
import CurrencyFormatter from "@/lib/value-converison";
import { useUpdateAcceptRecommendationJira } from "@/services/snapshots/addjiraAccept.repo";
import { useSnapTableStatistics } from "@/services/snapshots/getTableStatics.repo";

const AchieveBodySection = ({ width, service, name, refreshHeaders }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page");
  const itemsPerPage = searchParams.get("itemsPerPage");
  const [enableUnarchiveModal, setEnableUnarchiveModal] = useState(false);
  const [copiedResourceId, setCopiedResourceId] = useState(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [showCopyMessage, setShowCopyMessage] = useState(false);
  const [toast, setToast] = useState(false);
  const [enableAcceptModal, setEnableAcceptModal] = useState(false);
  const [resourceId, setResourceId] = useState(null);
  const [message, setMessage] = useState();
  const [savingsId, setSavingsId] = useState(null);
  const [checkedHistory, setCheckedHistory] = useState(null);

  const handleCopyClick = (snapshotId) => {
    const textarea = document.createElement("textarea");
    textarea.value = snapshotId;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    setCopiedResourceId(snapshotId);

    setCopiedResourceId(snapshotId);
    setShowCopyMessage(true);

    setTimeout(() => {
      setShowCopyMessage(false);
    }, 5000);
  };
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  // const {
  //   data: addAcceptDismissSnapshotData,
  //   mutate: addAcceptDismissSnapshot,
  //   error: ActiveDismissError,
  //   isError: checking,
  // } = useAcceptDismissSnapshot();

  const {
    data: updateDatajira,
    mutate: acceptMutatejira,
    isSuccess: jiraAcceptSuccess,
    isError: isAcceptDismissErrorjira,
    error: acceptDismissErrorjira,
    isPending:UnretainPending
  } = useUpdateAcceptRecommendationJira();

  const {
    data: updateData,
    mutate: acceptMutate,
    isError: isErrorArchive,
    error: errorArchive,
  } = useUpdateAcceptRecommendation();

  const queryParam = useMemo(() => {
    return {
      service,
      id: savingsId,
    };
  }, [service, savingsId]);

  const { data: historyData } = useGetHistory(queryParam);

  useEffect(() => {
    if (updateDatajira) {
      refreshHeaders();
      setEnableUnarchiveModal(false);
    }
  }, [updateDatajira, refreshHeaders]);

  // const query = useMemo(() => {
  //   const parsedPage = !Number.isNaN(parseInt(page)) ? parseInt(page) : 1;
  //   const parsedItemsPerPage = !Number.isNaN(parseInt(itemsPerPage))
  //     ? parseInt(itemsPerPage)
  //     : 10;
  //   return {
  //     service,
  //     page: parsedPage || 1,
  //     limit: parsedItemsPerPage || 10,
  //   };
  // }, [itemsPerPage, page, service]);

  // const {
  //   data,
  //   isLoading,
  //   error,
  //   refetch: refetchUnarchiveTable,
  // } = useAchiveSnapTableStatistics(query, [updateData]);

  const query = useMemo(() => {
    const parsedPage = !Number.isNaN(parseInt(page)) ? parseInt(page) : 1;
    const parsedItemsPerPage = !Number.isNaN(parseInt(itemsPerPage))
      ? parseInt(itemsPerPage)
      : 10;
    return {
      service,
      page: parsedPage || 1,
      limit: parsedItemsPerPage || 10,
      status: "retained",
    };
  }, [itemsPerPage, page, service]);

  const {
    data,
    isLoading,
    error,
    refetch: refetchUnarchiveTable,
  } = useSnapTableStatistics(query, [updateDatajira]);

  const handleUnarchiveModal = (resourceid, history) => {
    setEnableUnarchiveModal(Boolean(resourceid));
    setResourceId(resourceid);
    setSavingsId(resourceid);
    setCheckedHistory(history);
  };

  const handleUnarchiveModalClose = () => {
    setEnableUnarchiveModal(false);
  };

  const tooltip = (
    <Tooltip id="tooltip">
      <span style={{ padding: "4px 8px" }}>Unretain</span>
    </Tooltip>
  );

  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredRowIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredRowIndex(null);
  };

  useEffect(() => {
    if (isErrorArchive) {
      setEnableUnarchiveModal(false);
      setMessage({
        message: errorArchive?.message,
        type: "Anomaly",
      });
      setToast(true);
    }
  }, [isErrorArchive, errorArchive, setMessage]);

  const downloadQuery = useMemo(() => {
    const parsedPage = !Number.isNaN(parseInt(page)) ? parseInt(page) : 1;
    const parsedItemsPerPage = !Number.isNaN(parseInt(itemsPerPage))
      ? parseInt(itemsPerPage)
      : 10;
    return {
      service,
      tableName: "download",
      page: parsedPage || 1,
      limit: parsedItemsPerPage || 10,
      status:"retained"
    };
  }, [itemsPerPage, page, service]);
  const { mutate: downloadMutate ,isPending:downLaodLoading} = UseSnapshotsDownload(downloadQuery);

  const handleDownloadClick = () => {
    downloadMutate(downloadQuery);
  };
  const shouldDisableRow = (row) => row.status === "retained_deleted";
  useEffect(()=>{
    if(downLaodLoading){
      document.body.style.cursor="wait"
    }else{
      document.body.style.cursor = "default"
    }
    return () => {
      document.body.style.cursor = 'default';
    };
  
  },[downLaodLoading])
  return (
    <>
      {toast && (
        <ToastComponent
          message={message}
          toast={toast}
          setToast={setToast}
          timeout={5000}
        />
      )}
      {isLoading && 
      <div style={{paddingLeft:"40px",paddingRight:"40px",paddingTop:"30px"}}>
        
        <TableErrorState />
      </div>
      }
      {error && (
        <ScenariosErrorState
          error={error.message}
          messageConfig={{
            [API_NO_DATA_FOUND_FOR_FILTERS_ERROR]: {
              message: "No Data Available for Selected Filters",
              additionalMessage:
                "It looks like there are no data matching your filter criteria. Please try adjusting your filters to explore other options.",
            },
            [API_NO_DATA_FOUND_ERROR]: {
              message: "No Data Available",
              additionalMessage:
                "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
            },
            [API_LOADING_ERROR]: {
              message: "Oops!",
              additionalMessage:
                "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
            },
            [API_NOT_FOUND_ERROR]: {
              message: "404 Error",
              additionalMessage:
                "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
            },
          }}
        />
      )}
      {!isLoading && !error && data && data.snapshots && data.snapshots.length > 0 && (
        <div className={styles.body_section}>
          <div className={styles.Chart_table_section} style={{ width: width , paddingRight:"0px"}}>
            <div className={styles.snap_Heading_section}>
              <div className={styles.justified_space}>
                <span className={styles.heading_text_body}>
                  Retained Snapshots ({data?.total || 0} Snapshots)
                </span>
                <div className={styles.menu_icon_css1} style={{cursor:downLaodLoading?"wait":"pointer"}}>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="tooltip">Download as CSV</Tooltip>}
                  >
                    <span
                      className={styles.downloadButton}
                      onClick={handleDownloadClick}
                    >
                      <img src={downlaodVM} alt="" />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className={styles.snap_table_section}>
              <div
                className={styles.table_container}
                style={{ width: "100%", overflowX: "scroll" }}
              >
                <table
                  className="table table-hover"
                  id="customers"
                  style={{ margin: "0px", width: "100%" }}
                >
                  <thead>
                    <tr
                      style={{
                        background: "#F6F6F6",
                        borderBottom: "1px solid #F6F6F6",
                      }}
                    >
                      <th className={styles.snap_th_class}>{name}</th>
                      <th className={styles.snap_th_class}>Snapshot Name</th>
                      {service === "azure" && (
                        <th className={styles.snap_th_class}>Resource Group</th>
                      )}
                      <th className={styles.snap_th_class}>Snapshot ID</th>
                      <th className={styles.snap_th_class}>Region</th>
                      <th className={styles.snap_th_class}>Incremental</th>
                      <th className={styles.snap_th_class}>Volume ID</th>
                      <th className={styles.snap_th_class}>Disk Size</th>
                      <th
                        className={clsx(
                          styles.snap_th_class,
                          styles.snap_th_class_center
                        )}
                      >
                        Snapshot Age
                      </th>
                      <th
                          className={clsx(
                            styles.snap_th_class,
                            styles.snap_th_class_center
                          )}
                        >
                          Storage Class
                        </th>
                    </tr>
                  </thead>

                  <tbody style={{ borderRight: "white", borderLeft: "white" }}>
                    {isLoading && (
                      <tr>
                        <td colSpan={8}>
                          <div className="d-flex justify-content-center my-3">
                            <Spinner animation="border" role="status">
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </Spinner>
                          </div>
                        </td>
                      </tr>
                    )}
                    {(data?.snapshots || []).map((data, index) => (
                      <tr
                        className={clsx(
                          {
                            [styles.trhovered]: index === hoveredRowIndex,
                            [styles.disabledRow]: shouldDisableRow(data),
                          },
                          styles.for_copy_hover
                        )}
                        key={index}
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={handleMouseLeave}
                        style={
                          shouldDisableRow(data)
                            ? { pointerEvents: 'none', opacity: 0.5, backgroundColor: '#f0f0f0' }
                            : undefined
                        }
                      >
                        <td
                          className={clsx(
                            styles.snap_table_data__Class,
                            "elipese"
                          )}
                          title={data?.accountName}
                        >
                          {data?.accountName || "NA"}
                        </td>
                        <td
                          className={clsx(
                            styles.snap_table_data__Class,
                            "elipese"
                          )}
                          title={data?.snapshotName}
                        >
                          {data?.snapshotName || "--"}
                        </td>
                        {service === "azure" && (
                          <td
                            className={clsx(
                              styles.snap_table_data__Class,
                              "elipese"
                            )}
                            title={data?.resourceGroupName}
                          >
                            {data?.resourceGroupName || "NA"}
                          </td>
                        )}
                        <td
                          className={styles.snap_table_data__Class}
                          title={data?.snapshotId}
                          style={{ marginRight: "8PX" }}
                        >
                          <span
                            style={{
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              maxWidth: "150px",
                              display: "inline-block",
                              lineHeight: "0.9",
                            }}
                          >
                            {data?.snapshotId || "NA"}
                          </span>
                          <span
                            className={`${styles.snap_copy_icon} ${
                              copiedResourceId === data?.snapshotId
                                ? "visible"
                                : ""
                            }`}
                            onClick={() => handleCopyClick(data?.snapshotId)}
                            style={{ marginLeft: "-4px" }}
                          >
                            <img src={copy} alt="" />
                          </span>
                        </td>
                        <td className={styles.snap_table_data__Class}>
                          {data?.region || 0}
                        </td>
                        <td className={styles.snap_table_data__Class}>
                          {data?.incremental === 0 ?"False":"True"}
                        </td>
                        <td
                          className={clsx(
                            styles.snap_table_data__Class,
                            styles.truncate,
                            "elipese"
                          )}
                          title={data?.diskId}
                        >
                          {data?.diskId || "--"}
                        </td>
                        <td className={styles.snap_table_data__Class}>
                          {data?.diskSize || 0}-GB
                        </td>
                        <td
                          className={clsx(
                            styles.snap_table_data__Class,
                            styles.snapshot_col_center
                          )}
                        >
                          {data?.snapshotAge || 0}
                        </td>
                        <td
                            className={clsx(
                              styles.snap_table_data__Class,
                              styles.snapshot_col_center,
                              styles.snapshot_col_weight,
                              // data?.snapshotClass === "ARCHIVE" &&
                              //   styles.snapshot_archived,
                              data?.snapshotClass !== "Unarchive" &&
                                data?.snapshotClass !== null &&
                                styles.snapshot_unarchived
                            )}
                            style={{
                              color:
                                data?.snapshotClass === "ARCHIVE"
                                  ? "#ed9615"
                                  : "#056ee5",
                            }}
                          >
                            {data?.snapshotClass
                              ? String(data.snapshotClass).toUpperCase() ===
                                "ARCHIVE"
                                ? "Archive"
                                : "Standard"
                              : "NA"}
                          </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className={styles.container_secTable}>
                <div
                  className={styles.table_container}
                  style={{ width: "100%" }}
                >
                  <table
                    className="table table-hover"
                    id="customers1"
                    style={{ margin: "0px", width: "100%" }}
                  >
                    <thead>
                      <tr
                        style={{
                          background: "#F6F6F6",
                          borderBottom: "1px solid #F6F6F6",
                        }}
                      >
                        <th
                          className={clsx(
                            styles.snap_th_class,
                            styles.snap_th_class_center
                          )}
                        >
                          Cost
                        </th>
                        <th
                          className={clsx(
                            styles.snapshot_col_end,
                            styles.snap_th_class
                          )}
                        >
                          Projected Monthly Savings
                        </th>
                        <th
                          className={clsx(
                            styles.snapshot_col_end,
                            styles.snap_th_class
                          )}
                        >
                          Status{" "}
                        </th>
                        <th className={styles.snap_th_class}></th>
                      </tr>
                    </thead>
                    <tbody
                      className={styles.snap_table_data__Class}
                      style={{ borderRight: "white", borderLeft: "white" }}
                    >
                      {(data?.snapshots || []).map((data, index) => (
                        <tr
                          className={clsx(
                            {
                              [styles.trhovered]: index === hoveredRowIndex,
                              [styles.disabledRow]: shouldDisableRow(data),
                            },
                            styles.for_copy_hover
                          )}
                          onMouseEnter={() => handleMouseEnter(index)}
                          onMouseLeave={handleMouseLeave}
                          key={index}
                          style={
                            shouldDisableRow(data)
                              ? { pointerEvents: 'none', opacity: 0.5, backgroundColor: '#f0f0f0' }
                              : undefined
                          }
                        >
                          {/* <td
                            className={clsx(
                              styles.snap_table_data__Class,
                              styles.snapshot_col_center,
                              styles.snapshot_col_weight,
                              // data?.snapshotClass === "ARCHIVE" &&
                              //   styles.snapshot_archived,
                              data?.snapshotClass !== "Unarchive" &&
                                data?.snapshotClass !== null &&
                                styles.snapshot_unarchived
                            )}
                            style={{
                              color:
                                data?.snapshotClass === "ARCHIVE"
                                  ? "#ed9615"
                                  : "#056ee5",
                            }}
                          >
                            {data?.snapshotClass
                              ? String(data.snapshotClass).toUpperCase() ===
                                "ARCHIVE"
                                ? "Archive"
                                : "Standard"
                              : "NA"}
                          </td> */}
                          <td
                            className={clsx(
                              styles.snap_table_data__Class,
                              styles.snapshot_col_weight,
                              styles.snapshot_col_end
                            )}
                          >
                            <ToolTipComponent data={data?.snapshotcost || "--"}>
                              {/* {data?.snapshotcost !== null} */}
                              {data?.snapshotcost === null && "--"}
                              {data?.snapshotcost != null &&
                                data?.snapshotcost < 0.1 &&
                                "< $0.1"}
                              {data?.snapshotcost > 0.1 && (
                                <CurrencyFormatter
                                  value={data?.snapshotcost}
                                  hideDollar={false}
                                />
                              )}
                            </ToolTipComponent>

                            {/* <Tooltip2
                              tooltipContent={
                                data?.snapshotcost != null
                                  ? "$" + (data?.snapshotcost || 0).toFixed(8)
                                  : "$0"
                              }
                              overlayProps={{ placement: "top" }}
                            >
                              {(show, setShow) => (
                                <Button
                                  onMouseLeave={() => setShow(false)}
                                  onMouseEnter={() => setShow(true)}
                                  variant="link"
                                  className={clsx(
                                    styles.snap_table_data__Class,
                                    styles.snapshot_col_weight,
                                    styles.snapshot_col_end
                                  )}
                                >
                                  {data?.snapshotcost === null && "$0"}
                                  {data?.snapshotcost != null &&
                                    data?.snapshotcost < 0.1 &&
                                    "< $0.1"}
                                  {data?.snapshotcost > 0.1 &&
                                    "$" + (data?.snapshotcost || 0).toFixed(2)}
                                </Button>
                              )}
                            </Tooltip2> */}
                          </td>
                          <td
                            className={clsx(
                              styles.snap_table_data__Class,
                              styles.snapshot_col_weight,
                              styles.snapshot_col_end
                            )}
                          >
                            <ToolTipComponent data={data?.remaining_saving || "--"}>
                              {/* {data?.remaining_saving !== null} */}
                              {data?.remaining_saving?data?.remaining_saving=== null && "--":"--"}
                              {data?.remaining_saving != null &&
                                data?.remaining_saving < 0.1 &&
                                "< $0.1"}
                              {data?.remaining_saving > 0.1 && (
                                <CurrencyFormatter
                                  value={data?.remaining_saving}
                                  hideDollar={false}
                                />
                              )}
                            </ToolTipComponent>
                          </td>
                          <td
                            className={clsx(
                              styles.snap_table_data__Class,
                              styles.snapshot_col_weight,
                              styles.snapshot_col_end
                            )}
                          >{data?.status !== null ?
                            (
                              <>
                            {data?.status == "retained" && (
                              <span className={styles.badgeName}> Active</span>
                            )}
                            {data?.status == "open" && (
                              <span className={styles.badgeName}> Open</span>
                            )}
                            {data?.status == "accepted" && (
                              <span className={styles.badgeNamePending}>
                                Pending Approval
                              </span>
                            )}
                            {data?.status == "in_progress" && (
                              <span className={styles.badgeNameProgress}>
                                In Progress
                              </span>
                            )}
                            {data?.status == "retained_deleted" && (
                              <span className={styles.badgeNameDone}>
                                {" "}
                                <img src={DoneIcon} alt=""></img> Deleted
                              </span>
                            )}
                              </>
                            ):
                              <span
                                className={styles.badgeName}
                              >
                                --
                              </span>
                            }
                          </td>

                          <td
                            className={clsx(
                              styles.snap_table_data__Class,
                              styles.snapshot_col_weight
                            )}
                          >
                            <OverlayTrigger
                              placement="bottom"
                              overlay={tooltip}
                            >
                              <button
                                className="fgvhjikj"
                                style={{
                                  border: "none",
                                  background: "transparent",
                                }}
                                onClick={() => {
                                  acceptMutatejira({
                                    service,
                                    status: "un_retain",
                                    comment: "",
                                    resources: [
                                      {
                                        snapshotId: data.snapshotId,
                                        snapshotName: data.snapshotName,
                                        accountId: `${data.account}`,
                                        accountName: data.accountName,
                                        snapshotCost: data.snapshotcost,
                                      },
                                    ],
                                  });
                                }}
                                // onClick={() => {
                                //   handleUnarchiveModal(
                                //     data?.snapshotId,
                                //     data?.history
                                //   );
                                // }}
                                onMouseEnter={() => setHoveredIndex(index)}
                                onMouseLeave={() => setHoveredIndex(null)}
                              >
                                <img
                                  src={
                                    hoveredIndex === index
                                      ? unarchieve
                                      : uploadicon
                                  }
                                  alt=""
                                />
                              </button>
                            </OverlayTrigger>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <Pagination totalItems={data?.total || 0} />
          </div>
        </div>
      )}
      {showCopyMessage && (
        <div className={styles.copyMessage}>Copy to Clipboard!</div>
      )}

      <UnarchiveModal
        enable={enableUnarchiveModal}
        disable={handleUnarchiveModalClose}
        mutate={acceptMutate}
        data={updateData}
        refetchTable={refetchUnarchiveTable}
        savingid={savingsId}
        historyData={historyData}
        checkedHistory={checkedHistory}
        isErrorArchive={isErrorArchive}
        name="Snapshot"
        onSubmit={(data) => {
          acceptMutate({
            resourceId: resourceId,
            service,
            status: "unarchive",
            comment: data.comment,
          });
        }}
      />
    </>
  );
};

export default AchieveBodySection;

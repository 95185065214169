import React, { useMemo } from "react";
import AllCloudHeader from "../components/all-cloud-components/all-cloud-header.part";
import AllCloudBudgetBarGraph from "../components/all-cloud-components/all-cloud-budget-bar-graph.part";
import styles from "@/page-components/budgets/components/budgets.module.css";
import BudgetSpendingOverview from "../components/all-cloud-components/budget-spending-all-cloud";
import AllCloudTableBarComponent from "../components/all-cloud-components/all-cloud-table-bar-component";
import { useTotalStats } from "@/services/budget/getAllCloudTotalStats.repo";
import AllPageNoFilterLoading from "@/components/States/AllPageNoFilterLoading";

const AllCloudBudgetPage = () => {
  const query = useMemo(()=>{
    const service="all-cloud"
    return{
      service
    }

  },[])
  const {data:totalStats,isLoading:totalStatsLoading,error:totalStatsError} = useTotalStats(query)
  return (
    <>
    
    {totalStatsLoading && <AllPageNoFilterLoading/>}
    {!totalStatsLoading &&
    
    <div className={styles.all_main_page}>
      <AllCloudHeader data={totalStats?.data} isloading={totalStatsLoading} isError={totalStatsError}/>
      <div className={styles.bar_graph_side}>
        <AllCloudBudgetBarGraph />
        <BudgetSpendingOverview />
      </div>
      <AllCloudTableBarComponent />
    </div>
    
    }

    </>
  );
};

export default AllCloudBudgetPage;

import { AppContext } from "@/App.context";
import config from "@/config";
import { useMutation } from "@tanstack/react-query";
import { useContext } from "react";
import Joi from "joi";

export const AcceptRecommendationJira = (token) => {
  /**
   *
   * @param {UpdateAcceptRecommendationsReq} req
   * @returns {Promise<boolean>}
   */
  return async (req) => {
    const {service, ...rest } = req;
    const addFields = await fetch(
      `${config.apiHost}/v1/${service}/cost-recommendations/update-status`,
      {
        method: "PUT",
        body: JSON.stringify(rest),
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!addFields.ok) {
      throw new Error((await addFields.json()).message);
    }
    return await addFields.json();
  };
};

/**
 * @param {any[]=} deps
 * @param {UpdateAcceptRecommendationsReq} req
 */
export const useUpdateAcceptCost_RecommendationJira = (deps = []) => {
  const appContext = useContext(AppContext);
  const mutation = useMutation({
    mutationKey: ["AcceptRecommendation", appContext.idToken, ...deps],
    mutationFn: AcceptRecommendationJira(appContext.idToken),
  });
  return mutation;
};

import React, { useRef, useMemo } from "react";
import "./bargraph.css";
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { useAllIndividualStatistics } from "@/services/costExplorer/getAllCloudIndividual.repo";
import { ReactComponent as StorageIcon } from "@/assets/img/storage-Icon.svg";
import { ReactComponent as NetworkIcon } from "@/assets/img/Network-Icon.svg";
import { ReactComponent as DatabaseIcon } from "@/assets/img/database.svg";
import { ReactComponent as DotIcon } from "@/assets/img/three-dot-icon.svg";
import { ReactComponent as cpuIcon } from "@/assets/img/CPU-icon.svg";
import styleSheet from "@/page-components/cost-explorer/cost.explorer.module.css";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";
import VerticalBarGraph from "@/components/States/verticalBarErrorState";
import clsx from "clsx";
import CurrencyFormatter from "@/lib/value-converison";
import config from "@/config";

const IndividualCloudCost = ({ toDate, fromDate, daysDifference }) => {
  const query = useMemo(() => {
    return {
      fromDate,
      toDate,
    };
  }, [fromDate, toDate]);

  const { data, error, isLoading } = useAllIndividualStatistics(query);
  const days = daysDifference > 1 ? "days" : "day";

  const cloudData = {
    ...(config.enabledClouds.has("aws") &&
      data?.awsCostStatics && {
        AWS: {
          totalCost: data?.awsCostStatics?.totalCost || 0, // Defaults to 0 if no value
          chartData: [
            {
              name: "Compute",
              uv: Number(data?.awsCostStatics?.Compute) || 0,
              fill: "#E7C067",
              Image: cpuIcon,
            },
            {
              name: "Network",
              uv: Number(data?.awsCostStatics?.Network) || 0,
              fill: "#DF8367",
              Image: NetworkIcon,
            },
            {
              name: "Storage",
              uv: Number(data?.awsCostStatics?.Storage) || 0,
              fill: "#7FBAFF",
              Image: StorageIcon,
            },
            {
              name: "Databases",
              uv: Number(data?.awsCostStatics?.Databases) || 0,
              fill: "#9D626E",
              Image: DatabaseIcon,
            },
            {
              name: "Others",
              uv: Number(data?.awsCostStatics?.Other) || 0,
              fill: "#CED4DA",
              Image: DotIcon,
            },
          ],
        },
      }),

    ...(config.enabledClouds.has("azure") &&
      data?.azureCostStatics && {
        Azure: {
          totalCost: data?.azureCostStatics?.totalCost || 0,
          chartData: [
            {
              name: "Compute",
              uv: Number(data?.azureCostStatics?.Compute) || 0,
              fill: "#E7C067",
              Image: cpuIcon,
            },
            {
              name: "Network",
              uv: Number(data?.azureCostStatics?.Network) || 0,
              fill: "#DF8367",
              Image: NetworkIcon,
            },
            {
              name: "Storage",
              uv: Number(data?.azureCostStatics?.Storage) || 0,
              fill: "#7FBAFF",
              Image: StorageIcon,
            },
            {
              name: "Databases",
              uv: Number(data?.azureCostStatics?.Databases) || 0,
              fill: "#9D626E",
              Image: DatabaseIcon,
            },
            {
              name: "Others",
              uv: Number(data?.azureCostStatics?.Other) || 0,
              fill: "#CED4DA",
              Image: DotIcon,
            },
          ],
        },
      }),

    ...(config.enabledClouds.has("gcp") &&
      data?.gcpCostStatics && {
        GCP: {
          totalCost: data?.gcpCostStatics?.totalCost || 0,
          chartData: [
            {
              name: "Compute",
              uv: Number(data?.gcpCostStatics?.Compute) || 0,
              fill: "#E7C067",
              Image: cpuIcon,
            },
            {
              name: "Network",
              uv: Number(data?.gcpCostStatics?.Network) || 0,
              fill: "#DF8367",
              Image: NetworkIcon,
            },
            {
              name: "Storage",
              uv: Number(data?.gcpCostStatics?.Storage) || 0,
              fill: "#7FBAFF",
              Image: StorageIcon,
            },
            {
              name: "Databases",
              uv: Number(data?.gcpCostStatics?.Databases) || 0,
              fill: "#9D626E",
              Image: DatabaseIcon,
            },
            {
              name: "Others",
              uv: Number(data?.gcpCostStatics?.Other) || 0,
              fill: "#CED4DA",
              Image: DotIcon,
            },
          ],
        },
      }),
  };

  return (
    <>
      {error && (
        <ScenariosErrorState
          error={error.message}
          messageConfig={{
            [API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR]: {
              message: "No Data for the Selected Date Range",
              additionalMessage:
                "The selected date range doesn’t contain any savings data. Try choosing a different range to view available savings.",
            },
            [API_LOADING_ERROR]: {
              message: "Oops!",
              additionalMessage:
                "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
            },
            [API_NOT_FOUND_ERROR]: {
              message: "404 Error",
              additionalMessage:
                "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
            },
            [API_NO_DATA_FOUND_ERROR]: {
              message: "No Data Available",
              additionalMessage:
                "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
            },
          }}
        />
      )}
      {!error && !data && isLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            gap: "16px",
            height: "30vh",
          }}
        >
          {Array.from(config.enabledClouds).map((cloud, index) => (
            <VerticalBarGraph key={index} cloud={cloud} />
          ))}
        </div>
      )}
      {!error && !isLoading && data && (
        <div
          className={clsx("container-fluid")}
          style={{ paddingBottom: "32px" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              gap: "8px",
              alignItems: "start",
              marginBottom: "16px",
            }}
          >
            <div className="all_cloud_text1">Individual Cloud Costs</div>
            <div className="last_days_name1">{daysDifference}</div>
          </div>
          <div className="graph_main_div row">
            {Object.keys(cloudData).map((cloud) => (
              <CloudCostChart
                key={cloud}
                title={cloud}
                totalCost={cloudData[cloud]?.totalCost}
                chartData={cloudData[cloud]?.chartData}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default IndividualCloudCost;

const CloudCostChart = ({ title, totalCost, chartData }) => {
  return (
    <div className="col-sm-6 col-md-4 cost_graph">
      <div className="d-flex justify-content-start align-items-center w-100">
        <div className="aws_costs_div">
          <div className="aws_cost_name">{title} Costs</div>
          <div className="aws_cost_value1">
            <CurrencyFormatter value={totalCost} />
          </div>
        </div>
      </div>
      <div className="row w-100">
        <div className="col-sm-12 p-0">
          <div className="vertical-bar-chart">
            <ResponsiveContainer width="100%" height={195}>
              <BarChart
                data={chartData}
                layout="vertical"
                margin={{ top: 0, right: 0, bottom: 10, left: 0 }}
                stackOffset="sign"
              >
                <Tooltip
                  content={CustomTooltip}
                  cursor={{ fill: "transparent" }}
                />

                <XAxis
                  type="number"
                  axisLine={false}
                  tickLine={false}
                  stroke="#A3A3A3"
                  fontSize={10}
                  fontWeight={400}
                />
                <YAxis
                  dataKey="name"
                  type="category"
                  axisLine={false}
                  tickLine={false}
                  width={100}
                  fontSize={10}
                  fontWeight={400}
                />
                <CartesianGrid strokeDasharray="0" stroke="#F0F0F0" />
                <Bar dataKey="uv" fill="#CF9FFF" barSize={20} />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload?.length) {
    return (
      <div className={styleSheet.customize_tool}>
        <div
          className={styleSheet.tooltip_header}
          style={{ paddingLeft: "8px", opacity:"0.7" }}
        >
          {payload.map((entry, index) => (
            <span key={index} style={{ color: "#fff" }}>
              {entry.payload?.Image && (
                <entry.payload.Image className={styleSheet.cpuIcon} />
              )}
            </span>
          ))}

          <div
            className={styleSheet.tooltip_date_css}
            style={{ color: "#fff" }}
          >
            {label}
          </div>
        </div>

        <div style={{ padding: "8px 12px", width: "100%" }}>
          {payload.map((entry, index) => (
            <div
              key={`${styleSheet.tooltip_row} ${index}`}
              className={styleSheet.tolltip_cost}
            >
              <span className={styleSheet.tooltip_cost} style={{opacity:"0.7"}}>Cost</span>
              <span className={styleSheet.cost_cell}>
                <CurrencyFormatter value={entry?.value || 0} />
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return null;
};

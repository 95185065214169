import { AppContext } from "@/App.context";
import config from "@/config";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { DownloadFile } from "@/lib/download-file";

export const getAnomalyData = (token) => {

  return async (
    {service},
    signal
  ) => {
    const recommendationsList = await fetch(
      `${config.apiHost}/v1/${service}/cost-anomaly/download`,
      {
        method: "POST",
        body: JSON.stringify({
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        signal,
      }
    );
    if (!recommendationsList.ok) {
      throw new Error((await recommendationsList.json()).message);
    }
    const reader = recommendationsList.body.getReader();
    const stream = new ReadableStream({
      start(controller) {
        function push() {
          reader.read().then(({ done, value }) => {
            if (done) {
              controller.close();
              return;
            }
            controller.enqueue(value);
            push();
          });
        }
        push();
      },
    });
    const blob = await new Response(stream).blob();
    const fileName = `${service}-Anomaly-Stats.csv`;
    DownloadFile(blob, fileName);
  };
};
export const useAnomalyDataDownlaod = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useMutation({
    mutationKey: ["getAnomalyData", req, ...deps],
    mutationFn: ({ signal }) =>
        getAnomalyData(appContext.idToken)(req, signal),
  });
  return query;
};

import React from "react";
import { Placeholder, Row, Col, Stack, Table } from "react-bootstrap";
import styles from "./states.module.css";
import clsx from "clsx";

export const SemiPieChartSkeleton = () => {
  return (
    <div style={{ paddingLeft: "20px", paddingBottom:"40px" }}>
      <Row>
        <Placeholder
          className={styles.placeholder_css}
          xs={5}
          style={{ height: "25px", marginBottom: "20px" }}
        />
      </Row>
      <div
        className="container-fluid row p-0 pt-0 gap-3"
        style={{ marginLeft: "2px" }}
      >
        <div className="col-md-12 d-flex gap-3 pt-2" style={{ width: "100%" }}>
          <SemiPieChartLoading />
          <SemiPieChartLoading />
        </div>

        <div
          className={clsx(
            "col-md-12 d-flex gap-3 pt-0",
            styles.adding_margin_bottom
          )}
        >
          <SemiPieChartLoading />
          <SemiPieChartLoading />
        </div>
      </div>
    </div>
  );
};

const SemiPieChartLoading = () => {
  return (
    <div
      style={{
        border: "1px solid var(--neutral-d-8, #e9ecef)",
        width: "100%",
        padding: "12px 4px 0px 20px",
        display: "flex",
        borderRadius: "8px",
      }}
    >
      <Col style={{ width: "100%" }}>
        <Row>
          <Placeholder
            className={styles.placeholder_css}
            xs={6}
            style={{ height: "25px", marginBottom: "12px" }}
          />
        </Row>
        <Row style={{marginTop:"16px"}}>
          <div className="col-sm-12 col-md-12" style={{ paddingBottom: "0px" }}>
            <div
              style={{
                width: "200px",
                height: "100px",
                position: "relative",
                overflow: "hidden",
              }}
            >
              {/* Outer semi-circle */}
              <div
                style={{
                  position: "absolute",
                  top: "60%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  height: "120px",
                  width: "200px",
                  borderRadius: "150px 150px 0 0",
                  backgroundColor: " rgba(0, 0, 0, 0.1)",
                  opacity: 0.5,
                }}
              ></div>

              {/* Inner cutout (hollow effect) */}
              <div
                style={{
                  width: "98px",
                  height: "70px",
                  borderRadius: "100px 100px 0 0",
                  backgroundColor: "white",
                  position: "absolute",
                  top: "46px",
                  left: "50px",
                }}
              ></div>
            </div>
          </div>{" "}
        </Row>
      </Col>
      <Col style={{marginTop:"20px"}}>
        <Table style={{ borderBottom: "1px solid #fff" }}>
          <thead>
            <tr></tr>
          </thead>
          <tbody>
            {[...Array(4)].map((_, rowIndex) => (
              <tr key={rowIndex}>
                <td>
                  <Placeholder
                    style={{ width: "100%", height: 20 }}
                    className={styles.placeholder_css}
                  />
                </td>
                <td style={{ textAlign: "right" }}>
                  <Placeholder
                    style={{ width: "20%", height: 20 }}
                    className={styles.placeholder_css}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Col>
      {/* </Col> */}
    </div>
  );
};

export default SemiPieChartSkeleton;

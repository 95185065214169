import IP from "@/assets/img/IP.svg";
import vms from "@/assets/img/vms.svg";
import storage from "@/assets/img/storage.svg";
import loadbalancer from "@/assets/img/loadBalancer.svg";
import Dropdown from "react-bootstrap/Dropdown";
import styles from "@/page-components/idle-resource/idle.module.css";
import clsx from "clsx";
import { ToolTipComponent } from "@/page-components/cost-explorer/components/cost-explorer-header.component";
import { formatCount } from "@/page-components/cost-explorer/components/cost-explorer-header.component";
import TabsComponent from "@/page-components/idle-resource/components/idle-resource-tabs.part";
import { SnapshotProvider } from "@/page-components/snapshot/snap.contex";
import { useContext, useMemo } from "react";
import { IdleContext } from "@/page-components/idle-resource/idle-context-file.part";
import IdleDropdownHistory from "./idle-dropdown-history.part";
import { useHistoryStatistics } from "@/services/IdleResources/getHistoryTotalStats.repo";
import HeaderState from "@/components/States/HeaderState";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR_CUSTOM_WIDGET,
  API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET,
  API_NOT_FOUND_ERROR_CUSTOM_WIDGET,
} from "@/lib/errors/error-constants";

export const IdleHistoryHeader = ({
  service,
  showDropdown,
  data,
  ipName,
  diskName,
  vmName,
}) => {
  return (
    <SnapshotProvider>
      <IdleResourceHeaderComp
        service={service}
        showDropdown={showDropdown}
        data={data}
        ipName={ipName}
        vmName={vmName}
        diskName={diskName}
      />
    </SnapshotProvider>
  );
};

const IdleResourceHeaderComp = ({
  service,
  showDropdown,
  ipName,
  vmName,
  diskName,
}) => {
  const context = useContext(IdleContext);

  const query = useMemo(() => {
    return {
      service,
      period: context?.selectMonthRange.toLowerCase(),
      start_date: context?.fromDate,
      end_date: context?.toDate,
      filters: {
        accounts: context?.selectAccounts?.length
          ? context.selectAccounts.map((e) => e.id)
          : undefined,
        rg: context?.selectedResourceGroup?.length
          ? context.selectedResourceGroup.map((e) => e.id)
          : undefined,
        regions: context?.selectRegions?.length
          ? context.selectRegions.map((e) => e.id)
          : undefined,
      },
    };
  }, [
    context?.fromDate,
    context.selectAccounts,
    context?.selectMonthRange,
    context.selectRegions,
    context.selectedResourceGroup,
    context?.toDate,
    service,
  ]);

  const {
    data,
    isLoading,
    error,
    refetch: refreshHeaders,
  } = useHistoryStatistics(query);

  const formattedSavings = formatCount(
    data?.data?.data?.idle_resource_total?.saved_on_deletion || 0
  );
  return (
    <>
      <div
        className={clsx(
          "container-fluid",
          (!isLoading && data) || error ? styles.main_container : ""
        )}
      >
        <div className={styles.Inner_main_container}>
          {((!isLoading && data) || error) && (
            <div className={styles.inner_first_container}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className={styles.inner_first_container_text}>
                  Idle Resources History
                </div>
                <IdleDropdownHistory/>
              </div>
            </div>
          )}
          {isLoading && <HeaderState />}
          {error && (
            <ScenariosErrorState
              error={error.message}
              headerItem="header"
              messageConfig={{
                [API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET]: {
                  message: "No Data Available",
                  additionalMessage:
                    "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
                },
                [API_LOADING_ERROR_CUSTOM_WIDGET]: {
                  message: "Oops!",
                  additionalMessage:
                    "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
                },
                [API_NOT_FOUND_ERROR_CUSTOM_WIDGET]: {
                  message: "404 Error",
                  additionalMessage:
                    "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
                },
              }}
            />
          )}
          {/* --------------------------------------header------------------------------------------------- */}
          {!error && data && (
            <div
              className={clsx("container-fluid", styles.inner_second_container)}
            >
              <div className="row" style={{ margin: "0px" }}>
                <div
                  className={clsx(
                    "container col-lg-3 col-sm-12",
                    styles.second_div_first_container
                  )}
                >
                  <div className="row">
                    <div
                      className={clsx(
                        styles.second_div_first_container_firstDiv,
                        " col-md-6"
                      )}
                    >
                      <div
                        className={clsx(
                          "container",
                          styles.ideal_resource_count
                        )}
                      >
                        IDLE RESOURCE DELETED
                      </div>

                      <div
                        className={clsx("container", styles.number_container)}
                      >
                        {data?.data?.data?.idle_resource_total
                          ?.idle_resource_count || 0}
                      </div>
                    </div>
                  </div>
                </div>
                {/* ---------------------------------------second---------------------------------------- */}
                <div
                  className={clsx(
                    "container col-lg-6 col-sm-12",
                    styles.second_div_second_container
                  )}
                >
                  <div
                    className={clsx(
                      "container-fluid",
                      styles.second_div_second_main_container
                    )}
                  >
                    <div className={clsx("container", styles.text_container)}>
                      IDLE RESOURCE DELETED{" "}
                    </div>
                    <div
                      className={clsx(
                        "container-fluid row",
                        styles.content_container
                      )}
                      style={{ margin: "0px" }}
                    >
                      <div className="row">
                        <div
                          className={clsx(
                            "container",
                            styles.content_container_box
                          )}
                        >
                          <div
                            className={clsx(
                              "container",
                              styles.content_container_check
                            )}
                          >
                            <div className={styles.content}>
                              <div
                                className={clsx(
                                  "container",
                                  styles.upperContent
                                )}
                              >
                                <img src={IP} alt="" />
                                <div
                                  className={clsx(
                                    "container",
                                    styles.upperContent_text
                                  )}
                                >
                                  {ipName}
                                </div>
                              </div>
                              <div className={styles.css_for_gap}>
                                <div
                                  className={clsx(
                                    "container",
                                    styles.number_container1
                                  )}
                                >
                                  {data?.data?.data?.idle_resources?.ip
                                    ?.count || "--"}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className={clsx(
                              "container",
                              styles.content_container_check
                            )}
                          >
                            <div className={styles.content}>
                              <div
                                className={clsx(
                                  "container",
                                  styles.upperContent
                                )}
                              >
                                <img src={vms} alt="" />
                                <div
                                  className={clsx(
                                    "container",
                                    styles.upperContent_text
                                  )}
                                >
                                  {vmName}
                                </div>
                              </div>
                              <div className={styles.css_for_gap}>
                                <div
                                  className={clsx(
                                    "container",
                                    styles.number_container1
                                  )}
                                >
                                  {data?.data?.data?.idle_resources?.vm
                                    ?.count || "--"}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={clsx(
                            "container",
                            styles.content_container_box
                          )}
                        >
                          <div
                            className={clsx(
                              "container",
                              styles.content_container_check
                            )}
                          >
                            <div className={styles.content}>
                              <div
                                className={clsx(
                                  "container",
                                  styles.upperContent
                                )}
                              >
                                <img src={storage} alt="" />
                                <div
                                  className={clsx(
                                    "container",
                                    styles.upperContent_text
                                  )}
                                >
                                  {diskName}
                                </div>
                              </div>
                              <div className={styles.css_for_gap}>
                                <div
                                  className={clsx(
                                    "container",
                                    styles.number_container1
                                  )}
                                >
                                  {data?.data?.data?.idle_resources?.disk
                                    ?.count || "--"}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className={clsx(
                              "container",
                              styles.content_container_check
                            )}
                          >
                            <div className={styles.content}>
                              <div
                                className={clsx(
                                  "container",
                                  styles.upperContent
                                )}
                              >
                                <img src={loadbalancer} alt="" />
                                <div
                                  className={clsx(
                                    "container",
                                    styles.upperContent_text
                                  )}
                                >
                                  Load Balancer
                                </div>
                              </div>

                              <div className={styles.css_for_gap}>
                                <div
                                  className={clsx(
                                    "container",
                                    styles.number_container1
                                  )}
                                >
                                  {data?.data?.data?.idle_resources?.lb
                                    ?.count || "--"}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* -----------------------third box------------------------------------------ */}
                <div
                  className={clsx(
                    "container col-lg-3 col-sm-12",
                    styles.second_div_third_container
                  )}
                >
                  <div
                    className={clsx(
                      "container-fluid pt-2",
                      styles.innerContainer - 1
                    )}
                  >
                    <div
                      className={clsx(
                        "container p-2",
                        styles.innerContainer_div
                      )}
                    >
                      <div
                        className={clsx(
                          "container",
                          styles.innerContainer_text
                        )}
                      >
                        <div
                          className={clsx(
                            "container",
                            styles.innerContainer_text_1
                          )}
                        >
                          SAVED ON DELETION
                        </div>
                        <div
                          className={clsx(
                            "container",
                            styles.innerContainer_text_2
                          )}
                        >
                          <ToolTipComponent
                            data={
                              data?.data?.data?.idle_resource_total
                                ?.saved_on_deletion || 0
                            }
                          >
                            {formattedSavings}
                          </ToolTipComponent>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* ---------------------------------------------------------------- */}
          {((!isLoading && data) || error) && (

          <div className={styles.inner_third_container}>
            {/* {service !== "all-cloud" && <TabsComponent />} */}
            <div
              className={clsx("container-fluid", styles.inner_contaier_text)}
              style={{ paddingBottom: "16px" }}
            >
              Updated Today at 7:05AM
            </div>
          </div>
          )}
        </div>
      </div>
    </>
  );
};

export default IdleHistoryHeader;

import { AppContext } from "@/App.context";
import config from "@/config";
import {
  API_LOADING_ERROR,
  API_NOT_FOUND_ERROR,
  ERROR_STATUS_CODES,
} from "@/lib/errors/error-constants";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";


/**
 * @typedef User
 * @property {string} createdAt
 * @property {string} creatorName
 * @property {string} email
 * @property {string} name
 * @property {string} roles
 * @property {string} description
 * @property {number} id
 */

/**
 * @typedef UserProfile
 * @property {string} name
 * @property {string} email
 * @property {string} department
 * @property {string} country
 * @property {string} jobTitle
 * @property {string} preferredLanguage
 * @property {string} ipAddress
 * @property {string} lastLogin
 * @property {boolean} accountEnabled
 */

/**
 * @typedef GetDetailsRes
 * @property {User[]} user
 * @property {UserProfile[]} userProfile
 */

/**
 * @param {*} token
 */

export const getDetails = (token) => {
  /**
   * @param {number} id
   * @param {AbortSignal} signal
   * @returns {Promise<GetDetailsRes>}
   */

  return async (id, signal) => {
    const getDetails = await fetch(
      `${config.apiHost}/v1/users/${id}/get-user-profile`,
      {
        method: "GET",
        signal,
        headers: {
          Authorization: token,
        },
      }
    );
    if (!getDetails.ok) {
      if (ERROR_STATUS_CODES.includes(getDetails.status)) {
        throw new Error(API_LOADING_ERROR);
      }
      if (getDetails.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR);
      }
      throw new Error((await getDetails.json()).message);
    }
    return await getDetails.json();
  };
};

export const useUserProfileDetails = (id, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    enabled: Boolean(id),
    queryKey: ["getDetails", id, appContext.idToken, ...deps],
    queryFn: ({ signal }) => getDetails(appContext.idToken)(id, signal),
  });
  return query;
};

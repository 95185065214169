import React, { useMemo, useRef } from "react";
import styles from "@/page-components/budgets/components/budgets.module.css";
import IndividualVertcalGraph from "./all-cloud-individual-graph.part";
import { useTableSection } from "@/services/budget/getAllCloudTable.repo";
import TableAndGraph from "@/components/States/TableAndGraph";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import downlaodVM from "@/assets/img/downloadVM.svg";

import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import HorizontalBarGraphwithTable from "@/components/States/horiZontalGraphwithTableState";
import { CSVLink } from "react-csv";

const AllCloudGraphAndTable = () => {
  const query = useMemo(() => {
    const service = "all-cloud";
    return {
      service,
    };
  }, []);
  const { data, isLoading, error } = useTableSection(query);
  const colorMap = {
    aws: "#82B3AF",
    azure: "#4A7B9A",
  };
  const csvLinkRef = useRef(null);
  const csvHeaders = [
    { label: "Budget Name", key: "budget_name" },
    { label: "Actual Spent", key: "actual_spent" },
    { label: "Total Budget", key: "total_budget" },
    { label: "Percentage Spent", key: "spent_percentage" },
    { label: "Forecasted Amount", key: "forcasted_amount" },
    { label: "Period", key: "period" },
  ];
  const graphData = useMemo(() => {
    if (!data || typeof data !== "object") return [];
    return Object.entries(data?.data || []).map(([cloud, cloudData]) => ({
      serviceType: cloud,
      data1: Array.isArray(cloudData)
        ? cloudData.map((item) => ({
            name: item.budget_name,
            actualSpent: item.actual_spent,
            totalBudget: item.total_budget,
            percentSpent: item.spent_percentage,
            forecastedAmount: item.forcasted_amount,
            period: item.period,
            uv: Math.round(item.total_budget / 1000),
          }))
        : [],
      color: colorMap[cloud] || "#A3A3A3",
    }));
  }, [data]);

  const handleDownloadCsv = () => {
    csvLinkRef.current.link.click();
  };
  const transformedGraphData = useMemo(() => {
    if (!data || !data.data) return [];
    return Object.values(data.data)
      .flat()
      .map((row) => ({
        budget_name: row.budget_name,
        actual_spent: row.actual_spent.toFixed(2),
        total_budget: row.total_budget.toFixed(2),
        spent_percentage: row.spent_percentage.toFixed(2),
        forcasted_amount: row.forcasted_amount.toFixed(2),
        period: row.period,
      }));
  }, [data]);

  return (
    <>
      <div className={styles.containered}>
        <span
          className={styles.individual_title}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          Individual Cloud Top Budgets by Cost
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="tooltip">Download as CSV</Tooltip>}
          >
            <span className={styles.downloadButton} onClick={handleDownloadCsv}>
              <img src={downlaodVM} alt="" />
            </span>
          </OverlayTrigger>
          {transformedGraphData.length > 0 && (
            <CSVLink
              data={transformedGraphData}
              headers={csvHeaders}
              filename={`Cloud-Budget-Data.csv`}
              target="_blank"
              ref={csvLinkRef}
              style={{ display: "none" }}
            />
          )}
        </span>
        {isLoading && (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "40px",
                width: "100%",
              }}
            >
              <div
                className={styles.graph_table_cont}
                style={{ flexDirection: "column", width: "100%" }}
              >
                <HorizontalBarGraphwithTable />
              </div>
              <div
                className={styles.graph_table_cont}
                style={{ flexDirection: "column", width: "100%" }}
              >
                <HorizontalBarGraphwithTable />
              </div>
            </div>
          </>
        )}

        {error && (
          <ScenariosErrorState
            error={error.message}
            headerItem="header"
            messageConfig={{
              [API_NO_DATA_FOUND_ERROR]: {
                message: "No Data Available",
                additionalMessage:
                  "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
              },
              [API_LOADING_ERROR]: {
                message: "Oops!",
                additionalMessage:
                  "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
              },
              [API_NOT_FOUND_ERROR]: {
                message: "404 Error",
                additionalMessage:
                  "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
              },
            }}
          />
        )}
        <div className={styles.graph_table_cont}>
          {graphData.map((graph, index) => (
            <IndividualVertcalGraph
              key={index}
              data={graph}
              color={graph.color}
              tableData={data?.data}
              error={error}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default AllCloudGraphAndTable;

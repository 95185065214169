import React, { useContext, useEffect, useMemo, useState } from "react";
import styles from "@/page-components/snapshot/snapshots.module.css";
import clsx from "clsx";
import { AppContext } from "@/App.context";
import { AnomalyContext } from "../cost-anomaly.context";
import { Dropdown, Offcanvas } from "react-bootstrap";
import StyledStackedBarGraph from "./stacked-bar-chart";
import menuicon from "@/assets/img/menu.svg";
import Pagination from "@/components/pagination/pagination.component";
import downloadIcon from "@/assets/img/download.svg";
import editIcon from "@/assets/img/editoracces.svg";
import binIcon from "@/assets/img/bin.svg";
import style from "../anomaly.module.css";
import TableErrorState from "@/components/States/TableErrorState";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import DropdowmComponent from "@/common/DropdownComponent";
import download from "@/assets/img/download.svg";

import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";
import { useAnomalyDataDownlaod } from "@/services/cost-anomaly/download.repo";

function TableSection({
  service,
  data,
  isLoading,
  error,
  refetch,
  setConfirmationmodal,
  setDeleteData,
  deleteSuccess,
}) {
  const context = useContext(AppContext);
  const contexts = useContext(AnomalyContext);
  const name = String(context?.user?.name).toLowerCase();
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const [isChartModal, setisChartModal] = useState(false);
  const items = [
    {
      eventKey: "Download as CSV",
      icon: download,
      label: "Download as CSV",
    },
  ];

  const handleMouseEnter = (index) => {
    setHoveredRowIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredRowIndex(null);
  };
  const shouldDisableRow = (row) => row.status === "retained_deleted";
  const handleclick = () => {
    setisChartModal(true);
  };

  const HandleDeleted = (id) => {
    contexts?.setSelectedAnomalyID(id);
  };

  const HandleEditDAta = (data) => {
    contexts?.setEditdata(data[0]);
  };
  const downloadMutate = useMemo(() => {
    return {
      service: service,
    };
  }, []);
  const { mutate: downloadMutateDiscard } =
    useAnomalyDataDownlaod(downloadMutate);

  const handleDownloadClicked = () => {
    downloadMutateDiscard(downloadMutate);
  };

  useEffect(() => {
    setisChartModal(false);
    refetch();
  }, [deleteSuccess]);


  return (
    <>
      {isLoading && <TableErrorState />}
      {error && (
        <ScenariosErrorState
          error={error.message}
          messageConfig={{
            [API_NO_DATA_FOUND_ERROR]: {
              message: "No Data Available",
              additionalMessage:
                "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
            },
            [API_LOADING_ERROR]: {
              message: "Oops!",
              additionalMessage:
                "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
            },
            [API_NOT_FOUND_ERROR]: {
              message: "404 Error",
              additionalMessage:
                "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
            },
          }}
        />
      )}
      {!isLoading && !error && data && (
        <>
          <div className={styles.table_container} style={{ width: "100%" }}>
            <table
              className="table table-hover"
              id="customers"
              style={{ margin: "0px", width: "100%" }}
            >
              <thead>
                <tr
                  style={{
                    background: "#F6F6F6",
                    borderBottom: "1px solid #F6F6F6",
                  }}
                >
                  <th className={styles.snap_th_class}>Title</th>
                  <th className={styles.snap_th_class}>Anomalies</th>
                  <th className={styles.snap_th_class}>Last Occurred On</th>
                  <th className={styles.snap_th_class}>Evaluation period</th>
                  <th className={styles.snap_th_class}>Threshold</th>
                  <th className={styles.snap_th_class}>Filter</th>
                  <th className={styles.snap_th_class}>
                    <DropdowmComponent
                      menuIcon={menuicon}
                      items={items}
                      onItemClick={handleDownloadClicked}
                    />
                  </th>
                </tr>
              </thead>

              <tbody style={{ borderRight: "white", borderLeft: "white" }}>
                {(data?.data || []).map((data, index) => (
                  <tr
                    className={clsx(
                      {
                        [styles.trhovered]: index === hoveredRowIndex,
                        [styles.disabledRow]: shouldDisableRow(data),
                      },
                      styles.for_copy_hover
                    )}
                    key={index}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                    style={{ lineHeight: "17px" }}
                  >
                    <td
                      // className={clsx(style.snap_table_data__Class, "elipese")}
                      title={data?.name}
                      onClick={() => {
                        contexts?.setSelectedNameID(data?.anomaly_id);
                        contexts?.setSelectedUserNAme(data?.username);
                        contexts?.setSelectedAnomaliesCount(data?.anomalies);
                        contexts?.setSelectedThreshold(data?.threshold);
                        contexts?.setSelectedperiod(data?.previous_days);
                        contexts?.setSelectedOccoured(data?.occured_on);
                        contexts?.setSelectedFilter(data?.filters);
                        HandleEditDAta([data]);
                        handleclick();
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "1px",
                        }}
                      >
                        <span
                          className={clsx(
                            style.snap_table_data__Class,
                            "elipese"
                          )}
                          style={{ color: "#056EE5", cursor: "pointer" }}
                        >
                          {data?.name ? data?.name : "--"}
                        </span>

                        <div
                          style={{
                            fontSize: "11px",
                            fontWeight: "400",
                            fontStyle: "italic",
                            color: "#495057",
                          }}
                        >
                          Created By{" "}
                          {String(data?.username).toLowerCase() === name
                            ? "You"
                            : data?.username}
                        </div>
                      </div>
                    </td>
                    <td
                      className={clsx(style.snap_table_data__Class, "elipese")}
                      title={data?.anomalies}
                      style={{ paddingTop: "16px" }}
                    >
                      {data?.anomalies ? data?.anomalies : "--"}
                    </td>
                    <td
                      className={style.snap_table_data__Class}
                      title={data?.occured_on}
                      style={{ marginRight: "8PX", paddingTop: "16px" }}
                    >
                      <span
                        style={{
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          maxWidth: "150px",
                          display: "inline-block",
                          lineHeight: "0.9",
                        }}
                      >
                        {data?.occured_on
                          ? new Date(data.occured_on).toLocaleDateString(
                              "en-GB"
                            )
                          : "--"}
                      </span>
                    </td>
                    <td
                      className={style.snap_table_data__Class}
                      style={{ paddingTop: "16px" }}
                    >
                      {data?.previous_days
                        ? data?.previous_days + " Days"
                        : "--"}
                    </td>
                    <td
                      className={style.snap_table_data__Class}
                      style={{ paddingTop: "16px" }}
                    >
                      {data?.threshold_type === "ABSOLUTE_VALUE"
                        ? data?.threshold
                          ? "$" + data?.threshold
                          : "--"
                        : data?.threshold
                        ? data?.threshold + "%"
                        : "--"}
                    </td>

                    <td
                      className={style.snap_table_data__Class}
                      style={{ paddingTop: "16px" }}
                    >
                      {[
                        ...(data?.filters.tags || []),
                        ...(data?.filters.dimensions || []),
                      ].map((item) => (
                        <div
                          style={{ display: "flex", gap: "2px" }}
                          key={item?.name}
                        >
                          <span>{item?.name?.name?.replace(/([a-z])([A-Z])/g, "$1 $2").replace(/\b\w/g, (char) => char.toUpperCase()) || "--"}</span> :
                          {(item?.values || []).map((i) => (
                            <span key={i}>{i?.name || "--"}</span>
                          ))}
                        </div>
                      ))}
                    </td>
                    <td
                      className={clsx(
                        style.snap_table_data__Class,
                        styles.snapshot_col_weight
                      )}
                      style={{ paddingTop: "16px" }}
                    >
                      <Dropdown style={{ marginTop: "-6px" }}>
                        <Dropdown.Toggle
                          className={styles.dropdown_toggle}
                          variant="link"
                          id={`dropdown-basic-${index}`}
                          style={{
                            background: "none",
                            border: "none",
                            padding: 0,
                            marginTop: "5px",
                          }}
                        >
                          {(context?.selectedItems || []).length < 1 && (
                            <img
                              src={menuicon}
                              alt=""
                              style={{ padding: "4px 6px" }}
                            ></img>
                          )}
                        </Dropdown.Toggle>

                        <Dropdown.Menu
                          id={styles.dropdown_menu_item}
                          style={{
                            border: "none",
                            boxShadow: "0 19px 14px rgba(0, 0, 0, 0.1)",
                          }}
                          className={style.inset}
                        >
                          <Dropdown.Item
                            id={styles.dropdown_item}
                            onClick={() => {
                              contexts?.setIsLayoverOpen(true);
                              HandleEditDAta([data]);
                            }}
                          >
                            <span className={styles.dropdown_names_css}>
                              <img
                                src={editIcon}
                                alt=""
                                className={style.dropdown_icon}
                              />
                              Edit
                            </span>
                          </Dropdown.Item>
                          {/* <Dropdown.Divider />

                          <Dropdown.Item
                            id={styles.dropdown_item}
                            onClick={() => {}}
                          >
                            <span
                              className={styles.dropdown_names_css}
                              onClick={handleDownloadClicked}
                            >
                              <img
                                src={downloadIcon}
                                alt=""
                                className={style.dropdown_icon}
                              />
                              Download as CSV
                            </span>
                          </Dropdown.Item> */}
                          <Dropdown.Divider />

                          <Dropdown.Item
                            id={styles.dropdown_item}
                            onClick={() => {
                              setConfirmationmodal(true);
                              setDeleteData(data?.anomaly_id);
                              // HandleDeleted(data?.anomaly_id);
                            }}
                          >
                            <span className={styles.dropdown_names_css}>
                              <img
                                src={binIcon}
                                alt=""
                                className={style.dropdown_icon}
                              />
                              Delete Anomaly
                            </span>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Offcanvas
              placement="end"
              show={isChartModal}
              onHide={() => {
                setisChartModal(false);
                contexts?.setEditdata();
              }}
              style={{
                width: "90%",
              }}
            >
              <Offcanvas.Body
                className={styles.modal_body}
                style={{
                  paddingTop: 16,
                }}
              >
                <StyledStackedBarGraph
                  service={service}
                  name={name}
                  setisChartModal={setisChartModal}
                  datar={data}
                  anomalyId={contexts?.SelectedNameID}
                  setConfirmationmodal={setConfirmationmodal}
                  setDeleteData={setDeleteData}
                />
              </Offcanvas.Body>
            </Offcanvas>
          </div>
          <Pagination totalItems={data?.total_count} />
        </>
      )}
    </>
  );
}

export default TableSection;

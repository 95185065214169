import React, { useState, useMemo } from "react";
import CostExplorerContainer from "@/page-components/cost-explorer/components/cost-explorer-container.component";
import CostBarGraph from "./charts-tables/cost-bar-graph.part";
import ServiceTable from "./charts-tables/all-cloud-service-table.part";
import IndividualCloudCost from "./charts-tables/individual-cost-graph.part";
import format from "date-fns/format";
import subDays from "date-fns/subDays";
import differenceInDays from "date-fns/differenceInDays";
import parse from "date-fns/parse";
import { useAllStatistics } from "@/services/costExplorer/getStatistics.repo";
import DataLoading from "@/components/States/DataLoading";
import { useAllCloudTableData } from "@/services/costExplorer/getAllCloudTable.repo";
import AllPageNoFilterLoading from "@/components/States/AllPageNoFilterLoading";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";
import TableAndGraph from "@/components/States/TableAndGraph";

const AllCloudCostExplorer = () => {
  const [selectedCustomTabs, setSelectedCustomTabs] = useState(false);
  const [selectedForecastTab, setSelectedForecastTab] = useState(false);
  const [range, setRange] = useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: subDays(new Date(), 2),
      key: "selection",
    },
  ]);

  let fromDate = format(range[0].startDate, "dd-MM-yyyy");
  let toDate = format(range[0].endDate, "dd-MM-yyyy");
  const date2 = parse(toDate, "dd-MM-yyyy", new Date());
  const date1 = parse(fromDate, "dd-MM-yyyy", new Date());

  const formattedDate1 = date1.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
  });
  const formattedDate2 = date2.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
  });

  const daysDifference = `${formattedDate1} - ${formattedDate2}`;

  const query = useMemo(() => {
    return {
      service: "allclouds",
      fromDate,
      toDate,
    };
  }, [fromDate, toDate]);

  const { data, isLoading: tableLoading, error } = useAllCloudTableData(query);

  const [graphData, dates, services] = useMemo(() => {
    const sortedMap = new Map();
    let services = [];
    if (data) {
      services.push(...Object.keys(data));
      services.forEach((e) => {
        let serviceData = data[e] || [];
        if (Array.isArray(serviceData) === false) {
          serviceData = [];
        }
        serviceData.forEach((x) => {
          let map = {};
          const key = x.usage_start_date || x.date;
          if (sortedMap.has(key)) {
            map = sortedMap.get(key);
          }
          map[e] = x.total_cost || 0;

          sortedMap.set(key, map);
        });
      });
    }
    const dates = Array.from(sortedMap.keys()).sort(
      (a, b) => new Date(a) - new Date(b)
    );
    return [
      dates.map((e) => {
        let dateFormat = parse(e, "yyyy-MM-dd", new Date());
        let formatted = format(dateFormat, "dd LLL");
        const obj = {
          label: formatted,
          ...sortedMap.get(e),
        };
        return obj;
      }),
      dates,
      services,
    ];
  }, [data]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 p-0">
            <CostExplorerContainer
              range={range}
              setRange={setRange}
              toDate={toDate}
              fromDate={fromDate}
              service="allclouds"
              selectedCustomTabs={selectedCustomTabs}
              setSelectedCustomTabs={setSelectedCustomTabs}
              daysDifference={daysDifference}
              setSelectedForecastTab={setSelectedForecastTab}
              selectedForecastTab={selectedForecastTab}
            />
            <div
              style={{
                display: "flex",
                alignItems: "stretch",
                flexDirection: "column",
                gap: "24px",
                paddingLeft: "40px",
                marginTop: "20px",
                paddingRight: "40px",
              }}
            >
              {!selectedCustomTabs && (
                <>
                  {tableLoading && <TableAndGraph />}
                  {error && (
                    <ScenariosErrorState
                      error={error.message}
                      messageConfig={{
                        [API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR]: {
                          message: "No Data for the Selected Date Range",
                          additionalMessage:
                            "The selected date range doesn’t contain any savings data. Try choosing a different range to view available savings.",
                        },
                        [API_LOADING_ERROR]: {
                          message: "Oops!",
                          additionalMessage:
                            "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
                        },
                        [API_NOT_FOUND_ERROR]: {
                          message: "404 Error",
                          additionalMessage:
                            "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
                        },
                        [API_NO_DATA_FOUND_ERROR]: {
                          message: "No Data Available",
                          additionalMessage:
                            "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
                        },
                      }}
                    />
                  )}
                  {!tableLoading && (data || dates.length > 0) && (
                    <>
                      {" "}
                      <CostBarGraph
                        toDate={toDate}
                        fromDate={fromDate}
                        data={data}
                        graphData={graphData}
                        dates={dates}
                        services={services}
                      />
                      <ServiceTable
                        toDate={toDate}
                        fromDate={fromDate}
                        data={data}
                        graphData={graphData}
                        dates={dates}
                        services={services}
                      />{" "}
                    </>
                  )}
                  <IndividualCloudCost
                    daysDifference={daysDifference}
                    toDate={toDate}
                    fromDate={fromDate}
                  />{" "}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* )} */}
    </>
  );
};

export default AllCloudCostExplorer;

import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import styles from "@/page-components/budgets/components/budgets.module.css";
import IndividualTableAllBudget from "./individual-table.part";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import { API_NO_DATA_FOUND_ERROR } from "@/lib/errors/error-constants";
import styless from "@/page-components/cost-explorer/cost.explorer.module.css";


const IndividualVertcalGraph = ({ title, data, color, tableData,error }) => {
  console.log("data",data)
  return (
    <div className={styles.graphsContainer}>
      <span className={styles.top_budget_name}>
        {String(data.serviceType).toUpperCase()} Top 5 Budgets
      </span>
      <>
        {(tableData?.[data.serviceType].length === 0 ) && (
          <ScenariosErrorState
            error={API_NO_DATA_FOUND_ERROR}
            headerItem="header"
            messageConfig={{
              [API_NO_DATA_FOUND_ERROR]: {
                message: "No Data Available",
                additionalMessage:
                  "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
              },
            }}
          />
        )}
        {tableData?.[data?.serviceType].length> 0  && (
          <>
          <div>
            <ResponsiveContainer width="100%" height={212}>
              <BarChart
                barSize={16}
                data={data.data1}
                layout="vertical"
                margin={{ top: 0, right: 15, bottom: 10, left: -16 }}
              >
                <XAxis
                  type="number"
                  axisLine={false}
                  tickLine={false}
                  stroke="#495057"
                  fontSize={10}
                  fontWeight={400}
                  tickFormatter={(value) => `$${value}`}
                />
                <YAxis
                  dataKey="name"
                  type="category"
                  axisLine={false}
                  tickLine={false}
                  width={120}
                  fontSize={10}
                  fontWeight={400}
                  stroke="#495057"
                  tick={({ payload, x, y, ...props }) => {
                    const displayText =
                      payload.value.length > 15
                        ? `${payload.value.slice(0, 14)}...`
                        : payload.value;
                    return (
                      <text x={x} y={y} {...props} title={payload.value}>
                        <title>{payload.value}</title>
                        {displayText}
                      </text>
                    );
                  }}
                />
                 <Tooltip
                          content={CustomTooltip}
                          cursor={{ fill: "transparent" }}
                        />
               
                <CartesianGrid strokeDasharray="0" stroke="#F0F0F0" />
                <Bar dataKey="totalBudget" fill={color} />
              </BarChart>
            </ResponsiveContainer>
          </div>
    
          <IndividualTableAllBudget
            data={data.serviceType === "aws" ? tableData.aws : tableData.azure}
          />
          </>
        )}
      </>
    </div>
  );
};

export default IndividualVertcalGraph;

const   CustomTooltip = ({ active, payload, month, service }) => {
  console.log("payload",payload);
  if (!active) {
    return null;
  }
  return (
    <div className={styless.custom_tooltip}>
      <div className="tooltip_date">
        <div className="tooltip_date_css">
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "211px",
            }}
          >
            {payload?.[0]?.payload?.month ||
              payload?.[0]?.payload?.name ||
              payload?.[0]?.payload?.idle_days ||
              payload?.[0]?.payload?.date}
          </span>
        </div>
      </div>
      <table>
        <tbody>
          {(payload || []).map((entry, index) => (
            <>
            <tr>
              <td className={styless.cloud_cell}>Total Budget</td>
              <td className={styless.cost_cell}>
                ${Number(entry.payload?.totalBudget || 0)?.toFixed(2)}
              </td>
            </tr>
            <tr>
              <td className={styless.cloud_cell}>Actual Spent</td>
              <td className={styless.cost_cell}>
                ${Number(entry.payload?.actualSpent || 0)?.toFixed(2)}
              </td>
            </tr>
            </>
          ))}
        </tbody>
      </table>
    </div>
  );
};

import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
  ERROR_STATUS_CODES,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_QUERY_RESOURCE_FILTERS,
  API_NO_DATA_FOUND_FOR_QUERY_IDLE_FILTERS,
} from "@/lib/errors/error-constants";

/**
 * @typedef GetAllStatistics
 * @property {GetDataStatistics} data
 * @property {GetTotalCountStatistics} count
 */

/**
 * @typedef GetTotalCountStatistics
 * @property {number} count
 */

/**
 * @typedef GetDataStatistics
 * @property {string} accountId
 * @property {string} startDate
 * @property {string} endDate
 * @property {string} noOfRIs
 * @property {string} scope
 * @property {string} region
 * @property {string} availability_zone
 * @property {string} platform
 * @property {string} tenancy
 * @property {string} offeringType
 * @property {string} reservationId
 * @property {string} reservationHours
 * @property {string} amortizedUpfrontFee
 * @property {string} amortizedRecurringFee
 * @property {string} effectiveReservationCost
 * @property {string} onDemandCost
 * @property {string} potentialSavings
 * @property {string} upfrontFee
 * @property {string} hourlyRecurringFee
 * @property {string} costOfUnsedHours
 */

/**
 * @typedef GetAllStatisticsRes
 * @property {GetAllStatistics[]} statistics
 */

export const getRiModalData = (token) => {
  /**
   * @param {GetStatisticsReq} req
   * @returns {Promise<GetAllStatistics>}
   */
  return async (
    {
      service,
      resourceType,
      filters,
      period,
      start_date,
      end_date,
      reservation_id,
    },
    signal
  ) => {
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/ri-utilization/get?id=${reservation_id}`,
      {
        method: "GET",
        signal,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!owners.ok) {
      if (ERROR_STATUS_CODES.includes(owners.status)) {
        throw new Error(API_LOADING_ERROR);
      }
      if (owners.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR);
      }
      throw new Error((await owners.json()).message);
    }

    const data = await owners.json();

    return data;
  };
};

/**

 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useRiModalData = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getRiModalData", req, ...deps],
    queryFn: ({ signal }) => getRiModalData(appContext.idToken)(req, signal),
  });
  return query;
};

import { AppContext } from "@/App.context";
import config from "@/config";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { DownloadFile } from "@/lib/download-file";
/**
 * @typedef Recommendation
 * @property {string} recommendation_id
 * @property {string} account_id
 * @property {string} current_resource_type
 * @property {string} recommended_resource_type
 * @property {string} resource_id
 * @property {string} region
 * @property {string} saving
 * @property {string} monthly_cost
 * @property {string} recommended_action
 * @property {number} restart
 * @property {number} rollback_possible
 * @property {number} monthly_saving
 * @property {number} recommended_resource_summary
 */

/**
 * @typedef GetRecommendationsListRes
 * @property {Recommendation[]} recommendations
 * @property {number} count
 */

/**
 * @typedef GetRecommendationsFilters
 * @property {string[]} accounts
 * @property {string[]} regions
 */

/**
 * @typedef GetRecommendationsListReq
 * @property {'aws' | 'gcp' | 'azure' | 'all'} service
 * @property {GetRecommendationsFilters} filters
 * @property {string} orderBy
 * @property {boolean} desc
 * @property {number} page
 * @property {number} recordsPerPage
 */

export const getRecommendationsList = (token) => {
  /**
   * @param {GetRecommendationsListReq} req
   * @returns {Promise<GetRecommendationsListRes>}
   */
  return async (
    {page, recordsPerPage, orderBy, desc,service, accounts, regions,resource_type,impact,status },
    signal
  ) => {
    const statusMap = {
      Active: "active",
      "In Progress": "in_progress",
      Retained: "retained",
    };
    const mappedStatus = statusMap[status] || status;

    const recommendationsList = await fetch(
      `${config.apiHost}/v1/${service}/cost-recommendations/listing-download?page=${page}&recordsPerPage=${recordsPerPage}&orderBy=${orderBy}&desc=${desc}&status=${mappedStatus}`,
      {
        method: "POST",
        body: JSON.stringify({
          accounts: accounts || [],
          regions: regions || [],
          resource_type:resource_type || [],
          impact:impact || []
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        signal,
      }
    );
    if (!recommendationsList.ok) {
      throw new Error((await recommendationsList.json()).message);
    }
    const reader = recommendationsList.body.getReader();
    const stream = new ReadableStream({
      start(controller) {
        function push() {
          reader.read().then(({ done, value }) => {
            if (done) {
              controller.close();
              return;
            }
            controller.enqueue(value);
            push();
          });
        }
        push();
      },
    });
    const blob = await new Response(stream).blob();
    const fileName = `${service}-${mappedStatus}-Recommendation-data.csv`;
    DownloadFile(blob, fileName);
  };
};

/**
 * @param {GetRecommendationsListReq} req
 * @param {any[]=} deps
 */
export const useCostRecommendationsListDownlaod = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useMutation({
    mutationKey: ["getRecommendationsList", req, ...deps],
    mutationFn: ({ signal }) =>
      getRecommendationsList(appContext.idToken)(req, signal),
  });
  return query;
};

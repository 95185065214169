import { AppContext } from "@/App.context";
import config from "@/config";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
  ERROR_STATUS_CODES,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_QUERY_RESOURCE_FILTERS,
  API_NO_DATA_FOUND_FOR_QUERY_IDLE_FILTERS,
} from "@/lib/errors/error-constants";
import { DownloadFile } from "@/lib/download-file";

/**
 * @typedef GetAllStatistics
 * @property {GetDataStatistics} data
 * @property {GetTotalCountStatistics} count
 */

/**
 * @typedef GetTotalCountStatistics
 * @property {number} total_data
 */

/**
 * @typedef GetDataStatistics
 * @property {string} account_name
 * @property {string} name
 * @property {string} id
 * @property {string} resource_group_name
 * @property {string} resource_type
 * @property {string} region
 * @property {string} owner
 * @property {number} idle_days
 * @property {string} resource_id
 * @property {number} saving
 * @property {string} status
 */

/**
 * @typedef GetIdleFilters
 * @property {string[]} accounts
 * @property {string[]} rg
 * @property {string[]} regions
 * @property {string[]} tags
 */

/**
 * @typedef GetAllStatisticsRes
 * @property {GetAllStatistics[]} statistics
 */
/**
 * @typedef GetStatisticsReq
 * @property {'aws' | 'gcp' | 'azure'  } service
 * @property {'ip' | 'disk' | 'vm' | 'lb' |'all'} resourceType
 * @property {GetIdleFilters} filters
 * @property {number} page
 * @property {number} recordsPerPage
 * @property {number} idleDays
 * @property {'true' | 'false'} desc
 * @property {'account_name' | 'name' | 'resource_group_name' |'resource_id' | 'resource_type' | 'region' | 'idle_days' | 'saving'} orderBy
 * @property { 'false' | 'true' } untagged
 */

// ------------------------------------------api for the archive table------------------------------------------------------------

export const getRiUtlizationDetails = (token) => {
  /**
   * @param {GetStatisticsReq} req
   * @returns {Promise<GetAllStatistics>}
   */
  return async (
    { service, page, filters, period, start_date, end_date },
    signal
  ) => {
    const body = {
      page: page,
      filters: {
        accounts: filters?.accounts || [],
        regions: filters?.regions || [],
        terms: filters?.term || [],
      },
      period: period,
      startDate: start_date,
      endDate: end_date,
    };

    const owners = await fetch(
      `${config.apiHost}/v1/${service}/ri-utilization/download`,
      {
        method: "POST",
        body: JSON.stringify(body),
        signal,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!owners.ok) {
      throw new Error((await owners.json()).message);
    }

    const reader = owners.body.getReader();
    const stream = new ReadableStream({
      start(controller) {
        function push() {
          reader.read().then(({ done, value }) => {
            if (done) {
              controller.close();
              return;
            }
            controller.enqueue(value);
            push();
          });
        }
        push();
      },
    });
    const blob = await new Response(stream).blob();
    const fileName = `Ri-Utilization-data.csv`;
    DownloadFile(blob, fileName);
  };
};

/**

 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const UseRiTableDownload = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const mutation = useMutation({
    mutationKey: ["getRiUtlizationDetails", req, ...deps],
    mutationFn: ({ signal }) =>
      getRiUtlizationDetails(appContext.idToken)(req, signal),
  });
  return mutation;
};

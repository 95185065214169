import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import {
  API_LOADING_ERROR_CUSTOM_WIDGET,
  API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET,
  API_NOT_FOUND_ERROR_CUSTOM_WIDGET,
  ERROR_STATUS_CODES,
} from "@/lib/errors/error-constants";

export const getKuberneteStatsData = (token) => {
  return async (service, fromDate, endDate) => {
    const stats = await fetch(
      `${config.apiHost}/v1/${service}/kubernetes/all-stats?fromDate=${fromDate}&toDate=${endDate}`,
      {
        method: "GET",
        headers: {
          Authorization: token,
        },
      }
    );
    if (!stats.ok) {
      if (ERROR_STATUS_CODES.includes(stats.status)) {
        throw new Error(API_LOADING_ERROR_CUSTOM_WIDGET);
      }
      if (stats.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR_CUSTOM_WIDGET);
      }
      throw new Error((await stats.json()).message);
    }
    const data = await stats.json();

    if (
      data?.leftBarValues?.thisYearValue === 0 &&
      data?.leftBarValues?.previousMonthValue === 0 &&
      (!data?.middleBarValue?.totalCostData ||
        data?.middleBarValue?.totalCostData === 0) &&
      (!data?.middleBarValue?.totalClusterData ||
        data?.middleBarValue?.totalClusterData === 0) &&
      (!data?.middleBarValue?.totalNodes ||
        data?.middleBarValue?.totalNodes === 0) &&
      (!data?.middleBarValue?.totalPods ||
        data?.middleBarValue?.totalPods === 0) &&
      (data?.middleBarValue?.totalCostDataPercentage === null ||
        data?.middleBarValue?.totalCostDataPercentage === undefined ||
        data?.middleBarValue?.totalCostDataPercentage === 0) &&
      (data?.middleBarValue?.totalClusterDataPercentage === null ||
        data?.middleBarValue?.totalClusterDataPercentage === undefined ||
        data?.middleBarValue?.totalClusterDataPercentage === 0) &&
      (data?.middleBarValue?.totalNodesPercentage === null ||
        data?.middleBarValue?.totalNodesPercentage === undefined ||
        data?.middleBarValue?.totalNodesPercentage === 0) &&
      (data?.middleBarValue?.totalPodsPercentage === null ||
        data?.middleBarValue?.totalPodsPercentage === undefined ||
        data?.middleBarValue?.totalPodsPercentage === 0) &&
      (!data?.rightBarValues?.daemonsets ||
        data?.rightBarValues?.daemonsets === 0) &&
      (!data?.rightBarValues?.deployments ||
        data?.rightBarValues?.deployments === 0) &&
      (!data?.rightBarValues?.replicasets ||
        data?.rightBarValues?.replicasets === 0) &&
      (!data?.rightBarValues?.pVs || data?.rightBarValues?.pVs === 0) &&
      (!data?.rightBarValues?.namespaces ||
        data?.rightBarValues?.namespaces === 0) &&
      (!data?.rightBarValues?.services || data?.rightBarValues?.services === 0) &&
      (!data?.rightBarValues?.statefulsets ||
        data?.rightBarValues?.statefulsets === 0) &&
      (!data?.rightBarValues?.containers ||
        data?.rightBarValues?.containers === 0)
    ) {
      throw new Error(API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET);
    }

    return data;
  };
};

export const useKubernetesStatistic = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getKuberneteStatsData", req, ...deps],
    queryFn: () =>
      getKuberneteStatsData(appContext.idToken)(
        req.service,
        req.formattedStartDate,
        req.formattedEndDate
      ),
  });
  return query;
};

// {
//   "leftBarValues": {
//       "thisYearValue": 257.763749226616,
//       "previousMonthValue": 0.20488071046208325
//   },
//   "middleBarValue": {
//       "totalCostData": 0,
//       "totalClusterData": 0,
//       "totalNodes": 0,
//       "totalPods": 0,
//       "totalCostDataPercentage": null,
//       "totalClusterDataPercentage": null,
//       "totalNodesPercentage": null,
//       "totalPodsPercentage": 0
//   },
//   "rightBarValues": {
//       "daemonsets": 0,
//       "deployments": 0,
//       "replicasets": 0,
//       "pVs": 0,
//       "namespaces": 0,
//       "services": 0,
//       "statefulsets": 0,
//       "containers": 0
//   }
// }

import React, { useMemo, useState } from "react";
import parse from "date-fns/parse";
import format from "date-fns/format";
import styles from "@/page-components/cost-explorer/cost.explorer.module.css";
import clsx from "clsx";
import CurrencyFormatter from "@/lib/value-converison";
import config from "@/config";

const AllCloudServiceTable = ({ data }) => {
  const [AWS, Azure, GCP] = useMemo(() => {
    const AWS = data?.AWS || [];
    const Azure = data?.Azure || [];
    const GCP = data?.GCP || [];
    return [AWS, Azure, GCP];
  }, [data]);

  const serviceAWSTotal = useMemo(
    () => AWS.reduce((sum, element) => sum + (element.total_cost || 0), 0),
    [AWS]
  );

  const serviceAzureTotal = useMemo(
    () => Azure.reduce((sum, element) => sum + (element.total_cost || 0), 0),
    [Azure]
  );

  const serviceGCPTotal = useMemo(
    () => GCP.reduce((sum, element) => sum + (element.total_cost || 0), 0),
    [GCP]
  );

  const totalService = serviceAWSTotal + serviceAzureTotal + serviceGCPTotal;

  const [graphData, dates, services] = useMemo(() => {
    const sortedMap = new Map();
    let services = [];

    if (data) {
      services.push(...Object.keys(data));

      services = services.filter((service) =>
        config.enabledClouds.has(service.toLowerCase())
      );

      services.forEach((e) => {
        let serviceData = data[e];
        if (Array.isArray(serviceData) === false) {
          serviceData = [];
        }

        serviceData.forEach((x) => {
          let map = {};
          const key = x.usage_start_date || x.date;
          if (sortedMap.has(key)) {
            map = sortedMap.get(key);
          }
          if (config.enabledClouds.has(e.toLowerCase())) {
            map[e] = x.total_cost;
          }
          sortedMap.set(key, map);
        });
      });
    }

    const dates = Array.from(sortedMap.keys()).sort(
      (a, b) => new Date(a) - new Date(b)
    );

    return [
      dates.map((e) => ({
        label: e,
        ...sortedMap.get(e),
      })),
      dates,
      services,
    ];
  }, [data, config.enabledClouds]);

  const dateFormated = (dates || []).map((item) => {
    let dateFormat = parse(item, "yyyy-MM-dd", new Date());
    let formatted = format(dateFormat, "dd LLL");
    return formatted;
  });

  const newArr = Object.values(data?.totalCostPerDate);

  return (
    <div
      className="container-fluid table_main_container p-0"
      style={{ minWidth: "30%", overflowX: "scroll" }}
    >
      <table style={{ width: "100%" }}>
        <thead style={{ backgroundColor: "#f6f6f6" }}>
          <tr>
            <th
              className={clsx(styles.table_class, styles.sticky_head)}
              style={{
                width: "64px",
                textAlign: "left",
                backgroundColor: "#f6f6f6",
              }}
            >
              Cloud
            </th>
            <th
              className={clsx(styles.table_class, styles.sticky_service_total)}
              style={{ width: "88px", backgroundColor: "#f6f6f6" }}
            >
              Service Total
            </th>

            {(dateFormated || []).map((item, index) => (
              <th className={styles.table_class} key={item + "-" + index}>
                {item}
              </th>
            ))}
          </tr>
        </thead>

        <>
          <tbody>
            {config.enabledClouds.has("aws") && (
              <tr>
                <td
                  className={clsx(styles.tableData, styles.sticky_head)}
                  style={{
                    textAlign: "left",
                    fontWeight: "400",
                    backgroundColor: "#fff",
                  }}
                >
                  AWS
                </td>
                <td
                  className={clsx(
                    styles.tableData,
                    styles.sticky_service_total
                  )}
                  style={{
                    textAlign: "right",
                    fontWeight: "400",
                    backgroundColor: "#fff",
                  }}
                >
                  <CurrencyFormatter
                    value={serviceAWSTotal}
                    hideDollar={true}
                  />
                </td>
                {(graphData || []).map((e) => (
                  <td
                    className={styles.tableData}
                    style={{ fontWeight: "400" }}
                  >
                    <CurrencyFormatter value={e?.AWS} hideDollar={true} />
                  </td>
                ))}
              </tr>
            )}
            {config.enabledClouds.has("azure") && (
              <tr>
                <td
                  className={clsx(styles.tableData, styles.sticky_head)}
                  style={{
                    textAlign: "left",
                    fontWeight: "400",
                    backgroundColor: "#fff",
                  }}
                >
                  Azure
                </td>
                <td
                  className={clsx(
                    styles.tableData,
                    styles.sticky_service_total
                  )}
                  style={{
                    textAlign: "right",
                    fontWeight: "400",
                    backgroundColor: "#fff",
                  }}
                >
                  <CurrencyFormatter
                    value={serviceAzureTotal}
                    hideDollar={true}
                  />
                </td>{" "}
                {(graphData || []).map((item) => (
                  <td
                    className={styles.tableData}
                    style={{ fontWeight: "400" }}
                  >
                    <CurrencyFormatter value={item?.Azure} hideDollar={true} />
                  </td>
                ))}
              </tr>
            )}
            {config.enabledClouds.has("gcp") && (
              <tr>
                <td
                  className={clsx(styles.tableData, styles.sticky_head)}
                  style={{
                    textAlign: "left",
                    fontWeight: "400",
                    backgroundColor: "#fff",
                  }}
                >
                  GCP
                </td>
                <td
                  className={clsx(
                    styles.tableData,
                    styles.sticky_service_total
                  )}
                  style={{
                    textAlign: "right",
                    fontWeight: "400",
                    backgroundColor: "#fff",
                  }}
                >
                  <CurrencyFormatter
                    value={serviceGCPTotal}
                    hideDollar={true}
                  />
                </td>{" "}
                {(graphData || []).map((item) => (
                  <td
                    className={styles.tableData}
                    style={{ fontWeight: "400" }}
                  >
                    <CurrencyFormatter value={item?.GCP} hideDollar={true} />
                  </td>
                ))}
              </tr>
            )}

            <td
              className={clsx(styles.totalCosts, styles.sticky_head)}
              style={{ textAlign: "left", background: "#fff" }}
            >
              {" "}
              Total Cost
            </td>
            <td
              className={clsx(styles.totalCosts, styles.sticky_service_total)}
              style={{ textAlign: "right", background: "#fff" }}
            >
              <CurrencyFormatter value={totalService} hideDollar={true} />
            </td>
            {(newArr || []).map((e, i) => (
              <td
                className={styles.totalCosts}
                key={i}
                style={{ textAlign: "right" }}
              >
                <CurrencyFormatter value={e} hideDollar={true} />
              </td>
            ))}
          </tbody>
          <tfoot>
            <tr className={styles.tFoot}>
              <td colSpan={1000} style={{ border: "none", padding: "8px" }}>
                *Figures in USD
              </td>
            </tr>
          </tfoot>
        </>
      </table>
    </div>
  );
};

export default AllCloudServiceTable;

import React, { useMemo, useState, useContext } from "react";
import clsx from "clsx";
import copyicon from "@/assets/img/copyicon.svg";
import Pagination from "@/components/pagination/pagination.component";
import styles from "./savings.module.css";
import { useSearchParams } from "react-router-dom";
import { useSavingSnapshotTable } from "@/services/saving/getSavings-snapshots-table.repo";
import TableErrorState from "@/components/States/TableErrorState";
import { SavingContext } from "@/page-components/savings/components/saving-context-file.part";
import sorting from "@/assets/img/sorting.svg";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";
import { useDownloadSavingData } from "@/services/saving/getDownloadData.repo";
import downlaodVM from "@/assets/img/downloadVM.svg";
import { OverlayTrigger, Tooltip as ReactTooltip } from "react-bootstrap";

const SnapshotTablePartAWSSaving = ({
  length,
  name,
  toDate,
  fromDate,
  service,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [showCopyMessage, setShowCopyMessage] = useState(false);
  const [copiedResourceId, setCopiedResourceId] = useState(null);
  const page = searchParams.get("page");
  const itemsPerPage = searchParams.get("itemsPerPage");
  const desc = searchParams.get("desc");
  const [orderBy, setOrderBy] = useState("");

  const context = useContext(SavingContext);
  const query = useMemo(() => {
    const parsedPage = !Number.isNaN(parseInt(page)) ? parseInt(page) : 1;
    const parsedItemsPerPage = !Number.isNaN(parseInt(itemsPerPage))
      ? parseInt(itemsPerPage)
      : 10;
    const tags = (context?.selectedTags || []).filter(
      (e) => e.filterOn.length > 0 && e.values.length > 0
    );

    return {
      service,
      toDate,
      fromDate,
      page: parsedPage || 1,
      orderBy: orderBy || (service === "aws" ? "account_id" : "subscriptionid"),
      desc: searchParams.get("desc") || "true",
      recordsPerPage: parsedItemsPerPage || 20,
      filters: {
        accounts: context?.selectAccounts?.length
          ? context.selectAccounts.map((e) => e.id)
          : undefined,
        rg: context?.selectRg?.length
          ? context?.selectRg.map((e) => e.id)
          : undefined,
        regions: context?.selectRegions?.length
          ? context.selectRegions.map((e) => e.id)
          : undefined,
        tags: tags?.length ? tags : undefined,
      },
    };
  }, [
    page,
    itemsPerPage,
    context?.selectedTags,
    context.selectAccounts,
    context.selectRegions,
    context?.selectRg,
    service,
    toDate,
    fromDate,
    orderBy,
    searchParams,
  ]);

  const handleCopyClick = (subscriptionId, snapshotId, SourceDiskId = "") => {
    const idToCopy = subscriptionId || snapshotId || SourceDiskId;
    const textarea = document.createElement("textarea");
    textarea.value = idToCopy;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);

    setCopiedResourceId(idToCopy);
    setShowCopyMessage(true);

    setTimeout(() => {
      setShowCopyMessage(false);
    }, 5000);
  };

  const { data, isLoading, error } = useSavingSnapshotTable(query);

  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredRowIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredRowIndex(null);
  };
  const handleOrderByClick = (columnName) => {
    setOrderBy((prevOrderBy) =>
      prevOrderBy === columnName ? columnName : columnName
    );

    const newBoolVal = desc === "true" ? "false" : "true";
    const updatedSearchParams = new URLSearchParams(searchParams);
    updatedSearchParams.set("orderBy", columnName);
    updatedSearchParams.set("desc", newBoolVal);
    updatedSearchParams.set("page", "1");

    setSearchParams(updatedSearchParams);
  };

  const downloadQuery = useMemo(() => {
    const parsedPage = !Number.isNaN(parseInt(page)) ? parseInt(page) : 1;
    const parsedItemsPerPage = !Number.isNaN(parseInt(itemsPerPage))
      ? parseInt(itemsPerPage)
      : 10;
    const tags = (context?.selectedTags || []).filter(
      (e) => e.filterOn.length > 0 && e.values.length > 0
    );

    return {
      service,
      toDate,
      fromDate,
      downloadService: "snapshots-download",
      page: parsedPage || 1,
      recordsPerPage: parsedItemsPerPage || 20,
      orderBy: orderBy || (service === "aws" ? "account_id" : "subscriptionid"),
      desc: searchParams.get("desc") || "true",
      filters: {
        accounts: context?.selectAccounts?.length
          ? context.selectAccounts.map((e) => e.id)
          : undefined,
        rg: context?.selectRg?.length
          ? context?.selectRg.map((e) => e.id)
          : undefined,
        regions: context?.selectRegions?.length
          ? context.selectRegions.map((e) => e.id)
          : undefined,
        tags: tags?.length ? tags : undefined,
      },
    };
  }, [
    context.selectAccounts,
    context.selectRegions,
    context?.selectRg,
    context?.selectedTags,
    fromDate,
    itemsPerPage,
    orderBy,
    page,
    searchParams,
    service,
    toDate,
  ]);
  const { mutate: downloadMutate } = useDownloadSavingData(downloadQuery);

  const handleDownloadClick = () => {
    downloadMutate(downloadQuery);
  };

  return (
    <>
      {isLoading && <TableErrorState />}
      {error && (
        <div className="main_cont_padding" style={{ paddingLeft: "12px" }}>
          <ScenariosErrorState
            error={error.message}
            messageConfig={{
              [API_NO_DATA_FOUND_FOR_FILTERS_ERROR]: {
                message: "No Data Available for Selected Filters",
                additionalMessage:
                  "It looks like there are no savings data matching your filter criteria. Please try adjusting your filters to explore other options.",
              },
              [API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR]: {
                message: "No Savings Data for the Selected Date Range",
                additionalMessage:
                  "The selected date range doesn’t contain any savings data. Try choosing a different range to view available savings.",
              },
              [API_LOADING_ERROR]: {
                message: "Oops!",
                additionalMessage:
                  "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
              },
              [API_NOT_FOUND_ERROR]: {
                message: "404 Error",
                additionalMessage:
                  "We couldn’t find the savings data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
              },
            }}
          />
        </div>
      )}
      {data?.count?.total_data > 0 && (
        <div
          style={{
            marginLeft: "12px",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            paddingBottom: "32px",
          }}
        >
          <div className={styles.menu_space_between}>
            <div className={styles.savKubernetesTable}>
              Snapshots Detail ({data?.count?.total_data || 0} Snapshots)
            </div>
            <div className={styles.menu_icon_css1}>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <ReactTooltip id="tooltip">Download as CSV</ReactTooltip>
                }
              >
                <span
                  className={styles.downloadButton}
                  onClick={handleDownloadClick}
                >
                  <img src={downlaodVM} alt="" />
                </span>
              </OverlayTrigger>
            </div>
          </div>
          <div style={{ width: "100%", paddingRight: "4%" }}>
            <div className={styles.two_table_container}>
              <div className={styles.scroll}>
                <table
                  id={styles.customers}
                  className={clsx(styles.scrollable, "table-hover")}
                >
                  <thead>
                    <tr>
                      <th className={styles.tableHead} style={{ width: "20%" }}>
                        {name}
                        <img
                          src={sorting}
                          alt=""
                          className={styles.sorting_icon}
                          onClick={() =>
                            service === "aws"
                              ? handleOrderByClick("account_id")
                              : handleOrderByClick("subscriptionid")
                          }
                        ></img>
                      </th>
                      <th className={styles.tableHead} style={{ width: "20%" }}>
                        Snapshot Name
                        <img
                          src={sorting}
                          alt=""
                          className={styles.sorting_icon}
                          onClick={() =>
                            service === "aws"
                              ? handleOrderByClick("snapshot_name")
                              : handleOrderByClick("snapshotName")
                          }
                        ></img>
                      </th>
                      <th className={styles.tableHead} style={{ width: "11%" }}>
                        Snapshot ID
                        <img
                          src={sorting}
                          alt=""
                          className={styles.sorting_icon}
                          onClick={() => handleOrderByClick("snapshot_id")}
                        ></img>
                      </th>
                      {service === "azure" && (
                        <th
                          className={styles.tableHead}
                          style={{ width: "11%" }}
                        >
                          Resource Group
                          <img
                            src={sorting}
                            alt=""
                            className={styles.sorting_icon}
                            onClick={() => handleOrderByClick("resourceGroup")}
                          ></img>
                        </th>
                      )}
                      <th className={styles.tableHead} style={{ width: "14%" }}>
                        Region
                        <img
                          src={sorting}
                          alt=""
                          className={styles.sorting_icon}
                          onClick={() => handleOrderByClick("region")}
                        ></img>
                      </th>
                      <th className={styles.tableHead} style={{ width: "15%" }}>
                        Disk ID
                        <img
                          src={sorting}
                          alt=""
                          className={styles.sorting_icon}
                          onClick={() =>
                            service === "aws"
                              ? handleOrderByClick("volume_id")
                              : handleOrderByClick("diskId")
                          }
                        ></img>
                      </th>
                      <th className={styles.tableHead} style={{ width: "7%" }}>
                        Disk Size
                        <img
                          src={sorting}
                          alt=""
                          className={styles.sorting_icon}
                          onClick={() =>
                            service === "aws"
                              ? handleOrderByClick("volume_size")
                              : handleOrderByClick("diskSize")
                          }
                        ></img>
                      </th>
                      <th className={styles.tableHead} style={{ width: "20%" }}>
                        Snapshot Age
                        <img
                          src={sorting}
                          alt=""
                          className={styles.sorting_icon}
                          onClick={() =>
                            service === "aws"
                              ? handleOrderByClick("idle_days")
                              : handleOrderByClick("snapshotAge")
                          }
                        ></img>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {(data?.data || []).map((item, i) => (
                      <tr
                        className={clsx(styles.accountCol, styles.elipese, {
                          [styles.hovered]: i === hoveredRowIndex,
                        })}
                        key={item.snapshotName + i}
                        onMouseEnter={() => handleMouseEnter(i)}
                        onMouseLeave={handleMouseLeave}
                      >
                        <td
                          className={clsx(
                            styles.accountCol,
                            styles.accountTitleCol,
                            styles.accountTitleColData,
                            styles.elipese
                          )}
                        >
                          <span title={item.account}>
                            {item.subscriptionName ||
                              item.account_name ||
                              item.ProjectId ||
                              "NA "}
                          </span>
                        </td>
                        <td
                          className={clsx(
                            styles.accountCol,
                            styles.accountTitleCol,
                            styles.accountTitleColData,
                            styles.elipese
                          )}
                          title={
                            item.snapshotName ||
                            item.snapshot_name ||
                            item.SourceDiskName
                          }
                        >
                          <span title={item.resourceId}>
                            {item.snapshotName ||
                              item.snapshot_name ||
                              item.SourceDiskName ||
                              "NA"}
                          </span>
                        </td>

                        <td
                          className={clsx(styles.accountCol)}
                          style={{ position: "relative" }}
                        >
                          <div>
                            <p className={styles.elipese}>
                              {item.subscriptionId ||
                                item.snapshot_id ||
                                item?.SnapshotId ||
                                "NA"}
                            </p>
                            <button
                              className={`${styles.copy_icon} ${
                                copiedResourceId === item?.snapshot_id
                                  ? "visible"
                                  : ""
                              }`}
                              onClick={() =>
                                handleCopyClick(
                                  item?.subscriptionId,
                                  item?.snapshot_id,
                                  item?.SourceDiskId
                                )
                              }
                            >
                              <img
                                src={copyicon}
                                alt=""
                                style={{ marginRight: "-15px" }}
                              />
                            </button>
                          </div>
                        </td>
                        {service === "azure" && (
                          <td
                            className={clsx(
                              styles.accountCol,
                              styles.accountTitleCol,
                              styles.accountTitleColData,
                              styles.elipese
                            )}
                          >
                            <span title={item.resourceGroup}>
                              {item.resourceGroup ||
                                item.account_id ||
                                item.ProjectId ||
                                "NA "}
                            </span>
                          </td>
                        )}

                        <td className={styles.accountCol}>
                          {item.region || item.Region || "NA"}
                        </td>

                        <td className={styles.accountCol}>
                          <span
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "4px",
                            }}
                          >
                            {/* <img src={groupImage} alt="" /> */}
                            <td
                              className={clsx(
                                styles.border_bottom_none,
                                styles.elipese
                              )}
                              title={
                                item.diskId ||
                                item.volume_id ||
                                item.SourceDiskId
                              }
                            >
                              {item.diskId ||
                                item.volume_id ||
                                item.SourceDiskId ||
                                0}
                            </td>
                          </span>
                        </td>
                        <td className={clsx(styles.accountCol, styles.elipese)}>
                          {item.diskSize ||
                            item.volume_size ||
                            item.SourceDiskSize ||
                            0}
                          -GB
                        </td>

                        <td
                          className={styles.accountCol}
                          style={{ textAlign: "center" }}
                        >
                          {item.snapshotAge || item.idle_days || item.Age || 0}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div
                className={clsx(styles.fixed, "table-hover")}
                style={{ paddingBottom: "17px" }}
              >
                <table
                  id={styles.customers}
                  style={{
                    boxShadow: "-25px -2px 11px -21px lightgrey",
                    width: "100%",
                  }}
                >
                  <thead>
                    <tr>
                      <th className={styles.tableHead}>
                        Storage Class
                        <img
                          src={sorting}
                          alt=""
                          className={styles.sorting_icon}
                          onClick={() =>
                            service === "aws"
                              ? handleOrderByClick("storage_tier")
                              : handleOrderByClick("snapshotClass")
                          }
                        ></img>
                      </th>
                      <th className={styles.tableHead}>
                        Snapshot Cost{" "}
                        <img
                          src={sorting}
                          alt=""
                          className={styles.sorting_icon}
                          onClick={() =>
                            service === "aws"
                              ? handleOrderByClick("total_cost")
                              : handleOrderByClick("saving")
                          }
                        ></img>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {(data?.data || []).map((item, i) => (
                      <tr
                        className={clsx(styles.accountCol, {
                          [styles.hovered]: i === hoveredRowIndex,
                        })}
                        key={item.snapshotName + i + "-side"}
                        onMouseEnter={() => handleMouseEnter(i)}
                        onMouseLeave={handleMouseLeave}
                      >
                        <td
                          className={clsx(
                            styles.accountCol,
                            styles.snapshot_col_center,
                            styles.elipese,
                            {
                              [styles.snapshot_archived]:
                                String(
                                  item.storage_tier ||
                                    item.snapshotClass ||
                                    item.SnapshotType
                                ).toLowerCase() === "archive",
                              [styles.snapshot_unarchived]:
                                String(
                                  item.storage_tier ||
                                    item.snapshotClass ||
                                    item.SnapshotType
                                ).toLowerCase !== "archive",
                            }
                          )}
                        >
                          <span
                            className={clsx({
                              [styles.naText]:
                                item.storage_tier == null &&
                                item.snapshotClass == null &&
                                item.SnapshotType == null,
                            })}
                          >
                            {item.storage_tier == null &&
                            item.snapshotClass == null &&
                            item.SnapshotType == null
                              ? "NA"
                              : String(
                                  item.storage_tier ||
                                    item.snapshotClass ||
                                    item.SnapshotType ||
                                    "NA"
                                ).toLowerCase() === "standard"
                              ? "Standard"
                              : String(
                                  item.storage_tier ||
                                    item.snapshotClass ||
                                    item.SnapshotType ||
                                    "NA"
                                ).toLowerCase() === "archive"
                              ? "Archive"
                              : "NA"}
                          </span>
                        </td>

                        <td
                          className={clsx(
                            styles.accountCol2,
                            styles.elipese,
                            styles.alignment
                          )}
                        >
                          {item?.total_cost === null
                            ? "$0"
                            : Number(item?.total_cost || 0) < 0.1
                            ? "< $0.1"
                            : "$" + Number(item?.total_cost || 0).toFixed(2)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <Pagination totalItems={data?.count?.total_data} />

          {showCopyMessage && (
            <div className={styles.copyMessage}>Copy to Clipboard!</div>
          )}
        </div>
      )}
    </>
  );
};

export default SnapshotTablePartAWSSaving;

import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

/**
 * @typedef GetDimensionReq
 * @property {'aws' | 'gcp' | 'azure' } service
 * @property {string} columnName
 * @property {BudgetName} BudgetName
 * @property {Id} Id
 * @property {any} TagValues
 */

export const getTagsValues = (token) => {
  /**
   * @param {GetDimensionReq} req
   * @returns {Promise<Record<string, string | number>[]>}
   */
  return async (
    { service, columnName, BudgetName, Id, TagValues },
    signal
  ) => {
    let tableData;
    if (
      BudgetName === undefined ||
      BudgetName === null ||
      Id === undefined ||
      Id === null ||
      (!BudgetName && !Id)
    ) {
      tableData = await fetch(
        `${config.apiHost}/v1/${service}/cost-anomaly/tag-values?columnName=${columnName}`,
        {
          method: "POST",
          signal,
          headers: { "Content-Type": "application/json", Authorization: token },
        }
      );
      if (!tableData.ok) {
        throw new Error((await tableData.json()).message);
      }
      return await tableData.json();
    }
    if (
      (BudgetName && Id) ||
      BudgetName !== undefined ||
      BudgetName !== null ||
      Id !== undefined ||
      Id !== null
    ) {
      const filteredData = (TagValues || []).filter(
        (f) => f.name === columnName
      );
      return filteredData;
    }
  };
};

/**
 * @param {GetDimensionReq} req
 * @param {any[]=} deps
 */
export const useTagValuesCost = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: [
      "getTagsValues",
      req.service,
      req.columnName,
      req.BudgetName,
      req.Id,
      req.TagValues,
      ...deps,
    ],
    queryFn: ({ signal }) => getTagsValues(appContext.idToken)(req, signal),
    retry: 0,
    enabled: false,
  });
  return query;
};

/**
 * @typedef GetDimensionKeysReq
 * @property {'aws' | 'gcp' | 'azure' } service
 * @property {Object} budgetQuery
 */

export const getDimensionTagsKeys = (token) => {
  /**
   * @param {GetDimensionKeysReq} req
   * @returns {Promise<Record<string, string | number>[]>}
   */
  return async ({ service, budgetQuery }, signal) => {
    let tableData;
    if (
      (!budgetQuery?.BudgetName && !budgetQuery?.Id) ||
      budgetQuery?.BudgetName === undefined ||
      budgetQuery?.Id === undefined
    ) {
      tableData = await fetch(`${config.apiHost}/v1/${service}/cost-anomaly/get-tags`, {
        method: "GET",
        signal,
        headers: {
          Authorization: token,
        },
      });
      if (!tableData.ok) {
        throw new Error((await tableData.json()).message);
      }
      return (await tableData.json())?.data;
    }
    if (
      (budgetQuery?.BudgetName && budgetQuery?.Id) ||
      budgetQuery?.BudgetName !== undefined ||
      budgetQuery?.Id !== undefined
    ) {
      return await budgetQuery?.tagsKeys;
    }
  };
};

/**
 * @param {GetDimensionKeysReq} req
 * @param {any[]=} deps
 */
export const useDimensionTagsKeys = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getDimensionTagsKeys", req.service, req.budgetQuery, ...deps],
    queryFn: ({ signal }) =>
      getDimensionTagsKeys(appContext.idToken)(req, signal),
    retry: 0,
  });
  return query;
};

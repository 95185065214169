import React from "react";
import style from ".././../utilization.module.css";

const TablePart = ({ data }) => {
  return (
    <div className={style.table_container} style={{paddingBottom:"30px"}}>
      <table className={style.table}>
        <tbody>
          <tr>
            <td
              className={style.td}
              style={{ borderRadius: "8px 0px 0px 0px" }}
            >
              <span className={style.label}>Account ID</span>
              <br />
              <span className={style.subname}>{data.accountId || "-"}</span>
            </td>
            <td className={style.td}>
              <span className={style.label}>Start Date Time</span>
              <br />
              {/* <span className={style.subname}>{data.startDate || "-"}</span> */}
              <span className={style.subname}>
                {data.startDate
                  ? new Date(data.startDate)
                      .toLocaleString("en-GB", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: false,
                      })
                      .replace(/\//g, "-")
                      .replace(",", "")
                  : "--"}
              </span>
            </td>
            <td className={style.td}>
              <span className={style.label}>End Date Time</span>
              <br />
              {/* <span className={style.subname}>{data.endDate || "-"}</span> */}
              <span className={style.subname}>
              {data.endDate
                  ? new Date(data.endDate)
                      .toLocaleString("en-GB", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: false,
                      })
                      .replace(/\//g, "-")
                      .replace(",", "")
                  : "--"}
              </span>
            </td>
            <td
              className={style.td}
              style={{ borderRadius: "0px 8px 0px 0px" }}
            >
              <span className={style.label}>No. Of RIs</span>
              <br />
              <span className={style.subname}>{data.noOfRIs || "-"}</span>
            </td>
          </tr>
          <tr>
            <td className={style.td}>
              <span className={style.label}>Scope</span>
              <br />
              <span className={style.subname}>{data.scope || "-"}</span>
            </td>
            <td className={style.td}>
              <span className={style.label}>Region</span>
              <br />
              <span className={style.subname}>{data.region || "-"}</span>
            </td>
            <td className={style.td}>
              <span className={style.label}>Availability Zone</span>
              <br />
              <span className={style.subname}>
                {data.availabilityZone || "-"}
              </span>
            </td>
            <td className={style.td}>
              <span className={style.label}>Platform</span>
              <br />
              <span className={style.subname}>{data.platform || "-"}</span>
            </td>
          </tr>
          <tr>
            <td className={style.td}>
              <span className={style.label}>Tenancy</span>
              <br />
              <span className={style.subname}>{data.tenancy || "-"}</span>
            </td>
            <td className={style.td}>
              <span className={style.label}>Payment Option</span>
              <br />
              <span className={style.subname}>{data.paymentOption || "-"}</span>
            </td>
            <td className={style.td}>
              <span className={style.label}>Offering Type</span>
              <br />
              <span className={style.subname}>{data.offeringType || "-"}</span>
            </td>
            <td className={style.td}>
              <span className={style.label}>Reservation ID</span>
              <br />
              <span className={style.subname}>{data.reservationId || "-"}</span>
            </td>
          </tr>
          <tr>
            <td className={style.td}>
              <span className={style.label}>Reservation Hours Purchased</span>
              <br />
              <span className={style.subname}>
                {data.reservationHours || "-"}
              </span>
            </td>
            <td className={style.td}>
              <span className={style.label}>Amortized Upfront Fee</span>
              <br />
              <span className={style.subname}>
                {data.amortizedUpfrontFee && data.amortizedUpfrontFee !==null?"$"+Number(data.amortizedUpfrontFee).toFixed(2) : "--"}
              </span>
            </td>
            <td className={style.td}>
              <span className={style.label}>Amortized Recurring Fee</span>
              <br />
              <span className={style.subname}>
                {data.amortizedRecurringFee && data.amortizedRecurringFee!==null?"$"+Number(data.amortizedRecurringFee).toFixed(2) : "--"}
              </span>
            </td>
            <td className={style.td}>
              <span className={style.label}>Effective Reservation Cost</span>
              <br />
              <span className={style.subname}>
                {data.effectiveReservationCost && data.effectiveReservationCost!==null?"$"+Number(data.effectiveReservationCost).toFixed(2) : "--"}
              </span>
            </td>
          </tr>
          <tr>
            <td
              className={style.td}
              style={{ borderRadius: "0px 0px 0px 8px" }}
            >
              <span className={style.label}>On-Demand Cost Equivalent</span>
              <br />
              <span className={style.subname}>{data.onDemandCost && data.onDemandCost!==null?"$"+Number(data.onDemandCost).toFixed(2) : "--"}</span>
            </td>
            <td className={style.td}>
              <span className={style.label}>Potential Savings</span>
              <br />
              <span className={style.subname}>
                {data.potentialSavings && data.potentialSavings !==null? "$"+Number(data.potentialSavings).toFixed(2) : "--"}
              </span>
            </td>
            <td className={style.td}>
              <span className={style.label}>Average On-Demand Hourly Rate</span>
              <br />
              <span className={style.subname}>{data.avgHourlyRate &&data.avgHourlyRate !==null?"$"+Number(data.avgHourlyRate ).toFixed(2): "--"}</span>
            </td>
            <td
              className={style.td}
              style={{ borderRadius: "0px 0px 8px 0px" }}
            >
              <span className={style.label}>Total Asset Value</span>
              <br />
              <span className={style.subname}>
                { data.totalAssetValue &&data.totalAssetValue !==null ? "$"+Number(data.totalAssetValue).toFixed(2) : "--"}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TablePart;

import React, { useMemo, useState } from "react";
import { subMonths } from "date-fns";
import CPU_ICON from "@/assets/img/CPU-icon.svg";
import CONT_Icon from "@/assets/img/Containers-Icon.svg";
import Storage_icon from "@/assets/img/storage-Icon.svg";
import Network_icon from "@/assets/img/Network-Icon.svg";
import zoom_in from "@/assets/img/enlarge_header.svg";
import database from "@/assets/img/database.svg";
import dot_icon from "@/assets/img/three-dot-icon.svg";
import format from "date-fns/format";
import ReactCalender from "./ReactCalender";
import MonthCosting from "./month-costing.component";
import styles from "@/page-components/cost-explorer/cost.explorer.module.css";
import SideModal from "./side.modal.cost.explorer";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { useAllStatistics } from "@/services/costExplorer/getStatistics.repo";
import HeaderState from "@/components/States/HeaderState";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR_CUSTOM_WIDGET,
  API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET,
  API_NOT_FOUND_ERROR_CUSTOM_WIDGET,
} from "@/lib/errors/error-constants";

export function formatCount(count) {
  const million = 1000000;
  const thousand = 1000;

  const isNegative = count < 0 && Math.abs(count) >= 0.0001; 
  const absoluteValue = Math.abs(count);

  let formattedValue;

  if (absoluteValue >= million) {
    formattedValue = `${(absoluteValue / million).toFixed(1)}M`;
  } else if (absoluteValue >= thousand) {
    formattedValue = `${(absoluteValue / thousand).toFixed(1)}k`;
  } else {
    formattedValue = absoluteValue.toFixed(2);
  }

  return isNegative ? `-$${formattedValue}` : `$${formattedValue}`;
}


const CostExplorerHeader = ({
  range,
  setRange,
  daysDifference,
  service,
  fromDate,
  toDate,
  data,
  isLoading,
  error,
}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const allData = data?.result;
  const formatedDate = subMonths(new Date(), 1);
  const monthAbbreviation = format(formatedDate, "LLL");
  const year = format(formatedDate, "yy");

  const previousMonth = `${monthAbbreviation}'${year}`;

  const formattedCount = formatCount(allData?.middleBarValue?.totalCost || 0);

  const formattedLastMonth = formatCount(
    allData?.leftBarValues?.previousMonthValue || 0
  );

  const formattedThisYearCost = formatCount(
    allData?.leftBarValues?.thisYearValue || 0
  );

  const formattedComputeCost = formatCount(
    allData?.middleBarValue?.Compute || 0
  );
  const formattedStorageCost = formatCount(
    allData?.middleBarValue?.Storage || 0
  );
  const formattedServerlessCost = formatCount(
    allData?.middleBarValue?.Serverless || 0
  );
  const formattedContainerCost = formatCount(
    allData?.middleBarValue?.Container || 0
  );
  const formattedDatabaseCost = formatCount(
    allData?.middleBarValue?.Databases || 0
  );

  const formattedOthersCost = formatCount(allData?.middleBarValue?.Other || 0);

  const formattedNetworkCost = formatCount(
    allData?.middleBarValue?.Network || 0
  );

  const formattedSavingsAvailable = formatCount(
    allData?.rightBarValues?.totalSavingsAvailable || 0
  );

  const formattedRealizedSavings = formatCount(
    allData?.rightBarValues?.totalRealizedSavings || 0
  );

  const days = daysDifference > 1 ? "days" : "day";
  return (
    <>
      <div className={styles.heading}>
        {" "}
        {/* second DIv for calender past, cost part and combo of three divs.. */}
        {((!isLoading && data) || error) && (
          <div className={styles.headingCostExplorer}>
            {" "}
            {/* Div for Cost Insights and calender Part */}
            <div className={styles.CEDIV}>Cost Insights</div>
            <div className={styles.calmaindiv}>
              {" "}
              {/* Div for date part */}
              <div className={styles.calheading}>Select Date</div>
              <div className={styles.calsecheading}>
                <div style={{ position: "relative" }}>
                  <ReactCalender range={range} setRange={setRange} />
                </div>
              </div>
            </div>
          </div>
        )}
        {isLoading && <HeaderState />}
        {error && (
          <ScenariosErrorState
            error={error.message}
            headerItem="header"
            messageConfig={{
              [API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET]: {
                message: "No Data Available",
                additionalMessage:
                  "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
              },
              [API_LOADING_ERROR_CUSTOM_WIDGET]: {
                message: "Oops!",
                additionalMessage:
                  "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
              },
              [API_NOT_FOUND_ERROR_CUSTOM_WIDGET]: {
                message: "404 Error",
                additionalMessage:
                  "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
              },
            }}
          />
        )}
        {!error && data && (
          <div className={styles.statisticContainer}>
            <div className="ComboFirstDiv col-lg-3 col-md-12">
              <div className={styles.ComboFirstDivInnerDiv}>
                <div className={styles.TPComboDiv}>Total Period Cost</div>
                <div className={styles.LDaysDiv}>{daysDifference || "--"}</div>
              </div>

              <div className={styles.ComboSECDivFirstDiv}>
                <ToolTipComponent
                  data={allData?.middleBarValue?.totalCost || 0}
                >
                  <span style={{ cursor: "context-menu" }}>
                    {formattedCount}
                  </span>
                </ToolTipComponent>

                <div></div>
              </div>

              <div className={styles.ComboSECONDDiv}>
                <div className={styles.ComboSECONDInnerDIv}>
                  <MonthCosting
                    period={{
                      header: "Previous Month",
                      text: ` ${previousMonth}`,
                      cost: (
                        <ToolTipComponent
                          data={allData?.leftBarValues?.previousMonthValue || 0}
                        >
                          <span style={{ cursor: "context-menu" }}>
                            {" "}
                            {formattedLastMonth}
                          </span>
                        </ToolTipComponent>
                      ),
                    }}
                  />
                  <div className="vertical_line"></div>
                  <MonthCosting
                    period={{
                      header: "This Year",
                      text: "1 Jan - Today",
                      cost: (
                        <ToolTipComponent
                          data={allData?.leftBarValues?.thisYearValue || 0}
                        >
                          <span style={{ cursor: "context-menu" }}>
                            {formattedThisYearCost}
                          </span>
                        </ToolTipComponent>
                      ),
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="SECONDComboDiv col-lg-5">
              <div
                className={styles.cost_servic_header}
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <span className={styles.cost_service_text}>
                  Costs By Services
                </span>
                <img
                  style={{ cursor: "pointer" }}
                  src={zoom_in}
                  alt=""
                  width={16}
                  height={16}
                  onClick={handleShow}
                />
              </div>
              <div className={styles.cost_header}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "33%",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "8px",
                      }}
                    >
                      <img
                        src={CPU_ICON}
                        width="16px"
                        height="16px"
                        alt="icon"
                      ></img>
                      <span className={styles.text_container}>COMPUTE</span>
                    </div>

                    <div className={styles.number_container}>
                      <ToolTipComponent
                        data={allData?.middleBarValue?.Compute || 0}
                      >
                        <span style={{ cursor: "context-menu" }}>
                          {formattedComputeCost}
                        </span>
                      </ToolTipComponent>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "8px",
                      }}
                    >
                      <img
                        src={CONT_Icon}
                        width="16px"
                        height="16px"
                        alt="icon"
                      ></img>
                      <span className={styles.second_text_container}>
                        Serverless
                      </span>
                    </div>
                    <div className={styles.number_container}>
                      <ToolTipComponent
                        data={allData?.middleBarValue?.Serverless || 0}
                      >
                        <span style={{ cursor: "context-menu" }}>
                          {formattedServerlessCost}
                        </span>
                      </ToolTipComponent>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    borderLeft: "1px solid #DEE2E6",
                    paddingLeft: "25px",
                    width: "33%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "8px",
                      }}
                    >
                      <img
                        src={Storage_icon}
                        width="16px"
                        height="16px"
                        alt="icon"
                      ></img>
                      <span className={styles.text_container}>Storage</span>
                    </div>

                    <div className={styles.number_container}>
                      <ToolTipComponent
                        data={allData?.middleBarValue?.Storage || 0}
                      >
                        <span style={{ cursor: "context-menu" }}>
                          {formattedStorageCost}
                        </span>
                      </ToolTipComponent>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "8px",
                      }}
                    >
                      <img
                        src={Network_icon}
                        width="16px"
                        height="16px"
                        alt="icon"
                      ></img>
                      <span className={styles.text_container}>Network</span>
                    </div>

                    <div className={styles.number_container}>
                      <ToolTipComponent
                        data={allData?.middleBarValue?.Network || 0}
                      >
                        <span style={{ cursor: "context-menu" }}>
                          {formattedNetworkCost}
                        </span>
                      </ToolTipComponent>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderLeft: "1px solid #DEE2E6",
                    width: "33%",
                    paddingLeft: "25px",
                  }}
                >
                  <div className={styles.cost_service_data}>
                    <div className={styles.main_div_header}>
                      <div className={styles.icon_text_container}>
                        <img
                          src={database}
                          width="16px"
                          height="16px"
                          alt="icon"
                        ></img>
                        <div className={styles.text_container}>Database</div>
                      </div>
                      <div className={styles.inner_main_header}>
                        <div className={styles.number_container}>
                          <ToolTipComponent
                            data={allData?.middleBarValue?.Databases || 0}
                          >
                            <span style={{ cursor: "context-menu" }}>
                              {formattedDatabaseCost}
                            </span>
                          </ToolTipComponent>
                        </div>
                        <div className={styles.numb_dropdown_container}></div>
                      </div>
                    </div>

                    <div className={styles.inner_container_data}>
                      <div className={styles.icon_text_container}>
                        <img
                          src={dot_icon}
                          alt="icon"
                          style={{ marginTop: "4px" }}
                          width="16px"
                          height="16px"
                        ></img>
                        <div className={styles.text_container}>Others</div>
                      </div>
                      <div className={styles.second_inner_container_data}>
                        <div className={styles.number_container}>
                          <div className={styles.second_text_container}></div>
                          <ToolTipComponent
                            data={allData?.middleBarValue?.Other || 0}
                          >
                            <span style={{ cursor: "context-menu" }}>
                              {formattedOthersCost}
                            </span>
                          </ToolTipComponent>
                        </div>
                        <div className={styles.second_dropDown_container}></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="third__Container col-lg-4 col-md-12 col-sm-12"
              style={{ borderRadius: "8px" }}
            >
              <div className={styles.cost_last_div}>
                <div className={styles.cost_last_inner_div}>
                  <div className={styles.last_inner_first}>
                    <div className={styles.first1_inner_first_cont}>
                      <div className={styles.last_dropdown_container}>
                        <div className="savings_headers">Projected Savings</div>
                      </div>
                      <div className="savings_cost">
                        <div className="value__CONT">
                          <ToolTipComponent
                            data={
                              allData?.rightBarValues?.totalSavingsAvailable ||
                              0
                            }
                          >
                            <span style={{ cursor: "context-menu" }}>
                              {formattedSavingsAvailable}
                            </span>
                          </ToolTipComponent>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="idle-main-container">
                    <div className="idle-main-headers">
                      <div className="idle-resource-texts">Idle Resources</div>
                      <div className="idle-costs">
                        {allData?.rightBarValues?.totalidleResources || 0}
                      </div>
                    </div>

                    {/* <div
                    className="idle-main-headers"
                    style={{
                      borderLeft: "1px solid rgba(33, 37, 41, 0.15)",
                      paddingLeft: "8px",
                      marginLeft: "8px",
                    }}
                  >
                    <div className="idle-resource-texts">Right-Sizing</div>
                    <div className="idle-costs">
                      {allData?.rightBarValues?.totalRightSizingCount || 0}
                    </div>
                  </div> */}

                    <div
                      className="idle-main-headers"
                      style={{
                        borderLeft: "1px solid rgba(33, 37, 41, 0.15)",
                        paddingLeft: "8px",
                        marginLeft: "8px",
                      }}
                    >
                      <div className="idle-resource-texts">Snapshots</div>
                      <div className="idle-costs">
                        {allData?.rightBarValues?.totalSnapshotCount || 0}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="last-month-saving-header">
                <div className="savings-container">
                  <div className="saving-main-div">
                    <div className="savings-texts">
                      Realized savings till date
                    </div>
                    <div className="savings-costs">
                      <ToolTipComponent
                        data={
                          allData?.rightBarValues?.totalRealizedSavings || 0
                        }
                      >
                        <span style={{ cursor: "context-menu" }}>
                          {formattedRealizedSavings}
                        </span>
                      </ToolTipComponent>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/**
       *
       *
       *  DO NOT REMOVE THIS SIDE MODAL
       *  OK MAM.
       *
       *  Sure !
       *
       *
       * */}

      <SideModal
        service={service}
        showModal={show}
        handleClose={handleClose}
        toDate={toDate}
        fromDate={fromDate}
      />
    </>
  );
};

export const ToolTipComponent = ({ data, children, prefix = "$" }) => {
  const isNegative = data < 0;
  const formattedData =
    (isNegative ? "-" : "") +
    prefix +
    new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 8,
      maximumFractionDigits: 8,
    }).format(Math.abs(data) || 0);

  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip id="tooltip" style={{ cursor: "pointer" }}>
          <strong>{formattedData}</strong>
        </Tooltip>
      }
    >
      <span>{children}</span>
    </OverlayTrigger>
  );
};

export default CostExplorerHeader;

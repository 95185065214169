import { AppContext } from "@/App.context";
import config from "../../config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

const setTagValue = (token) => {
  return async (Qdata) => {
    let url =  `${config.apiHost}/v1/${Qdata?.service}/budget/tag-values?tag=${Qdata?.tags}&account=${Qdata?.account}&scope=${Qdata?.scope}`
    const setBudget = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (!setBudget.ok) {
      const errorResponse = await setBudget.json();
      throw new Error(errorResponse.message);
    }
    const res = await setBudget.json();
    const uniqueRes = [];
    const seenIds = new Set();

    (res?.data||[]).forEach((item) => { 
      if (!seenIds.has(item.id)) {
        uniqueRes.push(item);
        seenIds.add(item.id);
      }
    });
    return res;
  };
};

export const useTagValue = (queryData) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["setTagValue", queryData],
    queryFn: () => setTagValue(appContext.idToken)(queryData),
    retry:false
  });
  return query;
};
import React, { useContext, useEffect, useMemo, useState } from "react";
import styles from "@/page-components/RightSizing/rightSizing.module.css";
import style from "@/page-components/cost-Recommendation/cost-Recommendation.module.css";
import clsx from "clsx";
import Pagination from "@/components/pagination/pagination.component";
import { useCostRecommendationsList } from "@/services/cost-recommendation/getTableStats.repo";
import { CostRecommendationContext } from "../costContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import jiraHover from "@/assets/img/jiraHover.svg";
import menuicon from "@/assets/img/menu.svg";

import {
  Dropdown,
  DropdownButton,
  Offcanvas,
  OverlayTrigger,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import sorting from "@/assets/img/sorting.svg";
import downlaodVM from "@/assets/img/downloadVM.svg";
import config from "@/config";
import Viajira from "@/assets/img/viajira.svg";
import disablejira from "@/assets/img/jiradisable.svg";
import retain from "@/assets/img/retainSnap.svg";
import retaindisable from "@/assets/img/reataindisable.svg";
import styless from "@/page-components/snapshot/snapshots.module.css";

import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import TableErrorState from "@/components/States/TableErrorState";
import RecommendationModal from "./modal/recommendationModal";
import CurrencyFormatter from "@/lib/value-converison";
import FilterSection from "./filter_section/Filter";
import { useCostRecommendationsListDownlaod } from "@/services/cost-recommendation/getTableDownload";
import ToastComponent from "@/page-components/budgets/components/toast";
import AcceptViaJira from "@/components/state-change/accept-via-jira";
import RetainModal from "@/components/state-change/retain-modal";
import ViewAcceptData from "@/components/state-change/view-accept-data";
import { useUpdateAcceptCost_RecommendationJira } from "@/services/cost-recommendation/getacceptrecommendation.repo";
import { useStatisticsjiraCostRecommendation } from "@/services/cost-recommendation/getJiraStatistics.repo";

const ActiveCostTable = ({ service, name, refetchheader }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page");
  const itemsPerPage = searchParams.get("itemsPerPage");
  const [toast, setToast] = useState(false);
  const [message, setMessage] = useState();
  const [showCopyMessage, setShowCopyMessage] = useState(false);
  const [copiedResourceId, setCopiedResourceId] = useState(null);
  const recommendation_id = searchParams.get("recommendation_id");
  const [actionType, setActionType] = useState("");
  const [summary, setsummary] = useState("");
  const desc = searchParams.get("desc");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const [resourceId, setResourceId] = useState(null);
  const navigate = useNavigate();
  const formattedItsmType = String(
    config?.itsmTool ? config?.itsmTool : "JIRA"
  ).toLowerCase();
  const Itsm_type =
    formattedItsmType === "snow"
      ? "SNOW"
      : formattedItsmType.replace(/^./, (char) => char.toUpperCase());

  const parsedPage = !Number.isNaN(parseInt(page)) ? parseInt(page) : 1;
  const parsedItemsPerPage = !Number.isNaN(parseInt(itemsPerPage))
    ? parseInt(itemsPerPage)
    : 10;

  const context = useContext(CostRecommendationContext);

  const handleCopyClick = (event, resource_Id) => {
    event.stopPropagation();
    const textarea = document.createElement("textarea");
    textarea.value = resource_Id;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    setCopiedResourceId(resource_Id);
    setShowCopyMessage(true);
    setTimeout(() => {
      setShowCopyMessage(false);
    }, 5000);
  };
  const selectedResourcesModified = context?.selectedItems?.map((item) => ({
    recommId: item.recommendation_id,
    // account_name: `${item.account_name !== null ? item.account_name : ""}`,
    // account_id: item.account_id,
    // // accountName: item.accountName,
    // monthly_cost: item.monthly_cost,
  }));
  const {
    data: updateDatajira,
    mutate: acceptMutatejira,
    isSuccess: jiraAcceptSuccess,
    isError: isAcceptDismissErrorjira,
    error: acceptDismissErrorjira,
    isPending: jiraPendingReq,
  } = useUpdateAcceptCost_RecommendationJira();

  const queryjiraPopup = useMemo(() => {
    return {
      service: service,
      id: context?.selectedJiraticket,
    };
  }, [context?.selectedJiraticket, service]);

  const { data: jiraData, isLoading: jiraModalLoading } =
    useStatisticsjiraCostRecommendation(
      queryjiraPopup,
      [],
      !!context?.selectedJiraticket
    );

  const query = useMemo(() => {
    const tags = (context?.selectedTags || []).filter(
      (e) => e.filterOn.length > 0 && e.values.length > 0
    );
    return {
      service,
      page: parsedPage || 1,
      recordsPerPage: parsedItemsPerPage || 10,
      orderBy: context?.orderBy,
      desc: searchParams.get("desc") || "true",
      accounts: context?.selectedAccounts?.length
        ? context.selectedAccounts.map((e) => e.id)
        : undefined,
      // resourceGroupName:
      //   context?.selectedResourceGroup?.length > 0 &&
      //   context?.selectedResourceGroup?.length
      //     ? context.selectedResourceGroup.map((e) => e.id)
      //     : undefined,
      regions: context?.selectedRegions?.length
        ? context.selectedRegions.map((e) => e.id)
        : undefined,
      resource_type: context?.selectedResourceType?.length
        ? context.selectedResourceType.map((e) => e.id)
        : undefined,
      impact: context?.selectedImpact?.length
        ? context.selectedImpact.map((e) => e.id)
        : undefined,
      tags: tags.length ? tags : undefined,
      status: context?.selectedResourcetype,
    };
  }, [
    context?.selectedTags,
    context?.orderBy,
    context.selectedAccounts,
    context.selectedRegions,
    context.selectedResourceType,
    context.selectedImpact,
    context?.selectedResourcetype,
    service,
    parsedPage,
    parsedItemsPerPage,
    searchParams,
  ]);

  const {
    data: tableData,
    isLoading,
    error,
    refetch,
  } = useCostRecommendationsList(query);

  const handleMouseEnter = (index) => {
    setHoveredRowIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredRowIndex(null);
  };

  const showRecommendationModal = (recommendation_id) => {
    searchParams.set("recommendation_id", recommendation_id);
    setSearchParams(searchParams);
  };
  useEffect(() => {
    searchParams.delete("recommendation_id");
    setSearchParams(searchParams);
  }, []);
  const handleOrderByClick = (columnName) => {
    context?.setOrderBy(
      context?.orderBy === columnName ? columnName : columnName
    );
    const newBoolVal = desc === "true" ? "false" : "true";

    const updatedSearchParams = new URLSearchParams(searchParams);
    updatedSearchParams.set("orderBy", context?.orderBy);
    updatedSearchParams.set("desc", newBoolVal);
    updatedSearchParams.set("page", "1");
    setSearchParams(updatedSearchParams);
  };

  const downloadMutate = useMemo(() => {
    const tags = (context?.selectedTags || []).filter(
      (e) => e.filterOn.length > 0 && e.values.length > 0
    );
    return {
      page: 1,
      recordsPerPage: 10,
      orderBy: context?.orderBy,
      desc: searchParams.get("desc") || "true",
      service: service,
      accounts: context?.selectedAccounts?.length
        ? context.selectedAccounts.map((e) => e.id)
        : undefined,
      // resourceGroupName:
      //   context?.selectedResourceGroup?.length > 0 &&
      //   context?.selectedResourceGroup?.length
      //     ? context.selectedResourceGroup.map((e) => e.id)
      //     : undefined,
      regions: context?.selectedRegions?.length
        ? context.selectedRegions.map((e) => e.id)
        : undefined,
      resource_type: context?.selectedResourceType?.length
        ? context.selectedResourceType.map((e) => e.id)
        : undefined,
      impact: context?.selectedImpact?.length
        ? context.selectedImpact.map((e) => e.id)
        : undefined,
      tags: tags.length ? tags : undefined,
      status: context?.selectedResourcetype,
    };
  }, [
    context?.selectedTags,
    context?.orderBy,
    context.selectedAccounts,
    context.selectedRegions,
    context.selectedResourceType,
    context.selectedImpact,
    context?.selectedResourcetype,
    searchParams,
  ]);
  const { mutate: downloadMutateDiscard } =
    useCostRecommendationsListDownlaod(downloadMutate);

  const handleDownloadClicked = () => {
    downloadMutateDiscard(query);
  };
  const handleResourceTypeSelect = (selectedType) => {
    let status;

    switch (selectedType) {
      case "Active":
        status = "Active";
        break;
      case "In Progress":
        status = "In Progress";
        break;
      default:
        status = selectedType.toLowerCase();
    }

    context?.setSelectedResourcetype(selectedType);

    const updatedSearchParams = new URLSearchParams(searchParams);
    updatedSearchParams.set("status", status);
    updatedSearchParams.set("page", "1");
    setSearchParams(updatedSearchParams);
  };
  const handleSelectAllChange = (isChecked) => {
    const currentPageItems = tableData?.data;

    context?.setSelectedItems((prev) => {
      if (isChecked) {
        const prevIds = new Set(prev.map((item) => item.recommendation_id));
        const newItems = currentPageItems.filter(
          (item) => !prevIds.has(item.recommendation_id)
        );
        let mergedItems = [...prev, ...newItems];
        if (mergedItems.length > 20) {
          if (mergedItems.length > 20 && prev.length === 20) {
            setMessage({
              message: "You can select up to 20 Recommendations at a time.",
              type: "Info",
            });
            setToast(true);
          }
          return mergedItems.slice(0, 20);
        }
        return mergedItems;
      } else {
        return prev.filter(
          (item) =>
            !currentPageItems.some(
              (currentPageItem) =>
                currentPageItem.recommendation_id === item.recommendation_id
            )
        );
      }
    });
  };
  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleCheckboxChange = (isChecked, item) => {
    context?.setSelectedItems((prev) => {
      if (isChecked) {
        if (
          !prev.some(
            (entry) => entry.recommendation_id === item.recommendation_id
          )
        ) {
          if (prev.length >= 20) {
            setMessage({
              message: "You can select up to 20 Snapshots at a time.",
              type: "Info",
            });
            setToast(true);
            return prev;
          }
          return [...prev, item];
        }
        return prev;
      } else {
        return prev.filter(
          (entry) => entry.recommendation_id !== item.recommendation_id
        );
      }
    });
  };
  const handleShowacceptmodal = (resource_id) => {
    context.setselectedJiraticket(resource_id);
    if (resource_id) {
      context?.setAcceptViaJira(true);
    }
  };

  const handleShowJiraModal = (item) => {
    context?.setShowJiraModal(true);
    context?.setSelectedItems([item]);
  };
  const handleCloseJiraModal = () => {
    context?.setShowJiraModal(false);
    context?.setSelectedItems([]);
  };
  const handleClosecceptmodal = () => {
    context?.setAcceptViaJira(false);
    // const searchParams = new URLSearchParams(window.location.search);
    // searchParams.set("page", "1");
    // navigate(`?${searchParams.toString()}`);
  };
  const handleShowRetainModal = (item) => {
    context?.setShowRetainModal(true);
    context?.setSelectedItems([item]);
  };
  const handleCloseRetainModal = () => {
    context?.setShowRetainModal(false);
    context?.setSelectedItems([]);
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("page", "1");
    navigate(`?${searchParams.toString()}`);
  };
  useEffect(() => {
    if (jiraAcceptSuccess) {
      handleCloseJiraModal();
      handleCloseRetainModal();
      refetchheader();
      refetch();
    }
  }, [jiraAcceptSuccess]);

  useEffect(() => {
    context?.setSelectedItems([]);
  }, [context.selectedResourcetype]);

  return (
    <>
      {toast && (
        <ToastComponent
          message={message}
          toast={toast}
          setToast={setToast}
          timeout={5000}
        />
      )}

      <div className={styles.tablesFilterDiv}>
        <div
          className={clsx(
            context?.viewType === "Active" && styles.tableDiv1,
            context?.viewType === "Retained" && styles.tableDiv
          )}
        >
          <span
            className={styles.recommendationsLabel}
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {context?.viewType === "Active" &&
              `Recommendations Details (${
                tableData?.count?.total_data || 0
              } Recommendations)`}
            {context?.viewType === "Retained" && "Retained Recommendations"}
          </span>
          <div className={styless.menuStatusContainer}>
            <div
              className={styless.statsSelectedPart}
              style={{ display: "flex", gap: "8px" }}
            >
              <div className={styless.res_type}>
                <div className={styless.res_type_text}>Status</div>
                <div>
                  <DropdownButton
                    id={styless.dropdown_item_button}
                    title={
                      context?.selectedResourcetype &&
                      context?.selectedResourcetype === "InProgress"
                        ? "In Progress"
                        : context?.selectedResourcetype
                    }
                  >
                    <Dropdown.Item
                      className={styless.dropdown_item}
                      as="button"
                      onClick={() => {
                        handleResourceTypeSelect("Active");
                        // context?.setEventkey("Active");
                        // setSelectedOptionStatus("Active")
                      }}
                    >
                      Active
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={styless.dropdown_item}
                      as="button"
                      onClick={() => {
                        handleResourceTypeSelect("In Progress");
                        // context?.setEventkey("Active");
                        // setSelectedOptionStatus("In Progress")
                      }}
                    >
                      In Progress
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              </div>
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                  color: "#212529",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Selected
                <span style={{ marginLeft: "5px", color: "#797B80" }}>
                  ({(context?.selectedItems || []).length || 0})
                </span>
              </span>
            </div>

            <div
              className={styless.downloadPart}
              style={{ display: "flex", gap: "10px" }}
            >
              <div
                className={styless.res_type_text}
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  color:
                    context?.selectedItems.length > 0 ? "#056EE5" : "#ADB5BD",
                  cursor: context?.selectedItems.length > 0 ? "pointer" : "",
                }}
                onClick={() => {
                  context?.selectedItems.length > 0 &&
                    context?.setShowJiraModal(true);
                  // setactionType("jira");
                }}
              >
                {Itsm_type === "jira" && (
                  <img
                    src={
                      context?.selectedItems.length > 0 ? Viajira : disablejira
                    }
                    alt=""
                    style={{ marginRight: "-6px", color: "#ADB5BD" }}
                  />
                )}
                Accept via {Itsm_type}
              </div>
              <div
                className={styless.res_type_text}
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  cursor: context?.selectedItems.length > 0 ? "pointer" : "",
                  color: context?.selectedItems.length > 0 ? "" : "#ADB5BD",
                }}
                onClick={() => {
                  context?.selectedItems.length > 0 &&
                    context?.setShowRetainModal(true);
                  // setactionType("accept")
                }}
              >
                <img
                  src={
                    context?.selectedItems.length > 0 ? retain : retaindisable
                  }
                  alt=""
                />
                Retain
              </div>
              <div className={styless.menu_icon_css1}>
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip id="tooltip">Download as CSV</Tooltip>}
                >
                  <span
                    className={styles.downloadButton}
                    onClick={handleDownloadClicked}
                  >
                    <img src={downlaodVM} alt="" />
                  </span>
                </OverlayTrigger>
              </div>
            </div>
          </div>

          {isLoading && (
            <div className={styles.wrapper}>
              <TableErrorState />
            </div>
          )}
          {error && (
            <ScenariosErrorState
              error={error.message}
              messageConfig={{
                [API_NO_DATA_FOUND_FOR_FILTERS_ERROR]: {
                  message: "No Data Available for Selected Filters",
                  additionalMessage:
                    "It looks like there is no matching your filter criteria. Please try adjusting your filters to explore other options.",
                },
                [API_NO_DATA_FOUND_ERROR]: {
                  message: "No Data Available",
                  additionalMessage:
                    "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
                },
                [API_LOADING_ERROR]: {
                  message: "Oops!",
                  additionalMessage:
                    "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
                },
                [API_NOT_FOUND_ERROR]: {
                  message: "404 Error",
                  additionalMessage:
                    "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
                },
              }}
            />
          )}
          {tableData?.data.length > 0 && (
            <div style={{ display: "flex", paddingBottom: "33px" }}>
              <div className={styles.wrapper}>
                <div className={styles.container}>
                  <div className={style.scroll} style={{ display: "flex" }}>
                    <div style={{ overflowX: "scroll", whiteSpace: "nowrap" }}>
                      <table
                        id="customers"
                        className={clsx(styles.scrollable, "table-hover")}
                      >
                        <thead>
                          <tr>
                            <th className={styles.tableHead}>
                              {context?.selectedResourcetype === "Active" ? (
                                <input
                                  type="checkbox"
                                  style={{
                                    cursor: "pointer",
                                    width: "16px",
                                    height: "16px",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  onChange={(e) =>
                                    handleSelectAllChange(e.target.checked)
                                  }
                                  checked={tableData?.data.every((item) =>
                                    context?.selectedItems.some(
                                      (entry) =>
                                        entry.recommendation_id ===
                                        item.recommendation_id
                                    )
                                  )}
                                  name=""
                                />
                              ) : (
                                <th
                                  className={styles.tableHead}
                                  style={{ width: "8%" }}
                                ></th>
                              )}
                            </th>
                            {service === "azure" && (
                              <th className={styles.tableHead}>
                                Impacted Resource
                              </th>
                            )}
                            {service === "aws" && (
                              <th className={styles.tableHead}>
                                Recommended Action
                              </th>
                            )}
                            <th className={styles.tableHead}>
                              {service === "aws" && "Account Name"}
                              {service === "azure" && "Subscription Name"}
                            </th>
                            <th className={styles.tableHead}>Region</th>
                            {service === "aws" && (
                              <th className={styles.tableHead}>
                                Monthly Cost
                                <img
                                  style={{ cursor: "pointer" }}
                                  src={sorting}
                                  alt=""
                                  className={styles.sorting_icon}
                                  onClick={() =>
                                    handleOrderByClick("monthly_cost")
                                  }
                                ></img>
                              </th>
                            )}
                            <th className={styles.tableHead}>Resource Type</th>
                            {service === "azure" && (
                              <th className={styles.tableHead}>
                                Recommended Action
                              </th>
                            )}
                            <th className={styles.tableHead}>
                              {service === "aws" &&
                                "Recommended Resource Summary"}
                              {service === "azure" && "Lookback Period Days"}
                            </th>

                            {service === "azure" && (
                              <th className={styles.tableHead}>Impact</th>
                            )}
                            <th className={styles.tableHead}></th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoading && (
                            <tr>
                              <td colSpan={9}>
                                <div
                                  style={{
                                    width: "100%",
                                    marginBottom: 40,
                                    marginTop: 40,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <Spinner
                                    animation="border"
                                    variant="primary"
                                    style={{ top: "50" }}
                                  />
                                </div>
                              </td>
                            </tr>
                          )}
                          {(tableData?.data || []).map((item, i) => (
                            <tr
                              id={styles.table_body_tr}
                              className={clsx(styles.accountCol, {
                                [styles.hovered]: i === hoveredRowIndex,
                              })}
                              key={item.resourceId + "-" + i}
                              onMouseEnter={() => handleMouseEnter(i)}
                              onMouseLeave={handleMouseLeave}
                            >
                              <td
                                className={clsx(
                                  styles.accountCol,
                                  styles.accountColNowrap,
                                  styles.accountTitleCol,
                                  styles.accountTitleColData,
                                  styles.my_white_space
                                )}
                                style={{
                                  paddingTop: "0px",
                                  paddingBottom: "0px",
                                }}
                              >
                                {context?.selectedResourcetype === "Active" ? (
                                  <input
                                    type="checkbox"
                                    style={{
                                      cursor: "pointer",
                                      width: "16px",
                                      height: "23px",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    onChange={(e) =>
                                      handleCheckboxChange(
                                        e.target.checked,
                                        item
                                      )
                                    }
                                    checked={context?.selectedItems.some(
                                      (entry) =>
                                        entry.recommendation_id ===
                                        item.recommendation_id
                                    )}
                                  />
                                ) : (
                                  <img
                                    style={{
                                      cursor: item?.ticket_id
                                        ? "pointer"
                                        : "not-allowed",
                                      width: "16px",
                                      height: "23px",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    src={jiraHover}
                                    alt=""
                                    onClick={() => {
                                      handleShowacceptmodal(item?.ticket_id);
                                    }}
                                  />
                                )}
                              </td>
                              {service === "aws" && (
                                <td
                                  className={clsx(
                                    styles.accountCol,
                                    styles.accountColNowrap,
                                    styles.accountTitleCol,
                                    styles.accountTitleColData,
                                    styles.my_white_space
                                  )}
                                >
                                  <span
                                    style={{
                                      color: "#056EE5",
                                      textDecoration: "underline",
                                    }}
                                    onClick={() => {
                                      showRecommendationModal(
                                        item?.recommendation_id
                                      );
                                      setActionType(item?.recommended_action);
                                      setsummary(
                                        item?.recommended_resource_summary
                                      );
                                    }}
                                  >
                                    <OverlayTrigger
                                      className={style.customTooltipMAin}
                                      placement="bottom"
                                      overlay={
                                        <Tooltip
                                          id="tooltip"
                                          className={style.customTooltip}
                                        >
                                          {item?.recommended_action}
                                        </Tooltip>
                                      }
                                    >
                                      <span>
                                        {item?.recommended_action !== null
                                          ? item?.recommended_action || "--"
                                          : 0}
                                      </span>
                                    </OverlayTrigger>
                                  </span>
                                </td>
                              )}
                              {service === "azure" && (
                                <td
                                  onClick={() => {
                                    showRecommendationModal(
                                      item?.recommendation_id
                                    );
                                    setActionType(item?.recommended_action);
                                    setsummary(
                                      item?.recommended_resource_summary
                                    );
                                  }}
                                  className={styles.accountCol}
                                  style={{
                                    textWrap: "nowrap",
                                    color: "#056EE5",
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                  }}
                                >
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                      <Tooltip className={style.customTooltip}>
                                        {item?.subscription_name ??
                                          item?.impacted_value ??
                                          "--"}
                                      </Tooltip>
                                    }
                                  >
                                    <span>
                                      {item?.subscription_name ??
                                        item?.impacted_value ??
                                        "--"}
                                    </span>
                                  </OverlayTrigger>
                                </td>
                              )}

                              <td
                                className={clsx(
                                  styles.accountCol,
                                  styles.accountColNowrap
                                  // styles.accountTitleCol,
                                  // styles.accountTitleColData
                                )}
                              >
                                <span>
                                  {(item.account_name ||
                                    item?.subscription_name) !== null
                                    ? item.account_name ||
                                      item?.subscription_name ||
                                      "--"
                                    : "--"}
                                </span>
                              </td>
                              <td
                                className={clsx(
                                  styles.accountCol,
                                  styles.my_white_space
                                )}
                              >
                                {item?.region !== null
                                  ? item?.region || "--"
                                  : "--"}
                              </td>

                              {service === "aws" && (
                                <td
                                  className={clsx(
                                    styles.accountCol,
                                    styles.my_white_space
                                  )}
                                >
                                  {item?.monthly_cost ? (
                                    <CurrencyFormatter
                                      value={item?.monthly_cost}
                                      hideDollar={false}
                                    />
                                  ) : (
                                    "--"
                                  )}
                                </td>
                              )}
                              <td className={styles.accountCol}>
                                {(item?.current_resource_type ||
                                  item?.current_vm_family) !== null
                                  ? item?.current_resource_type ||
                                    item?.current_vm_family ||
                                    "--"
                                  : "--"}
                              </td>
                              {service === "azure" && (
                                <td className={styles.accountCol}>
                                  <span
                                    style={{ textWrap: "nowrap" }}
                                    className={clsx(
                                      item?.recommended_action ===
                                        "PurchaseReservedInstances" &&
                                        style.PurchaseReservedInstances,
                                      item?.recommended_action ===
                                        "PurchaseSavingsPlans" &&
                                        style.PurchaseSavingsPlans,
                                      item?.recommended_action ===
                                        "Rightsize" && style.Rightsize,
                                      item?.recommended_action ===
                                        "MigrateToGraviton" &&
                                        style.MigrateToGraviton,
                                      item?.recommended_action === "Upgrade" &&
                                        style.Upgrade
                                    )}
                                  >
                                    <OverlayTrigger
                                      placement="bottom"
                                      overlay={
                                        <Tooltip
                                          className={styles.customTooltip}
                                        >
                                          {item?.recommended_action !== null
                                            ? item?.recommended_action || "--"
                                            : "--"}
                                        </Tooltip>
                                      }
                                    >
                                      <div
                                        style={{
                                          maxWidth: "260px",
                                          textWrap: "nowrap",
                                          textOverflow: "ellipsis",
                                          overflow: "hidden",
                                        }}
                                      >
                                        {item?.recommended_action !== null
                                          ? item?.recommended_action || "--"
                                          : "--"}
                                      </div>
                                    </OverlayTrigger>
                                  </span>
                                </td>
                              )}
                              {/* <td className={styles.accountCol}>
                        {item?.recommended_resource_type !== null
                          ? item?.recommended_resource_type || "--"
                          : "--"}
                      </td> */}

                              <td className={styles.accountCol}>
                                {service === "aws" && (
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                      <Tooltip className={styles.customTooltip}>
                                        {item?.recommended_resource_summary ||
                                          "--"}
                                      </Tooltip>
                                    }
                                  >
                                    <div
                                      style={{
                                        maxWidth: "260px",
                                        textWrap: "nowrap",
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                      }}
                                    >
                                      {item?.recommended_resource_summary ||
                                        "--"}
                                    </div>
                                  </OverlayTrigger>
                                )}
                                {service === "azure" &&
                                  (item?.lookback_period_days !== null
                                    ? item?.lookback_period_days || "--"
                                    : "--")}
                              </td>
                              {service === "azure" && (
                                <td
                                  className={styles.accountCol}
                                  style={{ textWrap: "nowrap" }}
                                >
                                  {item?.impact || "--"}
                                </td>
                              )}
                              <td style={{ width: "2%" }}></td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className={style.fixed}>
                      <div>
                        <table
                          id="customers"
                          style={{ width: "100%" }}
                          className="table-hover"
                        >
                          <thead>
                            <tr>
                              <th
                                style={{
                                  textAlign: "end",
                                }}
                                className={clsx(
                                  styles.tableHead,
                                  styles.accountCol_center
                                )}
                              >
                                Projected Monthly Saving
                                <img
                                  style={{ cursor: "pointer" }}
                                  src={sorting}
                                  alt=""
                                  className={styles.sorting_icon}
                                  onClick={() =>
                                    handleOrderByClick("monthly_saving")
                                  }
                                ></img>
                              </th>
                              {context?.selectedResourcetype ===
                                "In Progress" && (
                                <th
                                  className={clsx(
                                    styles.tableHead,
                                    styles.accountCol_center
                                  )}
                                >
                                  {Itsm_type} Status
                                </th>
                              )}
                              <th
                                style={{
                                  textAlign: "end",
                                }}
                                className={clsx(
                                  styles.tableHead,
                                  styles.accountCol_center
                                )}
                              ></th>
                              {/* {service === "aws" && (

                      <th
                        className={clsx(
                          styles.tableHead,
                          styles.accountCol_center
                        )}
                        style={{
                          textAlign: "center",
                          paddingRight: "10px",
                        }}
                      >
                        Saving %
                        <img
                          style={{ cursor: "pointer" }}
                          src={sorting}
                          alt=""
                          className={styles.sorting_icon}
                          onClick={() => handleOrderByClick("saving")}
                        ></img>
                      </th>
                      )} */}
                            </tr>
                          </thead>
                          <tbody>
                            {!isLoading &&
                              (tableData?.data || []).map((item, i) => (
                                <tr
                                  style={{ position: "relative" }}
                                  className={clsx(styles.accountCol, {
                                    [styles.hovered]: i === hoveredRowIndex,
                                  })}
                                  key={item.resourceId + "-" + i}
                                  onMouseEnter={() => handleMouseEnter(i)}
                                  onMouseLeave={handleMouseLeave}
                                >
                                  <td
                                    style={{ textAlign: "center" }}
                                    className={clsx(
                                      styles.accountCol,
                                      styles.accountCol_weight,

                                      styles.accountCol_end,
                                      Number(item?.monthly_saving || 0) < 0 &&
                                        styles.accountCol_red,
                                      Number(item?.monthly_saving || 0) > 0 &&
                                        styles.accountCol_green
                                    )}
                                  >
                                    {item?.monthly_saving ? (
                                      <CurrencyFormatter
                                        value={item?.monthly_saving}
                                        hideDollar={false}
                                      />
                                    ) : (
                                      "--"
                                    )}
                                  </td>
                                  {context?.selectedResourcetype ===
                                    "In Progress" && (
                                    <td
                                      className={clsx(
                                        styles.accountCol,
                                        styles.accountCol_weight
                                      )}
                                      style={{ textAlign: "left" }}
                                    >
                                      {" "}
                                      <span
                                        className={
                                          item?.ticket_status &&
                                          styless.badgeName
                                        }
                                      >
                                        {" "}
                                        {item?.ticket_status !== null
                                          ? item?.ticket_status || "--"
                                          : "--"}
                                      </span>
                                    </td>
                                  )}
                                  {context?.selectedResourcetype !==
                                    "In Progress" && (
                                    <td
                                      className={clsx(styles.accountCol)}
                                      style={{
                                        paddingTop: "0px",
                                        paddingBottom: "0px",
                                      }}
                                    >
                                      {/* {!invalidStatus(data?.status) &&
                                            data?.status !== "done" &&
                                            Boolean(data?.isLegallyHeld) ===
                                              false
                                               && ( */}
                                      <Dropdown
                                        style={{ marginTop: "-6px" }}
                                        show={dropdownOpen[i]}
                                        onToggle={() => handleDropdownToggle(i)}
                                      >
                                        <Dropdown.Toggle
                                          className={styless.dropdown_toggle}
                                          variant="link"
                                          // id={`dropdown-basic-${}`}
                                          style={{
                                            background: "none",
                                            border: "none",
                                            padding: 0,
                                            marginTop: "5px",
                                          }}
                                        >
                                          {(context?.selectedItems || [])
                                            .length < 1 && (
                                            <img src={menuicon} alt=""></img>
                                          )}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu
                                          id={styless.dropdown_menu_item}
                                          style={{
                                            boxShadow:
                                              "0 19px 14px rgba(0, 0, 0, 0.1)",
                                            transform:
                                              "translate3d(-4px, 31.2px, 0px)",
                                            border: " 1px solid #ccc8c8",
                                          }}
                                        >
                                          <>
                                            <Dropdown.Item
                                              id={styless.dropdown_item}
                                              onClick={() => {
                                                handleShowJiraModal(item);
                                                // context?.setSelectedItems((prev) => [...prev, { ...data, index }]);
                                              }}
                                            >
                                              <span
                                                className={
                                                  styless.dropdown_names_css
                                                }
                                              >
                                                Accept via {Itsm_type}
                                              </span>
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              id={styless.dropdown_item}
                                              onClick={() => {
                                                handleShowRetainModal(item);
                                                // context?.setSelectedItems((prev) => [...prev, { ...data, index }]);
                                              }}
                                            >
                                              <span
                                                className={
                                                  styless.dropdown_names_css
                                                }
                                              >
                                                Retain
                                              </span>
                                            </Dropdown.Item>
                                          </>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                      {/* )} */}
                                    </td>
                                  )}
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <Pagination totalItems={tableData?.count?.total_data || 0} />
                </div>
                {showCopyMessage && (
                  <div className={styles.copyMessage}>Copy to Clipboard!</div>
                )}
              </div>
            </div>
          )}
        </div>

        {context?.viewType === "Active" && (
          <FilterSection service="azure" name="Subscription Name" />
        )}
      </div>

      <AcceptViaJira
        Itsm_type={Itsm_type}
        show={context?.showJiraModal}
        handleClose={handleCloseJiraModal}
        name="Recommendation"
        type={name}
        selectedRows={context?.selectedItems}
        updateStatusPending={jiraPendingReq}
        onSubmit={(data) => {
          acceptMutatejira({
            service,
            status: "accept",
            comment: data.comment,
            recommendations: selectedResourcesModified,
          });
        }}
      />
      <RetainModal
        type={name}
        show={context?.showRetainModal}
        handleClose={handleCloseRetainModal}
        name="Recommendation"
        selectedRows={context?.selectedItems}
        updateStatusPending={jiraPendingReq}
        onSubmit={(data) => {
          acceptMutatejira({
            service,
            status: "retained",
            comment: data.comment,
            recommendations: selectedResourcesModified,
          });
        }}
      />
      <ViewAcceptData
        type={name}
        Itsm_type={Itsm_type}
        show={context?.acceptViaJira}
        handleClose={handleClosecceptmodal}
        name="Recommendation"
        selectedRows={jiraData?.data}
        isLoading={jiraModalLoading}
        selectedResourceId={resourceId}
      />

      <Offcanvas
        placement="end"
        show={recommendation_id}
        onHide={() => {
          searchParams.delete("recommendation_id");
          setSearchParams(searchParams);
        }}
        style={{
          width: "70%",
        }}
      >
        <Offcanvas.Body
          className={styles.modal_body}
          style={{
            paddingTop: 16,
          }}
        >
          <RecommendationModal
            resourceId={recommendation_id}
            service={service}
            action_type={actionType}
            summary={summary}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ActiveCostTable;

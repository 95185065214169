import React, { useContext, useEffect } from "react";
import IP from "@/assets/img/IP.svg";
import vms from "@/assets/img/vms.svg";
import storage from "@/assets/img/storage.svg";
import loadbalancer from "@/assets/img/loadBalancer.svg";
import "../idle.module.css";
import styles from "../idle.module.css";
import clsx from "clsx";
import { ToolTipComponent } from "@/page-components/cost-explorer/components/cost-explorer-header.component";
import { formatCount } from "@/page-components/cost-explorer/components/cost-explorer-header.component";
import HeaderState from "@/components/States/HeaderState";
import { IdleContext } from "../idle-context-file.part";
import { useSearchParams } from "react-router-dom";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR_CUSTOM_WIDGET,
  API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET,
  API_NOT_FOUND_ERROR_CUSTOM_WIDGET,
} from "@/lib/errors/error-constants";
import ActiveRetainDropdown from "./active-dropdown-component.part";

const ArchieveHeader = ({
  service,
  data,
  isLoading,
  ipName,
  vmName,
  diskName,
  error,
}) => {
  const context = useContext(IdleContext);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const option = searchParams.get("option") || "Active";
    context?.setSelectedOption(option);
  }, [searchParams, context]);

  const formattedUnblendedCost = formatCount(
    data?.idle_resource_total?.unblended_cost || 0
  );
  const formattedIpCost = formatCount(data?.idle_resources?.ip?.cost || 0);
  const formattedVmCost = formatCount(data?.idle_resources?.vm?.cost || 0);
  const formattedDiskCost = formatCount(data?.idle_resources?.disk?.cost || 0);
  const formattedLbCost = formatCount(data?.idle_resources?.lb?.cost || 0);

  return (
    <>
      <div
        className={
          (!isLoading && data) || error
            ? clsx("container-fluid", styles.main_container)
            : ""
        }
      >
        <div className={styles.Inner_main_container}>
          {((!isLoading && data) || error) && (
            <div className={styles.inner_first_container}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className={styles.inner_first_container_text}>
                  Retained Idle Resources
                </div>
                <ActiveRetainDropdown />
              </div>
            </div>
          )}
          {isLoading && <HeaderState />}
          {error && (
            <ScenariosErrorState
              error={error.message}
              headerItem="header"
              messageConfig={{
                [API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET]: {
                  message: "No Data Available",
                  additionalMessage:
                    "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
                },
                [API_LOADING_ERROR_CUSTOM_WIDGET]: {
                  message: "Oops!",
                  additionalMessage:
                    "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
                },
                [API_NOT_FOUND_ERROR_CUSTOM_WIDGET]: {
                  message: "404 Error",
                  additionalMessage:
                    "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
                },
              }}
            />
          )}
          {!error && data && (
            <div
              className={clsx("container-fluid", styles.inner_second_container)}
            >
              <div className="row" style={{ margin: "0px" }}>
                {/* ----------------------------first one------------------------------------------- */}
                <div
                  className={clsx(
                    "container col-lg-3 col-sm-12",
                    styles.second_div_first_container
                  )}
                >
                  <div className="row">
                    <div
                      className={clsx(
                        styles.second_div_first_container_firstDiv,
                        " col-md-6"
                      )}
                    >
                      <div
                        className={clsx(
                          "container",
                          styles.ideal_resource_count
                        )}
                      >
                        Idle Resource Count
                      </div>

                      <div
                        className={clsx("container", styles.number_container)}
                      >
                        {data?.idle_resource_total?.idle_resource_count || 0}
                      </div>

                      <div className={styles.cost_block}>
                        <span className={styles.costs_name_css}>Cost:</span>
                        <span className={styles.cost_value_count}>
                          <ToolTipComponent
                            data={
                              data?.idle_resource_total?.unblended_cost || 0
                            }
                          >
                            {formattedUnblendedCost}
                          </ToolTipComponent>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* --------------------------second box------------------------------------------------------- */}
                <div
                  className={clsx(
                    "container col-lg-6 col-sm-12",
                    styles.second_div_second_container
                  )}
                >
                  <div
                    className={clsx(
                      "container-fluid",
                      styles.second_div_second_main_container
                    )}
                  >
                    <div className={clsx("container", styles.text_container)}>
                      Idle Resource{" "}
                    </div>
                    <div
                      className={clsx(
                        "container-fluid row",
                        styles.content_container
                      )}
                      style={{ margin: "0px" }}
                    >
                      <div className="row">
                        <div
                          className={clsx(
                            "container",
                            styles.content_container_box
                          )}
                        >
                          <div
                            className={clsx(
                              "container",
                              styles.content_container_check
                            )}
                          >
                            <div className={styles.content}>
                              <div
                                className={clsx(
                                  "container",
                                  styles.upperContent
                                )}
                              >
                                <img src={IP} alt="" />
                                <div
                                  className={clsx(
                                    "container",
                                    styles.upperContent_text
                                  )}
                                >
                                  {ipName}
                                </div>
                              </div>
                              <div className={styles.css_for_gap}>
                                <div
                                  className={clsx(
                                    "container",
                                    styles.number_container1
                                  )}
                                >
                                  {data?.idle_resources?.ip?.count || "--"}
                                </div>

                                <div className={styles.cost_block}>
                                  <span className={styles.costs_name_css}>
                                    Cost:
                                  </span>
                                  <span className={styles.cost_value_css}>
                                    <ToolTipComponent
                                      data={data?.idle_resources?.ip?.cost || 0}
                                    >
                                      {formattedIpCost}
                                    </ToolTipComponent>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className={clsx(
                              "container",
                              styles.content_container_check
                            )}
                          >
                            <div className={styles.content}>
                              <div
                                className={clsx(
                                  "container",
                                  styles.upperContent
                                )}
                              >
                                <img src={vms} alt="" />
                                <div
                                  className={clsx(
                                    "container",
                                    styles.upperContent_text
                                  )}
                                >
                                  {vmName}
                                </div>
                              </div>
                              <div className={styles.css_for_gap}>
                                <div
                                  className={clsx(
                                    "container",
                                    styles.number_container1
                                  )}
                                >
                                  {data?.idle_resources?.vm?.count || "--"}
                                </div>

                                <div className={styles.cost_block}>
                                  <span className={styles.costs_name_css}>
                                    Cost:
                                  </span>
                                  <span className={styles.cost_value_css}>
                                    <ToolTipComponent
                                      data={data?.idle_resources?.vm?.cost || 0}
                                    >
                                      {formattedVmCost}
                                    </ToolTipComponent>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={clsx(
                            "container",
                            styles.content_container_box
                          )}
                        >
                          <div
                            className={clsx(
                              "container",
                              styles.content_container_check
                            )}
                          >
                            <div className={styles.content}>
                              <div
                                className={clsx(
                                  "container",
                                  styles.upperContent
                                )}
                              >
                                <img src={storage} alt="" />
                                <div
                                  className={clsx(
                                    "container",
                                    styles.upperContent_text
                                  )}
                                >
                                  {diskName}
                                </div>
                              </div>
                              <div className={styles.css_for_gap}>
                                <div
                                  className={clsx(
                                    "container",
                                    styles.number_container1
                                  )}
                                >
                                  {data?.idle_resources?.disk?.count || "--"}
                                </div>

                                <div className={styles.cost_block}>
                                  <span className={styles.costs_name_css}>
                                    Cost:
                                  </span>
                                  <span className={styles.cost_value_css}>
                                    <ToolTipComponent
                                      data={
                                        data?.idle_resources?.disk?.cost || 0
                                      }
                                    >
                                      {formattedDiskCost}
                                    </ToolTipComponent>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className={clsx(
                              "container",
                              styles.content_container_check
                            )}
                          >
                            <div className={styles.content}>
                              <div
                                className={clsx(
                                  "container",
                                  styles.upperContent
                                )}
                              >
                                <img src={loadbalancer} alt="" />
                                <div
                                  className={clsx(
                                    "container",
                                    styles.upperContent_text
                                  )}
                                >
                                  Load Balancer
                                </div>
                              </div>

                              <div className={styles.css_for_gap}>
                                <div
                                  className={clsx(
                                    "container",
                                    styles.number_container1
                                  )}
                                >
                                  {data?.idle_resources?.lb?.count || "--"}
                                </div>

                                <div className={styles.cost_block}>
                                  <span className={styles.costs_name_css}>
                                    Cost:
                                  </span>
                                  <span className={styles.cost_value_css}>
                                    <ToolTipComponent
                                      data={data?.idle_resources?.lb?.cost || 0}
                                    >
                                      {formattedLbCost}
                                    </ToolTipComponent>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* -----------------------third box------------------------------------------ */}
                {/* <div
              style={{
                background: "var(--Neutral-D9, #F8F9FA)",
                borderTopRightRadius: "4px",
                borderBottomRightRadius: "4px",
              }}
              className={clsx("container col-lg-3 col-sm-12")}
            ></div> */}
                <div
                  className={clsx(
                    "container col-lg-3 col-sm-12",
                    styles.third_box_container
                  )}
                >
                  <div
                    className={clsx(
                      "container-fluid pt-2",
                      styles.innerContainer - 1
                    )}
                  >
                    <div
                      className={clsx(
                        "container p-2",
                        styles.innerContainer_div
                      )}
                    >
                      <div
                        className={clsx(
                          "container",
                          styles.innerContainer_text
                        )}
                      >
                        <div
                          className={clsx(
                            "container",
                            styles.innerContainer_text_1
                          )}
                        >
                          OPPORTUNITY MISSED
                        </div>
                        <div
                          className={clsx(
                            "container",
                            styles.innerContainer_text_2
                          )}
                        >
                          <ToolTipComponent
                            data={
                              data?.idle_resource_total?.unblended_cost || 0
                            }
                          >
                            {formattedUnblendedCost}
                          </ToolTipComponent>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className={clsx("container-fluid", styles.innerContainer_Box)}>
                <div
                  className={clsx(
                    "container-fluid",
                    styles.innerSecondContainer
                  )}
                >
                  <div
                    className={clsx(
                      styles.innerSecondContainer_text
                    )}
                  >
                   Savings on Idle Resources
                  </div>
                  <div
                    className={clsx(
                      styles.innerSecondContainer_num
                    )}
                  >
                    ${Number(data?.savings_till_date || 0).toFixed(2)}
                  </div>
                </div>
              </div> */}
                </div>
              </div>
            </div>
          )}
          {/* ---------------------------------------------------------------- */}
          {((!isLoading && data) || error) && (
            <div className={styles.inner_third_container}>
              <div
                className={clsx("container-fluid", styles.inner_contaier_text)}
              >
                Updated Today at 7:05AM
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ArchieveHeader;

/** @type {import('./global')} */
import React, { createContext, useState } from "react";

/**
 * @type {React.Context<BudgetType>}
 */
/**
 * @typedef Anomaly_ContextType
 * @property {string[]} SelectedEmail
 * @property {React.Dispatch<React.SetStateAction<string>>} setSelectedEmail
 */

export const AnomalyContext = createContext();

/**
 * @returns {Anomaly_ContextType}
 */
export const useAnomalyContext = () => {
  const [selectedID,setSelectedID] = useState()
  const [Editloading,seteditloading] = useState(false)
  const [SelectedEmail, setSelectedEmail] = useState([]);
  const [SelectedNameID, setSelectedNameID] = useState("");
  const [SelectedUserNAme, setSelectedUserNAme] = useState("");
  const [SelectedAnomalyID, setSelectedAnomalyID] = useState("");
  const [SelectedAnomaliesCount, setSelectedAnomaliesCount] = useState("");
  const [SelectedThreshold, setSelectedThreshold] = useState("");
  const [SelectedFilter, setSelectedFilter] = useState();
  const [Selectedperiod, setSelectedperiod] = useState("");
  const [SelectedOccoured, setSelectedOccoured] = useState("");
  const [isLayoverOpen, setIsLayoverOpen] = useState(false);
  const [Editdata, setEditdata] = useState();
  return {
    Editloading,
    seteditloading,
    selectedID,
    setSelectedID,
    Editdata,
    setEditdata,
    isLayoverOpen,
    setIsLayoverOpen,
    SelectedUserNAme,
    setSelectedUserNAme,
    SelectedAnomaliesCount,
    setSelectedAnomaliesCount,
    SelectedThreshold,
    setSelectedThreshold,
    SelectedFilter,
    setSelectedFilter,
    Selectedperiod,
    setSelectedperiod,
    SelectedOccoured,
    setSelectedOccoured,
    SelectedAnomalyID,
    setSelectedAnomalyID,
    SelectedEmail,
    setSelectedEmail,
    SelectedNameID,
    setSelectedNameID,
  };
};

export const AnomalyProvider = ({ children }) => {
  const context = useAnomalyContext();

  return (
    <>
      <AnomalyContext.Provider value={context}>
        {children}
      </AnomalyContext.Provider>
    </>
  );
};

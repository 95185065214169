import React, { useMemo } from "react";
import "../snapshots.module.css";
import SnapShotHeaderSection from "../components/headerSection";
import SnapshotBodySection from "../components/Snapshot.BodySection";
import { useHeaderStatistics } from "@/services/snapshots/getAllCloudHeader.repo";
import AllPageLoading from "@/components/States/AllPageLoading";
import DataLoading from "@/components/States/DataLoading";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";
import config from "@/config";

const SnapshotsMainPage = ({ service = "all-cloud" }) => {

  const EnableCloud  = config?.enabledClouds
  const query = useMemo(() => {
    return {
      service,
    };
  }, [service]);

  const { data, isLoading, isError, error } = useHeaderStatistics(query);
  

  return (
    <>
      {isLoading && <AllPageLoading />}
      {!isLoading && (
        <>
          <SnapShotHeaderSection
          EnableCloud={EnableCloud}
            isLoading={isLoading}
            error={error}
            data={data}
            service="all-cloud"
          />
          <SnapshotBodySection isLoading={isLoading}
          EnableCloud={EnableCloud}
            error={error}
            data={data}/>
        </>
      )}
    </>
  );
};

export default SnapshotsMainPage;

import { AppContext } from "@/App.context";
import config from "@/config";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NOT_FOUND_ERROR,
  ERROR_STATUS_CODES,
} from "@/lib/errors/error-constants";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

// const dummy = {
//   "data": {
//       "totalPeriodSpend": 7812.363970519055,
//       "chartData": [
//           {
//               "cost": 118.2541136865906,
//               "date": "2025-01-28",
//               "anomaly": 1
//           },
//           {
//               "cost": 119.33536277593568,
//               "date": "2025-01-29",
//               "anomaly": 1
//           },
//           {
//               "cost": 119.56617899369829,
//               "date": "2025-01-30",
//               "anomaly": 1
//           },
//           {
//               "cost": 114.37748326133188,
//               "date": "2025-01-31",
//               "anomaly": 0
//           },
//           {
//               "cost": 94.770493423788,
//               "date": "2025-02-01",
//               "anomaly": 0
//           },
//           {
//               "cost": 108.96324665555606,
//               "date": "2025-02-02",
//               "anomaly": 0
//           },
//           {
//               "cost": 112.13036486810933,
//               "date": "2025-02-03",
//               "anomaly": 0
//           },
//           {
//               "cost": 112.38707695651412,
//               "date": "2025-02-04",
//               "anomaly": 0
//           },
//           {
//               "cost": 113.42561086342478,
//               "date": "2025-02-05",
//               "anomaly": 0
//           },
//           {
//               "cost": 114.88062522072174,
//               "date": "2025-02-06",
//               "anomaly": 0
//           },
//           {
//               "cost": 115.88362760006147,
//               "date": "2025-02-07",
//               "anomaly": 0
//           },
//           {
//               "cost": 115.11262032552276,
//               "date": "2025-02-08",
//               "anomaly": 0
//           },
//           {
//               "cost": 115.3561338378225,
//               "date": "2025-02-09",
//               "anomaly": 0
//           },
//           {
//               "cost": 114.6983757013144,
//               "date": "2025-02-10",
//               "anomaly": 0
//           },
//           {
//               "cost": 113.9073195737668,
//               "date": "2025-02-11",
//               "anomaly": 0
//           },
//           {
//               "cost": 115.2760776664008,
//               "date": "2025-02-12",
//               "anomaly": 0
//           },
//           {
//               "cost": 117.3983516895659,
//               "date": "2025-02-13",
//               "anomaly": 1
//           },
//           {
//               "cost": 120.47409237423504,
//               "date": "2025-02-14",
//               "anomaly": 1
//           },
//           {
//               "cost": 120.96762987018144,
//               "date": "2025-02-15",
//               "anomaly": 1
//           },
//           {
//               "cost": 121.07304381642476,
//               "date": "2025-02-16",
//               "anomaly": 1
//           },
//           {
//               "cost": 121.1583519823334,
//               "date": "2025-02-17",
//               "anomaly": 1
//           },
//           {
//               "cost": 93.40946962722164,
//               "date": "2024-11-26",
//               "anomaly": 0
//           },
//           {
//               "cost": 81.27571190038846,
//               "date": "2024-12-01",
//               "anomaly": 0
//           },
//           {
//               "cost": 88.79427471199848,
//               "date": "2024-12-02",
//               "anomaly": 0
//           },
//           {
//               "cost": 91.19742913824275,
//               "date": "2024-12-03",
//               "anomaly": 0
//           },
//           {
//               "cost": 93.17079778244936,
//               "date": "2024-12-04",
//               "anomaly": 0
//           },
//           {
//               "cost": 93.78468174106315,
//               "date": "2024-12-05",
//               "anomaly": 0
//           },
//           {
//               "cost": 93.97618982724237,
//               "date": "2024-12-06",
//               "anomaly": 0
//           },
//           {
//               "cost": 92.69545648203425,
//               "date": "2024-12-07",
//               "anomaly": 0
//           },
//           {
//               "cost": 92.61729618315078,
//               "date": "2024-12-08",
//               "anomaly": 0
//           },
//           {
//               "cost": 92.74295954327602,
//               "date": "2024-12-09",
//               "anomaly": 0
//           },
//           {
//               "cost": 89.02414474392413,
//               "date": "2024-12-10",
//               "anomaly": 0
//           },
//           {
//               "cost": 86.97151583427981,
//               "date": "2024-12-11",
//               "anomaly": 0
//           },
//           {
//               "cost": 87.07644895081975,
//               "date": "2024-12-12",
//               "anomaly": 0
//           },
//           {
//               "cost": 87.01354184266681,
//               "date": "2024-12-13",
//               "anomaly": 0
//           },
//           {
//               "cost": 87.11468558203559,
//               "date": "2024-12-14",
//               "anomaly": 0
//           },
//           {
//               "cost": 88.08309875818264,
//               "date": "2024-12-15",
//               "anomaly": 0
//           },
//           {
//               "cost": 88.73983816485149,
//               "date": "2024-12-16",
//               "anomaly": 0
//           },
//           {
//               "cost": 88.86012409340475,
//               "date": "2024-12-17",
//               "anomaly": 0
//           },
//           {
//               "cost": 88.74834613835615,
//               "date": "2024-12-18",
//               "anomaly": 0
//           },
//           {
//               "cost": 88.74631910493723,
//               "date": "2024-12-19",
//               "anomaly": 0
//           },
//           {
//               "cost": 88.85577003529737,
//               "date": "2024-12-20",
//               "anomaly": 0
//           },
//           {
//               "cost": 88.83207970858271,
//               "date": "2024-12-21",
//               "anomaly": 0
//           },
//           {
//               "cost": 88.80752965111498,
//               "date": "2024-12-22",
//               "anomaly": 0
//           },
//           {
//               "cost": 88.78122599604454,
//               "date": "2024-12-23",
//               "anomaly": 0
//           },
//           {
//               "cost": 88.7901171621925,
//               "date": "2024-12-24",
//               "anomaly": 0
//           },
//           {
//               "cost": 88.72750590874413,
//               "date": "2024-12-25",
//               "anomaly": 0
//           },
//           {
//               "cost": 83.53662546621332,
//               "date": "2024-12-26",
//               "anomaly": 0
//           },
//           {
//               "cost": 88.72961839765821,
//               "date": "2024-12-27",
//               "anomaly": 0
//           },
//           {
//               "cost": 88.83531026912583,
//               "date": "2024-12-28",
//               "anomaly": 0
//           },
//           {
//               "cost": 88.87332125872176,
//               "date": "2024-12-29",
//               "anomaly": 0
//           },
//           {
//               "cost": 89.37198214513862,
//               "date": "2024-12-30",
//               "anomaly": 0
//           },
//           {
//               "cost": 90.06687423751062,
//               "date": "2024-12-31",
//               "anomaly": 0
//           },
//           {
//               "cost": 74.67254931743157,
//               "date": "2025-01-01",
//               "anomaly": 1
//           },
//           {
//               "cost": 83.68830109690253,
//               "date": "2025-01-02",
//               "anomaly": 0
//           },
//           {
//               "cost": 86.75693331249015,
//               "date": "2025-01-03",
//               "anomaly": 0
//           },
//           {
//               "cost": 88.41168811259054,
//               "date": "2025-01-04",
//               "anomaly": 0
//           },
//           {
//               "cost": 88.31272081231099,
//               "date": "2025-01-05",
//               "anomaly": 0
//           },
//           {
//               "cost": 88.62950767199571,
//               "date": "2025-01-06",
//               "anomaly": 0
//           },
//           {
//               "cost": 90.65033927602168,
//               "date": "2025-01-07",
//               "anomaly": 0
//           },
//           {
//               "cost": 92.03918654363449,
//               "date": "2025-01-08",
//               "anomaly": 0
//           },
//           {
//               "cost": 92.5073156005066,
//               "date": "2025-01-09",
//               "anomaly": 0
//           },
//           {
//               "cost": 92.55092909034369,
//               "date": "2025-01-10",
//               "anomaly": 0
//           },
//           {
//               "cost": 92.57418034315876,
//               "date": "2025-01-11",
//               "anomaly": 0
//           },
//           {
//               "cost": 92.59026959862148,
//               "date": "2025-01-12",
//               "anomaly": 0
//           },
//           {
//               "cost": 92.9595981003429,
//               "date": "2025-01-13",
//               "anomaly": 0
//           },
//           {
//               "cost": 93.80863357515068,
//               "date": "2025-01-14",
//               "anomaly": 0
//           },
//           {
//               "cost": 93.43145164567008,
//               "date": "2025-01-15",
//               "anomaly": 0
//           },
//           {
//               "cost": 93.71735644248312,
//               "date": "2025-01-16",
//               "anomaly": 0
//           },
//           {
//               "cost": 96.65635155639497,
//               "date": "2025-01-17",
//               "anomaly": 0
//           },
//           {
//               "cost": 98.05827954212978,
//               "date": "2025-01-18",
//               "anomaly": 0
//           },
//           {
//               "cost": 98.02082078834886,
//               "date": "2025-01-19",
//               "anomaly": 0
//           },
//           {
//               "cost": 97.90744024445662,
//               "date": "2025-01-20",
//               "anomaly": 0
//           },
//           {
//               "cost": 98.56413998065425,
//               "date": "2025-01-21",
//               "anomaly": 0
//           },
//           {
//               "cost": 99.05516272578024,
//               "date": "2025-01-22",
//               "anomaly": 0
//           },
//           {
//               "cost": 99.44853003542912,
//               "date": "2025-01-23",
//               "anomaly": 0
//           },
//           {
//               "cost": 99.21564418578508,
//               "date": "2025-01-24",
//               "anomaly": 0
//           },
//           {
//               "cost": 103.39160700760222,
//               "date": "2025-01-25",
//               "anomaly": 0
//           },
//           {
//               "cost": 104.38176707650813,
//               "date": "2025-01-26",
//               "anomaly": 0
//           },
//           {
//               "cost": 104.7167953061432,
//               "date": "2025-01-27",
//               "anomaly": 0
//           }
//       ]
//   }
// }
/**
 * @typedef AllTableStatistics
 * @property {AlltableStatics[]} snapshots
 *
 */
/**
 * @typedef AlltableStatics
 * @property {String} title
 * @property {String} anomilies
 * @property {String} lastOccurred
 * @property {String} EvaluationPeriod
 * @property {String} threshold
 * @property {Number} filter
 */

/**
 * @typedef AllBodyStatisticsRes
 * @property {AllTableStatistics} statistics
 */

/**
 * @typedef GetStatisticsCostAnomalyReq
 * @property {'aws' | 'azure' |'gcp'  } service
 * @property {number} page
 * @property {number} limit
 */

/**
 * @param {GetStatisticsCostAnomalyReq} req
 * @returns {Promise<AllBodyStatisticsRes>}
 */

export const getStatisticsCostAnomalyGraph = (token) => {
  return async ({ service,id }) => {
    // return dummy
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/cost-anomaly/list-value?anomalyId=${id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!owners.ok) {
      if (ERROR_STATUS_CODES.includes(owners.status)) {
        throw new Error(API_LOADING_ERROR);
      }
      if (owners.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR);
      }
      throw new Error((await owners.json()).message);
    }
    const res = await owners.json();
    if (res.data.chartData.length === 0) {
      throw new Error(API_NO_DATA_FOUND_ERROR);
    }

    return res;
  };
};

/**
 * @param {GetStatisticsCostAnomalyReq} req
 * @param {any[]=} deps
 */
export const useCostANomalyGraphStats = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getStatisticsCostAnomalyGraph", req, ...deps],
    queryFn: () => getStatisticsCostAnomalyGraph(appContext.idToken)(req),
    retry:false
  });
  return query;
};

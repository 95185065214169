import React, { useContext, useEffect, useMemo, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import closeCross from "@/assets/img/closecross.svg";
import info from "@/assets/img/info.svg";
import { useState } from "react";
import {
  UseAllAccountStatics,
  UseAllAWSAccountStatics,
} from "@/services/budget/getBugetsAccount.repo";
import { useUpdateBudget } from "@/services/budget/updateBudget.repo";
import styles from "./budgets.module.css";
import { joiResolver } from "@hookform/resolvers/joi";
import Offcanvas from "react-bootstrap/Offcanvas";
import DatePicker from "react-datepicker";
import includeIcon from "@/assets/img/addbuttoninblue.svg";
import {
  EditAwsBudget,
  EditAzureBudget,
  InputFields,
  SelectField,
  convertToDateFormat,
} from "./helper";
import ToastComponent from "./toast";
import SpinnerComponent from "@/page-components/admin/components/spinner.component";
import { BudgetContext } from "../budget.context";
import BudgetFilterComponent from "./FilterComponent";

const Update = ({
  editData,
  service,
  setEdit,
  alert,
  Title,
  name,
  setCurrentData,
  setConformation,
  edit,
  description,
  loading,
}) => {
  const [tempSelectValues, setTempSelectValues] = useState([]);

  const query = useMemo(() => {
    return {
      service,
    };
  }, [service]);
  const context = useContext(BudgetContext);
  

  useEffect(() => {
    if (editData?.budgetDetails?.alertReceipient) {
      const recipients = Array.isArray(editData.budgetDetails.alertReceipient)
        ? editData.budgetDetails.alertReceipient
        : [editData.budgetDetails.alertReceipient];
      const trimmedRecipients = recipients.map((email) => email.trim());
      context?.setSelectedEmail(trimmedRecipients);
    }
  }, [editData?.budgetDetails?.alertReceipient]);

  const [message, setMessage] = useState("");
  const [toast, setToast] = useState(false);
  let {
    data: updateData,
    mutate: updateBudget,
    error: updateError,
    isSuccess: successMessage,
    isError,
    isPending,
  } = useUpdateBudget();

  const filters = useMemo(() => {
    const modifiedFormat = (selected, type, data) => {
      return Object.keys(selected)
        .filter((e) => e !== "Dimension Values" && e !== "Tag Values")
        .map((key) => {
          const matchingEntry = data.find((entry) => entry.id === key);

          return {
            name: matchingEntry ? matchingEntry.name : key,
            values: selected[key].map((item) => item.id),
          };
        });
    };

    return {
      dimension: modifiedFormat(
        context?.selectedValues,
        "dimension",
        context?.extractData
      ),
      tags: modifiedFormat(
        context?.selectedTagValues,
        "tags",
        context?.extractTagsData
      ),
    };
  }, [
    context?.selectedValues,
    context?.selectedTagValues,
    context?.extractData,
    context?.extractTagsData,
  ]);

  useEffect(() => {
    context?.setSelectedValues("");
    context?.setSelectedTagValues("");
  }, [context?.selectedGroup]);

  // const { data: totaldata } = UseAllAWSAccountStatics(query);
  // const { data: TotalAzureData } = UseAllAccountStatics(query);

  const onSubmit = async (data) => {
    updateBudget(
      {
        body: {
          ...data,
          CHARGE_TYPE: "COST",
          START_DATE: convertToDateFormat(data.START_DATE),
          EXPIRATION_DATE: convertToDateFormat(data.EXPIRATION_DATE),
          AMOUNT: data.AMOUNT,
          filters,

        
        },
        service: service,
      },
      {
        onError: () => {
          setToast(true);
        },
        onSuccess: () => {
          setToast(true);
        },
      }
    );
  };
  useEffect(() => {
    if (successMessage && updateData !== undefined) {
      // setToast(true);
      setMessage({ message: updateData?.message, type: "success" });
      context?.setSelectedEmail("");
      context?.setSelectedAccounts("");
      context?.setSelectedManagement("");
      setTimeout(() => setEdit(false), 3000);
    }
    if (isError || updateError) {
      setMessage({ message: updateError?.message, type: "Anomaly" });
    }
  }, [successMessage, setEdit, setMessage, isError, updateData, updateError]);

  const handleCloseOverlay = () => {
    setEdit(false);
    context?.setSelectedEmail("");
    if(service==="azure"){
      context?.setSelectedAccounts("");
    }
    context?.setSelectedManagement("")
    // setIsOpen(false);
  };

  const validations = useMemo(() => {
    switch (service) {
      case "azure":
        return EditAzureBudget;

      case "aws":
        return EditAwsBudget;

      default:
        return null;
    }
  }, [service]);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors,dirtyFields },
    getValues,
    setValue,
    setError,
    reset,
    watch,
  } = useForm({
    resolver: joiResolver(validations),
    defaultValues: {
      CHARGE_TYPE: "COST",
      ALERTS_RECEIPIENTS:[]
      
    },
  });
  const watchAlert = watch("ALERTS_RECEIPIENTS");
  useEffect(() => {
    const fetchDataAndUpdateState = () => {
      const newData = editData?.budgetDetails;

      const fieldsToUpdate = [
        "management_group",
        "resource_group",
        "scope",
        "START_DATE",
        "EXPIRATION_DATE",
        "ACCOUNT_ID",
        "NAME",
        "PERIOD",
        "AMOUNT",
        "NOTIFICATION_TYPE",
        "COMPARISON_OPERATOR",
        "THRESHOLD_TYPE",
        "THRESHOLD",
      ];

      const startDate = convertToDateFormat(
        newData?.StartDate || newData?.startDate
      );
      setValue("ACCOUNT_ID", newData?.accountId || newData?.AccountId);

      if (name === "Subscription") {
        setValue("management_group", newData?.managementGroupId || "null");
        setValue("resource_group", newData?.resourceGroup || "null");
      }
      setValue("scope", context?.selectedGroup || null);
      setValue("NAME", newData?.BudgetName || newData?.budgetName);
      setValue("PERIOD", newData?.TimeUnit || newData?.timeUnit);
      if (name === "Account ID") {
        setValue("AMOUNT", String(newData?.Amount) || newData?.amount);
      }
      if (name === "Subscription") {
        setValue("AMOUNT", newData?.Amount || newData?.amount);
      }
      setValue(
        "NOTIFICATION_TYPE",
        newData?.NotificationType || newData?.notificationType || "--"
      );
      setValue(
        "COMPARISON_OPERATOR",
        newData?.ComparisonOperator || newData?.comparisonOperator || "--"
      );
      setValue(
        "THRESHOLD_TYPE",
        newData?.ThresholdType || newData?.thresholdType || "--"
      );
      setValue("THRESHOLD", newData?.Threshold || newData?.threshold || "--");
      // if (name === "Account ID") {
      //   setValue("ALERTS_RECEIPIENTS", newData?.AlertReceipient);
      // }

      setValue(
        "START_DATE",
        convertToDateFormat(newData?.StartDate) ||
          convertToDateFormat(newData?.startDate)
      );
      setValue(
        "EXPIRATION_DATE",
        convertToDateFormat(newData?.EndDate) ||
          convertToDateFormat(newData?.endDate)
      );

      if (control?._fields && control?._fields?.value) {
        fieldsToUpdate.forEach((field) => {
          setValue(field, control?._fields?.value);
        });
      }
    };

    fetchDataAndUpdateState();
  }, [editData, setValue, control?._fields, name, context?.selectedGroup]);

  useEffect(() => {
      const alertsRecipients =
        Array.isArray(context?.SelectedEmail) &&
        context.SelectedEmail.length > 0
          ? context.SelectedEmail
          : [];
      setValue("ALERTS_RECEIPIENTS", alertsRecipients);
  }, [context.SelectedEmail, name, setValue]);

  // useEffect(() => {
  //   const fetchDataAndUpdateState = () => {
  //     const newData = editData?.budgetDetails;

  //     const fieldsToUpdate = [
  //       "management_group",
  //       "resource_group",
  //       "scope",
  //       "START_DATE",
  //       "EXPIRATION_DATE",
  //       "ACCOUNT_ID",
  //       "NAME",
  //       "PERIOD",
  //       "AMOUNT",
  //       "NOTIFICATION_TYPE",
  //       "COMPARISON_OPERATOR",
  //       "THRESHOLD_TYPE",
  //       "THRESHOLD",
  //       "ALERTS_RECEIPIENTS",
  //     ];

  //     const startDate = convertToDateFormat(
  //       newData?.StartDate || newData?.startDate
  //     );
  //     setValue("ACCOUNT_ID", newData?.accountId || newData?.AccountId);

  //     if (name === "Subscription") {
  //       setValue("management_group", newData?.managementGroupId || "null");
  //       setValue("resource_group", newData?.resourceGroup || "null");
  //     }
  //     setValue("scope", context?.selectedGroup || null);
  //     setValue("NAME", newData?.BudgetName || newData?.budgetName);
  //     setValue("PERIOD", newData?.TimeUnit || newData?.timeUnit);
  //     if (name === "Account ID") {
  //       setValue("AMOUNT", String(newData?.Amount) || newData?.amount);
  //     }
  //     if (name === "Subscription") {
  //       setValue("AMOUNT", newData?.Amount || newData?.amount);
  //     }
  //     setValue(
  //       "NOTIFICATION_TYPE",
  //       newData?.NotificationType || newData?.notificationType || "--"
  //     );
  //     setValue(
  //       "COMPARISON_OPERATOR",
  //       newData?.ComparisonOperator || newData?.comparisonOperator || "--"
  //     );
  //     setValue(
  //       "THRESHOLD_TYPE",
  //       newData?.ThresholdType || newData?.thresholdType || "--"
  //     );
  //     // debugger
  //     setValue("THRESHOLD", newData?.Threshold || newData?.threshold || "--");
  //     if (name === "Account ID") {
  //       setValue("ALERTS_RECEIPIENTS", newData?.AlertReceipient);
  //     }
  //     // debugger
  //     if (name === "Subscription") {
  //       const alertsRecipients = Array.isArray(context?.SelectedEmail) && context.SelectedEmail.length > 0
  //           ? context.SelectedEmail
  //           : [];
  //       setValue("ALERTS_RECEIPIENTS", alertsRecipients);
  //   }

  //     setValue(
  //       "START_DATE",
  //       convertToDateFormat(newData?.StartDate) ||
  //         convertToDateFormat(newData?.startDate)
  //     );
  //     setValue(
  //       "EXPIRATION_DATE",
  //       convertToDateFormat(newData?.EndDate) ||
  //         convertToDateFormat(newData?.endDate)
  //     );

  //     // fieldsToUpdate.forEach((field) => {
  //     //   setValue(
  //     //     field,
  //     //     field === "START_DATE" || field === "EXPIRATION_DATE"
  //     //       ? convertToDateFormat(newData?.[field])
  //     //       : newData?.[field]
  //     //   );
  //     // });

  //     if (control?._fields && control?._fields?.value) {
  //       fieldsToUpdate.forEach((field) => {
  //         setValue(field, control?._fields?.value);
  //       });
  //     }
  //   };
  //   fetchDataAndUpdateState();
  // }, [editData, setValue, control?._fields, name, context?.selectedGroup, context.SelectedEmail]);

  useEffect(() => {
    if (updateError && updateError.message) {
      const regex = /\"([^"]+)\".+$/;
      const matches = updateError.message.match(regex);
      if (matches && matches.length >= 2) {
        const fieldName = matches[1];
        const errorMessage = matches[0];
        setError(fieldName, { type: "server", message: errorMessage });
      }
    }
  }, [updateError, setError]);

  const disableCondition = useMemo(() => {
    if (service === "azure") {
      return true;
    }
    if (service === "aws") {
      return false;
    }
  }, [service]);

  const [recId, setRecId] = useState(" ");

  const handleAddEmail = () => {
    const input = document.getElementById("emailInput");
    const value = input.value.trim();
  
    if (value) {
      const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
  
      if (!isValidEmail) {
        setError("ALERTS_RECEIPIENTS", {
          type: "manual",
          message: "Please enter a valid email address",
        });
      } else {
        setError("ALERTS_RECEIPIENTS", null);
        context?.setSelectedEmail((prevRecipients) => {
          const updatedRecipients = [...prevRecipients, value];
          setValue("ALERTS_RECEIPIENTS", updatedRecipients, {
            shouldValidate: true,
          });
          return updatedRecipients;
        });
        input.value = ""; // Clear the input field
      }
    }
  };

  const handleRemoveEmail = (index) => {
    context?.setSelectedEmail((prevRecipients) =>
      prevRecipients.filter((_, i) => i !== index)
    );
  };

  useEffect(() => {
    if (service === "aws") {
      const fetchDataAndUpdateState = () => {
        const newData = editData?.budgetDetails;
        const expirationDate =
          convertToDateFormat(newData?.EndDate) ||
          convertToDateFormat(newData?.endDate);
        setValue(
          "EXPIRATION_DATE",
          expirationDate ? new Date(expirationDate) : null
        );
      };
      fetchDataAndUpdateState();
    }
  }, [editData, setValue, service]);

  const selectedPeriod = watch("PERIOD");

  const getMinExpirationDate = (startDate) => {
    if (!startDate) return null;

    const start = new Date(startDate);
    if (selectedPeriod?.toLowerCase() === "monthly") {
      return new Date(start.getFullYear(), start.getMonth() + 1, 0);
    } else if (selectedPeriod?.toLowerCase() === "quarterly") {
      return new Date(start.getFullYear(), start.getMonth() + 3, 0);
    } else if (selectedPeriod?.toLowerCase() === "annually") {
      return new Date(start.getFullYear() + 1, start.getMonth(), 0);
    }
    return null;
  };

  const prevSelectedPeriodRef = useRef();
  const currentYear = new Date().getFullYear();
  useEffect(() => {
    if (editData?.budgetDetails?.startDate && editData?.budgetDetails?.endDate) {
      const startDate = new Date(editData.budgetDetails.StartDate || editData.budgetDetails.startDate);
      const endDate = new Date(editData.budgetDetails.EndDate || editData.budgetDetails.endDate);
  
      if (selectedPeriod?.toLowerCase() === "quarterly") {
        const startQuarter = `Q${Math.floor(startDate.getMonth() / 3) + 1}`;
        const endQuarter = `Q${Math.floor(endDate.getMonth() / 3) + 1}`;
  
        setPeriodValue(startQuarter);
        setPeriodValueEnd(endQuarter);
      } else if (selectedPeriod?.toLowerCase() === "monthly") {
        const startMonth = startDate.toLocaleString("default", { month: "short" });
        const endMonth = endDate.toLocaleString("default", { month: "short" });
  
        setPeriodValue(startMonth);
        setPeriodValueEnd(endMonth);
      }
  
      setYearValue(startDate.getFullYear());
      setYearValueEnd(endDate.getFullYear());
    } else {
      if (selectedPeriod?.toLowerCase() === "quarterly") {
        setPeriodValue("Q1");
        setPeriodValueEnd("Q1");
      } else if (selectedPeriod?.toLowerCase() === "monthly") {
        setPeriodValue("Jan");
        setPeriodValueEnd("Jan");
      } else if (selectedPeriod?.toLowerCase() === "annually") {
        setPeriodValue("Jan");
        setPeriodValueEnd("Jan");
      }
      setYearValue(currentYear);
      setYearValueEnd(currentYear);
    }
  }, [selectedPeriod, editData, currentYear]);
  
  const [periodValue, setPeriodValue] = useState(() => {
    if (selectedPeriod?.toLowerCase() === "quarterly") {
      return "Q1";
    } else {
      return "Jan";
    }
  });
  const [yearValue, setYearValue] = useState(currentYear);
  
  const [periodValueEnd, setPeriodValueEnd] = useState(() => {
    if (selectedPeriod?.toLowerCase() === "quarterly") {
      return "Q1";
    } else {
      return "Jan";
    }
  });
  const [yearValueEnd, setYearValueEnd] = useState(currentYear);


  useEffect(() => {
    if (
      prevSelectedPeriodRef.current !== undefined &&
      prevSelectedPeriodRef.current !== selectedPeriod
    ) {
      setValue("EXPIRATION_DATE", "");
    }
    prevSelectedPeriodRef.current = selectedPeriod;
  }, [selectedPeriod, setValue]);

  return (
    <>
      {toast && (
        <ToastComponent message={message} toast={toast} setToast={setToast} />
      )}
      <Offcanvas
        show={edit}
        placement="end"
        onHide={handleCloseOverlay}
        backdrop="static"
        style={{ width: "53%" }}
      >
        {loading && <SpinnerComponent />}
        {!loading && (
          <form
            className="mybudgetform"
            onSubmit={handleSubmit((d) => onSubmit(d))}
          >
            <div className="budget_main_div">
              <div className="budget_first_box">
                <Offcanvas.Header id="createBudgetHeader" closeButton>
                  <div className="create_budget">
                    <Offcanvas.Title>
                      <div className="create_budget_name">{Title}</div>
                    </Offcanvas.Title>
                  </div>
                </Offcanvas.Header>

                <p className="create_budget_disc">
                  Establish a financial plan and configure notifications to
                  assist you in tracking your expenditures.
                </p>
              </div>

              {/* --------------------------------input fields---------------------------------------------------------------- */}
              <Offcanvas.Body id="createBudgetBody">
                {/* -------------------------------------Account ID---------------------------------------------------------- */}
                <div className="budget_account_box">
                  <div className="budget_account">
                    <div className="budget_name">Budget Account</div>
                    <div className="budget_discription">{description}</div>
                  </div>

                  {name === "Account ID" && (
                    <>
                      <span className="radioButtonContainer">
                        <RadioButton
                          name="scope"
                          value="organization"
                          title="Organization"
                          control={control}
                          isDisable={true}
                          editData={editData?.budgetDetails?.scope}
                        />
                        <RadioButton
                          name="scope"
                          value="account"
                          title="Account"
                          control={control}
                          isDisable={true}
                          editData={editData?.budgetDetails?.scope}
                        />
                      </span>
                      <SelectField
                        control={control}
                        title={name}
                        fieldName="ACCOUNT_ID"
                        // fieldData={(totaldata?.data || []).map((item) => item)}
                        editData={
                          editData?.budgetDetails?.subscriptionname ||
                          editData?.budgetDetails?.account_name ||
                          editData?.budgetDetails?.subscriptionname ||
                          editData?.budgetDetails?.account_name ||
                          "--"
                        }
                        rules={errors.ACCOUNT_ID}
                        service={service}
                        disableOption={true}
                      />

                      <>
                        <BudgetFilterComponent
                          service={service}
                          control={control}
                          rules={errors}
                          setValue={setValue}
                          selectedTagValues={context?.selectedTagValues}
                          selectedValues={context?.selectedValues}
                          setSelectedTagValues={context?.setSelectedTagValues}
                          setSelectedValues={context?.setSelectedValues}
                          tempSelectValues={tempSelectValues}
                          setTempSelectValues={setTempSelectValues}
                          extractTagsData={context?.extractTagsData}
                          setExtractTagsData={context?.setSelectedTagValues}
                          extractData={context?.extractData}
                          setExtractData={context?.setExtractData}
                          editData={editData}
                        />
                      </>
                    </>
                  )}

                  {name === "Subscription" && (
                    <>
                      <span className="radioButtonContainer">
                        <RadioButton
                          name="scope"
                          value="management_group"
                          title="Management Group "
                          control={control}
                          isDisable={true}
                          editData={context?.selectedGroup}
                        />
                        <RadioButton
                          name="scope"
                          value="subscription"
                          title="Subscription"
                          control={control}
                          isDisable={true}
                          editData={context?.selectedGroup}
                        />
                        <RadioButton
                          name="scope"
                          value="resource_group"
                          title="Resource Group"
                          control={control}
                          isDisable={true}
                          editData={context?.selectedGroup}
                        />
                      </span>

                      <div className="charge_select">
                        {context?.selectedGroup === "management_group" && (
                          <SelectField
                            control={control}
                            title={"Management Group Name"}
                            fieldName="management_group"
                            service={service}
                            rules={errors?.baseSchema?.management_group}
                            disableOption={true}
                            editData={
                              editData?.budgetDetails?.managementGroupName ||
                              "--"
                            }
                          />
                        )}

                        {(context?.selectedGroup === "subscription" ||
                          context?.selectedGroup === "resource_group") && (
                          <SelectField
                            control={control}
                            title={"Subscription"}
                            fieldName="ACCOUNT_ID"
                            service={service}
                            rules={errors?.baseSchema?.ACCOUNT_ID}
                            disableOption={true}
                            editData={
                              editData?.budgetDetails?.subscriptionName || "--"
                            }
                          />
                        )}

                        {context?.selectedGroup === "resource_group" && (
                          <SelectField
                            control={control}
                            title={"Resource Group"}
                            placeholder={"Resource Group"}
                            fieldName="resource_group"
                            service={service}
                            rules={errors?.baseSchema?.resource_group}
                            disableOption={true}
                            editData={
                              editData?.budgetDetails?.resourceGroup || "--"
                            }
                          />
                        )}
                      </div>
                      <BudgetFilterComponent
                        service={service}
                        control={control}
                        rules={errors}
                        setValue={setValue}
                        selectedTagValues={context?.selectedTagValues}
                        selectedValues={context?.selectedValues}
                        tempSelectValues={tempSelectValues}
                        setTempSelectValues={setTempSelectValues}
                        setSelectedTagValues={context?.setSelectedTagValues}
                        setSelectedValues={context?.setSelectedValues}
                        extractTagsData={context?.extractTagsData}
                        setExtractTagsData={context?.setSelectedTagValues}
                        extractData={context?.extractData}
                        setExtractData={context?.setExtractData}
                        editData={editData}
                      />
                    </>
                  )}
                </div>

                {/*- ------------------------------------------Name, period,start date and end date---------------------------------------------------------- */}
                <div className="budget_account_box">
                  <div className="budget_account">
                    <div className="budget_name">Budget Details</div>
                    <div className="budget_discription">
                      Give your budget a unique name. Select the time window it
                      analyses during each evaluation period, its expiration
                      date and the amount.
                    </div>
                  </div>
                  <div className="charge_select">
                    <InputFields
                      title={"Name"}
                      data={
                        editData?.budgetDetails?.BudgetName ||
                        editData?.budgetDetails?.budgetName ||
                        "--"
                      }
                      register={register("NAME")}
                      errors={errors.Name}
                      service={service}
                      disableOption={true}
                    />
                    <span className="semicolon_css"></span>
                    <SelectField
                      control={control}
                      title={"Period"}
                      editData={
                        editData?.budgetDetails?.TimeUnit ||
                        editData?.budgetDetails?.timeUnit ||
                        "--"
                      }
                      disabled={true}
                      fieldName="PERIOD"
                      commonFieldData={[
                        { id: "MONTHLY", name: "Monthly" },
                        { id: "QUARTERLY", name: "Quarterly" },
                        { id: "ANNUALLY", name: "Annually" },
                      ]}
                      rules={errors.PERIOD}
                      service={service}
                      disableOption={true}
                    />
                  </div>
                  {selectedPeriod && (
                  <div className="charge_select" style={{ width: "93%" }}>
                    {/* Start Date Section */}
                    <div className="accountid_box" style={{ width: "50%" }}>
                      <div className="accountid_name">
                        Start{" "}
                        {selectedPeriod?.toLowerCase() === "monthly"
                          ? "Month"
                          : selectedPeriod?.toLowerCase() === "annually"
                          ? "Year"
                          : selectedPeriod?.toLowerCase() === "quarterly"
                          ? "Quarter"
                          : ""}
                        <span className="astriek_css">
                          <b>*</b>
                        </span>
                      </div>
                      <div style={{ width: "100%" }}>
                        <Controller
                          control={control}
                          name="START_DATE"
                          rules={errors.START_DATE}
                          render={({ field }) => {
                            const calculateDate = (
                              selectedYear,
                              selectedPeriodValue
                            ) => {
                              let date;
                              if (selectedPeriod?.toLowerCase() === "quarterly") {
                                const selectedQuarter = parseInt(selectedPeriodValue.replace("Q", ""), 10) || 1;
                                const startMonthOfQuarter =
                                  (selectedQuarter - 1) * 3;

                                date = new Date(
                                  selectedYear,
                                  startMonthOfQuarter,
                                  2
                                );
                              } else if (selectedPeriod?.toLowerCase() === "monthly") {
                                const selectedMonth = new Date(
                                  Date.parse(selectedPeriodValue + " 1")
                                ).getMonth();
                                date = new Date(selectedYear, selectedMonth, 2);
                              } else {
                                date = new Date(selectedYear, 0, 2);
                              }
                             
                              return date.toISOString().split("T")[0];
                            };

                            const handlePeriodChange = (
                              selectedPeriodValue
                            ) => {
                              const newDate = calculateDate(
                                yearValue,
                                selectedPeriodValue
                              );
                              field.onChange(newDate);
                              setPeriodValue(selectedPeriodValue); 
                            };

                            const handleYearChange = (selectedYear) => {
                              const newDate = calculateDate(
                                selectedYear,
                                periodValue
                              );
                              field.onChange(newDate);
                              setYearValue(selectedYear);
                            };

                            if (!field.value) {
                              const initialDate = calculateDate(yearValue, periodValue);
                              field.onChange(initialDate);
                          }
                            return (
                              <>
                                <div style={{ display: "flex", gap: "10px" }}>
                                  {selectedPeriod?.toLowerCase() !== "annually" && (
                                    <select
                                    disabled={true}
                                    className={styles.input_box_css}
                                      value={periodValue}
                                      onChange={(e) =>
                                        handlePeriodChange(e.target.value)
                                      }
                                    >
                                      {selectedPeriod?.toLowerCase() === "quarterly"
                                        ? ["Q1", "Q2", "Q3", "Q4"].map(
                                            (quarter) => (
                                              <option
                                                key={quarter}
                                                value={quarter}
                                              >
                                                {quarter}
                                              </option>
                                            )
                                          )
                                        : [
                                            "Jan",
                                            "Feb",
                                            "Mar",
                                            "Apr",
                                            "May",
                                            "Jun",
                                            "Jul",
                                            "Aug",
                                            "Sep",
                                            "Oct",
                                            "Nov",
                                            "Dec",
                                          ].map((month) => (
                                            <option key={month} value={month}>
                                              {month}
                                            </option>
                                          ))}
                                    </select>
                                  )}
                                  <select
                                    disabled={true}
                                    className={styles.input_box_css}
                                    value={yearValue}
                                    onChange={(e) =>
                                      handleYearChange(
                                        parseInt(e.target.value, 10)
                                      )
                                    }
                                  >
                                    {Array.from(
                                      { length: 10 },
                                      (_, i) => currentYear + i
                                    ).map((year) => (
                                      <option key={year} value={year}>
                                        {year}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                {errors.START_DATE && (
                                  <p className="budgetsError">
                                    {errors.START_DATE.message}
                                  </p>
                                )}
                              </>
                            );
                          }}
                        />
                      </div>
                    </div>
                    <span className="semicolon_css"> </span>

                    {/* End Date Section */}
                    <div className="accountid_box" style={{ width: "50%" }}>
                      <div className="accountid_name">
                        End{" "}
                        {selectedPeriod?.toLowerCase() === "monthly"
                          ? "Month"
                          : selectedPeriod?.toLowerCase() === "annually"
                          ? "Year"
                          : selectedPeriod?.toLowerCase() === "quarterly"
                          ? "Quarter"
                          : ""}
                        <span className="astriek_css">
                          <b>*</b>
                        </span>
                      </div>
                      <div style={{ width: "100%" }}>
                        <Controller
                          control={control}
                          name="EXPIRATION_DATE"
                          rules={errors.EXPIRATION_DATE}
                          render={({ field }) => {
                            const calculateLastDate = (
                              selectedYear,
                              selectedPeriodValue
                            ) => {
                              let date;
                              if (selectedPeriod?.toLowerCase() === "quarterly") {
                                const selectedQuarter = parseInt(selectedPeriodValue.replace("Q", ""), 10) || 1;
                                const endMonthOfQuarter =
                                  selectedQuarter * 3 - 1;
                                date = new Date(
                                  selectedYear,
                                  endMonthOfQuarter + 1,
                                  0
                                ); 
                              } else if (selectedPeriod?.toLowerCase() === "monthly") {
                                const selectedMonth = new Date(
                                  Date.parse(selectedPeriodValue + " 1")
                                ).getMonth();
                                date = new Date(
                                  selectedYear,
                                  selectedMonth + 1,
                                  0
                                );
                              } else {
                                date = new Date(selectedYear, 11, 31);
                              }
                              return date.toISOString().split("T")[0];
                            };

                            if (!field.value) {
                              const defaultDate = calculateLastDate(
                                currentYear,
                                periodValueEnd
                              );
                              field.onChange(defaultDate);
                            }

                            const handlePeriodChange = (
                              selectedPeriodValue
                            ) => {
                              const newDate = calculateLastDate(
                                yearValueEnd,
                                selectedPeriodValue
                              );
                              field.onChange(newDate); 
                              setPeriodValueEnd(selectedPeriodValue);
                            };

                            const handleYearChange = (selectedYear) => {
                              const newDate = calculateLastDate(
                                selectedYear,
                                periodValueEnd
                              );
                              field.onChange(newDate);
                              setYearValueEnd(selectedYear);
                            };

                            return (
                              <>
                                <div style={{ display: "flex", gap: "10px" }}>
                                  {selectedPeriod?.toLowerCase() !== "annually" && (
                                    <select
                                    disabled={true}
                                    className={styles.input_box_css}
                                      value={periodValueEnd}
                                      onChange={(e) =>
                                        handlePeriodChange(e.target.value)
                                      }
                                    >
                                      {selectedPeriod?.toLowerCase() === "quarterly"
                                        ? ["Q1", "Q2", "Q3", "Q4"].map(
                                            (quarter) => (
                                              <option
                                                key={quarter}
                                                value={quarter}
                                              >
                                                {quarter}
                                              </option>
                                            )
                                          )
                                        : [
                                            "Jan",
                                            "Feb",
                                            "Mar",
                                            "Apr",
                                            "May",
                                            "Jun",
                                            "Jul",
                                            "Aug",
                                            "Sep",
                                            "Oct",
                                            "Nov",
                                            "Dec",
                                          ].map((month) => (
                                            <option key={month} value={month}>
                                              {month}
                                            </option>
                                          ))}
                                    </select>
                                  )}
                                  <select
                                    disabled={true}
                                    className={styles.input_box_css}
                                    value={yearValueEnd}
                                    onChange={(e) =>
                                      handleYearChange(
                                        parseInt(e.target.value, 10)
                                      )
                                    }
                                  >
                                    {Array.from(
                                      { length: 10 },
                                      (_, i) => currentYear + i
                                    ).map((year) => (
                                      <option key={year} value={year}>
                                        {year}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                {errors.EXPIRATION_DATE && (
                                  <p className="budgetsError">
                                    {errors.EXPIRATION_DATE.message}
                                  </p>
                                )}
                              </>
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
                </div>
                {/* ---------------------------------------amount section------------------------------------------------------------- */}
                <div className="budget_account_box">
                  <div className="budget_account">
                    <div className="budget_name">Budget Amount</div>
                    <div className="budget_discription">
                      Give your budget amount threshold
                    </div>
                  </div>
                  <InputFields
                    unit={"($)"}
                    title={"Amount"}
                    data={editData?.data?.Amount || "--"}
                    register={register("AMOUNT")}
                    errors={errors.AMOUNT}
                    service={service}
                  />
                </div>
                {/* -------------------------------------threshold section-------------------------------------------------- */}
                <div className="budget_account_box">
                  <div className="budget_account">
                    <div className="budget_name">
                      {alert}
                      <span className="astriek_css">
                        <b>*</b>
                      </span>
                    </div>
                    <div className="budget_discription">
                      Configure alert conditions and send email notifications
                      based on your spend.
                    </div>
                  </div>
                  <div className="charge_select">
                    <SelectField
                      control={control}
                      title={"NOTIFICATION TYPE"}
                      editData={
                        editData?.budgetDetails?.NotificationType ||
                        editData?.budgetDetails?.notificationType ||
                        "--"
                      }
                      fieldName="NOTIFICATION_TYPE"
                      commonFieldData={[
                        { id: "ACTUAL", name: "Actual" },
                        { id: "FORECAST", name: "Forecast" },
                      ]}
                      azureFieldData={[
                        { id: "Actual", name: "Actual" },
                        { id: "Forecasted", name: "Forecasted" },
                      ]}
                      rules={errors.NOTIFICATION_TYPE}
                      service={service}
                      disableOption={true}
                    />
                    <span className="semicolon_css"></span>

                    <SelectField
                      control={control}
                      title={"Comparison Operator"}
                      placeholder={"Select"}
                      editData={
                        editData?.budgetDetails?.ComparisonOperator ||
                        editData?.budgetDetails?.comparisonOperator ||
                        "--"
                      }
                      fieldName="COMPARISON_OPERATOR"
                      azureFieldData={[
                        { id: "GreaterThan" },
                        { id: "EqualTo" },
                        {
                          id: "GreaterThanOrEqualTo",
                        },
                      ]}
                      commonFieldData={[
                        { id: "GREATER_THAN", name: "Greater Than" },
                        { id: "LESS_THAN", name: "Less Than" },
                        { id: "EQUAL_TO", name: "Equal To" },
                      ]}
                      service={service}
                      disableOption={false}
                      rules={errors.COMPARISON_OPERATOR}
                    />
                  </div>

                  <div className="charge_select">
                    {service === "aws" &&(
                      <>
                      
                      <SelectField
                      control={control}
                      title={"Threshold Type"}
                      editData={
                        editData?.budgetDetails?.ThresholdType ||
                        editData?.budgetDetails?.thresholdType ||
                        "--"
                      }
                      fieldName="THRESHOLD_TYPE"
                      commonFieldData={[
                        { id: "PERCENTAGE", name: "Percentage" },
                        { id: "ABSOLUTE_VALUE", name: "Absolute Value" },
                      ]}
                      azureFieldData={[{ id: "PERCENTAGE" }]}
                      rules={errors.THRESHOLD_TYPE}
                      service={service}
                      // disableOption={false}
                      disableOption={disableCondition}
                    />

                    <span className="semicolon_css"> </span>
                      </>)
                    }
                    <InputFields
                      title={"Threshold"}
                      type={"number"}
                      data={
                        editData?.budgetDetails?.Threshold ||
                        editData?.budgetDetails?.threshold ||
                        "--"
                      }
                      fieldName="THRESHOLD"
                      register={register("THRESHOLD")}
                      errors={errors.THRESHOLD}
                      service={service}
                      // disableOption={false}
                    />
                  </div>
                </div>
                {/* ---------------------------------email section----------------------------------------- */}
                <div className="budget_account_box">
                  <div className="budget_account">
                    <div className="budget_name">
                      Alert recipients (email)
                      <span className="astriek_css">
                        <b> *</b>
                      </span>
                    </div>
                  </div>
                  <div className="accountid_box">
                    <div className="accountid_name"></div>
                    {/* {name === "Account ID" && (
                      <>
                        <div>
                          <input
                            className="input_box_css"
                            name="ALERTS_RECEIPIENTS"
                            placeholder="example@email.com"
                            defaultValue={
                              editData?.budgetDetails?.AlertReceipient || "--"
                            }
                            {...register("ALERTS_RECEIPIENTS")}
                          />
                          {errors.ALERTS_RECEIPIENTS && (
                            <p className="budgetsError">
                              {errors.ALERTS_RECEIPIENTS.message}
                            </p>
                          )}
                        </div>
                      </>
                    )} */}

                  <div style={{ display: "flex" }}>
                        <div>
                          <input
                            id="emailInput"
                            type="email"
                            className="input_box_css"
                            name="ALERTS_RECEIPIENTS"
                            placeholder="example@email.com"
                            // onKeyDown={handleKeyPress}
                          />
                          {errors?.ALERTS_RECEIPIENTS && (
                            <p className="budgetsError">
                              {errors?.ALERTS_RECEIPIENTS.message ||
                                (Array.isArray(errors?.ALERTS_RECEIPIENTS) &&
                                  errors?.ALERTS_RECEIPIENTS[0]?.message)}
                            </p>
                          )}
                        </div>
                        <div
                      style={{
                        marginLeft: "16px",
                        display: "flex",
                        gap: "4px",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={handleAddEmail}
                    >
                      <img style={{ width: "12px" }} src={includeIcon} alt="" />
                      <span
                        style={{
                          color: "#056EE5",
                          fontSize: "12px",
                          fontWeight: "600",
                        }}
                      >
                        Add Email
                      </span>
                    </div>
                        </div>




                        <div
                          style={{ width: "100%", display: "flex", gap: "8px" }}
                        >
                          {(context?.SelectedEmail || []).map(
                            (recipient, index) => (
                              <div
                                key={index}
                                className={styles.badgeContainers}
                              >
                                <span
                                  key={index}
                                  className={styles.badgesValues}
                                >
                                  <span className={styles.badgeSelectedValue}>
                                    {recipient}
                                  </span>
                                  <span>
                                    <img
                                      src={closeCross}
                                      alt="crossIcon"
                                      onClick={() => handleRemoveEmail(index)}
                                    />
                                  </span>
                                </span>
                              </div>
                            )
                          )}
                        </div>
                     
                  </div>
                </div>
                {/* ----------------------------------learn more section------------------------------------------ */}
                <div className="learn_more_box">
                  <div>
                    <img src={info} alt="" />
                  </div>
                  <div>
                    <span className="learn_more_css">
                      Your budget evaluation will begin in a few hours.
                    </span>
                    <span className="highlighted_name">Learn More</span>
                  </div>
                </div>
                {/* ------------------------------------buttons------------------------------------------ */}
                <div className="button_box_edit">
                  <button
                    type="button"
                    className="delete_button"
                    onClick={() => {
                      setCurrentData({
                        managementGroup:
                          editData?.budgetDetails?.managementGroupId,
                        resourceGroup: editData?.budgetDetails?.resourceGroup,
                        ACCOUNT_ID:
                          editData?.budgetDetails?.accountId ||
                          editData?.budgetDetails?.AccountId,
                        NAME:
                          editData?.budgetDetails?.budgetName ||
                          editData?.budgetDetails?.BudgetName,
                        OPERATION: "delete",
                      });
                      setConformation((prev) => ({
                        ...prev,
                        dialog: true,
                        delete: false,
                      }));
                      // setIsOpen(false);
                      setEdit(false);
                    }}
                  >
                    Delete
                  </button>
                  <div className="cancel_create_button">
                    <button
                      className="cancel_button"
                      type="button"
                      onClick={() => handleCloseOverlay()}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="create_button">
                      {isPending ? "Updating..." : "save"}
                    </button>
                  </div>
                </div>
              </Offcanvas.Body>
            </div>
          </form>
        )}
      </Offcanvas>
    </>
  );
};
export default Update;

export const RadioButton = ({
  control,
  name,
  title,
  value,
  isDisable,
  editData,
}) => {
  const context = useContext(BudgetContext);
  return (
    <div className="radioButtonSection">
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <>
            <input
              disabled={isDisable}
              type="radio"
              name={name}
              id={value}
              defaultChecked={editData === value}
              onChange={(e) => {
                field.onChange(e.target.value);
                context?.setSelectedGroup(e.target.value);
              }}
            />
            <label>{title}</label>
          </>
        )}
      />
    </div>
  );
};

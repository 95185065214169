import React, { useContext, useState, useMemo, useEffect } from "react";
import { FilterContext } from "../../components/filter-section/filter-section.component";
import styles from "@/page-components/cost-explorer/cost.explorer.module.css";
import format from "date-fns/format";
import parse from "date-fns/parse";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAllTableStatistics } from "@/services/costExplorer/getTableData.repo";
import TableErrorState from "@/components/States/TableErrorState";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";
import Pagination from "@/components/pagination/pagination.component";
import CurrencyFormatter from "@/lib/value-converison";
import clsx from "clsx";

const AzureServiceTable = ({
  dimension,
  filters,
  tags,
  fromDate,
  toDate,
  service,
  dimensionData,
  tagname,
}) => {
  const filterContext = useContext(FilterContext);
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page");
  const itemsPerPage = searchParams.get("itemsPerPage");

  const handleMouseEnter = (index) => {
    setHoveredRowIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredRowIndex(null);
  };

  const tableQuery = useMemo(() => {
    let tags = (filterContext?.selectedTags || []).filter((e) => {
      return e.filterOn.length > 0 && e.values.length > 0;
    });
    if (filterContext?.untagged) {
      tags = [];
    }
    if (filterContext?.untaggable) {
      tags = [];
    }
    const filters = (filterContext?.selectedFilters || []).filter((e) => {
      return e.dimension.length > 0 && e.values.length > 0;
    });
    const parsedPage = !Number.isNaN(parseInt(page)) ? parseInt(page) : 1;
    const parsedItemsPerPage = !Number.isNaN(parseInt(itemsPerPage))
      ? parseInt(itemsPerPage)
      : 10;

    return {
      fromDate,
      toDate,
      serviceName: "filter",
      service,
      page: parsedPage || 1,
      limit: parsedItemsPerPage || 20,
      dimension: filterContext?.selectedDimension,
      granularity: filterContext?.selectedGranularity,
      showUntaggedOnly: filterContext?.untagged ? true : undefined,
      showUntaggableOnly: filterContext?.untaggable ? true : undefined,
      filters: filters?.length > 0 ? filters : undefined,
      tags: tags?.length > 0 ? tags : undefined,
      tagname: filterContext?.selectedGroupTags,
    };
  }, [
    filterContext?.selectedTags,
    filterContext?.untagged,
    filterContext?.untaggable,
    filterContext?.selectedFilters,
    filterContext?.selectedDimension,
    filterContext?.selectedGranularity,
    filterContext?.selectedGroupTags,
    page,
    itemsPerPage,
    fromDate,
    toDate,
    service,
  ]);

  const {
    data,
    isLoading: tableLoading,
    error,
  } = useAllTableStatistics(tableQuery);

  useEffect(() => {
    const backButtonClicked = JSON.parse(
      localStorage.getItem("backButtonClicked")
    );

    if (backButtonClicked !== true) {
      setSearchParams((prevParams) => {
        const updatedParams = new URLSearchParams(prevParams);
        updatedParams.set("page", "1");
        return updatedParams;
      });
    }
  }, [
    filterContext.selectedTags,
    filterContext.untagged,
    filterContext.untaggable,
    filterContext.selectedFilters,
    filterContext.selectedDimension,
    filterContext.selectedGranularity,
    filterContext.selectedGroupTags,
  ]);

  const dataFiltered = data?.dates || [];

  const handleUsageDetails = (e, services) => {
    const dimensionCheck = (dimensionData?.dimensions || []).find(
      (d) => d.id === filterContext.selectedDimension
    );
    const dimensionUsageName = dimensionCheck?.name || "--";
    const localdata = {
      service: services,
      toDate: toDate,
      fromDate: fromDate,
      dimension: dimension,
      dimensionUsageName,
      tags: tags,
      filter: filters,
      cloud: service,
      granularity: filterContext.selectedGranularity,
      showUntaggedOnly: filterContext.untagged,
      showUntaggableOnly: filterContext.untaggable,
      filterName: filterContext?.selectedDimension,
      tagname: tagname,
    };
    localStorage.setItem("currentPage", page);
    localStorage.setItem("usageDetails", JSON.stringify(localdata));

    navigate("/azure/details");
    window.scrollTo(0, 0);
  };
  const dimensionCheck = (dimensionData?.dimensions || []).find(
    (d) => d.id === filterContext.selectedDimension
  );
  const dimensionName = dimensionCheck?.name || "All Dimensions";

  const removeLocalStorageItems = (keys, delay = 0) => {
    setTimeout(() => {
      keys.forEach((key) => localStorage.removeItem(key));
    }, delay);
  };

  useEffect(() => {
    const savedPage = localStorage.getItem("currentPage");
    const backButtonClicked = JSON.parse(
      localStorage.getItem("backButtonClicked")
    );

    if (backButtonClicked === true && savedPage) {
      setSearchParams((prevParams) => {
        const updatedParams = new URLSearchParams(prevParams);
        updatedParams.set("page", savedPage);
        return updatedParams;
      });
    }
    removeLocalStorageItems(["backButtonClicked", "currentPage"]);
  }, []);

  // const totalRowValues = (data?.data || []).map((e) => {
  //   return (dataFiltered || []).reduce((acc, date) => {
  //     return acc + (e[date] ? parseFloat(e[date]) : 0);
  //   }, 0);
  // });
  const dataWithTotal = (data?.data || []).map((e) => {
    const total = (dataFiltered || []).reduce((acc, date) => {
      return acc + (e[date] ? parseFloat(e[date]) : 0);
    }, 0);
    return { ...e, total };
  });

  const sortedData = [...dataWithTotal].sort((a, b) => b.total - a.total);

  const totalRowValues = sortedData.map((e) => e.total);

  const totalDimensionName =
    dimensionCheck?.name && `${dimensionCheck?.name} Values`;

  return (
    <>
      {tableLoading && <TableErrorState />}
      {error && (
        <ScenariosErrorState
          error={error.message}
          messageConfig={{
            [API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR]: {
              message: "No Data for the Selected Date Range",
              additionalMessage:
                "The selected date range doesn’t contain any savings data. Try choosing a different range to view available savings.",
            },
            [API_NO_DATA_FOUND_FOR_FILTERS_ERROR]: {
              message: "No Data Available for Selected Filters",
              additionalMessage:
                "It looks like there is no matching your filter criteria. Please try adjusting your filters to explore other options.",
            },
            [API_LOADING_ERROR]: {
              message: "Oops!",
              additionalMessage:
                "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
            },
            [API_NOT_FOUND_ERROR]: {
              message: "404 Error",
              additionalMessage:
                "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
            },
            [API_NO_DATA_FOUND_ERROR]: {
              message: "No Data Available",
              additionalMessage:
                "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
            },
          }}
        />
      )}
      {!tableLoading && dataFiltered.length > 0 && (
        <>
          <div className="table_main_container">
            <div className="all_cloud_text mb-3">Costs and usage breakdown</div>
            <div style={{ overflowX: "auto", maxWidth: "100%" }}>
              <table style={{ width: "100%" }}>
                <thead style={{ backgroundColor: "#f6f6f6" }}>
                  <tr>
                    <div
                      className={styles.sticky_div}
                      style={{
                        backgroundColor: "#f6f6f6",
                      }}
                    >
                      <th
                        className={styles.table_class}
                        style={{
                          maxWidth: "170px",
                          textWrap: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          width: "165px",
                          textAlign: "left",
                        }}
                        title={dimensionName}
                      >
                        {dimensionName}
                      </th>
                      <th className={styles.table_class}>Total</th>
                    </div>
                    {(dataFiltered || []).map((item, index) => (
                      <th
                        className={styles.table_class}
                        key={item + "-" + index}
                      >
                        {filterContext?.selectedGranularity === "date" &&
                          format(
                            parse(item, "dd-MM-yyyy", new Date()),
                            "dd LLL",
                            new Date()
                          )}
                        {filterContext?.selectedGranularity !== "date" && item}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {(sortedData || []).map((e, index) => (
                    <tr
                      key={"date-" + e}
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={handleMouseLeave}
                      style={{
                        backgroundColor:
                          hoveredRowIndex === index ? "#F0F6FF" : "transparent",
                        borderBottom: "1px solid #f6f6f6",
                      }}
                    >
                      <div
                        className={styles.sticky_div}
                        style={{
                          backgroundColor:
                            hoveredRowIndex === index ? "#F0F6FF" : "#fff",
                          // boxShadow:"39px 0px 31px -15px rgba(0,0,0,0.42)"
                        }}
                      >
                        {e["Service"] !== "Dimensions" && (
                          <td
                            className={styles.table_values}
                            style={{
                              textAlign: "left",
                              cursor: "pointer",
                              color: "#0F2573",
                              fontSize: "14px",
                              left: 0,
                              maxWidth: "200px",
                              textWrap: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              fontWeight: 600,
                            }}
                            onClick={() =>
                              handleUsageDetails(
                                e,
                                e["Service"] !== "Null"
                                  ? e["Service"] || "NA"
                                  : "NA"
                              )
                            }
                            title={
                              e["Service"] !== "Null"
                                ? e["Service"] || "NA"
                                : "NA"
                            }
                          >
                            {e["Service"] !== "Null"
                              ? e["Service"] || "NA"
                              : "NA"}
                          </td>
                        )}
                        {e["Service"] === "Dimensions" && (
                          <td className={styles.table_values}>Total Costs</td>
                        )}

                        <td
                          className={clsx(styles.table_values)}
                          style={{
                            textAlign: "right",
                            minWidth: "120px",
                          }}
                        >
                          <CurrencyFormatter
                            value={totalRowValues[index]}
                            hideDollar={true}
                          />
                        </td>
                      </div>
                      {(dataFiltered || []).map((date) => {
                        return (
                          <td
                            className={styles.tableData}
                            key={`date-column-${date}`}
                            style={{ textAlign: "right", fontWeight: "400" }}
                          >
                            {e[date] ? (
                              <CurrencyFormatter
                                value={e[date] || 0}
                                hideDollar={true}
                              />
                            ) : (
                              "0.00"
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr className={styles.tFoot}>
                    <td
                      colSpan={1000}
                      style={{ border: "none", padding: "8px" }}
                    >
                      *Figures in USD
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
          <Pagination totalItems={data?.total || 0} />
        </>
      )}
    </>
  );
};

export default AzureServiceTable;

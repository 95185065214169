import React, { useContext, useMemo, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import awsLogo from "@/assets/img/aws_logo.svg";
import azureLogo from "@/assets/img/azurelogo.svg";
import gcpLogo from "@/assets/img/gcp_logo.svg";
import styles from "@/page-components/billing-credit/billing-credit.module.css";
import tooltipStyles from "@/page-components/idle-resource/idle.module.css";
import { parse, compareAsc } from "date-fns";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import { BillingContext } from "../billing-context.part";
import { useAllCloudBillingCredit } from "@/services/billing-credit/getAllCloudBarData.repo";
import BarGraphErrorStates from "@/components/States/BarGraphErrorStates";
import CurrencyFormatter from "@/lib/value-converison";
import config from "@/config";

const AllCloudBarChart = ({ service }) => {
  const [hoveredStack, setHoveredStack] = useState(null);
  const context = useContext(BillingContext);

  const query = useMemo(() => {
    const queryData = {
      service: service,
    };

    return queryData;
  }, [service]);

  const { data, isLoading, error } = useAllCloudBillingCredit(query);

  const transformData = (data) => {
    const allMonths = Array.from(
      new Set([
        ...(data?.aws || []).map((d) => d.month),
        ...(data?.azure || []).map((d) => d.month),
        ...(data?.gcp || []).map((d) => d.month),
      ])
    );

    const sortedData = allMonths.sort((a, b) => {
      const aDate = parse(a, "MMM yy", new Date());
      const bDate = parse(b, "MMM yy", new Date());
      return compareAsc(aDate, bDate);
    });

    return sortedData.map((month) => ({
      name: month,
      awsBilledAmount:
        data.aws?.find((d) => d.month === month)?.billed_amount || 0,
      awsCreditApplied:
        data.aws?.find((d) => d.month === month)?.credit_applied || 0,
      awsTotalCharges:
        data.aws?.find((d) => d.month === month)?.total_charges || 0,
      azureBilledAmount:
        data.azure?.find((d) => d.month === month)?.billed_amount || 0,
      azureCreditApplied:
        data.azure?.find((d) => d.month === month)?.credit_applied || 0,
      azureTotalCharges:
        data.azure?.find((d) => d.month === month)?.total_charges || 0,
      gcpBilledAmount:
        data.gcp?.find((d) => d.month === month)?.billed_amount || 0,
      gcpCreditApplied:
        data.gcp?.find((d) => d.month === month)?.credit_applied || 0,
      gcpTotalCharges:
        data.gcp?.find((d) => d.month === month)?.total_charges || 0,
    }));
  };

  const transformedData = transformData(data);

  const hasAWSData =
    config.enabledClouds.has("aws") &&
    transformedData.some(
      (entry) => entry.awsBilledAmount > 0 || entry.awsCreditApplied > 0
    );
  const hasAzureData =
    config.enabledClouds.has("azure") &&
    transformedData.some(
      (entry) => entry.azureBilledAmount > 0 || entry.azureCreditApplied > 0
    );
  const hasGCPData =
    config.enabledClouds.has("gcp") &&
    transformedData.some(
      (entry) => entry.gcpBilledAmount > 0 || entry.gcpCreditApplied > 0
    );

  const groupedBarConfigurations = {
    ...(hasAWSData && {
      AWS: [
        { label: "Billed Amount", dataKey: "awsBilledAmount", fill: "#FFE1B3" },
        {
          label: "Credit Applied",
          dataKey: "awsCreditApplied",
          fill: "#FF9900",
        },
      ],
    }),
    ...(hasAzureData && {
      Azure: [
        {
          label: "Billed Amount",
          dataKey: "azureBilledAmount",
          fill: "#BCD7E9",
        },
        {
          label: "Credit Applied",
          dataKey: "azureCreditApplied",
          fill: "#1F78B4",
        },
      ],
    }),
    ...(hasGCPData && {
      GCP: [
        { label: "Billed Amount", dataKey: "gcpBilledAmount", fill: "#C3E6D5" },
        {
          label: "Credit Applied",
          dataKey: "gcpCreditApplied",
          fill: "#0F9D58",
        },
      ],
    }),
  };

  const CustomTooltip = ({ active, payload, label, stackId }) => {
    if (active && payload && payload.length) {
      const cloudData = transformedData.find((d) => d.name === label) || {};

      const cloudInfo = {
        AWS: {
          billedAmount: cloudData.awsBilledAmount,
          creditApplied: cloudData.awsCreditApplied,
          totalCharges: cloudData.awsTotalCharges,
          logo: awsLogo,
        },
        Azure: {
          billedAmount: cloudData.azureBilledAmount,
          creditApplied: cloudData.azureCreditApplied,
          totalCharges: cloudData.azureTotalCharges,
          logo: azureLogo,
        },
        GCP: {
          billedAmount: cloudData.gcpBilledAmount,
          creditApplied: cloudData.gcpCreditApplied,
          totalCharges: cloudData.gcpTotalCharges,
          logo: gcpLogo,
        },
      };

      if (!stackId || !config.enabledClouds.has(stackId.toLowerCase()))
        return null;
      const { billedAmount, creditApplied, totalCharges, logo } =
        cloudInfo[stackId] || {};

      return (
        <div
          className={tooltipStyles.custom_tooltip}
          style={{ width: "275px" }}
        >
          <div
            className={tooltipStyles.tooltip_date}
            // style={{ paddingLeft: "8px" }}
          >
            <div className={tooltipStyles.tooltip_date_css}>{label}</div>
          </div>

          <table style={{ width: "92%", margin: "0px 12px 4px 12px" }}>
            <tbody>
              <tr className={styles.tooltip_row}>
                <td style={{ paddingLeft: "0px" }}>
                  <img src={logo} alt="" />
                  <span className={styles.tooltip_tr_name}>{stackId}</span>
                </td>
              </tr>
              <tr className={styles.tooltip_row}>
                <div className={styles.tooltip_table_flex}>
                  <span className={styles.tooltip_cloud_name}>
                    Billed Amount
                  </span>
                  <span className={styles.tooltip_tr_value}>
                    <CurrencyFormatter value={billedAmount} />
                  </span>
                </div>
              </tr>
              <tr className={styles.tooltip_row}>
                <div className={styles.tooltip_table_flex}>
                  <span className={styles.tooltip_cloud_name}>
                    Credit Applied
                  </span>
                  <span className={styles.tooltip_tr_value}>
                    <CurrencyFormatter value={creditApplied} />
                  </span>
                </div>
              </tr>
              <tr className={styles.tooltip_row}>
                <div className={styles.tooltip_table_flex}>
                  <span className={styles.tooltip_cloud_name}>
                    Total Charges
                  </span>
                  <span className={styles.tooltip_tr_value}>
                    <CurrencyFormatter value={totalCharges} />
                  </span>
                </div>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }
    return null;
  };

  const CustomLegend = ({ groupedBars }) => {
    return (
      <div
        style={{ display: "flex", justifyContent: "center", margin: "10px 0" }}
      >
        {Object.keys(groupedBars).map((provider, index) => (
          <div
            key={index}
            style={{ display: "flex", alignItems: "center", marginRight: 40 }}
          >
            <span
              style={{
                fontWeight: 500,
                fontSize: 12,
                fontFamily: "Inter",
                marginRight: 10,
                color: "#000000",
              }}
            >
              {provider}:
            </span>
            {groupedBars[provider].map((bar, barIndex) => (
              <div
                key={barIndex}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: 15,
                }}
              >
                <div
                  style={{
                    width: 14,
                    height: 14,
                    backgroundColor: bar.fill,
                    marginRight: 5,
                    borderRadius:"2px"
                  }}
                ></div>
                <span
                  style={{
                    fontSize: 12,
                    fontWeight: 400,
                    fontFamily: "Inter",
                    color: "#000000",
                  }}
                >
                  {bar.label}
                </span>
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className={styles.graph_main}>
      {((!isLoading && data) || error) && (
        <div className={styles.date_total}>
          <span className={styles.billing_details}>
            Billing/Credits Applied
          </span>
          <span className={styles.total_month}>Last 3 months</span>
        </div>
      )}

      {isLoading && <BarGraphErrorStates />}
      {error && (
        <ScenariosErrorState
          error={error.message}
          messageConfig={{
            [API_NO_DATA_FOUND_ERROR]: {
              message: "No Data Available",
              additionalMessage:
                "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
            },
            [API_LOADING_ERROR]: {
              message: "Oops!",
              additionalMessage:
                "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
            },
            [API_NOT_FOUND_ERROR]: {
              message: "404 Error",
              additionalMessage:
                "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
            },
          }}
        />
      )}
      {data && (data?.aws?.length > 0 || data?.azure?.length > 0) && (
        <div style={{ width: "100%" }}>
          <ResponsiveContainer height={300} width="100%">
            <BarChart
              width={500}
              height={300}
              data={transformedData}
              margin={{
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              <XAxis
                dataKey="name"
                tickLine={false}
                axisLine={false}
                fontSize={10}
                fontFamily="Inter"
                fontWeight={400}
                stroke="#A3A3A3"
              />
              <YAxis
                label={{ fill: "#A3A3A3" }}
                tickLine={false}
                axisLine={false}
                fontSize={10}
                fontFamily="Inter"
                fontWeight={400}
                stroke="#A3A3A3"
              />
              <CartesianGrid strokeDasharray="0" stroke="#F0F0F0" />
              <Tooltip
                content={<CustomTooltip stackId={hoveredStack} />}
                cursor={{ fill: "transparent" }}
              />
              {Object.keys(groupedBarConfigurations).map((provider) =>
                groupedBarConfigurations[provider].map((bar, index) => (
                  <Bar
                    key={`${provider}-${index}`}
                    // barSize={100}
                    dataKey={bar.dataKey}
                    fill={bar.fill}
                    stackId={provider}
                    name={bar.label}
                    onMouseEnter={() => setHoveredStack(provider)}
                  />
                ))
              )}
            </BarChart>
            {Object.keys(groupedBarConfigurations).length > 0 && (
              <CustomLegend groupedBars={groupedBarConfigurations} />
            )}
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
};

export default AllCloudBarChart;

import { AppContext } from "@/App.context";
import config from "@/config";
import {
  API_LOADING_ERROR_CUSTOM_WIDGET,
  API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET,
  API_NOT_FOUND_ERROR_CUSTOM_WIDGET,
  ERROR_STATUS_CODES,
} from "@/lib/errors/error-constants";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

/**
 * @typedef GetAllStatisticsFilters
 * @property {keyof typeof AWS_TAGS | keyof typeof AZURE_TAGS} filterOn
 * @property {'include' | 'exclude'} filterType
 * @property {string[]} values
 */

/**
 * @typedef GetAllStatistics
 * @property {GetTotalStatistics} idle_resource_total
 * @property {GetIdleStatistics} idle_resources
 * @property {number} savings_till_date
 * @property {number} savings_available
 */
/**
 * @typedef GetTotalStatistics
 * @property {number} idle_resource_count
 * @property {number} unblended_cost
 */

/**
 * @typedef GetIdleStatistics
 * @property {GetDiskStatistics=} disk
 * @property {GetVmStatistics=} vm
 * @property {GetIpStatistics=} ip
 * @property {GetLoadStatistics=} lb
 */

/**
 * @typedef GetDiskStatistics
 * @property {number} resource_type
 * @property {number} count
 * @property {number} cost
 */

/**
 * @typedef GetVmStatistics
 * @property {number} resource_type
 * @property {number} count
 * @property {number} cost
 */
/**
 * @typedef GetLoadStatistics
 * @property {number} resource_type
 * @property {number} count
 * @property {number} cost
 */

/**
 * @typedef GetIpStatistics
 * @property {number} resource_type
 * @property {number} count
 * @property {number} cost
 */

/**
 * @typedef GetIdleFilters
 * @property {string[]} accounts
 * @property {string[]} rg
 * @property {string[]} regions
 * @property {string[]} tags
 */

// /**
//  * @typedef GetResultStatistics
//  * @property {number} saving_available  - Email address
//  */

/**
 * @typedef GetAllStatisticsRes
 * @property {GetAllStatistics[]} statistics
 *  @property {GetIdleFilters} filters
 */
/**
 * @typedef GetStatisticsReq
 * @property {'aws' | 'gcp' | 'azure' | 'all-cloud' } service
 * @property { 'false' | 'true' } untagged
 */

export const getHistoryStatistics = (token) => {
  /**
   * @param {GetStatisticsReq} req
   * @param {AbortSignal=} signal
   * @returns {Promise<GetAllStatistics>}
   */
  return async ({ service, filters, end_date, start_date, period }, signal) => {
    const body = {
      accounts: filters?.accounts || [],
      regions: filters?.regions || [],
      period: period,
      start_date: start_date,
      end_date: end_date,
    };

    if (service === "azure") {
      body.rg = filters?.rg || [];
    }
    const url = new URL(
      `${config.apiHost}/v1/${service}/idle-resources/history-stats`
    );

    const owners = await fetch(url.toString(), {
      method: "POST",
      body: JSON.stringify(body),
      signal,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (!owners.ok) {
      if (ERROR_STATUS_CODES.includes(owners.status)) {
        throw new Error(API_LOADING_ERROR_CUSTOM_WIDGET);
      }
      if (owners.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR_CUSTOM_WIDGET);
      }
      throw new Error((await owners.json()).message);
    }
    const data = await owners.json();

    if (
      (!data?.data?.data?.idle_resource_total?.idle_resource_count ||
        data?.data?.data?.idle_resource_total?.idle_resource_count === 0) &&
      (!data?.data?.data?.idle_resource_total?.saved_on_deletion ||
        data?.data?.data?.idle_resource_total?.saved_on_deletion === 0) &&
      (!data?.data?.data?.idle_resources?.ip?.count ||
        data?.data?.data?.idle_resources?.ip?.count === 0) &&
      (!data?.data?.data?.idle_resources?.vm?.count ||
        data?.data?.data?.idle_resources?.vm?.count === 0) &&
      (!data?.data?.data?.idle_resources?.disk?.count ||
        data?.data?.data?.idle_resources?.disk?.count === 0) &&
      (!data?.data?.data?.idle_resources?.lb?.count ||
        data?.data?.data?.idle_resources?.lb?.count === 0) 
    ) {
      throw new Error(API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET);
    }

    return data;
  };
};

/**
 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useHistoryStatistics = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getHistoryStatistics", appContext.idToken, req, ...deps],
    queryFn: ({ signal }) =>
      getHistoryStatistics(appContext.idToken)(req, signal),
  });
  return query;
};

import { useContext } from "react";
import config from "../../config";
import { useQuery } from "@tanstack/react-query";
import { AppContext } from "@/App.context";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR,
  API_NOT_FOUND_ERROR,
  ERROR_STATUS_CODES,
} from "@/lib/errors/error-constants";

/**
 * @typedef GetAllCloudTableReq
 * @property {string} fromDate
 * @property {string} toDate
 */

/**
 * @typedef GetAllCloudTableRes
 * @property {Record<'type' | string, string>[]}
 */

export const getAllCloudTableData = (token) => {
  /**
   * @param {GetAllCloudTableReq} req
   * @param {AbortSignal} signal
   * @returns {Promise<GetAllCloudTableRes[]>}
   */
  return async ({ fromDate, toDate }, signal) => {
    const tableData = await fetch(
      `${config.apiHost}/v1/allclouds/cost/all-clouds-cost?fromDate=${fromDate}&toDate=${toDate}`,
      {
        method: "GET",
        signal,
        headers: {
          Authorization: token,
        },
      }
    );
    if (!tableData.ok) {
      if (ERROR_STATUS_CODES.includes(tableData.status)) {
        throw new Error(API_LOADING_ERROR);
      }
      if (tableData.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR);
      }
      throw new Error((await tableData.json()).message);
    }
    const data = await tableData.json();

    if (!data || (!data.awsData && !data.azureData)) {
      throw new Error(API_NO_DATA_FOUND_ERROR);
    }

    if (data.awsData.length === 0 && data.azureData.length === 0) {
      if (fromDate && toDate) {
        throw new Error(API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR);
      }
      throw new Error(API_NO_DATA_FOUND_ERROR);
    }

    const { awsData, azureData, gcpData, ...rest } = data;
    return {
      AWS: awsData,
      Azure: azureData,
      GCP: gcpData,
      ...rest,
    };
  };
};

/**
 * @param {GetAllStatisticsReq} req
 * @param {any[]=} deps
 */
export const useAllCloudTableData = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: [
      "getAllCloudTableData",
      req.service,
      req.fromDate,
      req.toDate,
      ...deps,
    ],
    queryFn: ({ signal }) =>
      getAllCloudTableData(appContext.idToken)(req, signal),
  });
  return query;
};

import React, { useState, useContext, useMemo, useEffect } from "react";
import styles from "@/page-components/search-module/search.module.css";
import download from "@/assets/img/download.svg";
import {
  FilterSearch,
  FilterSearchToggle,
} from "@/page-components/search-module/search-components/account-region-filter-search";
import ReactCalender from "@/page-components/search-module/search-components/react-calendar";
import { SearchContext } from "../search-context";
import { useSearchAccountRegion } from "@/services/costExplorer/getSearchMainAccountRegion.repo";
import { Breadcrumb } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";

const MainFilterComponent = ({ service }) => {
  const [selectedTittle, setSelectedTittle] = useState(null);
  const context = useContext(SearchContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const serviceCategoryParam = searchParams.get("serviceCategory");
  const serviceParam = searchParams.get("service");

  const query = useMemo(() => {
    return {
      service: service,
      accounts: context?.selectAccounts?.length
        ? context.selectAccounts.map((e) => e.id)
        : undefined,
      regions: context?.selectRegions?.length
        ? context.selectRegions.map((e) => e.id)
        : undefined,
      name: context?.name,
      type: context?.type,
      spendingBy: context.filterMapping[context?.filteredData] || "account",
      fromDate: context?.fromDate,
      toDate: context?.toDate,
      serviceName:
        context?.type === "resource" ? context?.serviceName : undefined,
    };
  }, [
    context.filterMapping,
    context?.filteredData,
    context?.fromDate,
    context?.name,
    context.selectAccounts,
    context.selectRegions,
    context?.serviceName,
    context?.toDate,
    context?.type,
    service,
  ]);

  const { data } = useSearchAccountRegion(query);

  const clearFilters = () => {
    context?.setSelectAccounts();
    context?.setSelectRegions();
  };

  const renderSelectedValues = (selectedValues, tittle) => {
    if (selectedValues?.length > 0) {
      const firstValue = selectedValues[0].name;
      const additionalCount = selectedValues.length - 1;
      return (
        <div className={styles.selected_values}>
          <span className={styles.title_data}>
            {tittle}: <strong>{firstValue}</strong>
          </span>
          <span className={styles.title_css}>
            {additionalCount > 0 && ` +${additionalCount}`}
          </span>
        </div>
      );
    }
    return null;
  };
  useEffect(() => {
    if (serviceParam) {
      context?.setServiceName(serviceParam);
    }
  }, [context, serviceParam]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        gap: "12px",
        width: "100%",
      }}
    >
      {context?.type === "serviceCategory" && (
        <div className={styles.search_result}>
          <span className={styles.search_name}>Search Result</span>
          <div className={styles.filter_maindiv}>
            <div className={styles.filtered}>
              <Filter
                tittle={service === "azure" ? "Subscription" : "Account"}
                setSelectedTittle={setSelectedTittle}
                data={data?.accounts}
                filterType="account"
                selectedValues={context?.selectAccounts}
                onSelect={(list) => {
                  context?.setSelectAccounts(list);
                }}
                onRemove={(list) => {
                  context?.setSelectAccounts(list);
                }}
                setSelectedAccount={context?.setSelectAccounts}
              />
              <Filter
                tittle="Region"
                setSelectedTittle={setSelectedTittle}
                data={data?.regions}
                filterType="region"
                selectedValues={context?.selectRegions}
                onSelect={(list) => {
                  context?.setSelectRegions(list);
                }}
                onRemove={(list) => {
                  context?.setSelectRegions(list);
                }}
                setSelectedRegion={context?.setSelectRegions}
              />

              <div
                className={styles.clear_div}
                onClick={clearFilters}
                style={{ cursor: "pointer" }}
              >
                Clear Filters
              </div>
            </div>
          </div>
          <div className={styles.filtered}>
            <span>
              {renderSelectedValues(context?.selectAccounts, "Account")}
            </span>
            <span>
              {renderSelectedValues(context?.selectRegions, "Region")}
            </span>
          </div>
        </div>
      )}
      {!serviceCategoryParam && context?.type !== "serviceCategory" && (
        <span className={styles.search_name}>Search Result</span>
      )}
      {serviceCategoryParam && (
        <div>
          <Breadcrumb className={styles.search_breadCrumb}>
            <Breadcrumb.Item
              className={styles.trucated_resource}
              title={serviceCategoryParam}
              href="#"
              onClick={() => {
                navigate(
                  `/${service}/search?name=${serviceCategoryParam}&type=serviceCategory`
                );
              }}
            >
              Service Category- {serviceCategoryParam}
            </Breadcrumb.Item>
            {serviceParam && (
              <Breadcrumb.Item
                className={styles.trucated_resource}
                title={serviceParam}
                href="#"
                onClick={() => {
                  // context?.setServiceName(serviceParam);
                  navigate(
                    `/${service}/search?name=${serviceParam}&type=service&serviceCategory=${serviceCategoryParam}`
                  );
                }}
              >
                {serviceParam}
              </Breadcrumb.Item>
            )}
            <Breadcrumb.Item
              href="#"
              className={styles.trucated_resource}
              title={context?.name}
              active
            >
              {context?.name}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      )}

      <div
        className={styles.filtered}
        style={{
          width: context?.type === "serviceCategory" && "41%",
          justifyContent: "flex-end",
        }}
      >
        <div className={styles.select_date} style={{ textWrap: "nowrap" }}>
          Select Date
        </div>
        <div className={styles.calendar_cssed} style={{ position: "relative" }}>
          <ReactCalender range={context?.range} setRange={context?.setRange} />
        </div>
        {/* <div className={styles.download_csv}>
          <img src={download} alt="Download" style={{ paddingRight: "4px" }} />
          Download as CSV
        </div> */}
      </div>
    </div>
  );
};

const Filter = ({
  tittle,
  data,
  setSelectedTittle,
  filterType,
  selectedValues,
  setSelectedAccount,
  setSelectedRegion,
}) => {
  const handleSelect = (selectedList) => {
    if (filterType === "account") {
      setSelectedAccount(selectedList);
    }
    if (filterType === "region") {
      setSelectedRegion(selectedList);
    }
  };
  const handleRemove = (selectedList) => {
    handleSelect(selectedList);
  };
  return (
    <div className={styles.show_data} style={{ cursor: "pointer" }}>
      <div>
        <FilterSearch
          multiselect={true}
          options={(data || []).map((e) => {
            return {
              id: e.id,
              name: e.name,
            };
          })}
          selectedValues={selectedValues || []}
          onSelect={(list) => {
            handleRemove(list);
          }}
          onRemove={(list) => {
            handleRemove(list);
          }}
          displayValue="name"
          placeholder="--Select--"
          toggle={
            <>
              <FilterSearchToggle placeholder={tittle} />
            </>
          }
        />
      </div>
    </div>
  );
};

export default MainFilterComponent;

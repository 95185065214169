import { useContext } from "react";
import config from "../../config";
import { useQuery } from "@tanstack/react-query";
import { AppContext } from "@/App.context";
import {
  API_LOADING_ERROR_CUSTOM_WIDGET,
  API_NOT_FOUND_ERROR_CUSTOM_WIDGET,
  ERROR_STATUS_CODES,
} from "@/lib/errors/error-constants";

/**
 * @typedef GetAllStatistics
 * @property {GetAllLeftBarValues} leftBarValues
 * @property {GetAllMiddleBarValues} middleBarValue
 * @property {GetAllRightBarValues} rightBarValues
 */

/**
 * @typedef GetAllLeftBarValues
 * @property {number} previousMonthValue
 * @property {number} thisYearValue
 */

/**
 * @typedef GetAllRightBarValues
 * @property {number} totalidleResources
 * @property {number} totalRealizedSavings
 * @property {number} totalRightSizingCount
 * @property {number} totalSavingsAvailable
 * @property {number} totalSnapshotCount
 */

/**
 * @typedef GetAllMiddleBarValues
 * @property {number} Network
 * @property {number} Other
 * @property {number} Compute
 * @property {number} Databases
 * @property {number} Container
 * @property {number} Serverless
 * @property {number} Storage
 * @property {number} totalCost
 * @property {number} ComputePercentage
 * @property {number} NetworkPercentage
 * @property {number} DatabasesPercentage
 * @property {number} ServerlessPercentage
 * @property {number} ContainerPercentage
 * @property {number} StoragePercentage
 * @property {number} totalCostPercentage
 * @property {number} OtherPercentage
 *
 */

/**
 * @typedef GetAllStatisticsRes
 * @property {GetAllStatistics} result
 */

/**
 * @typedef GetAllStatisticsReq
 * @property {'aws' | 'gcp' | 'azure' | 'allclouds' } service
 * @property {string} fromDate
 * @property {string} toDate
 */

export const getAllStatistics = (token) => {
  /**
   * @param {GetAllStatisticsReq} req
   * @param {AbortSignal=} signal
   * @returns {Promise<GetAllStatisticsRes>}
   */
  return async ({ service, fromDate, toDate }, signal) => {
    const statistics = await fetch(
      `${config.apiHost}/v1/${service}/cost/total-stats?fromDate=${fromDate}&toDate=${toDate}`,
      {
        method: "GET",
        signal,
        headers: {
          Authorization: token,
        },
      }
    );

    if (!statistics.ok) {
      if (ERROR_STATUS_CODES.includes(statistics.status)) {
        throw new Error(API_LOADING_ERROR_CUSTOM_WIDGET);
      }
      if (statistics.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR_CUSTOM_WIDGET);
      }
      throw new Error((await statistics.json()).message);
    }
    const response = await statistics.json();
    return response;
  };
};

/**
 * @param {GetAllStatisticsReq} req
 * @param {any[]=} deps
 */
export const useAllStatistics = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getAllStatistics", req, ...deps],
    queryFn: ({ signal }) => getAllStatistics(appContext.idToken)(req, signal),
  });
  return query;
};

import React, { useState, useMemo, useContext } from "react";
import IdleResourceHeader from "../components/idle-resource-header.part";
import FilterSections from "../../idle-resource/components/aws-filter-section/filter-section.part";
import ArchieveTablePart from "../components/retained-table.part";
import IdleAWSResourceTablePart from "../components/idle-aws-resource-table.part";
import IdleResourceSemiPieChart from "../components/idle-resource-semipie-chart.part";
import { IdleProvider } from "../idle-context-file.part";
import styles from "../idle.module.css";
import clsx from "clsx";
import ArchieveHeader from "../components/retained-header.part";
import { useStatistics } from "@/services/IdleResources/getAllStatics.repo";
import { IdleContext } from "../idle-context-file.part";
import { useStatsArchievedStatistics } from "@/services/IdleResources/getTotalStatArchieved.repo";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";
import IdleResourceHistoryPage from "../components/idle-resource-history/idle-resource-history.page";
import SemiPieChartSkeleton from "@/components/States/semiPieChartSkelton";
import FilterState from "@/components/States/FilterState";

const GCPIdleResource = () => {
  return (
    <IdleProvider>
      <GCPIdle />
    </IdleProvider>
  );
};

const GCPIdle = () => {
  const [showError, setShowError] = useState({
    isError: false,
    errorMessage: "",
  });
  const service = "gcp";
  const context = useContext(IdleContext);

  const query = useMemo(() => {
    if (context?.selectedOption === "History") {
      return null;
    }
    const tags = (context?.selectedTags || []).filter(
      (e) => e.filterOn.length > 0 && e.values.length > 0
    );
    return {
      service,
      untagged: context.showUntaggedOnly,
      filters: {
        accounts: context?.selectAccounts?.length
          ? context.selectAccounts.map((e) => e.id)
          : undefined,
        rg: context?.selectedResourceGroup?.length
          ? context.selectedResourceGroup.map((e) => e.id)
          : undefined,
        regions: context?.selectRegions?.length
          ? context.selectRegions.map((e) => e.id)
          : undefined,
        tags: tags.length ? tags : undefined,
      },
    };
  }, [
    context.selectAccounts,
    context.selectRegions,
    context?.selectedOption,
    context.selectedResourceGroup,
    context?.selectedTags,
    context.showUntaggedOnly,
  ]);

  const {
    data,
    isLoading,
    error,
    refetch: refreshHeaders,
  } = useStatistics(query);
  const {
    data: archiveData,
    error: archiveError,
    isLoading: archieveLoading,
    refetch: archiveHeaderRefeth,
  } = useStatsArchievedStatistics(query);

  return (
    <>
      {context?.selectedOption === "Active" && (
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 p-0">
              <IdleResourceHeader
                service="gcp"
                data={data}
                isLoading={isLoading}
                error={error}
              />
              <div
                className="container-fluid col-lg-12 "
                style={{
                  display: "flex",
                  alignItems: "start",
                  flexDirection: "column",
                  gap: "24px",
                  paddingLeft: "40px",
                  paddingRight: "19px",
                }}
              >
                <div className="row w-100">
                  {context?.eventkey === "Idle Resources" && (
                    <>
                      <div
                        className={clsx(
                          styles.adding_border_line,
                          "col-10 p-0 pe-2 pt-4 d-flex gap-2 flex-column"
                        )}
                      >
                        {!showError.isLoading && (
                          <div
                            className={clsx(
                              "container-fluid",
                              styles.IdleResource_head
                            )}
                          >
                            <div
                              className={clsx(
                                "contaier",
                                styles.IdleResource_heading
                              )}
                            >
                              Idle Resources
                            </div>
                          </div>
                        )}
                        <>
                          <div
                            className="container-fluid row p-0 pt-0 gap-0"
                            style={{
                              marginLeft: "2px",
                              marginRight: "24px",
                            }}
                          >
                            {showError.isLoading && <SemiPieChartSkeleton />}
                            {showError.isError && !showError.isLoading ? (
                              <div
                                className="container-fluid"
                                style={{ paddingBottom: "40px" }}
                              >
                                <ScenariosErrorState
                                  error={showError.errorMessage}
                                  messageConfig={{
                                    [API_LOADING_ERROR]: {
                                      message: "Oops!",
                                      additionalMessage:
                                        "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
                                    },
                                    [API_NOT_FOUND_ERROR]: {
                                      message: "404 Error",
                                      additionalMessage:
                                        "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
                                    },
                                  }}
                                />
                              </div>
                            ) : (
                              <>
                                <div
                                  className="col-md-12 d-flex gap-3 pt-2"
                                  style={{ width: "99.5%" }}
                                >
                                  <IdleResourceSemiPieChart
                                    service="gcp"
                                    resourceType="ip"
                                    setShowError={setShowError}
                                    ipName="Static IPs"
                                  />
                                  <IdleResourceSemiPieChart
                                    service="gcp"
                                    resourceType="disk"
                                    setShowError={setShowError}
                                    diskName="Disks"
                                  />
                                </div>

                                <div
                                  className={clsx(
                                    "col-md-12 d-flex gap-3 pt-0",
                                    styles.adding_margin_bottom
                                  )}
                                  style={{ width: "99.5%" }}
                                >
                                  <IdleResourceSemiPieChart
                                    service="gcp"
                                    resourceType="vm"
                                    setShowError={setShowError}
                                    vmName="Compute Engine"
                                  />
                                  <IdleResourceSemiPieChart
                                    service="gcp"
                                    resourceType="lb"
                                    setShowError={setShowError}
                                  />
                                </div>
                              </>
                            )}
                          </div>

                          <div
                            className={clsx(
                              "container-fluid ",
                              styles.adding_margin_bottom
                            )}
                          >
                            <IdleAWSResourceTablePart
                              service="gcp"
                              name="Project Name"
                              refreshHeaders={refreshHeaders}
                              archiveHeaderRefeth={archiveHeaderRefeth}
                              ipName="Static IPs"
                              diskName="Disks"
                              vmName="Compute Engine"
                            />
                          </div>
                        </>
                      </div>

                      <div className="col-2 p-0" style={{ height: "100%" }}>
                        {isLoading && <FilterState />}
                        {(error || data) && (
                          <FilterSections service="gcp" name="Project Name" />
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {context?.selectedOption === "Retained" && (
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 p-0">
              <ArchieveHeader
                service="gcp"
                showDropdown={true}
                data={archiveData}
                isLoading={archieveLoading}
                ipName="Static IPs"
                diskName="Disks"
                vmName="Compute Engine"
                error={archiveError}
              />

              <div
                className="container-fluid col-lg-12 "
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "24px",
                  paddingLeft: "40px",
                  paddingRight: "19px",
                  marginTop: "24px",
                }}
              >
                <ArchieveTablePart
                  service="gcp"
                  name="Project"
                  refreshHeaders={refreshHeaders}
                  archiveHeaderRefeth={archiveHeaderRefeth}
                  ipName="Static IPs"
                  diskName="Disks"
                  vmName="Compute Engine"
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {context?.selectedOption === "History" && (
        <div>
          <IdleResourceHistoryPage
            service={service}
            name="Project Name"
            ipName="Static IPs"
            diskName="Disks"
            vmName="Compute Engine"
          />
        </div>
      )}
    </>
  );
};

export default GCPIdleResource;

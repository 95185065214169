import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Legend,
  Tooltip,
} from "recharts";
import { CustomLegend, CustomTooltip } from "./bar-graph.component";

/**
 * @typedef LineChartGraphProps
 * @property {*} barChartData
 * @property {*} colorMap
 * @property {string} string
 * @property {boolean=} showLegend
 * @property {boolean=} showTooltip
 * @property {number=} height
 */

/**
 * @param {LineChartGraphProps} props
 */
const LineChartGraph = ({
  service,
  showLegend,
  showTooltip,
  lineChartData,
  colorMap,
  height = 300,
  dimensionData,
  intervalvalue,
}) => {
  const interval =
    lineChartData && lineChartData.length > intervalvalue
      ? Math.floor(lineChartData.length / 12)
      : 0;
  const uniqueKeys = Array.isArray(lineChartData)
    ? Array.from(
        new Set(
          lineChartData.flatMap((data) =>
            Object.keys(data).filter((key) => key !== "label")
          )
        )
      )
    : [];
  return (
    <ResponsiveContainer width="100%" height={height}>
      <LineChart
        data={lineChartData}
        margin={{ top: 0, right: 3, left: 0, bottom: 0 }}
      >
        <CartesianGrid strokeDasharray="0" vertical={false} stroke="#F0F0F0" />
        <XAxis
          dataKey="label"
          tickLine={false}
          axisLine={false}
          fontSize={10}
          fontFamily="Inter"
          fontWeight={400}
          stroke="#A3A3A3"
          interval={interval}
        />
        <YAxis
          label={{ fill: "#A3A3A3" }}
          tickLine={false}
          axisLine={false}
          fontSize={10}
          fontFamily="Inter"
          fontWeight={400}
          stroke="#A3A3A3"
        />
        {showTooltip && (
          <Tooltip
            content={
              <CustomTooltip service={service} dimensionData={dimensionData} />
            }
            cursor={{ fill: "transparent" }}
          />
        )}
        {showLegend && <Legend content={<CustomLegend />} />}

        {uniqueKeys.length > 0 &&
          uniqueKeys.map((key) => (
            <Line key={key} dataKey={key} stackId="a" stroke={colorMap[key]} />
          ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LineChartGraph;

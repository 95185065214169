import React, {  useEffect } from "react";
import { Toast, ToastContainer, ToastHeader } from "react-bootstrap";
import styles from "./budgets.module.css";
import warning from '@/assets/img/warningIcon.svg'
import clsx from "clsx";

const ToastComponent = ({ message, toast, setToast, timeout = 3000 }) => {
  useEffect(() => {
    if (message) {
      setToast(true);
      const timeoutId = setTimeout(() => {
        setToast(false);
      }, timeout);

      return () => clearTimeout(timeoutId);
    }
  }, [message, setToast, timeout]);

  const toastBgColor = clsx( {
    [styles.success]: message?.type === "success",
    [styles.warning]: message?.type === "Under Progress",
    [styles.error]: message?.type === "Anomaly",
    [styles.info]:message?.type === "Info"
  });

  return (
    <div style={{ textAlign: "center", margin: " auto" }}>
      <ToastContainer
        style={{
          position: "fixed",
          padding: "12px",
          left: "50%",
          top: "27px",
          width:"645px",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Toast
          style={{ display: "flex", justifyContent: "space-between" }}
          className={toastBgColor}
          show={toast}
          onClose={() => setToast(false)}
          delay={9000}
          autohide
        >
          <ToastHeader className={styles.toastHeader}>
            <div className={styles.toastNotification}>
            <span className={message?.type === "Info" ? styles.info_message :styles.messageType}>
             <img src={warning} alt="info" style={{marginRight:"8px"}}/>
              {message?.type  }
            </span>
            <span className={message?.type === "Info" ? styles.get_messaged :" text-center"}>
              {message?.message ||"Under Progress"}
            </span>
            </div>
          </ToastHeader>
        </Toast>
      </ToastContainer>
    </div>
  );
};

export default ToastComponent;

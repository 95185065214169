import { useContext } from "react";
import config from "../../config";
import {  useQuery } from "@tanstack/react-query";
import { AppContext } from "@/App.context";

/**
 * @typedef GetFilterAccountOptions
 * @property {string} accountId
 * @property {string} accountName
 */

/**
 * @typedef GetFilterRegionsOptions
 * @property {string} regionId
 * @property {string} regionName
 */

/**
 * @typedef GetResourceGroupNameOptions
 * @property {string} resourceGroup
 * @property {string} resourceGroupName
 */

/**
 * @typedef GetFilterOptionsReq
 * @property {'aws' | 'gcp' | 'azure' | 'all'} service
 */

/**
 * @typedef GetFilterOptionsRes
 * @property {GetFilterAccountOptions[]} accounts
 * @property {GetFilterRegionsOptions[]} regions
 * @property {GetResourceGroupNameOptions[]} resourceGroupName
 */

export const getFilterOptions = (token) => {

  return async ({ service,selectedTitle,tab }) => {
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/ri-recommendations/filter?type=${selectedTitle}&tab=${tab}`,
      {
        method: "POST",
        headers: {
          Authorization: token,
          'Content-Type': 'application/json'
        },
    
      }
    );
    if (!owners.ok) {
      throw new Error((await owners.json()).message);
    }
    return await owners.json();
  };
};


export const useFilterOptionsRI = (req, deps = [],enabled=true) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getFilterOptions", appContext.idToken, req, ...deps],
    queryFn: ({ signal }) => getFilterOptions(appContext.idToken)(req, signal),
    retry:true,
    enabled
  });
  return query;
};

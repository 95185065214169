
import React from 'react'
import { CartesianGrid, Line,Tooltip, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts'

import styless from "@/page-components/cost-explorer/cost.explorer.module.css";
import { CustomTooltip } from './customtooltip.part';

const LineChartSection = ({service,Linedata}) => {
    
  return (
         <ResponsiveContainer width="100%" height={300}>
                <LineChart
                  data={Linedata?.data}
                  margin={{ top: 0, right: 0,left:-15, bottom: 20 }}
                >
                  <CartesianGrid
                    strokeDasharray="0"
                    stroke="#F0F0F0"
                    vertical={false}
                  />
                  <Tooltip
                    content={CustomTooltip}
                    cursor={{ fill: "transparent" }}
                  />
                  <XAxis
                    label={{ 
                      value: "No of Days",
                      fill: "#495057",
                      fontSize: 10,
                      textAnchor: "middle",
                      fontWeight: 400,
                      position: "insideBottom", 
                      dy: 10 
                    }}
                    dataKey={service ==="azure" ? "date" : "name"}
                    tickLine={false}
                    axisLine={false}
                    fontSize={10}
                    fontWeight={400}
                    stroke="#495057"
                    minTickGap={5}
                    fontFamily="Roboto"
                  />
                  <YAxis
                    label={{ 
                      value:"No of resources",fill:"#495057",angle: -90, fontSize: 10,textAnchor: "middle",fontWeight:400,
                      position: "insideLeft",dy: 50,dx:20  }}
                    tickLine={false}
                    axisLine={false}
                    fontSize={10}
                    fontWeight={400}
                    stroke="#495057"
                  />
                  {service === "aws" && (
                    <>
                      <Line
                        type="monotone"
                        dataKey="Auto_Scaling"
                        stroke="#056EE5"
                        stackId="a"
                      />
                      <Line
                        type="monotone"
                        dataKey="Manually_Created"
                        stroke="#E8BF67"
                        stackId="a"
                      />
                    </>
                  )}
                  {service === "azure" && (
                    <>
                      <Line
                        type="monotone"
                        dataKey="count"
                        stroke="#056EE5"
                        stackId="a"
                      />
                    </>
                  )}
                </LineChart>
              </ResponsiveContainer>
  )
}

export default LineChartSection
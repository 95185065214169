import React, { useMemo, useState } from "react";
import style from "@/page-components/RightSizing/rightSizing.module.css";
import styles from "@/page-components/cost-Recommendation/cost-Recommendation.module.css";
import crossModal from "@/assets/img/modal_cross.svg";
import infoIcon from "@/assets/img/vmRec_i_icon.svg";
import clsx from "clsx";
import { useSearchParams } from "react-router-dom";
import modulestyle from "@/page-components/RI-Capacity/ri.module.css";
import { useCostRecommendationById } from "@/services/cost-recommendation/getByIdModal.repo";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";
import { Spinner } from "react-bootstrap";
import CurrencyFormatter from "@/lib/value-converison";

const RecommendationModal = ({ resourceId, service, action_type, summary }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const handleClose = () => {
    searchParams.delete("recommendation_id");
    setSearchParams(searchParams);
  };
  const query = useMemo(() => {
    return {
      resourceId,
      service,
      action_type,
    };
  }, [action_type, resourceId, service]);
  const { data, isLoading, error } = useCostRecommendationById(query);
  return (
    <>
      <div className={style.recommendationHeader}>
        <span
          style={{
            fontSize: "18px",
            fontWeight: "700",
            display: "flex",
            alignItems: "center",
          }}
        >
          Recommendation Details
          <img
            style={{ marginLeft: "8px" }}
            src={infoIcon}
            width={16}
            height={16}
            alt="infoIcon"
          />
        </span>
        <img
          style={{ cursor: "pointer" }}
          src={crossModal}
          alt="Close Notification"
          onClick={handleClose}
        />
      </div>
      {isLoading && (
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner />
        </div>
      )}
      {error && (
        <ScenariosErrorState
          error={error.message}
          messageConfig={{
            [API_NO_DATA_FOUND_ERROR]: {
              message: "No Data Available",
              additionalMessage:
                "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
            },
            [API_LOADING_ERROR]: {
              message: "Oops!",
              additionalMessage:
                "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
            },
            [API_NOT_FOUND_ERROR]: {
              message: "404 Error",
              additionalMessage:
                "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
            },
          }}
        />
      )}
      {!error && resourceId && !isLoading && !data?.isError && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            marginTop: "-13px",
          }}
        >
          <div className={clsx("container", style.batch)}>
            <div>
              <span
                className={clsx(
                  data?.action_type === "PurchaseReservedInstances" &&
                    styles.PurchaseReservedInstances,
                  data?.action_type === "PurchaseSavingsPlans" &&
                    styles.PurchaseSavingsPlans,
                  data?.action_type === "Rightsize" && styles.Rightsize,
                  data?.action_type === "MigrateToGraviton" &&
                    styles.MigrateToGraviton,
                  data?.action_type === "Upgrade" && styles.Upgrade
                )}
              >
                {data?.action_type || "Null"}
              </span>
            </div>
          </div>

          <div className={style.recommendationText}>
            <p>
              Based on your usage patterns and cost optimization goals, we
              recommend considering Reserved Instances (RIs) or Savings Plans.
              By leveraging these flexible pricing models, you can optimize your
              cloud spend, ensure predictable costs, and maximize your savings
              potential.
            </p>
          </div>
          <div
            style={{
              display: "flex",
              gap: "28px",
              padding: "16px 15px 16px 12px",
              border: "1px solid #E9ECEF",
              borderRadius: "8px",
            }}
          >
            <div className={modulestyle.VM_family_block}>
              <span className={modulestyle.vm_name}>
                {service === "aws" && "Account Name"}
                {service === "azure" && "Subscription Name"}
              </span>
              <span
                className={styles.vm_values}
                title={data?.account_name || data?.subscription_name}
                style={{
                  overflowX: "hidden",
                  textOverflow: "ellipsis",
                  textWrap: "nowrap",
                  width: "200px",
                }}
              >
                {data?.account_name || data?.subscription_name || "--"}
              </span>
            </div>
            <div className={modulestyle.VM_family_block}>
              <span className={modulestyle.vm_name}>
                {service === "aws" && "Source"}
                {service === "azure" && "Impacted Value"}
              </span>
              <span
                style={{
                  textWrap: "nowrap",
                  width: "170px",
                  overflowX: "hidden",
                  textOverflow: "ellipsis",
                }}
                className={styles.vm_other_value}
                title={
                  service === "aws"
                    ? data?.source || "--"
                    : service === "azure"
                    ? data?.impacted_value || "--"
                    : "--"
                }
              >
                {service === "aws" && (data?.source || "--")}
                {service === "azure" && (data?.impacted_value || "--")}
              </span>
            </div>
            <div className={modulestyle.VM_family_block}>
              <span className={modulestyle.vm_name}>
                {service === "aws" && "Account Scope"}
                {service === "azure" && "Scope"}
              </span>
              <span
                className={styles.vm_other_value}
                title={data?.account_scope}
              >
                {data?.account_scope || "--"}
              </span>
            </div>
            {service === "aws" && (
              <div className={modulestyle.VM_family_block}>
                <span className={modulestyle.vm_name}>Service</span>
                <span
                  className={styles.vm_other_value}
                  style={{
                    textWrap: "nowrap",
                    width: "170px",
                    overflowX: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  title={data?.service}
                >
                  {data?.service || "--"}
                </span>
              </div>
            )}

            {data?.action_type === "PurchaseReservedInstances" && (
              <div className={modulestyle.VM_family_block}>
                <span className={modulestyle.vm_name}>Instance Family</span>
                <span
                  className={styles.vm_other_value}
                  style={{
                    textWrap: "nowrap",
                    width: "170px",
                    overflowX: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {data?.instance_family || "--"}
                </span>
              </div>
            )}
          </div>

          <div style={{ paddingLeft: "12px" }}>
            <div
              style={{
                fontSize: "16px",
                fontWeight: "600",
                color: "#212224",
              }}
            >
              Recommendation Model
            </div>
            <table className={style.recommendationTable}>
              <thead>
                <tr>
                  <th
                    className={style.headerText}
                    style={{
                      verticalAlign: "top",
                      textAlign: "left",
                      paddingTop: "11px",
                    }}
                  >
                    SPECS
                  </th>
                  <th className={style.tableHeader}>CURRENT RESOURCES/PLAN</th>
                  <th>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                      className={`${style.tableHeadRec} ${style.backgroundDownsize}`}
                    >
                      RECOMMENDED ACTION
                      <span
                        className={style.tittle}
                        style={{ fontWeight: 700, width: "300px" }}
                      >
                        {data?.action_type}
                      </span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.action_type === "PurchaseReservedInstances" && (
                  <tr>
                    <td className={style.recInfoSpec}>Instance Type</td>
                    <td className={style.recSpec}>
                      {data?.current_instance_type || "--"}
                    </td>
                    <td
                      className={clsx(
                        style.recProjectedValues,
                        style.backgroundDownsize
                      )}
                    >
                      {data?.recommended_instance_type || "--"}
                    </td>
                  </tr>
                )}
                <tr>
                  <td className={style.recInfoSpec}>Type</td>
                  <td className={style.recSpec}>
                    {data?.current_resource_type ||
                      data?.current_vm_family ||
                      "--"}
                  </td>
                  <td
                    style={{ fontSize: "13px", fontWeight: "700" }}
                    className={`${style.recProjectedValues} ${style.backgroundDownsize} `}
                  >
                    {data?.recommended_resource_type || "--"}
                  </td>
                </tr>
                {data?.action_type === "PurchaseReservedInstances" && (
                  <tr>
                    <td className={style.recInfoSpec}>
                      Instance Qty To Purchase
                    </td>
                    <td className={style.recSpec}>--</td>
                    <td
                      style={{ fontSize: "13px", fontWeight: "700" }}
                      className={`${style.recProjectedValues} ${style.backgroundDownsize} `}
                    >
                      {data?.instance_qty_to_purchase || "--"}
                    </td>
                  </tr>
                )}
                <tr>
                  <td className={style.recInfoSpec}>Term</td>
                  <td className={style.recSpec}>{data?.term || "--"}</td>
                  <td
                    style={{ fontSize: "13px", fontWeight: "700" }}
                    className={`${style.recProjectedValues} ${style.backgroundDownsize} `}
                  >
                    {data?.recommended_term || "--"}
                  </td>
                </tr>
                {service === "aws" && (
                  <tr>
                    <td className={style.recInfoSpec}>Payment Option</td>
                    <td className={style.recSpec}>{data?.payment || "--"}</td>
                    <td
                      style={{ fontSize: "13px", fontWeight: "700" }}
                      className={`${style.recProjectedValues} ${style.backgroundDownsize} `}
                    >
                      {data?.recommended_payment_option || "--"}
                    </td>
                  </tr>
                )}

                {data?.action_type === "PurchaseReservedInstances" && (
                  <tr>
                    <td className={style.recInfoSpec}>Upfront Cost</td>
                    <td className={style.recSpec}>
                      {/* ${Number(data?.current_upfront_cost).toFixed(2) || "--"} */}
                      --
                    </td>
                    <td
                      style={{ fontSize: "13px", fontWeight: "700" }}
                      className={`${style.recProjectedValues} ${style.backgroundDownsize} `}
                    >
                      {data?.recommended_upfront_cost?
                  <CurrencyFormatter value={data?.recommended_upfront_cost} hideDollar={false} />:"--"}
                     
                    </td>
                  </tr>
                )}
                {service === "aws" && (

                <tr>
                  <td className={style.recInfoSpec}>Monthly Recurring Cost</td>
                  <td className={style.recSpec}>
                    {data?.current_monthly_recurring_cost?
                  <CurrencyFormatter value={data?.current_monthly_recurring_cost} hideDollar={false} />:"--"}
                  
                  </td>
                  <td
                    style={{ fontSize: "13px", fontWeight: "700" }}
                    className={`${style.recProjectedValues} ${style.backgroundDownsize} `}
                  >
                    {data?.recommended_monthly_recurring_cost?
                  <CurrencyFormatter value={data?.recommended_monthly_recurring_cost} hideDollar={false} />:"--"}
                   
                  </td>
                </tr>
                )}

                <tr>
                  <td></td>
                  <td></td>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        gap: "8px",
                        flexDirection: "column",
                      }}
                      className={`${style.saving} ${style?.backgroundDownsizeHighlight}`}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          gap: "8px",
                        }}
                      >
                        <span style={{ fontSize: "14px", fontWeight: "400" }}>
                          Saving Per Month
                        </span>
                        <span style={{ fontSize: "18px", fontWeight: "700" }}>
                        {data?.savings_per_month?
                  <CurrencyFormatter value={data?.savings_per_month} hideDollar={false} />:"--"}
                        </span>
                      </div>
                      <div>
                        <span>Saving percentage: </span>
                        <span
                          style={{
                            fontSize: "12px",
                            fontWeight: "600",
                            color: "white",
                          }}
                        >
                          {data?.savings_percentage != null
                            ? `${data.savings_percentage}%`
                            : "--"}
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style={{ padding: "12px", background: "#F8F9FA" }}>
            <h1 style={{ fontSize: "16px", fontWeight: "600" }}>
              Recommendation Summary
            </h1>
            <p style={{ fontWeight: "400", fontSize: "13px" }}>
              {service === "aws" && summary}
              {service === "azure" && (data?.action_type || "--")}
            </p>
          </div>
          <div
            style={{ display: "flex", justifyContent: "center", gap: "5px" }}
          >
            <span className={styles.endDetails}>
              Region:
              <p className={styles.detailValues}>
                {data?.region !== null ? data?.region : "--"}
                {service === "aws" ? "," : ""}
              </p>
            </span>
            {service === "aws" && (
              <>
                <span className={styles.endDetails}>
                  Operating System:
                  <p className={styles.detailValues}>
                    {data?.operating_system || "--"},
                  </p>
                </span>
                <span className={styles.endDetails}>
                  Restart:
                  <p className={styles.detailValues}>
                    {data?.restart_needed === 0 && "No"}
                    {data?.restart_needed === 1 && "Yes"},
                  </p>
                </span>
                <span className={styles.endDetails}>
                  Rollback Possible:{" "}
                  <p className={styles.detailValues}>
                    {data?.rollback_possible === 0 && "No"}
                    {data?.rollback_possible === 1 && "Yes"}
                  </p>
                </span>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default RecommendationModal;

import React, { useState, useRef, useMemo } from "react";
import menuicon from "@/assets/img/menu.svg";
import download from "@/assets/img/download.svg";
import barcharticon from "@/assets/img/graphCharticon.svg";
import linecharticon from "@/assets/img/lineCharticon.svg";
import Dropdown from "react-bootstrap/Dropdown";
import { CSVLink } from "react-csv";
import styles from "@/page-components/idle-resource/idle.module.css";
import StackedBarGraphIdleRes from "../stacked-bar-graph-idle-res.part";
import LineChartIdleResource from "../line-chart-idle-resource.part";
import { useStackedStatistics } from "@/services/IdleResources/getStackedStatistics.repo";
import BarGraphErrorStates from "@/components/States/BarGraphErrorStates";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR,
  API_NOT_FOUND_ERROR,
  API_NO_DATA_FOUND_ERROR,
} from "@/lib/errors/error-constants";
import { compareAsc, parse } from "date-fns";
import config from "@/config";

const AllCloudBarGraph = ({ service }) => {
  const [chartType, setChartType] = useState("bar");
  const csvLinkRef = useRef(null);

  const query = useMemo(() => {
    return {
      service,
    };
  }, [service]);

  const { data, isLoading, isError, error } = useStackedStatistics(query);

  const colorMap = {
    AWS: "#F90",
    Azure: "#1F78B4",
    GCP: "#0F9D58",
  };
  const monthlyData = {};

  if (config.enabledClouds.has("aws") && data?.aws?.length > 0) {
    data?.aws?.forEach(({ month, unblended_cost }) => {
      if (!monthlyData[month]) {
        monthlyData[month] = { AWS: 0, Azure: 0, GCP: 0 };
      }
      monthlyData[month].AWS = unblended_cost;
    });
  }

  if (config.enabledClouds.has("azure") && data?.azure?.length > 0) {
    data?.azure?.forEach(({ month, unblended_cost }) => {
      if (!monthlyData[month]) {
        monthlyData[month] = { AWS: 0, Azure: 0, GCP: 0 };
      }
      monthlyData[month].Azure = unblended_cost;
    });
  }

  if (config.enabledClouds.has("gcp") && data?.gcp?.length > 0) {
    data?.gcp?.forEach(({ month, unblended_cost }) => {
      if (!monthlyData[month]) {
        monthlyData[month] = { AWS: 0, Azure: 0, GCP: 0 };
      }
      monthlyData[month].GCP = unblended_cost;
    });
  }

  const ChartDataSS = Object.entries(monthlyData).map(([month, savings]) => {
    const filteredSavings = Object.keys(savings)
      .filter((key) => config.enabledClouds.has(key.toLowerCase()))
      .reduce((obj, key) => {
        obj[key] = savings[key];
        return obj;
      }, {});

    return {
      Month: month,
      ...filteredSavings,
    };
  });

  ChartDataSS.sort((a, b) => {
    const aDate = parse(a.Month, "MMM, yy", new Date());
    const bDate = parse(b.Month, "MMM, yy", new Date());
    return compareAsc(aDate, bDate);
  });

  const maxLength = ChartDataSS?.length || 0;

  // -------------------------------------------------------download csv code---------------------------------------

  const handleDownloadCsv = () => {
    const headers = [
      "Month",
      ...Object.keys(ChartDataSS[0]).filter(
        (key) => key !== "Month" || key !== "month"
      ),
    ];

    const csvData = [
      headers,
      ...ChartDataSS.map((item) => [
        item.Month,
        ...headers.slice(1).map((header) => item[header] || "-"),
      ]),
    ];

    if (csvLinkRef.current) {
      csvLinkRef.current.link.click();
    }
  };

  return (
    <>
      {isLoading && <BarGraphErrorStates />}
      {error && (
        <ScenariosErrorState
          error={error.message}
          messageConfig={{
            [API_LOADING_ERROR]: {
              message: "Oops!",
              additionalMessage:
                "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
            },
            [API_NOT_FOUND_ERROR]: {
              message: "404 Error",
              additionalMessage:
                "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
            },
            [API_NO_DATA_FOUND_ERROR]: {
              message: "No Idle Resource Data Available",
              additionalMessage:
                "We couldn’t retrieve idle resource data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
            },
          }}
        />
      )}
      {((data?.azure && data?.azure.length > 0) ||
        (data?.azure && data?.azure.length > 0)) && (
        <div
          className="container-fluid p-0"
          style={{
            display: "flex",
            alignItems: "start",
            flexDirection: "column",
            gap: "20px",
            marginLeft: "12px",
            marginRight: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                gap: "8px",
                alignItems: "center",
              }}
            >
              <div className={styles.all_cloud_text}>
                All Cloud Idle Resources
              </div>
              <div className={styles.last_days_name}>
                Last {maxLength} months
              </div>
            </div>
            <div className={styles.menu_icon_css1}>
              <Dropdown style={{ marginBottom: 2 }}>
                <Dropdown.Toggle
                  variant="light"
                  id={styles.custom_dropdown_toggle}
                  className={styles.custom_dropdown_toggle}
                  style={{ background: "transparent", border: "none" }}
                >
                  <img src={menuicon} alt="Menu" />
                </Dropdown.Toggle>
                <Dropdown.Menu className={styles.dropdown_menu}>
                  <Dropdown.Item
                    className={styles.dropdown_item}
                    eventKey="bar"
                    onClick={() => setChartType("bar")}
                  >
                    <img
                      src={barcharticon}
                      alt=""
                      style={{ marginRight: "10px" }}
                    ></img>
                    Bar Chart
                  </Dropdown.Item>
                  <Dropdown.Item
                    className={styles.dropdown_item}
                    eventKey="line"
                    onClick={() => setChartType("line")}
                  >
                    <img
                      src={linecharticon}
                      alt=""
                      style={{ marginRight: "10px" }}
                    ></img>
                    Line Chart
                  </Dropdown.Item>
                  <Dropdown.Divider />

                  <Dropdown.Item
                    className={styles.dropdown_item}
                    eventKey="Download as CSV"
                    onClick={handleDownloadCsv}
                  >
                    <img
                      src={download}
                      alt=""
                      style={{ marginRight: "10px" }}
                    />
                    Download as CSV
                  </Dropdown.Item>
                  <CSVLink
                    data={ChartDataSS}
                    filename={`${service}-idleResource-data.csv`}
                    target="_blank"
                    ref={csvLinkRef}
                    style={{ display: "none" }}
                  >
                    Download
                  </CSVLink>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12 p-0">
                {ChartDataSS && chartType === "bar" ? (
                  <StackedBarGraphIdleRes
                    barChartData={ChartDataSS}
                    colorMap={colorMap}
                  />
                ) : (
                  <LineChartIdleResource
                    lineChartData={ChartDataSS}
                    colorMap={colorMap}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AllCloudBarGraph;

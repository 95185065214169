// ----------------------------------------------IN NO USE-------------------------
import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

const datamock = {
  success: true,
  message: "Fetched Successfully.",
  data: {
    ticket_title: "cloudEQ Snapshots - Deletion",
    ticket_id: "AJR-2452",
    ticket_status: "In Progress",
    comment:
      "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit..",
    resources: [
      {
        resource_id: "snap-07b6f17e3f4903524",
        accountName: "CEQ-DEV-POC",
        snapshotName: "snap-42dc4",
        snapshotId: "snap-07b6f17e3f4903524",
        region: "us-east-1",
        incremental: 0,
        diskId: "vol-0137769a633b42dc4",
        diskSize: 30,
        snapshotAge: 213,
        snapshotClass: "standard",
        snapshotcost: 0.5889068336000001,
        status: "open",
        jiraStatus: "open",
        account: "676634049556",
        history: 2,
        isLegallyHeld: 0,
      },
      {
        resource_id: "snap-07b6f17e3f4903524",
        accountName: "CEQ-DEV-POC",
        snapshotName: "snap-42dc4",
        snapshotId: "snap-07b6f17e3f4903524",
        region: "us-east-1",
        incremental: 0,
        diskId: "vol-0137769a633b42dc4",
        diskSize: 30,
        snapshotAge: 213,
        snapshotClass: "standard",
        snapshotcost: 0.5889068336000001,
        status: "open",
        jiraStatus: "open",

        account: "676634049556",
        history: 2,
        isLegallyHeld: 0,
      },
    ],
  },
};
/**
 * @typedef AllBodyStatistics
 * @property {String} ticket_title
 * @property {String} ticket_id
 * @property {String} ticket_status
 * @property {String} comment
 * @property {Array} AllBodyStatisticsTable
 */

/**
 * @typedef AllBodyStatisticsTable
 * @property {String} account
 * @property {String} snapshotName
 * @property {String} snapshotCost
 * @property {String} status
 * @property {String} jiraStatus
 */
/**
 * @typedef AllBodyStatisticsRes
 * @property {AllBodyStatistics} statistics
 */
/**
 * @typedef GetStatisticsReq
 * @property {'aws' | 'azure' } service
 */

export const getStatisticsjira = (token) => {
  /**
   * @param {GetStatisticsReq} req
   * @returns {Promise<AllBodyStatisticsRes>}
   */
  return async ({ service, id }) => {
    // return datamock
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/snapshots/ticket-status/${id}`,
      {
        method: "Get",
        headers: {
          Authorization: token,
        },
      }
    );
    if (!owners.ok) {
      throw new Error((await owners.json()).message);
    }
    return await owners.json();
  };
};

/**
 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useStatisticsjira = (req, deps = [],enabled=true) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getStatistics", req, ...deps],
    queryFn: () => getStatisticsjira(appContext.idToken)(req),
    retry:false,
    enabled
  });
  return query;
};

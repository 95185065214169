import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import awsLogo from "@/assets/img/aws_logo.svg";
import azureLogo from "@/assets/img/azurelogo.svg";
import styles from "./savings.module.css";
import CurrencyFormatter from "@/lib/value-converison";

const SavingLineChartPart = ({ lineChartData, colorMap, service, name }) => {
  const customTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div className={styles.custom_tooltip}>
          <div className={styles.tooltip_date} style={{ paddingLeft: "8px" }}>
            <div className={styles.tooltip_date_css}>{label}</div>
          </div>
          <div
            style={{ width: "100%", paddingRight: "16px", paddingLeft: "8px" }}
          >
            <table>
              <thead>
                <tr>
                  <th
                    className={styles.cloud_cell}
                    style={{ paddingRight: "24px" }}
                  >
                    {name}
                  </th>
                  <th
                    className={styles.cost_cell}
                    style={{ paddingRight: "24px" }}
                  >
                    Cost
                  </th>
                </tr>
              </thead>
              <tbody>
                {payload.map((entry, index) => (
                  <tr
                    key={`${styles.tooltip_row} ${index}`}
                    className={styles.horizontalline_tool}
                  >
                    <td
                      className={styles.cloud_cell}
                      style={{ paddingRight: "24px" }}
                    >
                      {(service === "aws" || service === "all-cloud") && (
                        <img
                          src={awsLogo}
                          alt=" "
                          className={styles.cloud_logo}
                        />
                      )}
                      {(service === "azure" || service === "all-cloud") && (
                        <img
                          src={azureLogo}
                          alt=" "
                          className={styles.cloud_logo}
                        />
                      )}
                      {/* {entry.dataKey === "GCP" && (
                        <img src={gcpLogo} alt=" " className={styles.cloud_logo}} />
                      )} */}
                      {entry.dataKey.toUpperCase()}
                    </td>
                    <td
                      className={styles.cost_cell}
                      style={{ paddingRight: "24px" }}
                    >
                      <CurrencyFormatter value={entry.value || 0} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      );
    }

    return null;
  };
  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart
        data={lineChartData}
        margin={{ top: 0, right: 0, left: -25, bottom: 0 }}
      >
        <CartesianGrid strokeDasharray="0" stroke="#F0F0F0" vertical={false} />
        <Tooltip content={customTooltip} cursor={{ fill: "transparent" }} />

        <XAxis
          dataKey="Month"
          tickLine={false}
          axisLine={false}
          fontSize={10}
          fontWeight={400}
          stroke="#495057"
          minTickGap={5}
          fontFamily="Roboto"
        />
        <YAxis
          label={{ fill: "#495057" }}
          tickLine={false}
          axisLine={false}
          fontSize={10}
          fontWeight={400}
          stroke="#495057"
        />
        {/* <Tooltip /> */}
        {service === "aws" && (
          <Line
            type="straight"
            dataKey="aws"
            stroke={colorMap.AWS}
            stackId="a"
            // strokeWidth={2}
          />
        )}
        {service === "azure" && (
          <Line
            type="straight"
            dataKey="azure"
            stroke={colorMap.Azure}
            stackId="a"
            // strokeWidth={2}
          />
        )}
        {service === "gcp" && (
          <Line
            type="straight"
            dataKey="gcp"
            stroke={colorMap.GCP}
            strokeWidth={2}
            stackId="a"
          />
        )}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default SavingLineChartPart;

import Pagination from "@/components/pagination/pagination.component";
import { useCostRecommendationsList } from "@/services/cost-recommendation/getTableStats.repo";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { CostRecommendationContext } from "../costContext";
import { useSearchParams } from "react-router-dom";
import styles from "@/page-components/RightSizing/rightSizing.module.css";
import style from "@/page-components/cost-Recommendation/cost-Recommendation.module.css";
import styless from "@/page-components/snapshot/snapshots.module.css";
import clsx from "clsx";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import CurrencyFormatter from "@/lib/value-converison";
import sorting from "@/assets/img/sorting.svg";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import unarchieve from "@/assets/img/unarchieve.svg";
import uploadicon from "@/assets/img/uploadicon.svg";
import DoneIcon from "@/assets/img/doneStateChange.svg";
import downlaodVM from "@/assets/img/downloadVM.svg";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";
import TableErrorState from "@/components/States/TableErrorState";
import { useUpdateAcceptCost_RecommendationJira } from "@/services/cost-recommendation/getacceptrecommendation.repo";
import { useCostRecommendationsListDownlaod } from "@/services/cost-recommendation/getTableDownload";

const RetainedBodySection = ({ service, refetchheader }) => {
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const handleMouseEnter = (index) => {
    setHoveredRowIndex(index);
  };
  const tooltip = (
    <Tooltip id="tooltip">
      <span style={{ padding: "4px 8px" }}>Unretain</span>
    </Tooltip>
  );

  const handleMouseLeave = () => {
    setHoveredRowIndex(null);
  };
  const context = useContext(CostRecommendationContext);
  const page = searchParams.get("page");
  const itemsPerPage = searchParams.get("itemsPerPage");
  const parsedPage = !Number.isNaN(parseInt(page)) ? parseInt(page) : 1;
  const parsedItemsPerPage = !Number.isNaN(parseInt(itemsPerPage))
    ? parseInt(itemsPerPage)
    : 10;

  const {
    data: updateDatajira,
    mutate: acceptMutatejira,
    isSuccess: jiraAcceptSuccess,
    isError: isAcceptDismissErrorjira,
    error: acceptDismissErrorjira,
    isPending: UnretainPending,
  } = useUpdateAcceptCost_RecommendationJira();

  useEffect(() => {
    if (jiraAcceptSuccess) {
      refetchheader();
    }
  }, [jiraAcceptSuccess]);
  const query = useMemo(() => {
    const tags = (context?.selectedTags || []).filter(
      (e) => e.filterOn.length > 0 && e.values.length > 0
    );
    return {
      service,
      page: parsedPage || 1,
      recordsPerPage: parsedItemsPerPage || 10,
      orderBy: context?.orderBy,
      desc: searchParams.get("desc") || "true",

      status: "Retained",
    };
  }, [
    context?.orderBy,
    context?.selectedTags,
    parsedItemsPerPage,
    parsedPage,
    searchParams,
    service,
  ]);
  const {
    data: tableData,
    isLoading,
    error,
  } = useCostRecommendationsList(query, [updateDatajira]);
  const { mutate: downloadMutateDiscard } =
    useCostRecommendationsListDownlaod(query);
  const handleDownloadClicked = () => {
    downloadMutateDiscard(query);
  };
  return (
    <div
      style={{
        padding: "0px 40px 30px 40px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <span
        className={styles.recommendationsLabel}
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {context?.viewType === "Active" && " Recommendations"}
        {context?.viewType === "Retained" && "Retained Recommendations"} (
        {tableData?.count?.total_data || 0} Recommendations )
        <div className={styless.menu_icon_css1}>
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="tooltip">Download as CSV</Tooltip>}
          >
            <span
              className={styles.downloadButton}
              onClick={handleDownloadClicked}
            >
              <img src={downlaodVM} alt="" />
            </span>
          </OverlayTrigger>
        </div>
      </span>
      <>
        {isLoading && (
          <div className={styles.wrapper}>
            <TableErrorState />
          </div>
        )}
        {error && (
          <ScenariosErrorState
            error={error.message}
            messageConfig={{
              [API_NO_DATA_FOUND_ERROR]: {
                message: "No Data Available for Selected Filters",
                additionalMessage:
                  "It looks like there is no matching your filter criteria. Please try adjusting your filters to explore other options.",
              },
              [API_NO_DATA_FOUND_ERROR]: {
                message: "No Data Available",
                additionalMessage:
                  "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
              },
              [API_LOADING_ERROR]: {
                message: "Oops!",
                additionalMessage:
                  "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
              },
              [API_NOT_FOUND_ERROR]: {
                message: "404 Error",
                additionalMessage:
                  "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
              },
            }}
          />
        )}
        {tableData?.data.length > 0 && (
          <div style={{ display: "flex", paddingBottom: "33px" }}>
            <div className={styles.wrapper}>
              <div className={styles.container}>
                <div className={style.scroll} style={{ display: "flex" }}>
                  <div style={{ overflowX: "scroll", width: "80%" }}>
                    <table
                      style={{ width: "100%" }}
                      id="customers"
                      className={clsx(styles.scrollable, "table-hover")}
                    >
                      <thead>
                        <tr>
                          {service === "azure" && (
                            <th
                              className={styles.tableHead}
                              // style={{ width: "20%" }}
                            >
                              Impacted Resource
                            </th>
                          )}
                          {service === "aws" && (
                            <th
                              className={styles.tableHead}
                              // style={{ width: "20%" }}
                            >
                              Recommended Action
                            </th>
                          )}
                          <th
                            className={styles.tableHead}
                            // style={{ width: "11%" }}
                          >
                            {service === "aws" && "Account Name"}
                            {service === "azure" && "Subscription Name"}
                          </th>
                          <th
                            className={styles.tableHead}
                            style={{ width: "15%" }}
                          >
                            Region
                          </th>
                          {service === "aws" && (
                            <th
                              className={styles.tableHead}
                              // style={{ width: "7%" }}
                            >
                              Monthly Cost
                              <img
                                style={{ cursor: "pointer" }}
                                // src={sorting}
                                alt=""
                                className={styles.sorting_icon}
                                // onClick={() =>
                                //   handleOrderByClick("monthly_cost")
                                // }
                              ></img>
                            </th>
                          )}
                          <th
                            className={styles.tableHead}
                            // style={{ width: "20%" }}
                          >
                            Resource Type
                          </th>
                          {service === "azure" && (
                            <th
                              className={styles.tableHead}
                              // style={{ width: "8%" }}
                            >
                              Recommended Action
                            </th>
                          )}
                          <th
                            className={styles.tableHead}
                            // style={{ width: "8%" }}
                          >
                            {service === "aws" &&
                              "Recommended Resource Summary"}
                            {service === "azure" && "Lookback Period Days"}
                          </th>

                          {service === "azure" && (
                            <th
                              className={styles.tableHead}
                              // style={{ width: "8%" }}
                            >
                              Impact
                            </th>
                          )}
                          <th
                            className={styles.tableHead}
                            // style={{ width: "2%" }}
                          ></th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading && (
                          <tr>
                            <td colSpan={9}>
                              <div
                                style={{
                                  width: "100%",
                                  marginBottom: 40,
                                  marginTop: 40,
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Spinner
                                  animation="border"
                                  variant="primary"
                                  style={{ top: "50" }}
                                />
                              </div>
                            </td>
                          </tr>
                        )}
                        {(tableData?.data || []).map((item, i) => (
                          <tr
                            id={styles.table_body_tr}
                            className={clsx(styles.accountCol, {
                              [styles.hovered]: i === hoveredRowIndex,
                            })}
                            key={item.resourceId + "-" + i}
                            onMouseEnter={() => handleMouseEnter(i)}
                            onMouseLeave={handleMouseLeave}
                          >
                            {service === "aws" && (
                              <td
                                className={clsx(
                                  styles.accountCol,
                                  styles.accountColNowrap,
                                  styles.accountTitleCol,
                                  styles.accountTitleColData,
                                  styles.my_white_space
                                )}
                              >
                                {item?.recommended_action}
                              </td>
                            )}
                            {service === "azure" && (
                              <td
                                // onClick={() => {
                                //   showRecommendationModal(
                                //     item?.recommendation_id
                                //   );
                                //   setActionType(item?.recommended_action);
                                //   setsummary(
                                //     item?.recommended_resource_summary
                                //   );
                                // }}
                                className={styles.accountCol}
                                style={{
                                  textWrap: "nowrap",
                                }}
                              >
                                {item?.subscription_name ??
                                  item?.impacted_value ??
                                  "--"}
                              </td>
                            )}

                            <td
                              className={clsx(
                                styles.accountCol,
                                styles.accountColNowrap
                                // styles.accountTitleCol,
                                // styles.accountTitleColData
                              )}
                            >
                              <span>
                                {(item.account_name ||
                                  item?.subscription_name) !== null
                                  ? item.account_name ||
                                    item?.subscription_name ||
                                    "--"
                                  : "--"}
                              </span>
                            </td>
                            <td
                              className={clsx(
                                styles.accountCol,
                                styles.my_white_space
                              )}
                            >
                              {item?.region !== null
                                ? item?.region || "--"
                                : "--"}
                            </td>

                            {service === "aws" && (
                              <td
                                className={clsx(
                                  styles.accountCol,
                                  styles.my_white_space
                                )}
                              >
                                {item?.monthly_cost ? (
                                  <CurrencyFormatter
                                    value={item?.monthly_cost}
                                    hideDollar={false}
                                  />
                                ) : (
                                  "--"
                                )}
                              </td>
                            )}
                            <td className={styles.accountCol}>
                              {(item?.current_resource_type ||
                                item?.current_vm_family) !== null
                                ? item?.current_resource_type ||
                                  item?.current_vm_family ||
                                  "--"
                                : "--"}
                            </td>
                            {service === "azure" && (
                              <td className={styles.accountCol}>
                                <span
                                  style={{ textWrap: "nowrap" }}
                                  className={clsx(
                                    item?.recommended_action ===
                                      "PurchaseReservedInstances" &&
                                      style.PurchaseReservedInstances,
                                    item?.recommended_action ===
                                      "PurchaseSavingsPlans" &&
                                      style.PurchaseSavingsPlans,
                                    item?.recommended_action === "Rightsize" &&
                                      style.Rightsize,
                                    item?.recommended_action ===
                                      "MigrateToGraviton" &&
                                      style.MigrateToGraviton,
                                    item?.recommended_action === "Upgrade" &&
                                      style.Upgrade
                                  )}
                                >
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                      <Tooltip className={styles.customTooltip}>
                                        {item?.recommended_action !== null
                                          ? item?.recommended_action || "--"
                                          : "--"}
                                      </Tooltip>
                                    }
                                  >
                                    <div
                                      style={{
                                        maxWidth: "260px",
                                        textWrap: "nowrap",
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                      }}
                                    >
                                      {item?.recommended_action !== null
                                        ? item?.recommended_action || "--"
                                        : "--"}
                                    </div>
                                  </OverlayTrigger>
                                </span>
                              </td>
                            )}
                            {/* <td className={styles.accountCol}>
                        {item?.recommended_resource_type !== null
                          ? item?.recommended_resource_type || "--"
                          : "--"}
                      </td> */}

                            <td className={styles.accountCol}>
                              {service === "aws" && (
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={
                                    <Tooltip className={styles.customTooltip}>
                                      {item?.recommended_resource_summary ||
                                        "--"}
                                    </Tooltip>
                                  }
                                >
                                  <div
                                    style={{
                                      maxWidth: "260px",
                                      textWrap: "nowrap",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {item?.recommended_resource_summary || "--"}
                                  </div>
                                </OverlayTrigger>
                              )}
                              {service === "azure" &&
                                (item?.lookback_period_days !== null
                                  ? item?.lookback_period_days || "--"
                                  : "--")}
                            </td>
                            {service === "azure" && (
                              <td
                                className={styles.accountCol}
                                style={{ textWrap: "nowrap" }}
                              >
                                {item?.impact || "--"}
                              </td>
                            )}
                            <td style={{ width: "2%" }}></td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className={style.fixed} style={{ width: "20%" }}>
                    <div>
                    <table
                      style={{ width: "100%" }}
                      id="customers"
                      // style={{ width: "100%" }}
                      className="table-hover"
                    >
                      <thead>
                        <tr>
                          <th
                            style={{
                              textAlign: "end",
                            }}
                            className={clsx(
                              styles.tableHead,
                              styles.accountCol_center
                            )}
                          >
                            Projected Monthly Saving
                            <img
                              style={{ cursor: "pointer" }}
                              src={sorting}
                              alt=""
                              className={styles.sorting_icon}
                              // onClick={() =>
                              //   handleOrderByClick("monthly_saving")
                              // }
                            ></img>
                          </th>
                          <th
                            className={clsx(
                              styles.tableHead,
                              styles.accountCol_center
                            )}
                          >
                            Status
                          </th>
                          <th
                            className={clsx(
                              styles.tableHead,
                              styles.accountCol_center
                            )}
                          ></th>
                          <th
                            style={{
                              textAlign: "end",
                            }}
                            className={clsx(
                              styles.tableHead,
                              styles.accountCol_center
                            )}
                          ></th>
                          {/* {service === "aws" && (

                      <th
                        className={clsx(
                          styles.tableHead,
                          styles.accountCol_center
                        )}
                        style={{
                          textAlign: "center",
                          paddingRight: "10px",
                        }}
                      >
                        Saving %
                        <img
                          style={{ cursor: "pointer" }}
                          src={sorting}
                          alt=""
                          className={styles.sorting_icon}
                          onClick={() => handleOrderByClick("saving")}
                        ></img>
                      </th>
                      )} */}
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading &&
                          (tableData?.data || []).map((item, i) => (
                            <tr
                              style={{ position: "relative" }}
                              className={clsx(styles.accountCol, {
                                [styles.hovered]: i === hoveredRowIndex,
                              })}
                              key={item.resourceId + "-" + i}
                              onMouseEnter={() => handleMouseEnter(i)}
                              onMouseLeave={handleMouseLeave}
                            >
                              <td
                                style={{ textAlign: "center" }}
                                className={clsx(
                                  styles.accountCol,
                                  styles.accountCol_weight,

                                  styles.accountCol_end,
                                  Number(item?.monthly_saving || 0) < 0 &&
                                    styles.accountCol_red,
                                  Number(item?.monthly_saving || 0) > 0 &&
                                    styles.accountCol_green
                                )}
                              >
                                {item?.monthly_saving ? (
                                  <CurrencyFormatter
                                    value={item?.monthly_saving}
                                    hideDollar={false}
                                  />
                                ) : (
                                  "--"
                                )}
                              </td>{" "}
                              <td
                                className={clsx(
                                  styless.snap_table_data__Class,
                                  styless.snapshot_col_weight,
                                  styless.snapshot_col_end,
                                  styles.accountCol,
                                  styles.accountCol_weight,
                                )}          
                              >
                                {item?.status ? (
                                  <>
                                    {item?.status == "retained" && (
                                      <span className={styless.badgeName}>
                                        {" "}
                                        Active
                                      </span>
                                    )}
                                    {item?.status == "open" && (
                                      <span className={styless.badgeName}>
                                        {" "}
                                        Open
                                      </span>
                                    )}
                                    {item?.status == "accepted" && (
                                      <span
                                        className={styless.badgeNamePending}
                                      >
                                        Pending Approval
                                      </span>
                                    )}
                                    {item?.status == "in_progress" && (
                                      <span
                                        className={styless.badgeNameProgress}
                                      >
                                        In Progress
                                      </span>
                                    )}
                                    {item?.status == "retained_deleted" && (
                                      <span className={styless.badgeNameDone}>
                                        <img src={DoneIcon} alt="" />
                                        Deleted
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  <span
                                    className={
                                      item?.status && styless.badgeName
                                    }
                                  >
                                    --
                                  </span>
                                )}
                              </td>
                              <td
                                className={clsx(
                                  styless.snap_table_data__Class,
                                  styless.snapshot_col_weight,
                                  
                                )}
                              >
                                {/* {!UnretainPending && item?.resourceId &&( */}
                                  {/* <Spinner style={{width:"17px",height:"17px",color:"grey"}} /> */}
                                {/* )} */}
                                {/* {UnretainPending && item?.resourceId  && ( */}
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={tooltip}
                                >
                                  <button
                                    className="fgvhjikj"
                                    style={{
                                      border: "none",
                                      background: "transparent",
                                    }}
                                    onClick={() => {
                                      acceptMutatejira({
                                        service,
                                        status: "un_retain",
                                        comment: "",
                                        recommendations: [
                                          {
                                            recommId: item.recommendation_id,
                                          },
                                        ],
                                      });
                                    }}
                                    // onClick={() => {
                                    //   handleUnarchiveModal(
                                    //     data?.snapshotId,
                                    //     data?.history
                                    //   );
                                    // }}
                                    onMouseEnter={() => setHoveredIndex(i)}
                                    onMouseLeave={() => setHoveredIndex(null)}
                                  >
                                    <img
                                      src={
                                        hoveredIndex === i
                                          ? unarchieve
                                          : uploadicon
                                      }
                                      alt=""
                                    />
                                  </button>
                                </OverlayTrigger>
                                {/* // )} */}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <Pagination totalItems={tableData?.count?.total_data || 0} />
              </div>
              {/* {showCopyMessage && (
                  <div className={styles.copyMessage}>Copy to Clipboard!</div>
                )} */}
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default RetainedBodySection;

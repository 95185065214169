import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

/**
 * @typedef GetAllStatistics
 * @property {GetTotalCountStatistics[]} data
 */

/**
 * @typedef GetTotalCountStatistics
 * @property {String} id
 */

/**
 * @typedef GetStatisticsReq
 * @property {'aws' | 'gcp' | 'azure'  } service
 */

export const getRgFilterBudgets = (token) => {
  return async ({ service, subscriptions,scope }) => {
    if (service === "azure") {
      const filterAccount = await fetch(
        `${config.apiHost}/v1/${service}/budget/filters/rg?scope=${scope}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(subscriptions),
        }
      );
      return await filterAccount.json();
    }
  };
};

/**
 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useRgFilterBudgets = (req, deps = [],enabled=true) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getRgFilterBudgets", req, ...deps],
    queryFn: () => getRgFilterBudgets(appContext.idToken)(req),
    retry:false,
    enabled
  });
  return query;
};

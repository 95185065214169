import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
  ERROR_STATUS_CODES,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_QUERY_RESOURCE_FILTERS,
  API_NO_DATA_FOUND_FOR_QUERY_IDLE_FILTERS,
} from "@/lib/errors/error-constants";

/**
 * @typedef GetAllStatistics
 * @property {GetDataStatistics} data
 * @property {GetTotalCountStatistics} count
 */

/**
 * @typedef GetTotalCountStatistics
 * @property {number} total_data
 */

/**
 * @typedef GetDataStatistics
 * @property {string} account_name
 * @property {string} name
 * @property {string} id
 * @property {string} resource_group_name
 * @property {string} resource_type
 * @property {string} region
 * @property {string} owner
 * @property {number} idle_days
 * @property {string} resource_id
 * @property {number} saving
 * @property {string} status
 */

/**
 * @typedef GetIdleFilters
 * @property {string[]} accounts
 * @property {string[]} rg
 * @property {string[]} regions
 * @property {string[]} tags
 */

/**
 * @typedef GetAllStatisticsRes
 * @property {GetAllStatistics[]} statistics
 */
/**
 * @typedef GetStatisticsReq
 * @property {'aws' | 'gcp' | 'azure'  } service
 * @property {'ip' | 'disk' | 'vm' | 'lb' |'all'} resourceType
 * @property {GetIdleFilters} filters
 * @property {number} page
 * @property {number} recordsPerPage
 * @property {number} idleDays
 * @property {'true' | 'false'} desc
 * @property {'account_name' | 'name' | 'resource_group_name' |'resource_id' | 'resource_type' | 'region' | 'idle_days' | 'saving'} orderBy
 * @property { 'false' | 'true' } untagged
 */

// ------------------------------------------api for the archive table------------------------------------------------------------

export const getRiUtlizationDetails = (token) => {
  /**
   * @param {GetStatisticsReq} req
   * @returns {Promise<GetAllStatistics>}
   */
  return async (
    {
      service,
      page,
      filters,
      period,
      start_date,
      end_date,
    },
    signal
  ) => {
    const body = {
      page:page,
      filters: {
        accounts: filters?.accounts || [],
        regions: filters?.regions || [],
        terms: filters?.term || [], 
      },
      period: period,
      startDate: start_date,
      endDate: end_date,
    };

    const owners = await fetch(
      `${config.apiHost}/v1/${service}/ri-utilization/get-all`,
      {
        method: "POST",
        body: JSON.stringify(body),
        signal,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!owners.ok) {
      if (ERROR_STATUS_CODES.includes(owners.status)) {
        throw new Error(API_LOADING_ERROR);
      }
      if (owners.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR);
      }
      throw new Error((await owners.json()).message);
    }

    const data = await owners.json();

    if (data?.data.length === 0) {
      if (body.accounts.length || body.regions.length || body.term.length) {
        throw new Error(API_NO_DATA_FOUND_FOR_FILTERS_ERROR);
      }
      throw new Error(API_NO_DATA_FOUND_ERROR);
    }
    return data;
  };
};

/**

 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useRiUtlizationDetails = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getRiUtlizationDetails", req, ...deps],
    queryFn: ({ signal }) =>
      getRiUtlizationDetails(appContext.idToken)(req, signal),
  });
  return query;
};

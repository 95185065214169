import React, { useState, useRef, useMemo } from "react";
import download from "@/assets/img/download.svg";
import menuicon from "@/assets/img/menu.svg";
import barcharticon from "@/assets/img/graphCharticon.svg";
import linecharticon from "@/assets/img/lineCharticon.svg";
import Dropdown from "react-bootstrap/Dropdown";
import { CSVLink } from "react-csv";
import { useAllGraphStatistics } from "@/services/saving/getAllGraphStatistics.repo";
import AllBarData from "./all-cloud-bar-graph.part";
import AllCloudLine from "./all-cloud-line.part";
import BarGraphErrorStates from "@/components/States/BarGraphErrorStates";
import styles from "./savings.module.css";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";
import { compareAsc, parse } from "date-fns";

const SavingBarGraph = ({ service, toDate, fromDate }) => {
  const [chartType, setChartType] = useState("bar");
  const csvLinkRef = useRef(null);

  const colorMap = {
    AWS: "#F90",
    Azure: "#1F78B4",
    GCP: "#0F9D58",
  };
  const query = useMemo(() => {
    return {
      service,
      toDate,
      fromDate,
    };
  }, [fromDate, service, toDate]);

  const { data, isLoading, error } = useAllGraphStatistics(query);

  const awsData = data?.aws || [];
  const azureData = data?.azure || [];

  const monthlyData = {};

  awsData.forEach(({ month, total_savings }) => {
    if (!monthlyData[month]) {
      monthlyData[month] = { Aws: total_savings, Azure: 0 };
    } else {
      monthlyData[month].Aws += total_savings;
    }
  });

  azureData.forEach(({ month, total_savings }) => {
    if (!monthlyData[month]) {
      monthlyData[month] = { Aws: 0, Azure: total_savings };
    } else {
      monthlyData[month].Azure += total_savings;
    }
  });

  const ChartDataSS = Object.entries(monthlyData).map(([month, savings]) => ({
    Month: month,
    Azure: savings.Azure,
    AWS: savings.Aws,
  }));

  ChartDataSS.sort((a, b) => {
    const aDate = parse(a.Month, "MMM, yy", new Date());
    const bDate = parse(b.Month, "MMM, yy", new Date());
    return compareAsc(aDate, bDate);
  });

  const handleDownloadCsv = () => {
    const csvData = [
      ["Month", "AWS", "Azure"],
      ...ChartDataSS.map(({ Month, AWS, Azure }) => [Month, AWS, Azure]),
    ];

    csvLinkRef.current.link.click();
  };

  return (
    <>
      {isLoading && <BarGraphErrorStates />}
      {error && (
        <div style={{ paddingLeft: "12px" }}>
          <ScenariosErrorState
            error={error.message}
            messageConfig={{
              [API_NO_DATA_FOUND_FOR_FILTERS_ERROR]: {
                message: "No Data Available for Selected Filters",
                additionalMessage:
                  "It looks like there are no savings data matching your filter criteria. Please try adjusting your filters to explore other options.",
              },
              [API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR]: {
                message: "No Savings Data for the Selected Date Range",
                additionalMessage:
                  "The selected date range doesn’t contain any savings data. Try choosing a different range to view available savings.",
              },
              [API_LOADING_ERROR]: {
                message: "Oops!",
                additionalMessage:
                  "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
              },
              [API_NOT_FOUND_ERROR]: {
                message: "404 Error",
                additionalMessage:
                  "We couldn’t find the savings data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
              },
            }}
          />
        </div>
      )}
      {(data?.azure.length > 0 || data?.aws.length > 0) && (
        <div
          className="container-fluid p-0"
          style={{
            display: "flex",
            alignItems: "start",
            flexDirection: "column",
            gap: "20px",
            marginLeft: "12px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div className={styles.all_cloud_text}>All Cloud Savings</div>

            <div className={styles.menu_icon_css1}>
              <Dropdown style={{ marginBottom: "2px" }}>
                <Dropdown.Toggle
                  variant="light"
                  id={styles.custom_dropdown_toggle}
                  className={styles.custom_dropdown_toggle}
                  style={{ background: "transparent", border: "none" }}
                >
                  <img src={menuicon} alt="Menu" />
                </Dropdown.Toggle>
                <Dropdown.Menu className={styles.dropdown_menu}>
                  <Dropdown.Item
                    className={styles.dropdown_item}
                    eventKey="bar"
                    onClick={() => setChartType("bar")}
                  >
                    <img
                      src={barcharticon}
                      alt=""
                      style={{ marginRight: "10px" }}
                    ></img>
                    Bar Chart
                  </Dropdown.Item>
                  <Dropdown.Item
                    className={styles.dropdown_item}
                    eventKey="line"
                    onClick={() => setChartType("line")}
                  >
                    <img
                      src={linecharticon}
                      alt=""
                      style={{ marginRight: "10px" }}
                    ></img>
                    Line Chart
                  </Dropdown.Item>
                  <Dropdown.Divider />

                  <Dropdown.Item
                    className={styles.dropdown_item}
                    eventKey="Download as CSV"
                    onClick={handleDownloadCsv}
                  >
                    <img
                      src={download}
                      alt=""
                      style={{ marginRight: "10px" }}
                    />
                    Download as CSV
                  </Dropdown.Item>
                  <CSVLink
                    data={ChartDataSS}
                    filename={`${service}-savings-data`}
                    target="_blank"
                    ref={csvLinkRef}
                    style={{ display: "none" }}
                  >
                    Download
                  </CSVLink>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12 p-0">
                {chartType === "bar" ? (
                  <AllBarData
                    barData={ChartDataSS}
                    colorMap={colorMap}
                    showLegend={true}
                  />
                ) : (
                  <AllCloudLine
                    lineData={ChartDataSS}
                    colorMap={colorMap}
                    // activeLegend={activeLegend}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SavingBarGraph;

import React, { useContext, useMemo } from "react";
import styles from "@/page-components/snapshot/snapshots.module.css";
import clsx from "clsx";
import style from "@/page-components/RightSizing/rightSizing.module.css";
import styleMain from "@/page-components/ri-utilization/utilization.module.css";
import {
  formatCount,
  ToolTipComponent,
} from "@/page-components/cost-explorer/components/cost-explorer-header.component";
import CurrencyFormatter from "@/lib/value-converison";
import { useRiTotalStats } from "@/services/ri-utilization/get-total-stats.repo";
import { RiutilizationContext } from "../utilization.context";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR_CUSTOM_WIDGET,
  API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET,
  API_NOT_FOUND_ERROR_CUSTOM_WIDGET,
} from "@/lib/errors/error-constants";

const AWSHeaderSectionUtilization = ({ service, data, error }) => {
  const context = useContext(RiutilizationContext);

  const total_ri_vs_saving = formatCount(data?.total_ri_vs_saving || 0);

  return (
    <>
      {error && (
        <ScenariosErrorState
          error={error.message}
          headerItem="header"
          messageConfig={{
            [API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET]: {
              message: "No Data Available",
              additionalMessage:
                "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
            },
            [API_LOADING_ERROR_CUSTOM_WIDGET]: {
              message: "Oops!",
              additionalMessage:
                "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
            },
            [API_NOT_FOUND_ERROR_CUSTOM_WIDGET]: {
              message: "404 Error",
              additionalMessage:
                "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
            },
          }}
        />
      )}
      {!error && data && (
        <div className={clsx("row", styles.snap_sec_div)}>
          <div
            className={clsx(styles.snapshots_main, styleMain.containerPadding)}
            style={{ width: "25%" }}
          >
            <span className={styles.snapshot_text}>
              Total RI Utilization
              <span
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  color: "#ADB5BD",
                  marginLeft: "9px",
                }}
              >
                Last 3 months
              </span>
            </span>
            <span className={styles.snapshot_value}>
                <span style={{ cursor: "context-menu" }}>
                  {data?.total_ri_utilization !== null
                    ? Number(data?.total_ri_utilization).toFixed(2) + "%"
                    : "--"}
                </span>
          
            </span>
          </div>

          <div
            className={clsx(styles.snapshots_main, styleMain.containerPadding)}
            style={{
              width: "50%",
              background: "#F8F9FA",
              display: "flex",
              flexDirection: "row",
              gap: "40px",
            }}
          >
            <div style={{ width: "30%" }}>
              <span className={styles.snapshot_text}>UNUSED RI HOURS</span>
              <span className={styles.snapshot_value}>
                  <span style={{ cursor: "context-menu" }}>
                    {data?.unused_ri !== null ? data?.unused_ri + "%" : "--"}
                  </span>
              </span>
            </div>
            <div style={{ width: "30%" }}>
              <span className={styles.snapshot_text}>EXPIRING RIS</span>
              <span className={styles.snapshot_value}>
                  <span style={{ cursor: "context-menu" }}>
                    {data?.expiring_ri !== null ? data?.expiring_ri : "--"}
                  </span>
              </span>
            </div>
          </div>
          <div
            className={clsx(style.rec_second_div_third_container)}
            style={{ width: "25%", background: "#056EE5" }}
          >
            <div className="container-fluid innerContainer-1">
              <div className="container innerContainer-div">
                <div className="container innerContainer-text">
                  <div className="container innerContainer-text-1">
                    TOTAL RI COST VS. SAVINGS
                  </div>
                  <div
                    className="container innerContainer-text-2"
                    style={{ display: "flex", gap: "4px" }}
                  >
                    <ToolTipComponent data={data?.total_ri_vs_saving || 0}>
                      <span style={{ cursor: "context-menu" }}>
                        {total_ri_vs_saving}
                      </span>
                    </ToolTipComponent>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AWSHeaderSectionUtilization;

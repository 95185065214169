import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import styles from "@/page-components/budgets/components/budgets.module.css";
import styless from "@/page-components/cost-explorer/cost.explorer.module.css";
import clsx from "clsx";
import { useCombineQuery } from "@/services/budget/setDelete";
import download from "@/assets/img/download.svg";
import navigateImg from "@/assets/img/link-external.svg";
import {
  AreaChart,
  Area,
  YAxis,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import {
  eachDayOfInterval,
  eachMonthOfInterval,
  endOfMonth,
  format,
  getMonth,
  isBefore,
  isValid,
  parseISO,
  startOfMonth,
} from "date-fns";
import ProgressBar from "react-bootstrap/ProgressBar";
import SpinnerComponent from "@/page-components/admin/components/spinner.component";
import { Budget_Provider, BudgetContext } from "../budget.context";
import { CostChartDivision } from "./chartSectionComponent/ChartSectionMain.part";
import { CustomTooltip } from "./chartSectionComponent/customtooltip.part";
import { OverlayTrigger, Tooltip as BootstrapTooltip } from "react-bootstrap";
import CurrencyFormatter from "@/lib/value-converison";

const DetailedBudgetCost = ({ service }) => {
  return (
    <Budget_Provider>
      <DetailedBudget service={service} />
    </Budget_Provider>
  );
};
const DetailedBudget = ({ service }) => {
  const context = useContext(BudgetContext);

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();
  const {
    mutate: Body,
    data: Details,
    isPending: budgetDetailsLoading,
  } = useCombineQuery();

  useEffect(() => {
    const scope = searchParams.get("scope");
    const resourceGroup = searchParams.get("resourceGroup");

    let body;
    if (service === "azure") {
      body = {
        subscriptionId: params?.Id,
        budgetName: params?.BudgetName,
        scope: scope,
        resourceGroup: `${resourceGroup}`,
        managementGroup: params?.Id,
      };
    }
    if (service === "aws") {
      body = { accountId: params?.Id, budgetName: params?.BudgetName };
    }
    Body({
      body: body,
      service: service,
      selection: "singleRes",
    });
  }, [Body, params, service]);

  // const handleDownloadCsv = (data) => {
  //   const budgetData = data;
  //   const csvData = [Object.keys(budgetData), Object.values(budgetData)];
  //   const csvContent = csvData.map((row) => row.join(",")).join("\n");
  //   const blob = new Blob([csvContent], { type: "text/csv" });
  //   const link = document.createElement("a");
  //   link.href = window.URL.createObjectURL(blob);
  //   link.download = `${budgetData.budgetName}-csv-file.csv`;
  //   link.click();
  // };

  const startDate = new Date(
    Details?.budgetDetails?.StartDate || Details?.budgetDetails?.startDate
  );
  const endDate = new Date(
    Details?.budgetDetails?.EndDate || Details?.budgetDetails?.endDate
  );

  const inDays = (endDate - startDate) / (1000 * 60 * 60 * 24);
  const useageDataPercentage = Number(
    (
      ((Details?.budgetDetails?.usedAmount ||
        Details?.budgetDetails?.UsedAmount) /
        Number(
          Details?.budgetDetails?.amount || Details?.budgetDetails?.Amount
        )) *
      100
    )?.toFixed(2)
  );

  const forcastPercent = (() => {
    const forecastedAmount =
      Details?.budgetDetails?.forecastedAmount ??
      Details?.budgetDetails?.ForecastedAmount;
    const amount =
      Details?.budgetDetails?.amount ?? Details?.budgetDetails?.Amount;
    if (forecastedAmount && amount) {
      return `${((forecastedAmount / amount) * 100).toFixed(1)}%`;
    }
    return "--";
  })();
  const convertToDateFormats = (inputDate) => {
    try {
      const formattedDate = format(new Date(inputDate), "dd-MM-yyyy");
      return formattedDate;
    } catch (error) {
      return "";
    }
  };

  const awsAccount = Details?.budgetDetails?.AccountId;
  const azureSubscription = Details?.budgetDetails?.subscriptionName;
  const subscription = service === "aws" ? awsAccount : azureSubscription;

  const serviceLength = (Details?.filters?.dimensions || []).length;
  const tagLength = (Details?.filters?.tags || []).length;

  const formatedStartDateAzure = convertToDateFormats(
    Details?.budgetDetails?.startDate
  );
  const formatedEndDateAzure = convertToDateFormats(
    Details?.budgetDetails?.endDate
  );

  const formatedStartDateAws = convertToDateFormats(
    Details?.budgetDetails?.StartDate
  );
  const formatedEndDateAws = convertToDateFormats(
    Details?.budgetDetails?.EndDate
  );

  const formatedEndDate =
    service === "aws" ? formatedEndDateAws : formatedEndDateAzure;
  const formatedStartDate =
    service === "aws" ? formatedStartDateAws : formatedStartDateAzure;

  const azureRemainingBudget =
    (Details?.budgetDetails?.amount?.toFixed(2) || 0) -
    (Details?.budgetDetails?.usedAmount?.toFixed(2) || 0);

  const awRemainingBudget =
    (Details?.budgetDetails?.Amount || 0) -
    (Details?.budgetDetails?.UsedAmount || 0);

  const currentDate = new Date();
  const DaysRemaining = Math.floor(
    (endDate - currentDate) / (1000 * 60 * 60 * 24)
  );

  const remainingBudget =
    service === "aws" ? awRemainingBudget : azureRemainingBudget;
    const remainBudget = (
      <>
        USD <CurrencyFormatter value={remainingBudget || 0} hideDollar={true} />
      </>
    );
  const [errors, setError] = useState(null);
  const [dates, setDates] = useState([]);
  const [months, setMonths] = useState([]);
  const [selectedTab, setSelectedTabs] = useState("overview");


  const getMonthNames = (start, end) => {
    const startParsed = parseISO(start);
    const endParsed = parseISO(end);

    if (
      !isValid(startParsed) ||
      !isValid(endParsed) ||
      startParsed > endParsed
    ) {
      setMonths([]);
      setError("Invalid date range");
      return;
    }

    const interval = eachMonthOfInterval({
      start: startParsed,
      end: endParsed,
    });
    if (interval.length === 1) {
      const startOfMonths = startOfMonth(startParsed);
      const endOfMonths = endOfMonth(startParsed);
      const eachDay = eachDayOfInterval({
        start: startOfMonths,
        end: endOfMonths,
      });

      const datesArray = eachDay.map((date) => format(date, "d"));
      setDates(datesArray);
      setMonths([]);
    } else {
      const monthNames = interval.map((date) => format(date, "MMMM"));
      setMonths(monthNames);
    }
    setError(null);
    const monthNames = interval.map((date) => format(date, "MMMM"));
    setMonths(monthNames);
    setError(null);
  };

  useEffect(() => {
    if (service === "azure") {
      getMonthNames(
        Details?.budgetDetails?.startDate,
        Details?.budgetDetails?.endDate
      );
    }
    if (service === "aws") {
      getMonthNames(
        Details?.budgetDetails?.StartDate,
        Details?.budgetDetails?.EndDate
      );
    }
  }, [
    Details?.budgetDetails?.startdate,
    Details?.budgetDetails?.enddate,
    service,
    Details?.budgetDetails?.StartDate,
    Details?.budgetDetails?.EndDate,
    Details?.budgetDetails?.startDate,
    Details?.budgetDetails?.endDate,
  ]);

  const handleUrl = (name, id, scope) => {
    // if (serviceLength === 0 || tagLength > 0 ) {
    searchParams.set("BudgetName", `${name}`);
    searchParams.set("Id", id);
    searchParams.set("scope", scope);
    setSearchParams(searchParams);
    const url = `/${service}/dashboard?name=${name}&id=${id}&scope=${scope}`;
    window.open(url, "_blank");
    // }
  };

  const awsScope = "Account";
  const azureScope = searchParams.get("scope");
  const scope = service === "aws" ? awsScope : azureScope;
  const resourceGroup = searchParams.get("resourceGroup");

  return (
    <>
      {budgetDetailsLoading && (
        <div style={{ marginTop: "300px" }}>
          <SpinnerComponent />
        </div>
      )}
      {!budgetDetailsLoading && (
        <div className={styles.deltailePageContainer}>
          <div className={styles.detailePageHeader}>
            <span
              className={styles.budgetNavigation}
              onClick={() => navigate(-1)}
            >
              Budgets
            </span>
            <span>{">"}</span>
            <span className={styles.deltailePageTitle}>
              {params?.BudgetName}
            </span>
          </div>
          <div className={styles.detailePageBody}>
            <div className={styles.detailePageSidebarContainer}>
              <SidebarBoxes
                title={"Budgets"}
                heading={params?.BudgetName}
                headingmain={params?.BudgetName}
                costUsed={`${
                  Details?.budgetDetails?.amount?.toFixed(2) ||
                  Details?.budgetDetails?.Amount?.toFixed(2) ||
                  "--"
                }`}
                service={service}
              />
              <SidebarBoxes
                indayss={` in ${inDays} days`}
                title={"Current Spend"}
                heading={`${useageDataPercentage || 0}% of the Budget`}
                costUsed={`${
                  Details?.budgetDetails?.usedAmount?.toFixed(2) ||
                  Details?.budgetDetails?.UsedAmount?.toFixed(2) ||
                  "--"
                }`}
                enableProgressBar={{
                  enable: true,
                  data: {
                    usedPercentage: useageDataPercentage || 0,
                    amount:
                      Details?.budgetDetails?.amount ||
                      Details?.budgetDetails?.Amount,
                    usedAmount:
                      Details?.budgetDetails?.usedAmount?.toFixed(2) ||
                      Details?.budgetDetails?.UsedAmount?.toFixed(2),
                  },
                }}
                service={service}
              />
              <SidebarBoxes
                title={"Forecasted Cost"}
                heading={`${forcastPercent} of Budget`}
                costUsed={`${
                  Details?.budgetDetails?.forecastedAmount?.toFixed(2) ||
                  Details?.budgetDetails?.ForecastedAmount?.toFixed(2) ||
                  "--"
                }`}
                service={service}
              />
              <SidebarBoxes
                remainingDays={`${DaysRemaining} days remaining`}
                title={"Remaining Budget"}
                heading={remainBudget}
                costUsed={" "}
                service={service}
              />
            </div>
            <div className={styles.detailePageMainContainer}>
              <div className={styles.overviewBox}>
                <p className={styles.overviewTitle}>
                  <span
                    className={`${styles.tabsStyle} ${
                      selectedTab === "overview"
                        ? styles.selectedTabactive
                        : styles.selectedTabinactive
                    }`}
                    onClick={() => setSelectedTabs("overview")}
                  >
                    Overview
                  </span>
                  <span
                    className={`${styles.tabsStyle} ${
                      selectedTab === "insight"
                        ? styles.selectedTabactive
                        : styles.selectedTabinactive
                    }`}
                    onClick={() => setSelectedTabs("insight")}
                  >
                    Insights
                  </span>
                </p>
                <div className={styles.utilities}>
                  {/* {(serviceLength > 0 || tagLength > 0 || subscription) && ( )} */}
                  <span className={styles.utilityButtons}>
                    <img
                      src={navigateImg}
                      width={"12px"}
                      height={"12px"}
                      alt="navigate"
                    />
                    <p
                      className={styles.navigateT}
                      onClick={() =>
                        handleUrl(params?.BudgetName, params?.Id, scope)
                      }
                    >
                      View on Cost Insights
                    </p>
                  </span>

                  {/* <span className={styles.utilityButtons}>
                    <img
                      src={download}
                      width={"12px"}
                      height={"12px"}
                      alt="download"
                    />
                    <p
                      className={styles.download}
                      onClick={() => handleDownloadCsv(Details?.budgetDetails)}
                    >
                      Download as CSV
                    </p>
                  </span> */}
                </div>
              </div>
              {selectedTab === "insight" && (
                <div>
                  <div className={styles.graphSection}>
                    <span className={styles.graphHeading}>
                      Budget Utilization
                    </span>

                    <AreaChartComponent
                      data={Details}
                      months={months}
                      dates={dates}
                      service={service}
                    />
                  </div>
                  <div className={styles.graphSection}>
                    <div>
                      <span className={styles.graphHeading}>
                        Budget Performance Analysis
                      </span>
                      <span
                        style={{
                          fontWeight: "500",
                          fontSize: "12px",
                          color: "#ADB5BD",
                          marginLeft: "8px",
                        }}
                      >
                        This Month
                      </span>
                    </div>
                    <div>
                      <span></span>
                    </div>

                    <CostChartDivision
                      service={service}
                      params={params}
                      scope={scope}
                      resourceGroup={resourceGroup}
                    />
                  </div>
                </div>
              )}

              {selectedTab === "overview" && (
                <div className={styles.budgetDetailSection}>
                  <div style={{ width: "100%" }}>
                    <p className={styles.budgetDetailHeader}>Budget Details</p>
                    <div className={styles.detailBoxContainer}>
                      <DetailBox
                        title={["Scope", "Duration", "Services"]}
                        value={[
                          scope || "--",
                          `${formatedStartDate} - ${formatedEndDate}`,
                          serviceLength,
                        ]}
                      />
                      <DetailBox
                        title={["Periodicity", "Threshold", "Tags"]}
                        value={[
                          Details?.budgetDetails?.timeUnit ||
                            Details?.budgetDetails?.TimeUnit ||
                            "--",
                          Number(Details?.budgetDetails?.threshold) ||
                            Details?.budgetDetails?.Threshold?.toFixed(2) ||
                            "--",
                          tagLength,
                        ]}
                      />
                      <DetailBox
                        title={["Notifications", "Recipients", "Comparison"]}
                        value={[
                          Details?.budgetDetails?.notificationType ||
                            Details?.budgetDetails?.NotificationType ||
                            "--",
                          1,
                          Details?.budgetDetails?.comparisonOperator ||
                            Details?.budgetDetails?.ComparisonOperator ||
                            "--",
                        ]}
                      />
                    </div>
                  </div>
                  <div style={{ width: "100%" }}>
                    <p className={styles.budgetDetailHeader}>Filters</p>

                    <div className={styles.tableContainer}>
                      {/* {Details?.filters?.dimensions.length > 0 && ( */}
                      <DimensionTable
                        data={Details?.filters?.dimensions}
                        headerTitle={"Dimension"}
                      />
                      {/* )} */}
                      {/* {Details?.filters?.tags.length > 0 && ( */}
                      <DimensionTable
                        data={Details?.filters?.tags}
                        headerTitle={"Tags"}
                      />
                      {/* )} */}
                      {/* {Details?.filters?.tags.length === 0 &&
                      Details?.filters?.dimensions.length === 0 && (
                        <DataAbsense />
                      )} */}
                    </div>
                  </div>
                  <div style={{ width: "100%" }}>
                    <p
                      className={styles.budgetDetailHeader}
                      style={{ marginBottom: "12px" }}
                    >
                      Alert recipients (email)
                    </p>
                    <table style={{ width: "100%" }}>
                      <thead>
                        <th className={styles.theadStylings}>Recipients</th>
                      </thead>
                      <tbody>
                        {service === "aws" && (
                          <td className={styles.tdStyling}>
                            {Details?.budgetDetails?.AlertReceipient ||
                              "No Recipients"}
                          </td>
                        )}
                        {service === "azure" && (
                          <td
                            className={styles.tdStyling}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "10px",
                            }}
                          >
                            {Array.isArray(
                              Details?.budgetDetails?.alertReceipient
                            ) ? (
                              Details.budgetDetails.alertReceipient.map((i) => (
                                <div key={i}>{i}</div>
                              ))
                            ) : (
                              <div>
                                {Details?.budgetDetails?.alertReceipient}
                              </div>
                            )}
                          </td>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DetailedBudgetCost;

const SidebarBoxes = ({
  headingmain,
  remainingDays,
  indayss,
  title,
  heading,
  costUsed,
  enableProgressBar,
  service,
}) => {
  const headingStyles = clsx(styles.boxBodyTitleCS, {
    [styles.boxBodyTitle]: title === "Budgets",
    [styles.boxBodyTitleF]: title === "Forecasted Cost",
  });
  const costUsedStyles = clsx(styles.boxCostUsedC, {
    [styles.boxCostUsedT]: title === "Budgets",
    [styles.boxBudgetRemaining]: title === "Remaining Budget",
  });

  const barColor = clsx("forcastPercentageColor", {
    forcastPercentageExcededColor:
      enableProgressBar?.data?.usedAmount > enableProgressBar?.data?.amount,
  });

  return (
    <>
      <div className={styles.detailPageBoxes}>
        <div className={styles.boxTitle}>{title}</div>
        <div className={headingStyles}>
          {headingmain ? (
            <OverlayTrigger
              placement="bottom"
              overlay={
                <BootstrapTooltip id="tooltip">{headingmain}</BootstrapTooltip>
              }
            >
              <span>{heading || "--"} </span>
            </OverlayTrigger>
          ) : (
            <span>{heading || "--"} </span>
          )}

          <span className={styles.IndaysStyle}>{indayss}</span>
        </div>
        <div className={costUsedStyles}>
          {!remainingDays && (
            <>
            
            USD <CurrencyFormatter value={costUsed || 0} hideDollar={true} />
            </>

          )}
          {remainingDays}
        </div>
        {enableProgressBar?.enable === true && (
          <>
            <ProgressBar style={{ width: "250px", height: "8px" }}>
              <ProgressBar
                className={barColor}
                now={Number(enableProgressBar?.data.usedPercentage)}
                key={1}
              />
              {/* {enableProgressBar?.data?.usedAmount <
              {/* {enableProgressBar?.data?.usedAmount <
                enableProgressBar?.data?.amount && (
                <ProgressBar
                  style={{ background: "#EDEFF2" }}
                  now={Number(enableProgressBar?.data?.amount)}
                  key={2}
                />
              )} */}
            </ProgressBar>
          </>
        )}
      </div>
    </>
  );
};

const AreaChartComponent = ({ data, months, dates, service }) => {
  if (!data?.budgetDetails || !months || !dates) {
    return <div>No data available</div>;
  }
  let amount, usedamount, forecastedamount;
  if (service === "aws") {
    amount = data?.budgetDetails?.Amount || 0;
    usedamount = data?.budgetDetails?.UsedAmount || 0;
    forecastedamount = data?.budgetDetails?.ForecastedAmount || 0;
  } else {
    amount = data?.budgetDetails?.amount || 0;
    usedamount = data?.budgetDetails?.usedAmount || 0;
    forecastedamount = data?.budgetDetails?.forecastedAmount || 0;
  }

  if (
    amount === undefined ||
    usedamount === undefined ||
    forecastedamount === undefined
  ) {
    return <div>Data is incomplete</div>;
  }

  const quarter1 = ["January", "February", "March"];
  const quarter2 = ["April", "May", "June"];
  const quarter3 = ["July", "August", "September"];
  const quarter4 = ["October", "November", "December"];

  function getQuarter(month) {
    if (quarter1.includes(month)) {
      return quarter1;
    } else if (quarter2.includes(month)) {
      return quarter2;
    } else if (quarter3.includes(month)) {
      return quarter3;
    } else if (quarter4.includes(month)) {
      return quarter4;
    }
    return null;
  }

  let currentMonthIndex = new Date().getMonth(); // 0-based index for current month
  let currentDate = new Date().getDate(); // Current day of the month
  let totalMonths = months.length;
  let usedamountPerDay = (usedamount / currentDate).toFixed(2);
  let forecastedamountPerMonth = (forecastedamount / totalMonths).toFixed(2);
  let currentYear = new Date().getFullYear();
  let daysInCurrentMonth = new Date(
    currentYear,
    currentMonthIndex + 1,
    0
  ).getDate();
  let currentMonth = new Date(currentYear, currentMonthIndex);
  // let currentMonthFormate = format(currentMonth, "MMMM");
  let currentMonthFormate = format(currentMonth, "MMMM");
  let currentMonthPosition = months.indexOf(currentMonthFormate);

  let graphData;

  if (
    data?.budgetDetails?.timeUnit === "Monthly" ||
    data?.budgetDetails?.TimeUnit === "MONTHLY"
  ) {
    function getDaysInMonth(year, month) {
      let date = new Date(year, month, 1);
      let days = [];
      while (date.getMonth() === month) {
        days.push(new Date(date));
        date.setDate(date.getDate() + 1);
      }
      return days;
    }

    function getCurrentMonthDays() {
      let now = new Date();
      let year = now.getFullYear();
      let month = now.getMonth();
      return getDaysInMonth(year, month);
    }
    const days = getCurrentMonthDays();
    let forecastedamountPerMonth = (
      forecastedamount /
      (days.length - (currentDate - 1))
    ).toFixed(2);
    graphData = (days || []).map((day, index) => {
      return {
        month: format(day, "dd/MM/yy"),
        Budgeted: amount.toFixed(2),
        usedamount:
          index > currentDate - 1
            ? null
            : (usedamount / currentDate).toFixed(2) * (index + 1),
        Forecasted:
          currentDate > format(day, "d")
            ? null
            : (forecastedamountPerMonth * index).toFixed(2),
      };
    });
  }
  // let currentMonthIndex = new Date().getMonth();
  // let currentYear = new Date().getFullYear();
  // let currentMonth = new Date(currentYear, currentMonthIndex);
  // let currentMonthFormate = format(currentMonth, "MMMM");
  // let currentMonthPosition = months.indexOf(currentMonthFormate);
  // let forecastedamountPerMonth = (forecastedamount / totalMonths).toFixed(2);

  if (
    data?.budgetDetails?.timeUnit === "Quarterly" ||
    data?.budgetDetails?.TimeUnit === "QUARTERLY"
  ) {
    const quarterMonths = getQuarter(currentMonthFormate);
    const Qua = quarterMonths.indexOf(currentMonthFormate);
    if (totalMonths > 3) {
      graphData = (quarterMonths || []).map((month, index) => {
        let usedAmount;
        if (index > Qua) {
          usedAmount = null;
        }

        if (index <= Qua) {
          if (Qua === 0) {
            usedAmount = usedamount.toFixed(2);
          } else {
            usedAmount = ((usedamount / Qua) * index)?.toFixed(2);
          }
        }

        return {
          month: month,
          Budgeted: amount.toFixed(2),
          usedamount: usedAmount,
          Forecasted:
            index < Qua
              ? null
              : usedAmount +
                (index - Qua) *
                  (
                    (forecastedamount - usedamount) /
                    (quarterMonths.length - (Qua + 1))
                  ).toFixed(2),
        };
      });
    }
    if (totalMonths <= 3) {
      graphData = (months || []).map((month, index) => {
        let usedAmount;
        if (index > Qua) {
          usedAmount = null;
        }

        if (index <= Qua) {
          if (Qua === 0) {
            usedAmount = usedamount.toFixed(2);
          } else {
            usedAmount = ((usedamount / Qua) * index)?.toFixed(2);
          }
        }
        return {
          month: month,
          Budgeted: amount.toFixed(2),
          usedamount: usedAmount,
          Forecasted:
            index < Qua
              ? null
              : usedAmount +
                (index - Qua) *
                  (
                    (forecastedamount - usedamount) /
                    (quarterMonths.length - (Qua + 1))
                  ).toFixed(2),
        };
      });
    }
  }

  if (data?.budgetDetails?.timeUnit === "Annually") {
    if (totalMonths < 12 && data?.budgetDetails?.timeUnit === "Annually") {
      const Qua = months.indexOf(currentMonthFormate);

      graphData = (months || []).map((month, index) => {
        return {
          month: month,
          Budgeted: amount.toFixed(2),
          usedamount:
            index > Qua
              ? null
              : ((usedamount / (Qua + 1)) * (index + 1)).toFixed(2),
          Forecasted:
            forecastedamount && forecastedamount > 0
              ? index < Qua
                ? null
                : usedamount +
                  (index - Qua) *
                    (
                      (forecastedamount - usedamount) /
                      (totalMonths - (Qua + 1))
                    ).toFixed(2)
              : null,
        };
      });
    }
    if (totalMonths === 12) {
      const Qua = months.indexOf(currentMonthFormate);

      graphData = (months || []).map((month, index) => {
        return {
          month: month,
          Budgeted: amount.toFixed(2),
          usedamount:
            index > currentMonthIndex
              ? null
              : ((usedamount / (currentMonthIndex + 1)) * (index + 1)).toFixed(
                  2
                ),
          Forecasted:
            index < currentMonthIndex
              ? null
              : usedamount +
                (index - currentMonthIndex) *
                  (
                    (forecastedamount - usedamount) /
                    (totalMonths - (currentMonthIndex + 1))
                  ).toFixed(2),
        };
      });
    }
    // if (totalMonths > 12) {
    //   graphData = (months?.slice(0, 12) || []).map((month, index) => {
    //     console.log(
    //       "q",
    //       currentMonthIndex,
    //       index >= currentMonthIndex,
    //       ((usedamount / 1) * index).toFixed(2)
    //     );
    //     return {
    //       month: month,
    //       Budgeted: amount.toFixed(2),
    //       usedamount:
    //         index > currentMonthIndex
    //           ? null
    //           : ((usedamount / 12) * (index + 1)).toFixed(2),
    //       // Forecasted:
    //       //   index === 0
    //       //     ? 0
    //       //     : (forecastedamountPerMonth * (index + 1)).toFixed(2),
    //       // Forecasted:
    //       //   index === 0
    //       //     ? ((forecastedamount / totalMonths).toFixed(2) * index).toFixed(2)
    //       //     : (
    //       //         (forecastedamount / totalMonths - 1).toFixed(2) * index
    //       //       ).toFixed(2),
    //       Forecasted: (
    //         (forecastedamount / totalMonths).toFixed(2) *
    //         (index + 1)
    //       ).toFixed(2),
    //     };
    //   });
    // }
  }

  return (
    <>
      {/* <ResponsiveContainer width={700} height="80%"> */}
      <AreaChart
        width={943}
        height={300}
        data={graphData}
        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
      >
        <XAxis
          label={{ fill: "#A3A3A3" }}
          dataKey="month"
          fontFamily="Helvetica"
          fontSize={10}
          color="#A3A3A3"
          stroke="#A3A3A3"
          // minTickGap={20}
          fontWeight={400}
          interval={"preserveStartEnd"}
        />
        <YAxis
          label={{ fill: "#A3A3A3" }}
          domain={[0, forecastedamount]}
          tickLine={false}
          axisLine={false}
          fontSize={10}
          fontWeight={400}
          stroke="#A3A3A3"
          fontFamily="Inter"
          tickFormatter={(value) => value.toFixed(2)}

        />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip
          content={<CustomTooltip service={service} />}
          cursor={{ fill: "transparent" }}
        />
        <Legend
          align="center"
          verticalAlign="bottom"
          layout="horizontal"
          iconSize={12}
          iconType="Square"
          fontSize={12}
          fontWeight={400}
          fontFamily="Inter"
          fontStyle="normal"
        />
        <Area
          type="linear"
          dataKey="Budgeted"
          stroke="#EACE90"
          fill="#EACE90"
          connectNulls
        />
        <Area
          type="linear"
          dataKey="Forecasted"
          stroke="#F6D1D1"
          fill="#F6D1D1"
          connectNulls
        />
        <Area
          type="linear"
          dataKey="usedamount"
          stroke="#95C99A"
          fill="#95C99A"
          connectNulls
        />
      </AreaChart>
      {/* </ResponsiveContainer> */}
    </>
  );
};

const DetailBox = ({ title, value }) => {
  return (
    <div className={styles.detailBox}>
      <div className={styles.detailBoxRow}>
        <p className={styles.detailTitles}>{title[0]}</p>
        <p className={styles.detailValues}>{value[0] ?? "--"}</p>
      </div>
      <div className={styles.detailBoxRow}>
        <p className={styles.detailTitles}>{title[1]}</p>
        <p className={styles.detailValues}>{value[1] ?? "--"}</p>
      </div>
      <div className={styles.detailBoxRow}>
        <p className={styles.detailTitles}>{title[2]}</p>
        <p className={styles.detailValues}>{value[2] ?? "--"}</p>
      </div>
    </div>
  );
};

const DimensionTable = ({ data, headerTitle }) => {
  return (
    <table style={{ width: "100%" }}>
      <thead>
        <tr>
          <th className={styles.theadStylings} style={{ width: "239px" }}>
            {headerTitle}
          </th>
          <th className={styles.theadStylings} style={{ width: "239px" }}>
            Values
          </th>
        </tr>
      </thead>
      <tbody>
        {data &&
          data.length > 0 &&
          (data || []).map((dimension, index) => {
            const valuesToShow =
              dimension?.values?.length > 4
                ? dimension?.values?.slice(0, 4)
                : dimension?.values;
            return (
              <React.Fragment key={index}>
                <tr>
                  <td
                    rowSpan={valuesToShow.length}
                    className={styles.tdStyling}
                  >
                    {dimension.name}
                  </td>
                  <td className={styles.tdStyling}>{valuesToShow[0]}</td>
                </tr>
                {valuesToShow?.slice(1).map((value, valueIndex) => (
                  <tr key={`${index}-${valueIndex}`}>
                    <td className={styles.tdStyling}>{value}</td>
                  </tr>
                ))}
              </React.Fragment>
            );
          })}
        {data && data.length === 0 && (
          <tr>
            <td
              colSpan={2}
              style={{ padding: "8px" }}
              className={clsx(styles.noData, styles.tdStyling)}
            >
              No {headerTitle} applied
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

// const CustomTooltip = ({ active, payload, month, service }) => {
//   if (!active) {
//     return null;
//   }
//   return (
//     <div className={styless.custom_tooltip}>
//       <div className="tooltip_date">
//         <div className="tooltip_date_css">{payload?.[0]?.payload?.month}</div>
//       </div>
//       <table>
//         <thead>
//           <tr>
//             <th className={styless.cloud_cell}>Amount Types</th>
//             <th className={styless.cost_cell}>Costs</th>
//           </tr>
//         </thead>
//         <tbody>
//           {(payload || []).map((entry, index) => (
//             <tr key={`tooltip-row-${index}`}>
//               <td className={styless.cloud_cell}>{entry.dataKey}</td>
//               <td className={styless.cost_cell}>
//                 ${Number(entry.value)?.toFixed(2)}
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

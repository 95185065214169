import { useContext } from "react";
import config from "../../config";
import { useMutation } from "@tanstack/react-query";
import { AppContext } from "@/App.context";

const updateBudget = (token) => {
  return async ({ body, service }) => {
    if (body?.Dimension) {
      delete body.Dimension;
    }
    if (body?.Tags) {
      delete body.Tags;
    }
    const setBudget = await fetch(
      `${config.apiHost}/v1/${service}/budget/update`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );

    if (!setBudget.ok) {
      const errorResponse = await setBudget.json();
      throw new Error(errorResponse.message);
    }
    const data = await setBudget.json();

    return data;
  };
};

export const useUpdateBudget = (deps = []) => {
  const appContext = useContext(AppContext);
  const mutate = useMutation({
    mutationKey: ["updateBudget", ...deps],
    mutationFn: (body) => updateBudget(appContext.idToken)(body),
  });
  return mutate;
};

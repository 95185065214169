import React, { useContext, useMemo, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  Line,
  Legend,
  ReferenceLine,
  ComposedChart,
  Scatter,
  Cell,
} from "recharts";
import awsLogo from "@/assets/img/aws_logo.svg";
import azureLogo from "@/assets/img/azurelogo.svg";
import styles from "@/page-components/billing-credit/billing-credit.module.css";
import tooltipStyles from "@/page-components/idle-resource/idle.module.css";
import { parse, compareAsc } from "date-fns";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import { useAllCloudBillingCredit } from "@/services/billing-credit/getAllCloudBarData.repo";
import BarGraphErrorStates from "@/components/States/BarGraphErrorStates";
import CurrencyFormatter from "@/lib/value-converison";
import { BudgetContext } from "../../budget.context";
import forecastedImage from "@/assets/img/forecasted-img.svg";
import { useIndividualChartSec } from "@/services/budget/getAllCloudIndividualChart.repo";

const AllCloudBudgetBarGraph = ({ service }) => {
  const [hoveredStack, setHoveredStack] = useState(null);
  const context = useContext(BudgetContext);
  const query = useMemo(()=>{
    const service="all-cloud"
    return{
      service
    }

  },[])
  const { data: chartData, isLoading, error } = useIndividualChartSec(query);

  const data = Object.entries(chartData?.data || [])?.map(([key, value]) => ({
    services: key.toUpperCase(),
   
    forecasted_amount: value.forcasted_amount,
    actual_spent: value.spent_amount,
    budgeted_amount: value.budget_amount,
  }));


  const colorMapping = {
    AWS: {
      budgeted: "#FFE1B3",
      actual: "#FF9900",
    },
    AZURE: {
      budgeted: "#BCD7E9",
      actual: "#1F78B4",
    },
  };
const transformedData = data
  ?.map((entry) => ({
    name: entry.services,
    budgetedAmount: entry.budgeted_amount,
    actualSpent: entry.actual_spent,
    forecastedAmount: entry.forecasted_amount,
  }))
  .sort((a, b) => (a.name.toLowerCase() === "aws" ? -1 : b.name.toLowerCase() === "aws" ? 1 : 0));



  const groupedBars = {
    AWS: [
      { label: "Budgeted Amount", fill: "#FFE1B3" },
      { label: "Actual Spent", fill: "#FF9900" },
    ],
    AZURE: [
      { label: "Budgeted Amount", fill: "#BCD7E9" },
      { label: "Actual Spent", fill: "#1F78B4" },
    ],
  };

  const CustomTooltip = ({ active, payload, stackId }) => {
    if (active && payload && payload.length) {
      const entry = payload[0].payload;
      const lable = payload[0].payload?.name

      return (
        <>
          {isLoading && <BarGraphErrorStates />}
          {error && (
            <ScenariosErrorState
              error={error.message}
              headerItem="header"
              messageConfig={{
                [API_NO_DATA_FOUND_ERROR]: {
                  message: "No Data Available",
                  additionalMessage:
                    "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
                },
                [API_LOADING_ERROR]: {
                  message: "Oops!",
                  additionalMessage:
                    "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
                },
                [API_NOT_FOUND_ERROR]: {
                  message: "404 Error",
                  additionalMessage:
                    "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
                },
              }}
            />
          )}

          {!error && (
            <div
              className={tooltipStyles.custom_tooltip}
              style={{ width: "275px" }}
            >
              <div
                className={tooltipStyles.tooltip_date}
                style={{ paddingLeft: "8px" }}
              >
                <div className={tooltipStyles.tooltip_date_css}>{lable}</div>
              </div>

              <table style={{ width: "92%", margin: "0px 12px 4px 12px" }}>
                <tbody style={{ width: "100%" }}>
                  <tr className={styles.tooltip_row}>
                    <div>
                      <img src={lable === "AWS" ? awsLogo : azureLogo} alt="" />
                      <span className={styles.tooltip_tr_name}>{lable}</span>
                    </div>
                  </tr>
                  <tr className={styles.tooltip_row}>
                    <div className={styles.tooltip_table_flex}>
                      <span className={styles.tooltip_cloud_name}>
                        Budgeted Amount
                      </span>
                      <span className={styles.tooltip_tr_value}>
                        <CurrencyFormatter value={entry.budgetedAmount} />
                      </span>
                    </div>
                  </tr>
                  <tr className={styles.tooltip_row}>
                    <div className={styles.tooltip_table_flex}>
                      <span className={styles.tooltip_cloud_name}>
                        Actual Spent
                      </span>
                      <span className={styles.tooltip_tr_value}>
                        <CurrencyFormatter value={entry.actualSpent} />
                      </span>
                    </div>
                  </tr>
                  <tr className={styles.tooltip_row}>
                    <div className={styles.tooltip_table_flex}>
                      <span className={styles.tooltip_cloud_name}>
                        Forecasted Amount
                      </span>
                      <span className={styles.tooltip_tr_value}>
                        <CurrencyFormatter value={entry.forecastedAmount} />
                      </span>
                    </div>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </>
      );
    }

    return null;
  };

  const CustomLegend = ({ groupedBars }) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          margin: "10px 0",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "10px",
          }}
        >
          {Object.keys(groupedBars).map((provider, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: 40,
              }}
            >
              <span
                style={{
                  fontWeight: 500,
                  fontSize: 12,
                  fontFamily: "Inter",
                  marginRight: 10,
                  color: "#000000",
                }}
              >
                {provider}:
              </span>
              {groupedBars[provider].map((bar, barIndex) => (
                <div
                  key={barIndex}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: 15,
                  }}
                >
                  {bar.label !== "Forecasted Amount" ? (
                    <div
                      style={{
                        width: 14,
                        height: 14,
                        backgroundColor: bar.fill,
                        marginRight: 5,
                        borderRadius:"2px"
                      }}
                    ></div>
                  ) : (
                    <div
                      style={{
                        width: 14,
                        height: 2,
                        backgroundColor: bar.fill,
                        marginRight: 5,
                      }}
                    ></div>
                  )}
                  <span
                    style={{
                      fontSize: 12,
                      fontWeight: 400,
                      fontFamily: "Inter",
                      color: "#000000",
                    }}
                  >
                    {bar.label}
                  </span>
                </div>
              ))}
            </div>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              color: "#D01A1A",
              marginRight: 5,
              borderRadius: "2px",
            }}
          >
            ---
          </div>
          {/* <img src={forecastedImage} alt=""></img> */}
          <span
            style={{
              fontSize: 12,
              fontWeight: 400,
              fontFamily: "Inter",
              color: "#000000",
            }}
          >
            Forecasted Amount
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.graph_main} style={{ width: "70%" }}>
      {((!isLoading && data) || error) && (
        <span className={styles.billing_details}>
          Budgeted Amount/Actual Spent
        </span>
      )}

      {isLoading && <BarGraphErrorStates />}
      {error && (
        <ScenariosErrorState
          error={error.message}
          messageConfig={{
            [API_NO_DATA_FOUND_ERROR]: {
              message: "No Data Available",
              additionalMessage:
                "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
            },
            [API_LOADING_ERROR]: {
              message: "Oops!",
              additionalMessage:
                "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
            },
            [API_NOT_FOUND_ERROR]: {
              message: "404 Error",
              additionalMessage:
                "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
            },
          }}
        />
      )}
      {data && data?.length > 0 && (
        <div style={{ width: "100%" }}>
          <ResponsiveContainer height={292} width="100%">
            <ComposedChart
              width={500}
              height={300}
              data={transformedData}
              margin={{
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
              }}
              // barGap={-100}
            >
              <XAxis
                dataKey="name"
                tickLine={true}
                axisLine={false}
                fontSize={10}
                fontFamily="Inter"
                fontWeight={400}
                stroke="#495057"
              />
              <YAxis
                label={{ fill: "#A3A3A3" }}
                tickLine={false}
                axisLine={false}
                fontSize={10}
                fontFamily="Inter"
                fontWeight={400}
                stroke="#495057"
              />
              <CartesianGrid strokeDasharray="0" stroke="#F0F0F0" />
              <Tooltip
                content={<CustomTooltip stackId={hoveredStack} />}
                cursor={{ fill: "transparent" }}
              />

              <Bar dataKey="budgetedAmount" stackId="services" barSize={120}>
                {transformedData.map((entry, index) => (
                  <Cell
                    key={`budgeted-${index}`}
                    fill={colorMapping[entry.name]?.budgeted || "#CCCCCC"}
                  />
                ))}
              </Bar>

              <Bar dataKey="actualSpent" stackId="services" barSize={120}>
                {transformedData.map((entry, index) => (
                  <Cell
                    key={`actual-${index}`}
                    fill={colorMapping[entry.name]?.actual || "#888888"}
                  />
                ))}
              </Bar>

              <Scatter
                type="monotone"
                dataKey="forecastedAmount"
                stroke="#FF0000"
                strokeDasharray="3 3"
                name="Forecasted Amount"
                shape={(props) => {
                  const { x, y, payload } = props;

                  const barWidth = 120;

                  const leftEdge = x - barWidth / 2 + 62;
                  return (
                    <text
                      x={leftEdge}
                      y={y}
                      textAnchor="middle"
                      fill="#D01A1A"
                      fontSize={12}
                      fontFamily="Inter"
                      fontWeight="bold"
                    
                    >
                      {"------------------------"}
                    </text>
                  );
                }}
              />
            </ComposedChart>

            {Object.keys(groupedBars).length > 0 && (
              <CustomLegend groupedBars={groupedBars} />
            )}
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
};

export default AllCloudBudgetBarGraph;

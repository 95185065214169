import React, { useContext, useEffect, useMemo, useState } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import style from "../anomaly.module.css";
import crossIcon from "@/assets/img/closecross.svg";
import includeIcon from "@/assets/img/addbuttoninblue.svg";
import { AnomalyContext } from "../cost-anomaly.context";
import closeCross from "@/assets/img/closecross.svg";
import styless from "@/page-components/budgets/components/budgets.module.css";
import styles from "@/page-components/cost-explorer/components/edit-widget/edit-widget.module.css";
import Button from "react-bootstrap/Button";
import addsymbol from "@/assets/img/addsymbol.svg";
import { Offcanvas } from "react-bootstrap";

import {
  FilterSearch,
  FilterSearchToggle,
} from "@/components/filter-search/filter-search";
import TagItem from "./tagItem";
import FilterItem from "./filterItem";
import { useCreateCostANomaly } from "@/services/cost-anomaly/create-anomaly.repo";
import Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi";
import { useDimensionTagsKeys } from "@/services/cost-anomaly/get-tags.repo";
import ToastComponent from "@/page-components/budgets/components/toast";

const graphOptions = { bar: "Bar Graph", line: "Line Graph" };
const groupByOptions = {
  service: "Service",
  account: "Account",
  region: "Region",
};
const thresholdOptions = {
  absolute_value: "Absolute Value",
  percentage: "Percentage",
};

const CreateAnomalyForm = ({
  setConfirmationmodal,
  dimensionData,
  service,
  refetch,
  deleteLoading,
  deleteSuccess,
  deleteError,
  deleteData,
}) => {
  const {
    register,
    handleSubmit,
    getValues,
    control,
    watch,
    setValue,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(AddCostAnomalyReq),
    defaultValues: {
      previous_days: 90,
      filters: [{ dimension: "", isInclude: true, values: [] }],
      tags: [
        {
          filterOn: "",
          filterType: "include",
          values: [],
        },
      ],
    },
  });

  const threshold_type = watch("threshold_type")

  const context = useContext(AnomalyContext);
  const EditData = context?.Editdata;

  const { data: dimensionKeys } = useDimensionTagsKeys({ service });
  const [selectedType, setSelectedType] = useState("dimensions");
  const [toast, setToast] = useState(false);
  const [message, setMessage] = useState();


  const queryType = useMemo(() => (EditData ? "edit" : "create"), [EditData]);
  let {
    mutate: Id,
    data,
    reset: createReset,
    isError,
    error: createError,
    isSuccess: successMessage,
    isPending,
  } = useCreateCostANomaly(queryType);
  const HandleRest = () => {
    reset({
      title: "",
      previous_days: 90,
      graph_type: "",
      threshold_type: "",
      threshold: "",
      alerts_receipients: [],
      filters: [{ dimension: "", isInclude: true, values: [] }],
      tags: [{ filterOn: "", filterType: "include", values: [] }],
    });
  };
  const HandleClose = () => {
    context?.setSelectedEmail("");
    context?.setIsLayoverOpen(false);
    context?.setEditdata();
    setSelectedType("dimensions");
    HandleRest();
  };

  useEffect(() => {
    HandleRest();
    setSelectedType("dimensions");
  }, [context?.Editdata]);

  useEffect(() => {
    if (successMessage) {
      HandleClose();
      setMessage({
        message: data?.message,
        type: "success",
      });
      setToast(true);
      refetch();
    }
  }, [successMessage]);
  useEffect(() => {
    if (deleteSuccess) {
      HandleClose();
      setConfirmationmodal(false)
      setMessage({
        message: deleteData?.message,
        type: "success",
      });
      setToast(true);
      refetch();
    }
  }, [deleteSuccess]);

  useEffect(() => {
    if (createError) {
      // HandleClose();
      setMessage({
        message: createError.message,
        type: "Anomaly",
      });
      setToast(true);
    }
  }, [createError]);
  useEffect(() => {
    if (deleteError) {
      HandleClose();
      setConfirmationmodal(false)
      setMessage({
        message: deleteError.message,
        type: "Anomaly",
      });
      setToast(true);
    }
  }, [deleteError]);
  const onSubmit = (formData) => {
    const transformedData = {
      name: formData.title,
      graph_type: String(formData.graph_type).toUpperCase(),
      previous_days: 90,
      filters: {
        filter_type: selectedType,
        dimensions: formData.filters?.map((e) => ({
          name: {name:e.dimension,id:e.dimension},
          values: e.values.map(value => ({ name: value, id: context?.selectedID }))

        })),
        tags: formData.tags?.map((filter) => ({
          name: {name:filter.filterOn,id:filter.filterOn},
          values: filter.values.map(value => ({ name: value, id: context?.selectedID }))
        })),
      },
      threshold_type: String(formData.threshold_type).toUpperCase(),
      threshold: formData.threshold,
      alerts_receipients: formData.alerts_receipients,
      ...(EditData?.anomaly_id && { anomaly_id: EditData.anomaly_id }),
    };

    Id({
      body: transformedData,
      service: service,
    });
  };
  useEffect(() => {
    if (EditData?.alerts_receipients?.length) {
      context?.setSelectedEmail(EditData.alerts_receipients);
      setValue("alerts_receipients", EditData.alerts_receipients);
    } else {
      context?.setSelectedEmail([]);
      setValue("alerts_receipients", []);
    }
  }, [EditData, setValue]);

  const handleAddEmail = () => {
    const input = document.getElementById("emailInput");
    const value = input.value.trim();

    if (value) {
      const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);

      if (!isValidEmail) {
        setError("alerts_receipients", {
          type: "manual",
          message: "Please enter a valid email address",
        });
      } else if (context?.SelectedEmail?.includes(value)) {
        setError("alerts_receipients", {
          type: "manual",
          message:
            "This email address is already added. Please enter a different email.",
        });
      } else {
        setError("alerts_receipients", null);
        context?.setSelectedEmail((prevRecipients) => {
          const updatedRecipients = [...prevRecipients, value];
          setValue("alerts_receipients", updatedRecipients, {
            shouldValidate: true,
          });
          return updatedRecipients;
        });
        input.value = "";
      }
    }
  };

  const handleRemoveEmail = (index) => {
    context?.setSelectedEmail((prevRecipients) =>
      prevRecipients.filter((_, i) => i !== index)
    );
  };
  const {
    fields: filterFields,
    append: filterAppend,
    remove: filterRemove,
  } = useFieldArray({
    control,
    name: "filters",
  });

  const {
    fields: tagFields,
    append: tagAppend,
    remove: tagRemove,
  } = useFieldArray({
    control,
    name: "tags",
  });

  const [savedFilters, setSavedFilters] = useState([]);
  const [savedTags, setSavedTags] = useState([]);

  useEffect(() => {
    if (selectedType === "dimensions") {
      setValue("tags", [{ filterOn: "", filterType: "include", values: [] }]);
    } 
    if (selectedType === "tags")  {
      setValue("filters", [{ dimension: "", isInclude: true, values: [] }]);
    }
  }, [selectedType, setValue]);

  useEffect(() => {
    if (EditData?.anomaly_id) {
      setValue("title", EditData?.name);
      // setValue("previous_days", EditData?.previous_days);
      setValue("graph_type", EditData?.graph_type.toLowerCase());
      setValue("threshold_type", EditData?.threshold_type.toLowerCase());
      setValue("threshold", EditData?.threshold);
      if (EditData?.filters?.filter_type === "dimensions") {
        setSelectedType("dimensions");
        setValue(
          "filters",
          EditData?.filters?.dimensions?.map((dim) => (
            {
            dimension: dim.name.id?dim.name.id:dim.name.name,
            isInclude: true,
            values: (dim.values || []).map((i)=>i.name),
          })) || []
        );
      } else if (EditData?.filters?.filter_type === "tags") {
        setSelectedType("tags");
        setValue(
          "tags",
          EditData?.filters?.tags?.map((tag) => ({
            filterOn: tag?.name?.id ? tag?.name?.id:tag.name.name,
            filterType: "include",
            values: (tag.values || []).map((i)=>i.name),
          })) || []
        );
      }
    }
  }, [EditData, setValue]);
  useEffect(() => {
    if (EditData?.anomaly_id) {
      const alertsRecipients =
        Array.isArray(context?.SelectedEmail) &&
        context.SelectedEmail.length > 0
          ? context.SelectedEmail
          : [];
      setValue("alerts_receipients", alertsRecipients);
    }
  }, [context.SelectedEmail, setValue]);
  return (
    <>
      {toast && (
        <ToastComponent
          message={message}
          toast={toast}
          setToast={setToast}
          timeout={5000}
        />
      )}

      <Offcanvas
        placement="end"
        show={context?.isLayoverOpen}
        onHide={() => {
          HandleClose();
        }}
        style={{
          width: "40%",
        }}
      >
        <Offcanvas.Body
          className={styles.modal_body}
          style={{
            paddingTop: 16,
          }}
        >


          <div style={{ height: "100%", width: "100%" }}>
            <h2 className={style.Heading_Cross}>
              Create Anomaly Detection Policy
              <img
                src={crossIcon}
                alt=""
                style={{ cursor: "pointer" }}
                onClick={() => {
                  HandleClose();
                }}
              />
            </h2>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div>
                <div className={style.form_group}>
                  <label className={style.label}>Title</label>
                  <input
                    defaultValue={EditData?.name}
                    className={style.input}
                    {...register("title", { required: true })}
                    placeholder="Enter Title"
                  />
                  <div style={{ height: "10px", display: "flex" }}>
                    {errors.title && (
                      <span className="budgetsError">
                        {errors.title.message}
                      </span>
                    )}
                  </div>
                </div>

                {/* <div className={style.form_group}>
                  <label className={style.label}>Show Previous</label>
                  <input
                    defaultValue={EditData ? EditData?.previous_days : ""}
                    className={style.input}
                    type="number"
                    {...register("previous_days")}
                  />
                  <div style={{ height: "10px", display: "flex" }}>
                    {errors.previous_days && (
                      <span className="budgetsError">
                        {errors.previous_days.message}
                      </span>
                    )}
                  </div>
                </div> */}

                <div className={style.form_group}>
                  <label className={style.label}>Graph Type</label>
                  <Controller
                    control={control}
                    name="graph_type"
                    render={({ field }) => (
                      <FilterSearch
                        hideSearch
                        options={Object.entries(graphOptions).map(
                          ([label, value]) => ({
                            id: label,
                            name: value,
                          })
                        )}
                        displayValue="name"
                        selectedValues={field.value ? [field.value] : []}
                        onSelect={(selectedItem) => {
                          field.onChange(selectedItem.id);
                        }}
                        toggle={
                          <FilterSearchToggle placeholder="Select Graph Type" />
                        }
                      />
                    )}
                  />

                  <div style={{ height: "10px", display: "flex" }}>
                    {errors.graph_type && (
                      <span className="budgetsError">
                        {errors.graph_type.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className={style.form_group}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                      marginBottom: "6px",
                    }}
                  >
                    <label className={style.labelMain}>Filters</label>
                    <span className={style.discription}>
                      Select your filter & tags and enhance anomaly precision
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                      alignItems: "center",
                      marginTop: "20px",
                      marginBottom: "12px",
                    }}
                  >
                    <label style={{ display: "flex", gap: "4px" }}>
                      <input
                        className={style.input}
                        type="radio"
                        name="selectionType"
                        // value="filter"
                        checked={selectedType === "dimensions"}
                        onChange={() => {
                          setSelectedType("dimensions");
                        }}
                      />
                      Filter
                    </label>

                    <label style={{ display: "flex", gap: "4px" }}>
                      <input
                        className={style.input}
                        type="radio"
                        name="selectionType"
                        // value="tag"
                        checked={selectedType === "tags"}
                        onChange={() => {
                          setSelectedType("tags");
                        }}
                      />
                      Tag
                    </label>
                  </div>

                  <div
                    className={style.form_group}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    {selectedType === "dimensions" ? (
                      <div
                        className={styles.filters_css}
                        style={{ gap: "0px" }}
                      >
                        <div
                          className={styles.filter_name_div}
                          style={{ padding: "0px" }}
                        ></div>
                        {filterFields.map((e, i) => (
                          <FilterItem
                            service={service}
                            watch={watch}
                            field={e}
                            index={i}
                            setValue={setValue}
                            control={control}
                            errors={errors}
                            remove={filterRemove}
                            key={e.id}
                            append={filterAppend}
                            dimensionData={dimensionData}
                          />
                        ))}
                        {/* {errors?.filters?.length && (
                    <small className={styles.error_message}>
                      {errors.filters[0].message}
                    </small>
                  )} */}
                      </div>
                    ) : (
                      <div
                        className={styles.filters_css}
                        style={{ gap: "0px" }}
                      >
                        <div
                          className={styles.filter_name_div}
                          style={{ padding: "0px" }}
                        ></div>
                        {tagFields.map((e, i) => (
                          <TagItem
                            dimensionKeys={dimensionKeys}
                            watch={watch}
                            field={e}
                            index={i}
                            setValue={setValue}
                            control={control}
                            errors={errors}
                            remove={tagRemove}
                            service={service}
                            key={e.id}
                            append={tagAppend}
                          />
                        ))}
                        {errors?.tags?.length && (
                          <small className={styles.error_message}>
                            {errors.tags[0].message}
                          </small>
                        )}
                      </div>
                    )}
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                    marginTop: "16px",
                  }}
                >
                  <label className={style.labelMain}>
                    Alert & Notifications Conditions
                  </label>
                  <span className={style.discription}>
                    Configure alert conditions and send email notifications
                    based on your spend.
                  </span>
                </div>

                <div style={{ display: "flex", gap: "16px" }}>
                  <div className={style.form_group}>
                    <label className={style.label}>Threshold Type</label>
                    <Controller
                      control={control}
                      name="threshold_type"
                      render={({ field }) => (
                        <FilterSearch
                          hideSearch
                          options={Object.entries(thresholdOptions).map(
                            ([label, value]) => ({
                              id: label,
                              name: value,
                            })
                          )}
                          displayValue="name"
                          selectedValues={field.value ? [field.value] : []} // ✅ Use field.value directly
                          onSelect={(selectedItem) => {
                            field.onChange(selectedItem.id); // ✅ Update field value dynamically
                          }}
                          toggle={
                            <FilterSearchToggle placeholder="Select Type" />
                          }
                        />
                      )}
                    />

                    <div style={{ height: "10px", display: "flex" }}>
                      {errors.threshold_type && (
                        <span className="budgetsError">
                          {errors.threshold_type.message}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className={style.form_group}>
                    <label className={style.label}>Threshold{threshold_type ==="percentage"?" %":threshold_type==="absolute_value"?" $":""}</label>
                    <input
                      defaultValue={EditData ? EditData?.threshold : ""}
                      className={style.input}
                      placeholder="Enter"
                      type="number"
                      {...register("threshold")}
                    />
                    <div style={{ height: "10px", display: "flex" }}>
                      {errors.threshold && (
                        <span className="budgetsError">
                          {errors.threshold.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className={style.form_group}>
                  <label className={style.label}>
                    Add email addresses where you want to receive notifications
                  </label>
                  <div style={{ display: "flex" }}>
                    <input
                      className={style.input}
                      id="emailInput"
                      type="email"
                      // {...register("email")}
                      placeholder="Enter email"
                      name="alerts_receipients"
                    />
                    <div
                      style={{
                        marginLeft: "16px",
                        display: "flex",
                        gap: "4px",
                        alignItems: "center",
                        cursor: "pointer",
                        flexWrap: "wrap",
                        width: "20%",
                      }}
                      onClick={handleAddEmail}
                    >
                      <img style={{ width: "12px" }} src={includeIcon} alt="" />
                      <span
                        style={{
                          color: "#056EE5",
                          fontSize: "12px",
                          fontWeight: "600",
                        }}
                      >
                        Add Email
                      </span>
                    </div>
                  </div>
                  <div style={{ height: "10px", display: "flex" }}>
                    {errors.alerts_receipients && (
                      <p className="budgetsError">
                        {errors.alerts_receipients.message ||
                          (Array.isArray(errors.alerts_receipients) &&
                            errors.alerts_receipients?.message)}
                      </p>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    gap: "8px",
                    flexWrap: "wrap",
                  }}
                >
                  {(context?.SelectedEmail || []).map((recipient, index) => (
                    <div key={index} className={styless.badgeContainers}>
                      <span key={index} className={styless.badgesValues}>
                        <span className={styless.badgeSelectedValue}>
                          {recipient}
                        </span>
                        <span>
                          <img
                            style={{ cursor: "pointer" }}
                            src={closeCross}
                            alt="crossIcon"
                            onClick={() => handleRemoveEmail(index)}
                          />
                        </span>
                      </span>
                    </div>
                  ))}
                </div>
              </div>

              <div
                className="button_box"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "0px",
                  paddingBottom: "20px",
                }}
              >
                {EditData && (
                  <div>
                    <button
                      type="button"
                      style={{ cursor: "pointer" }}
                      className={style.deleted_button}
                      onClick={() => {
                        context?.setSelectedAnomalyID(EditData?.anomaly_id);
                      }}
                    >
                      {deleteLoading ? "Deleting..." : "Delete"}
                    </button>
                  </div>
                )}
                <div
                  className="cancel_create_button"
                  style={{ justifyContent: "end", width: "100%" }}
                >
                  <button
                    type="button"
                    style={{ cursor: "pointer" }}
                    className="cancel_button"
                    onClick={() => {
                      HandleClose();
                    }}
                  >
                    Cancel
                  </button>
                  {EditData && (
                    <button type="submit" className="create_button">
                      {isPending ? "Updating..." : "Edit"}
                    </button>
                  )}
                  {!EditData && (
                    <button type="submit" className="create_button">
                      {isPending ? "Creating..." : "Create"}
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
       
         
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default CreateAnomalyForm;

export const AddCostAnomalyReq = Joi.object({
  title: Joi.string().required().messages({
    "string.empty": "Title is required",
    "any.required": "Title is required",
  }),
  // previous_days: Joi.number().min(1).max(90).required().messages({
  //   "number.base": "Previous Days must be a number",
  //   "number.min": "Previous Days must be at least 1",
  //   "number.max": "Previous Days cannot exceed 90",
  //   "any.required": "Previous Days is required",
  // }),
  previous_days:Joi.optional(),
  graph_type: Joi.string().required().messages({
    "string.empty": "Graph Type is required",
    "any.required": "Graph Type is required",
  }),
  threshold_type: Joi.string().required().messages({
    "string.empty": "Threshold Type is required",
    "any.required": "Threshold Type is required",
  }),
  threshold: Joi.number()
  .required()
  .max(Joi.ref('threshold_type', {
    adjust: (value) => (value === "percentage" ? 100 : Infinity),
  }))
  .messages({
    "number.empty": "Threshold is required",
    "number.base": "Threshold is required",
    "any.required": "Threshold is required",
    "number.max": "Threshold cannot be more than 100 for percentage type",
  }),

  alerts_receipients: Joi.array()
    .items(
      Joi.string()
        .email({ tlds: { allow: false } })
        .messages({
          "string.email": "Email must be a valid email",
        })
    )
    .min(1)
    .required()
    .messages({
      "array.min": "At least one email is required",
      "any.required": "User Email is required",
      "array.includes": "Each item must be a valid email",
    }),

  // ✅ Separate Validation for Filters
  filter: Joi.array().optional(),
  filters: Joi.array().optional(),
  tags: Joi.array().optional(),
  dimensions: Joi.array().optional(),
});

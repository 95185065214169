import React, { useMemo, useContext, useEffect } from "react";
import { useCardStatistics } from "@/services/IdleResources/getCardStatistics.repo";
import { IdleContext } from "../idle-context-file.part";
import { PieChart, Pie, Cell, ResponsiveContainer, Label } from "recharts";

import styles from "@/page-components/idle-resource/idle.module.css";
import clsx from "clsx";
import CurrencyFormatter from "@/lib/value-converison";

const colors = ["#DE3E11", "#ED9615", "#F8C455", "green"];

const SquareIcon = ({ color }) => (
  <svg width="10" height="10" fill={color}>
    <rect width="10" height="10" />
  </svg>
);

const IdleResourceSemiPieChart = ({
  service,
  resourceType,
  setShowError,
  ipName,
  diskName,
  vmName,
}) => {
  const context = useContext(IdleContext);
  const query = useMemo(() => {
    const tags = (context?.selectedTags || []).filter(
      (e) => e.filterOn.length > 0 && e.values.length > 0
    );
    return {
      service,
      resourceType: resourceType,
      untagged: context.showUntaggedOnly,
      filters: {
        accounts: context?.selectAccounts?.length
          ? context.selectAccounts.map((e) => e.id)
          : undefined,
        rg: context?.selectedResourceGroup?.length
          ? context.selectedResourceGroup.map((e) => e.id)
          : undefined,
        regions: context?.selectRegions?.length
          ? context.selectRegions.map((e) => e.id)
          : undefined,
        tags: tags?.length ? tags : undefined,
      },
    };
  }, [
    context.selectAccounts,
    context.selectRegions,
    context.selectedResourceGroup,
    context?.selectedTags,
    context.showUntaggedOnly,
    resourceType,
    service,
  ]);

  const {
    data,
    isError,
    error,
    isLoading,
    refetch: semiCardRefetched,
  } = useCardStatistics(query, [context.addAcceptDismissIdleData]);

  useEffect(() => {
    setShowError({ isError, errorMessage: error?.message, isLoading });
  }, [isError, error, setShowError, isLoading]);

  useEffect(() => {
    if (!context.setSemiCardRefetch) return;

    context.setSemiCardRefetch((prev) => ({
      ...prev,
      [resourceType]: semiCardRefetched,
    }));
  }, [semiCardRefetched, resourceType]);

  const resourceNameMap = {
    ip: `${ipName}`,
    lb: "Load Balancers",
    vm: `${vmName}`,
    disk: `${diskName}`,
  };

  const costCardsData = [
    {
      // name: `Idle ${data?.resource || "NA"}`,
      name: resourceNameMap[data?.resource] || "NA",
      potentialSavings: (
        <CurrencyFormatter value={data?.potential_saving || 0} />
      ),
    },
  ];
  const semidata = [
    { name: "More Than 31 Days", value: data?.count["31_more"]?.count || 0 },
    { name: "16-30 Days", value: data?.count["15_30"]?.count || 0 },
    { name: "8-15 Days", value: data?.count["7_15"]?.count || 0 },
    { name: "0-7 Days", value: data?.count["0_7"]?.count || 0 },
  ];

  const hasData = semidata.some((entry) => entry.value > 0);
  const Skeletondata = [{ value: 100 }];

  return (
    <>
      {data && (
        <div className={clsx("col-sm-12 col-md-6", styles.cost_graph)}>
          <div className="d-flex justify-content-between align-items-center w-100">
            <div className={styles.aws_costs_div}>
              {costCardsData.map((card, index) => (
                <div key={index}>
                  <div className={styles.aws_cost_name2}>{card.name}</div>
                  <div className={clsx(styles.aws_cost_text_div)}>
                    <span className={styles.aws_cost_text}>
                      Potential Savings
                    </span>
                    <span className={styles.aws_cost_value}>
                      {card.potentialSavings || 0}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="container-fluid">
            <div className="container-fluid">
              <div className="row">
                {hasData ? (
                  <>
                    <div
                      className="col-sm-6 col-md-6"
                      style={{ paddingBottom: "0px" }}
                    >
                      <ResponsiveContainer width="100%" height={222}>
                        <PieChart>
                          <Pie
                            data={semidata}
                            labelLine={false}
                            outerRadius="105%"
                            innerRadius="65%"
                            margin={{ top: 0, right: 0, left: 0, bottom: -50 }}
                            dataKey="value"
                            gap={0}
                            stroke="1"
                            startAngle={180}
                            endAngle={0}
                          >
                            {semidata.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={colors[index % colors.length]}
                                style={{outline: 'none'}}
                              />
                            ))}
                          </Pie>
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  </>
                ) : (
                  <div
                    className="col-sm-6 col-md-6"
                    style={{ paddingBottom: "0px" }}
                  >
                    <ResponsiveContainer width="100%" height={222}>
                      <PieChart>
                        <Pie
                          data={Skeletondata}
                          dataKey="value"
                          labelLine={false}
                          outerRadius="105%"
                          innerRadius="65%"
                          margin={{ top: 0, right: 0, left: 0, bottom: -50 }}
                          gap={0}
                          stroke="1"
                          startAngle={180}
                          endAngle={0}
                        >
                          <Label
                            position="center"
                            className={styles.no_data_css}
                          >
                            No Data
                          </Label>
                          <Cell fill="#F8F9FA" style={{outline: 'none'}}/>
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                )}
                <div
                  className="col-sm-6"
                  style={{ paddingLeft: "12%", paddingRight: "0px" }}
                >
                  <table style={{ width: "100%" }}>
                    <tbody>
                      {semidata
                        .slice()
                        .reverse()
                        .map((entry, index) => (
                          <tr
                            key={`legend-${index}`}
                            style={{ display: "block" }}
                          >
                            <td
                              style={{ display: "inline-block", width: "77%" }}
                            >
                              <span
                                style={{
                                  marginRight: "8px",
                                  lineHeight: "30px",
                                }}
                              >
                                <SquareIcon
                                  color={
                                    colors.slice().reverse()[
                                      index % colors.length
                                    ]
                                  }
                                />
                              </span>
                              <span
                                style={{
                                  fontSize: "13px",
                                  color: "#495057",
                                  fontFamily: "sans-serif,inter",
                                  fontWeight: 550,
                                  lineHeight: "normal",
                                }}
                              >
                                {entry.name}
                              </span>
                            </td>
                            <td
                              style={{
                                width: "20%",
                                display: "inline-block",
                                textAlign: "right",
                              }}
                            >
                              <strong className={styles.table_value_css}>
                                {entry.value}
                              </strong>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default IdleResourceSemiPieChart;

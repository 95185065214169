import React, { useContext } from "react";
import { Offcanvas } from "react-bootstrap";
import { BudgetContext, useBudgetContext } from "../../budget.context";
import styles from "@/page-components/budgets/components/budgets.module.css";
import { TableSEction } from "./tableSection.part";
import clsx from "clsx";

function DetaiView() {
  const context = useContext(BudgetContext);
 
  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span className={styles.headingModal}>
          <img src="" alt="" />
          Newly Created Resources
        </span>
        <span className={styles.pahrahModal}>
          Monitor costs for newly created auto-scaling or manually added
          resources contributing to budget performance.
        </span>
      </div>

      <div className={styles.costheadingModal}>
        <div className={styles.costContainerModal}>
          <span className={styles.totalCostModal}>TOTAL COST</span>
          <span className={styles.total_CostModal}>$3434</span>
        </div>
        <div className={styles.costContainerModal}>
          <span className={styles.totalCostModal}>CONTRIBUTION %</span>
          <span className={styles.total_CostModal}>45.7%</span>
        </div>
      </div>

      <div
        style={{
          padding: "0px 16px 0px 8px",
          display: "flex",
          justifyContent: "space-between",
          marginBottom:"20px"
        }}
      >
        <div>
          <span
            style={{ borderRadius: "4px 0px 0px 4px" }}
            className={clsx(
              styles.newlyTabs,
              context?.NewlyView === "auto" ? styles.newlyTabActive : ""
            )}
            onClick={() => {
              context?.setNewlyView("auto");
            }}
          >
            Auto Scaling (
            {/* {NewlyData?.total_auto_scaling_count &&
                    (NewlyData?.total_auto_scaling_count || 0)} */}
            )
          </span>
          <span
            style={{ borderRadius: "0px 4px 4px 0px" }}
            className={clsx(
              styles.newlyTabs,
              context?.NewlyView === "manual" ? styles.newlyTabActive : ""
            )}
            onClick={() => {
              context?.setNewlyView("manual");
            }}
          >
            Manually Created (
            {/* {NewlyData?.total_manually_created_count &&
                    (NewlyData?.total_manually_created_count || 0)} */}
            )
          </span>
        </div>

      </div>
      <div style={{width:"100%",paddingLeft:"8px"}}>

      <TableSEction />
      </div>

    </>
  );
}

export default DetaiView;

import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { customTooltip } from "./stacked-bar-graph-idle-res.part";

const LineChartIdleResource = ({ lineChartData, colorMap }) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart
        data={lineChartData}
        margin={{ top: 0, right: 3, left: -13, bottom: 0 }}
      >
        <CartesianGrid strokeDasharray="0" vertical={false} stroke="#F0F0F0" />
        <XAxis
          dataKey="Month"
          tickLine={false}
          axisLine={false}
          fontSize={10}
          fontWeight={400}
          stroke="#A3A3A3"
        />
        <YAxis
          label={{ fill: "#A3A3A3" }}
          tickLine={false}
          axisLine={false}
          fontSize={10}
          fontWeight={400}
          stroke="#A3A3A3"
        />
        <Legend
          align="center"
          verticalAlign="bottom"
          layout="horizontal"
          iconSize={12}
          iconType="Square"
          wrapperStyle={{}}
          fontSize={12}
          fontWeight={400}
          fontStyle="normal"
        />
        <Tooltip content={customTooltip} cursor={{ fill: "transparent" }} />
        {Object.keys(lineChartData[0] || []).map((key) => {
          if (key === "Month") return null;
          return (
            <Line
              type="straight"
              dataKey={key}
              stackId="a"
              stroke={colorMap[key]}
            />
          );
        })}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LineChartIdleResource;

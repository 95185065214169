import React, { useContext } from "react";
import styles from "@/page-components/billing-credit/billing-credit.module.css";
import BillingBarGraph from "../components/billing-bar-graph.part";
import { BillingContext, BillingProvider } from "../billing-context.part";
import CreditFilterSection from "../components/filter-section/filter-section";
import AzureHeaderComponent from "../components/azure-header-component";
import BillingDetails from "../components/billing-details-table.part";

const GCPBillingPage = () => {
  return (
    <BillingProvider>
      <BillingPart />
    </BillingProvider>
  );
};

const BillingPart = () => {
  const context = useContext(BillingContext);
  const service = "gcp";

  return (
    <div className={styles.billing_comp}>
      <AzureHeaderComponent service={service} />
      <>
        <div className={styles.conatining}>
          <div style={{ width: "100%", marginTop: "24px" }}>
            <div className={styles.bar_card} style={{ width: "100%" }}>
              <BillingBarGraph service={service} />
            </div>

            <BillingDetails service={service} named="Project Name/ID" />
          </div>
        </div>
      </>
    </div>
  );
};

export default GCPBillingPage;

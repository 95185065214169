import React, { useContext, useMemo, useState } from "react";
import styles from "@/page-components/RightSizing/rightSizing.module.css";

import {
  CostRecommendationContext,
  CostRecommendationProvider,
} from "../costContext";
import ActiveCostHeader from "@/page-components/cost-Recommendation/component/activeCostHeader";
import { useCostStatistics } from "@/services/cost-recommendation/getTotalStats.repo";
import { useCostRecommendationsListDownlaod } from "@/services/cost-recommendation/getTableDownload";
import { useSearchParams } from "react-router-dom";
import AllPageLoading from "@/components/States/AllPageLoading";
import ProjectedChartSection from "../component/chartSection/projectedChartSection";
import IndividualChartsection from "../component/chartSection/individualChartsection";
import AllPageNoFilterLoading from "@/components/States/AllPageNoFilterLoading";
import clsx from "clsx";

function AllCloudCostPage({ service = "all-cloud" }) {
  return (
    <CostRecommendationProvider>
      <AllCloudCostRecommendation service={service} />
    </CostRecommendationProvider>
  );
}

const AllCloudCostRecommendation = ({ service = "all-cloud" }) => {
  const context = useContext(CostRecommendationContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const query = useMemo(() => {
    return {
      service: "all-cloud",
    };
  }, []);

  const { data: headerData, isLoading, error } = useCostStatistics(query);

  const downloadMutate = useMemo(() => {
    const tags = (context?.selectedTags || []).filter(
      (e) => e.filterOn.length > 0 && e.values.length > 0
    );
    return {
      page: 1,
      recordsPerPage: 10,
      orderBy: context?.orderBy,
      desc: searchParams.get("desc") || "true",
      service,
      accounts: context?.selectedAccounts?.length
        ? context.selectedAccounts.map((e) => e.id)
        : undefined,
      // resourceGroupName:
      //   context?.selectedResourceGroup?.length > 0 &&
      //   context?.selectedResourceGroup?.length
      //     ? context.selectedResourceGroup.map((e) => e.id)
      //     : undefined,
      regions: context?.selectedRegions?.length
        ? context.selectedRegions.map((e) => e.id)
        : undefined,
      resource_type: context?.selectedResourceType?.length
        ? context.selectedResourceType.map((e) => e.id)
        : undefined,
      tags: tags.length ? tags : undefined,
    };
  }, [
    context?.selectedTags,
    context?.orderBy,
    context.selectedAccounts,
    context.selectedRegions,
    context.selectedResourceType,
    searchParams,
    service,
  ]);

  const { mutate: downloadMutateDiscard } =
    useCostRecommendationsListDownlaod(downloadMutate);

  const handleDownloadClicked = () => {
    downloadMutateDiscard(query);
  };

  return (
    <>
      <CostRecommendationContext.Consumer
        children={(v) => (
          <>
            {isLoading && <AllPageNoFilterLoading />}
            {!isLoading && (
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12 p-0">

                  <div className={clsx(styles.rec_inner_first_container)}>
                <div
                  className="container-fluid main-container"
                  style={{ padding: "16px 50px 20px 40px" }}
                >
                  <div
                    className=" Inner_main_container"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="inner_first_container_text">
                      Recommendations
                    </div>
                  </div>
                  {v.viewType === "Active" && (
                      <ActiveCostHeader
                        data={headerData}
                        names="Recommendations"
                        savingFor="PROJECTED SAVINGS"
                        tilldate="Saved till date"
                        active="active"
                        error={error}
                        isLoading={isLoading}
                        service="all-cloud"
                        heading="ACTIVE RECOMMENDATIONS"
                      />
                    )}
                  <div
                    className={clsx(
                      "container-fluid",
                      styles.Rec_inner_contaier_text
                    )}
                  >
                    Updated Today at 7:05AM
                  </div>
                </div>
              </div>
                   
                  </div>
                </div>
                    <ProjectedChartSection />
                    <IndividualChartsection />
              </div>
            )}
          </>
        )}
      />
    </>
  );
};

export default AllCloudCostPage;

import React, { useContext } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Legend,
  Tooltip,
  ReferenceLine,
  ReferenceDot,
} from "recharts";
import { CustomLegend, CustomTooltip } from "./bar-chart";
import { AnomalyContext } from "../cost-anomaly.context";

/**
 * @typedef LineChartGraphProps
 * @property {*} lineChartData
 * @property {*} colorMap
 * @property {boolean=} showLegend
 * @property {boolean=} showTooltip
 * @property {number=} height
 */

/**
 * @param {LineChartGraphProps} props
 */
const LineChartGraph = ({
  data,
  service,
  showLegend,
  showTooltip,
  lineChartData,
  colorMap,
  height = 300,
  dimensionData,
}) => {
  const context = useContext(AnomalyContext);
  const scatterData = data
    .filter((item) => item.anomaly === 1)
    .map((item) => {
      const totalCostForDate = data.reduce((sum, curr) => {
        if (curr.date === item.date) {
          return sum + curr.cost;
        }
        return sum;
      }, 0);
      return {
        x: item.date,
        y: totalCostForDate,
      };
    });

  if (!data) return null;

  const transformDataForChart = (data) => {
    const groupedData = {};

    data?.forEach((item) => {
      if (!groupedData[item.date]) {
        groupedData[item.date] = { label: item.date };
      }

      const key = item.service || "cost";
      groupedData[item.date][key] = item.cost;
    });

    return Object.values(groupedData);
  };

  const barChartData = transformDataForChart(data);

  // Get unique anomaly keys
  // const keys = [...new Set(data?.map((d) => d.service))];
  const keys = Array.isArray(barChartData)
    ? Array.from(
        new Set(
          barChartData.flatMap((data) =>
            Object.keys(data).filter((key) => key !== "label")
          )
        )
      )
    : [];

  return (
    <ResponsiveContainer width="100%" height={height}>
      <LineChart
        data={barChartData}
        margin={{ top: 0, right: 3, left: 0, bottom: 0 }}
      >
        <CartesianGrid
          strokeDasharray="3 3"
          vertical={false}
          stroke="#F0F0F0"
        />
        <XAxis
          dataKey="label"
          tickLine={false}
          axisLine={false}
          fontSize={10}
          fontFamily="Inter"
          fontWeight={400}
          stroke="#A3A3A3"
        />
        <YAxis
          label={{ fill: "#A3A3A3" }}
          tickLine={false}
          axisLine={false}
          fontSize={10}
          fontFamily="Inter"
          fontWeight={400}
          stroke="#A3A3A3"
        />
        {/* <ReferenceLine
          y={context?.SelectedThreshold}
          stroke="orange"
          strokeDasharray="5 5"
        /> */}

        {showTooltip && (
          <Tooltip
            content={
              <CustomTooltip
                context={context}
                colorMap={colorMap}
                service={service}
                dimensionData={context?.SelectedFilter}
              />
            }
            cursor={{ fill: "transparent" }}
          />
        )}
        {showLegend && (
          <Legend
            content={<CustomLegend dimensionData={context?.SelectedFilter} />}
          />
        )}

        {keys.map((key) => (
          <Line
            key={key}
            type="monotone"
            dataKey={key}
            stroke={colorMap[key] || "#8884d8"}
            strokeWidth={2}
            dot={{ r: 3 }}
            activeDot={{ r: 5 }}
          />
        ))}
        {scatterData.map((point, index) => (
          <ReferenceDot
            key={index}
            x={point.x}
            y={point.y}
            r={5}
            fill="red"
            stroke="white"
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LineChartGraph;

import React, { useContext, useMemo, useRef, useState } from "react";
import crossIcon from "@/assets/img/closecross.svg";

import style from "../anomaly.module.css";
import styles from "@/page-components/cost-explorer/cost.explorer.module.css";
import StackedBarChart from "./bar-chart";
import { randomHexColorCode } from "@/lib/colors";
import LineChartGraph from "./linegraph";
import { Dropdown, Spinner } from "react-bootstrap";
import clsx from "clsx";
import binIcon from "@/assets/img/bin.svg";
import { CSVLink } from "react-csv";
import downloadIcon from "@/assets/img/download.svg";
import linecharticon from "@/assets/img/lineCharticon.svg";
import barcharticon from "@/assets/img/graphCharticon.svg";
import menuicon from "@/assets/img/menu.svg";
import editIcon from "@/assets/img/editoracces.svg";
import { useCostANomalyGraphStats } from "@/services/cost-anomaly/Graph-stats.repo";
import { AnomalyContext } from "../cost-anomaly.context";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";

function StyledStackedBarGraph({
  name,
  setisChartModal,
  service,
  datar,
  anomalyId,
  setDeleteData,
  setConfirmationmodal,
}) {
  const context = useContext(AnomalyContext);
  const query = useMemo(
    () => ({
      service,
      id: context?.SelectedNameID,
    }),
    []
  );

  const { data: chartData, isLoading, error } = useCostANomalyGraphStats(query);

  const data = chartData?.data?.chartData;
  const bartype = context?.Editdata?.graph_type
    ? String(context?.Editdata?.graph_type).toLocaleLowerCase()
    : "bar";

  const [chartType, setChartType] = useState(bartype);
  const csvLinkRef = useRef(null);

  const handleDownloadCsv = () => {
    csvLinkRef.current.link.click();
  };
  const transformedGraphData = useMemo(() => {
    if (!data) return [];
    return data?.map((row) => {
      const transformedRow = {};
      for (const key in row) {
        if (typeof row[key] === "number") {
          transformedRow[key] = row[key].toFixed(2);
        } else {
          transformedRow[key] = row[key];
        }
      }
      return transformedRow;
    });
  }, []);
  const colorMap = useMemo(() => {
    let colorMap = {
      Others: "#EBB78A",
    };
    if (data) {
      data?.forEach((item, index) => {
        colorMap[item.service || "cost"] = randomHexColorCode(index);
      });
    }
    return colorMap;
  }, [data]);

  return (
    <>
      {isLoading && (
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner />
        </div>
      )}
      {!isLoading && (
        <div
          className={style.chartModalMain}
          style={{ width: "100%", height: "100%" }}
        >
          <div className={style.chartModalHeader}>
            <div style={{ display: "flex", gap: "4px" }}>
              <span
                className={style.chartModalHeadertext}
                style={{ display: "flex", gap: "5px" }}
              >
                Cost Anomaly by
                {[
                  ...(context?.SelectedFilter?.tags || []),
                  ...(context?.SelectedFilter?.dimensions || []),
                ].map((item) => (
                  <div key={item?.name.name}>
                    <span>
                      {item?.name?.name?.replace(/([a-z])([A-Z])/g, "$1 $2")
                        .replace(/\b\w/g, (char) => char.toUpperCase())}
                    </span>
                    {/* {(item?.values || []).map((i) => (
                            <span key={i}>{i}</span>
                          ))} */}
                  </div>
                ))}
              </span>
              <span className={style.chartusertext2}>
                (Created By{" "}
                {String(context?.SelectedUserNAme).toLowerCase() === name
                  ? "You"
                  : context?.SelectedUserNAme}
                )
              </span>
            </div>

            <img
              src={crossIcon}
              alt=""
              style={{ cursor: "pointer" }}
              onClick={() => {
                setisChartModal(false);
                context?.setEditdata();
                setChartType("");
              }}
            />
          </div>

          {error && (
            // <div style={{height:"100vh"}}>

            <ScenariosErrorState
              error={error.message}
              messageConfig={{
                [API_NO_DATA_FOUND_ERROR]: {
                  message: "No Data Available",
                  additionalMessage:
                    "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
                },
                [API_LOADING_ERROR]: {
                  message: "Oops!",
                  additionalMessage:
                    "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
                },
                [API_NOT_FOUND_ERROR]: {
                  message: "404 Error",
                  additionalMessage:
                    "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
                },
              }}
            />
            // </div>
          )}
          {!isLoading && !error && data && (
            <div className={style.ChartSectionMain}>
              <div
                style={{
                  display: "flex",
                  gap: "40px",
                  flexDirection: "column",
                }}
              >
                <div
                  className={style.chartStats}
                  style={{ justifyContent: "space-between" }}
                >
                  <div className={style.chartStats}>
                    <span className={style.gap}>
                      <h1 className={style.statsHeadings}>
                        Total Period Spend
                      </h1>
                      <p className={style.chartStatsValue}>
                        {Number(chartData?.data?.totalPeriodSpend).toFixed(2) ||
                          "--"}
                      </p>
                    </span>
                    <span className={style.gap}>
                      <h1 className={style.statsHeadings}>Anomalies</h1>
                      <p className={style.chartStatsValue}>
                        {context?.SelectedAnomaliesCount || "--"}
                      </p>
                    </span>
                    <span className={style.gap}>
                      <h1 className={style.statsHeadings}>Threshold</h1>
                      <p className={style.chartStatsValue}>
                        {context?.Editdata?.threshold_type === "ABSOLUTE_VALUE"
                          ? context?.SelectedThreshold
                            ? "$" + context?.SelectedThreshold
                            : "--"
                          : context?.SelectedThreshold
                          ? context?.SelectedThreshold + "%"
                          : "--"}
                      </p>
                    </span>
                  </div>
                  <div className={styles.graph_icons}>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="light"
                        className={clsx(
                          "dropdown-disable-caret",
                          styles.custom_widget_btn,
                          styles.custom_widget_dropdown_toggle
                        )}
                        style={{
                          background: "rgb(240, 246, 255)",
                          border: "none",
                          width: "24px",
                          height: "24px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img src={menuicon} alt="Menu" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        className={clsx(
                          "rounded shadow py-2 px-2",
                          styles.custom_widget_dropdown
                        )}
                      >
                        <Dropdown.Item
                          eventKey="bar"
                          // disabled={customWidgetContext.deleteWidgetMutation.isPending}
                          onClick={() => setChartType("bar")}
                        >
                          <img
                            src={barcharticon}
                            alt=""
                            className={style.dropdown_icon}
                          />
                          Bar Chart
                        </Dropdown.Item>

                        <Dropdown.Divider />

                        <Dropdown.Item
                          eventKey="line"
                          // disabled={customWidgetContext.deleteWidgetMutation.isPending}
                          onClick={() => setChartType("line")}
                        >
                          <img
                            src={linecharticon}
                            alt=""
                            className={style.dropdown_icon}
                          />
                          Line Chart
                        </Dropdown.Item>
                        <Dropdown.Divider />

                        <Dropdown.Item
                          eventKey="line"
                          // disabled={customWidgetContext.deleteWidgetMutation.isPending}
                          onClick={() => {
                            setisChartModal(false);
                            context?.setIsLayoverOpen(true);
                          }}
                        >
                          <img
                            src={editIcon}
                            alt=""
                            className={style.dropdown_icon}
                          />
                          Edit
                        </Dropdown.Item>

                        <Dropdown.Divider />

                        <Dropdown.Item
                          eventKey="Download as CSV"
                          // disabled={customWidgetContext.deleteWidgetMutation.isPending}
                          onClick={handleDownloadCsv}
                        >
                          <img
                            src={downloadIcon}
                            alt=""
                            className={style.dropdown_icon}
                          />
                          Download as CSV
                        </Dropdown.Item>
                        {transformedGraphData && (
                          <CSVLink
                            data={transformedGraphData}
                            filename={`Anomaly-data.csv`}
                            target="_blank"
                            ref={csvLinkRef}
                            style={{ display: "none" }}
                          >
                            Download
                          </CSVLink>
                        )}

                        <Dropdown.Divider />

                        <Dropdown.Item
                          eventKey="deleteWidget"
                          onClick={() => {
                            setConfirmationmodal(true);
                            setDeleteData(anomalyId);
                          }}
                        >
                          <img
                            src={binIcon}
                            alt=""
                            className={style.dropdown_icon}
                          />
                          <span className="text-danger">Delete Anomaly</span>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <div className={style.chartStats} style={{ gap: "32px" }}>
                  <span className={style.chartspan}>
                    <h1 className={style.statsHeadings}>Filter</h1>
                    <p className={style.chartStatsValues}>
                      {[
                        ...(context?.SelectedFilter?.tags || []),
                        ...(context?.SelectedFilter?.dimensions || []),
                      ].map((item) => (
                        <div
                          style={{ display: "flex", gap: "2px" }}
                          key={item?.name?.name}
                        >
                          <span>
                            {item?.name?.name
                              .replace(/([a-z])([A-Z])/g, "$1 $2")
                              .replace(/\b\w/g, (char) => char.toUpperCase())}
                          </span>
                          :
                          {(item?.values || []).map((i) => (
                            <span key={i}>{i?.name}</span>
                          ))}
                        </div>
                      ))}
                      {/* Service : ECService for Kubernetes */}
                    </p>
                  </span>
                  <span className={style.chartspan}>
                    <h1 className={style.statsHeadings}>Evaluation period</h1>
                    <p className={style.chartStatsValues}>
                      {context?.Selectedperiod
                        ? context?.Selectedperiod + " days"
                        : "--"}
                    </p>
                  </span>
                  <span className={style.chartspan}>
                    <h1 className={style.statsHeadings}>Last Occurred On</h1>
                    <p className={style.chartStatsValues}>
                      {context?.SelectedOccoured || "--"}
                    </p>
                  </span>
                </div>
              </div>
              <div>
                {chartType === "bar" ? (
                  <StackedBarChart
                    data={data}
                    threshold
                    colorMap={colorMap}
                    showTooltip
                    showLegend
                    height={450}
                  />
                ) : (
                  <LineChartGraph
                    data={data}
                    colorMap={colorMap}
                    threshold
                    height={450}
                    showLegend
                    showTooltip
                  />
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default StyledStackedBarGraph;

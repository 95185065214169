/** @type {import('./global')} */
import { useRemoveRecommendationFromArchives } from "@/services/right-sizing/removeRecommendationFromArchives";
import React, { createContext, useState, useEffect } from "react";
import { differenceInDays, differenceInMonths, format, parse } from "date-fns";

/**
 * @typedef {import('@/services/right-sizing/removeRecommendationFromArchives').RemoveRecommendationFromArchivesRes} RemoveRecommendationFromArchivesRes
 */

/**
 * @typedef {import('@/services/right-sizing/removeRecommendationFromArchives').RemoveRecommendationFromArchivesReq} RemoveRecommendationFromArchivesReq
 */

/**
 * @typedef Ri_utilization
 * @property {keyof typeof VIEW_TYPES} viewType
 * @property {React.Dispatch<React.SetStateAction<keyof typeof VIEW_TYPES>>} setViewType
 * @property {string} recommendationType
 * @property {React.Dispatch<React.SetStateAction<string>>} setRecommendationType
 * @property {string[]} selectedAccounts
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSelectedAccounts
 * @property {string[]} selectedResourceGroup
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSelectedResourceGroup
 * @property {string[]} selectedRegions
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSelectedRegions
 * @property {string[]} selectedTags
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSelectedTags
 * @property {string[]} selectedTags
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSelectedTags
 * @property {string} SelectedPopupAccount
 * @property {React.Dispatch<React.SetStateAction<string>>} setSelectedPopupAccount
 */

/**
 * @type {React.Context<Ri_utilization>}
 */
export const RiutilizationContext = createContext();

/**
 * @returns {Ri_utilization}
 */
export const useRiUtilization = () => {
  // const [viewType, setViewType] = useState(VIEW_TYPES.recommendations);

  /** @type {UseState<string[]>} */
  const [selectedAccounts, setSelectedAccounts] = useState([]);

  /** @type {UseState<string[]>} */
  const [selectedResourceGroup, setSelectedResourceGroup] = useState([]);

  /** @type {UseState<string[]>} */
  const [selectedRegions, setSelectedRegions] = useState([]);

  /** @type {UseState<string[]>} */
  const [selectedImpact, setSelectedImpact] = useState([]);

  /** @type {UseState<string[]>} */
  const [selectedTerm, setSelectedTerm] = useState([]);

  /** @type {UseState<string>} */
  const [orderBy, setOrderBy] = useState("monthly_cost");
  /** @type {UseState<string>} */
  const [SelectedPopupAccount, setSelectedPopupAccount] = useState("");
  /** @type {UseState<string[]>} */
  const [selectMonthRange, setSelectMonthRange] = useState("3month");
  /** @type {UseState<string[]>} */
  const [dropdownView, setDropdownView] = useState("main");
  /** @type {UseState<string[]>} */
  const [selectedDate, setSelectedDate] = useState(null);

  /** @type {UseState<string[]>} */
  const [selectedTags, setSelectedTags] = useState([
    {
      filterOn: "",
      filterType: "include",
      values: [],
    },
  ]);
  /** @type {UseState<string[]>} */
  const [range, setRange] = useState([
    {
      startDate: "",
      endDate: "",
      key: "selection",
    },
  ]);
  let fromDate = range[0].startDate
    ? format(range[0].startDate, "yyyy-MM-dd")
    : "";
  let toDate = range[0].endDate ? format(range[0].endDate, "yyyy-MM-dd") : "";

  const date2 = parse(toDate, "yyyy-MM-dd", new Date());
  const date1 = parse(fromDate, "yyyy-MM-dd", new Date());
  const daysDifference = differenceInDays(date2, date1) + 1;
  const monthDifference = differenceInMonths(date2, date1) + 1;
  const [isOpenCalendar, setIsOpenCalendar] = useState(true);
  const [dateRanges, setdateRanges] = useState("3month");

  useEffect(() => {
    setSelectedResourceGroup([]);
  }, [selectedAccounts]);

  return {
    range,
    setRange,
    selectedDate,
    setSelectedDate,
    daysDifference,
    monthDifference,
    isOpenCalendar,
    setIsOpenCalendar,
    dateRanges,
    setdateRanges,
    dropdownView,
    setDropdownView,
    selectMonthRange,
    setSelectMonthRange,
    SelectedPopupAccount,
    setSelectedPopupAccount,
    selectedTerm,
    setSelectedTerm,
    selectedImpact,
    setSelectedImpact,
    orderBy,
    setOrderBy,
    selectedAccounts,
    setSelectedAccounts,
    selectedRegions,
    setSelectedRegions,
    selectedResourceGroup,
    setSelectedResourceGroup,
    fromDate,
    toDate,
  };
};

export const RiUtilizationProvider = ({ children }) => {
  const context = useRiUtilization();
  return (
    <>
      <RiutilizationContext.Provider value={context}>
        {children}
      </RiutilizationContext.Provider>
    </>
  );
};

import React, { useEffect, useState, useRef, useContext } from "react";
import addWidget from "@/assets/img/addWidget.svg";
import { CSVLink } from "react-csv";
import EmptyCustomWidget from "./skeleton.widget";
import StackedBarGraph from "../bar-graph.component";
import LineChartGraph from "../line-chart.component";
import EditWidget from "../edit-widget/edit-widget.component";
import { useGetWigdetById } from "@/services/costExplorer/getWidgetById.repo";
import { useMemo } from "react";
import { randomHexColorCode } from "@/lib/colors";
import { Spinner, Button } from "react-bootstrap";
import { INTERVAL } from "@/services/costExplorer/addCostExplorerWidget.repo";
import Dropdown from "react-bootstrap/Dropdown";
import menuicon from "@/assets/img/menu.svg";
import barcharticon from "@/assets/img/graphCharticon.svg";
import linecharticon from "@/assets/img/lineCharticon.svg";
import downloadIcon from "@/assets/img/download.svg";
import binIcon from "@/assets/img/bin.svg";
import editIcon from "@/assets/img/options.svg";
import {
  CustomWidgetContext,
  CustomWidgetProvider,
} from "./custom-widget.context";
import { Offcanvas } from "react-bootstrap";
import clsx from "clsx";
import styles from "./custom-widget.module.css";
import { useCallback } from "react";
import { parse, format } from "date-fns";
import { useWorker } from "@jseda/useworker";
import { useQuery } from "@tanstack/react-query";
import { useGetAllWidgets } from "@/services/costExplorer/getAllWidgets.repo";
import ScenariosErrorState, {
  CustomNoData,
} from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR,
  API_LOADING_ERROR_CUSTOM_WIDGET,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET,
  API_NOT_FOUND_ERROR,
  API_NOT_FOUND_ERROR_CUSTOM_WIDGET,
} from "@/lib/errors/error-constants";
import CurrencyFormatter from "@/lib/value-converison";

/**
 * @typedef CustomWidgetProps
 * @property {'aws' | 'azure' | 'allcloud' | 'gcp'} service
 */

/**
 * @param {CustomWidgetProps} props
 */
const CustomWidget = (props) => {
  return (
    <CustomWidgetProvider>
      <CustomWidgetComp {...props} />
    </CustomWidgetProvider>
  );
};

const CustomWidgetComp = ({ service, dimensionData }) => {
  const customWidgetContext = useContext(CustomWidgetContext);

  const {
    data: allWidgetsData,
    isLoading: allWidgetsLoading,
    error: allWidgetError,
  } = useGetAllWidgets({
    service,
  });

  useEffect(() => {
    if (allWidgetsData) {
      customWidgetContext.setWidgets((w) => {
        const newWidgets = w ? { ...w } : {};
        newWidgets[service] = allWidgetsData;
        return newWidgets;
      });
    }
  }, [allWidgetsData, service]);

  const toggleLayover = () => {
    customWidgetContext.setIsLayoverOpen((e) => !e);
  };

  const closeLayover = () => {
    customWidgetContext.setSelectedWidget(undefined);
    customWidgetContext.setIsLayoverOpen(false);
  };

  const removeWidgetFromList = useCallback(
    (id) => {
      customWidgetContext.setWidgets((w) => {
        const newWidgets = { ...w };
        const arr = [...(newWidgets[service] || [])];
        const index = arr.findIndex((e) => e.id === id);
        if (index >= 0) {
          arr.splice(index, 1);
          newWidgets[service] = arr;
        }
        return newWidgets;
      });
    },
    [customWidgetContext, service]
  );

  useEffect(() => {
    if (
      customWidgetContext.deleteWidgetMutation.isSuccess &&
      customWidgetContext.deleteWidgetId
    ) {
      customWidgetContext.setDeleteWidgetId(undefined);
      removeWidgetFromList(customWidgetContext.deleteWidgetId);
    }
  }, [
    customWidgetContext,
    customWidgetContext.deleteWidgetId,
    customWidgetContext.deleteWidgetMutation.isSuccess,
    removeWidgetFromList,
  ]);

  return (
    <>
      <div className={clsx(styles.custom_widget_topbar)}>
        <div className={styles.custom_widget_name}>Custom Widgets</div>
        <button className={styles.add_new_widget} onClick={toggleLayover}>
          <span className={styles.add_widgte_svg}>
            <img src={addWidget} alt=" " />
          </span>
          <span className={styles.add_widget_button}>Add New Widget</span>
        </button>
      </div>
      <div className="row g-3">
        {allWidgetError && (
          <ScenariosErrorState
            error={allWidgetError.message}
            messageConfig={{
              [API_LOADING_ERROR]: {
                message: "Oops!",
                additionalMessage:
                  "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
              },
              [API_NOT_FOUND_ERROR]: {
                message: "404 Error",
                additionalMessage:
                  "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
              },
            }}
          />
        )}
        {!allWidgetsLoading && allWidgetsData && (
          <>
            {(customWidgetContext.widgets[service] || []).map((e) => (
              <SingleWidget service={service} widget={e.id} />
            ))}
            <div className="col-sm-6">
              <EmptyCustomWidget onClick={toggleLayover} />
            </div>
          </>
        )}
        {allWidgetsLoading && !allWidgetsData && (
          <div
            className="d-flex align-items-center justify-content-center w-100"
            style={{ height: 200 }}
          >
            <Spinner
              animation="border"
              variant="primary"
              style={{ top: "50" }}
            />
          </div>
        )}
      </div>

      <Offcanvas
        placement="end"
        show={
          customWidgetContext.selectedWidget ||
          customWidgetContext?.isLayoverOpen
        }
        style={{ width: "464px" }}
      >
        <Offcanvas.Body className="p-0">
          <EditWidget
            widget={customWidgetContext.selectedWidget}
            widgetDimension={customWidgetContext.selectedWidgetDimension}
            onClose={closeLayover}
            service={service}
            dimensionData={dimensionData}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

/**
 * @callback RemoveWidgetFromList
 * @param {string} id
 */

/**
 * @typedef SingleWidgetProps
 * @property {string} service
 * @property {string} widget
 * @property {RemoveWidgetFromList} removeWidgetFromList
 */

const serviceDataFn = (data) => {
  if (data) {
    const init = new Map();
    return (data || []).reduce((map, c) => {
      map.set(c["Service"], c);
      return map;
    }, init);
  }
  return null;
};

const graphDataFn = (dataFiltered, serviceData, selectedDimension) => {
  if (dataFiltered && serviceData) {
    return (dataFiltered || []).map((e) => {
      let date = e;
      if (!selectedDimension) {
        const keys = Array.from(serviceData.keys());
        if (!keys.length) {
          return [];
        }
        return {
          label: date,
          Dimension: serviceData?.get(keys[0])[e] || 0,
        };
      } else {
        const obj = {
          label: date,
        };

        const sorted = Array.from(serviceData?.keys()).sort((a, b) => {
          return (serviceData?.get(b)[e] || 0) - (serviceData?.get(a)[e] || 0);
        });

        sorted.slice(0, 4).forEach((k) => {
          obj[k] = serviceData?.get(k)[e];
        });

        if (sorted.length > 5) {
          obj["Others"] = sorted.slice(4).reduce((p, c) => {
            return p + (serviceData?.get(c)[e] || 0);
          }, 0);
        }

        return obj;
      }
    });
  }
  return [];
};

/**
 * @param {SingleWidgetProps} props
 * @returns
 */
const SingleWidget = ({ service, widget }) => {
  const customWidgetContext = useContext(CustomWidgetContext);
  const [chartType, setChartType] = useState("bar");
  const csvLinkRef = useRef(null);

  const {
    data,
    isLoading,
    isError,
    error,
    refetch: refetchWidget,
  } = useGetWigdetById({
    id: widget,
    service,
  });

  useEffect(() => {
    if (customWidgetContext.updateWidgetId === widget) {
      refetchWidget();
      customWidgetContext.setUpdateWidgetId(undefined);
    }
  }, [
    customWidgetContext,
    customWidgetContext.updateWidgetId,
    refetchWidget,
    widget,
  ]);

  useEffect(() => {
    if (data?.widget?.graph_type) {
      setChartType(data?.widget?.graph_type);
    }
  }, [data?.widget?.graph_type]);

  const dataFiltered = data?.dates || [];

  const [serviceDataTrigger, { kill: serviceDataKill }] =
    useWorker(serviceDataFn);

  const [graphDataTrigger, { kill: graphDataKill }] = useWorker(graphDataFn);

  const { data: serviceData } = useQuery({
    queryKey: ["serviceData", data?.data],
    queryFn: ({ signal }) => {
      if (signal.aborted) serviceDataKill();
      return serviceDataTrigger(data?.data || []);
    },
  });

  const { data: graphData } = useQuery({
    queryKey: [
      "serviceData",
      dataFiltered,
      data,
      data?.selectedDimension,
      data?.granularity,
      serviceData,
    ],

    queryFn: async ({ signal }) => {
      if (signal.aborted) graphDataKill();
      return (
        await graphDataTrigger(
          dataFiltered,
          serviceData,
          data?.selectedDimension,
          data?.granularity
        )
      ).map((e) => {
        return {
          ...e,
          label:
            data.granularity === "date"
              ? format(parse(e.label, "dd-MM-yyyy", new Date()), "dd LLL")
              : e.label,
        };
      });
    },
  });

  const colorMap = useMemo(() => {
    let colorMap = {
      Others: "#EBB78A",
    };
    if (serviceData) {
      Array.from(serviceData?.keys()).forEach(
        (e, i) => (colorMap[e] = randomHexColorCode(i))
      );
    }
    return colorMap;
  }, [serviceData]);

  const handleDownloadCsv = () => {
    csvLinkRef.current.link.click();
  };

  const transformedGraphData = useMemo(() => {
    if (!graphData) return [];

    return graphData.map((row) => {
      const transformedRow = {};

      for (const key in row) {
        if (key === "label") {
          transformedRow[data.granularity] = row[key];
        } else if (typeof row[key] === "number") {
          transformedRow[key] = row[key].toFixed(2);
        } else {
          transformedRow[key] = row[key];
        }
      }

      return transformedRow;
    });
  }, [graphData, data?.granularity]);

  return (
    <div key={widget?.widgetId} className="col-sm-6">
      <div className={clsx(styles.graph_box1)}>
        {(customWidgetContext.deleteWidgetId === widget || isLoading) && (
          <div
            className={clsx(
              "align-self-center d-flex align-items-center justify-content-center",
              styles.containerHeight
            )}
          >
            <Spinner
              animation="border"
              variant="primary"
              style={{ top: "50" }}
            />
          </div>
        )}
        <div className={styles.graph_icons}>
          {!isError && (!isLoading || data?.data) && (
            <Button
              variant="light"
              className={clsx(styles.custom_widget_btn)}
              style={{ marginRight: 1 }}
              onClick={() => {
                customWidgetContext.setSelectedWidget(data?.widget);
                customWidgetContext.setSelectedWidgetDimension(
                  data?.selectedDimension
                );
              }}
            >
              <img src={editIcon} alt="Menu" />
            </Button>
          )}
          {!isLoading && (isError || data?.data) && (
            <Dropdown>
              <Dropdown.Toggle
                variant="light"
                className={clsx(
                  "dropdown-disable-caret",
                  styles.custom_widget_btn,
                  styles.custom_widget_dropdown_toggle
                )}
              >
                <img src={menuicon} alt="Menu" />
              </Dropdown.Toggle>
              <Dropdown.Menu
                className={clsx(
                  "rounded shadow py-2 px-2",
                  styles.custom_widget_dropdown
                )}
              >
                <Dropdown.Item
                  eventKey="bar"
                  disabled={customWidgetContext.deleteWidgetMutation.isPending}
                  onClick={() => setChartType("bar")}
                >
                  <img
                    src={barcharticon}
                    alt=""
                    className={styles.dropdown_icon}
                  />
                  Bar Chart
                </Dropdown.Item>

                <Dropdown.Item
                  eventKey="line"
                  disabled={customWidgetContext.deleteWidgetMutation.isPending}
                  onClick={() => setChartType("line")}
                >
                  <img
                    src={linecharticon}
                    alt=""
                    className={styles.dropdown_icon}
                  />
                  Line Chart
                </Dropdown.Item>

                <Dropdown.Divider />

                <Dropdown.Item
                  eventKey="Download as CSV"
                  disabled={customWidgetContext.deleteWidgetMutation.isPending}
                  onClick={handleDownloadCsv}
                >
                  <img
                    src={downloadIcon}
                    alt=""
                    className={styles.dropdown_icon}
                  />
                  Download as CSV
                </Dropdown.Item>
                {transformedGraphData && (
                  <CSVLink
                    data={transformedGraphData}
                    filename={`widget-data-${data?.widget.title}.csv`}
                    target="_blank"
                    ref={csvLinkRef}
                    style={{ display: "none" }}
                  >
                    Download
                  </CSVLink>
                )}

                <Dropdown.Divider />

                <Dropdown.Item
                  eventKey="deleteWidget"
                  disabled={customWidgetContext.deleteWidgetMutation.isPending}
                  onClick={() => {
                    customWidgetContext.deleteWidget({
                      service,
                      widgetId: widget,
                    });
                  }}
                >
                  <img src={binIcon} alt="" className={styles.dropdown_icon} />
                  <span className="text-danger">Delete</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>

        {!isLoading && (isError || data?.data) && (
          <div className={styles.graph_box_topname_bar}>
            <div className={styles.graph_box_names}>
              <div className={styles.css_projectName_days}>
                <div className={styles.finops_project_cost}>
                  {data?.widget.title || "--"}
                </div>

                <div className={styles.days_name_css}>
                  {data?.widget.show_previous}{" "}
                  {INTERVAL[data?.widget.widget_interval]}
                </div>
              </div>
              <div className={styles.cost_value}>
                <CurrencyFormatter value={data?.total || 0} />
              </div>
            </div>
          </div>
        )}

        {customWidgetContext.deleteWidgetId !== widget && error && (
          <div style={{ margin: "-8px 16px 0px 16px" }}>
            <ScenariosErrorState
              error={error.message}
              messageConfig={{
                [API_LOADING_ERROR_CUSTOM_WIDGET]: {
                  message: "Oops!",
                  additionalMessage:
                    "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
                },
                [API_NOT_FOUND_ERROR_CUSTOM_WIDGET]: {
                  message: "404 Error",
                  additionalMessage:
                    "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
                },
              }}
            />
          </div>
        )}
        {customWidgetContext.deleteWidgetId !== widget &&
          data?.data.length === 0 && (
            <div style={{ margin: "-8px 16px 0px 16px" }}>
              {" "}
              <CustomNoData />
            </div>
          )}
        {customWidgetContext.deleteWidgetId !== widget &&
          !isLoading &&
          !isError &&
          data?.data.length > 0 && (
            <>
              <div className="w-100 px-3">
                {chartType === "bar" ? (
                  <StackedBarGraph
                    service={service}
                    barChartData={graphData}
                    colorMap={colorMap}
                    showTooltip
                    showLegend
                    height={240}
                    intervalvalue={15}
                  />
                ) : (
                  <LineChartGraph
                    service={service}
                    lineChartData={graphData}
                    colorMap={colorMap}
                    height={240}
                    showLegend
                    showTooltip
                    intervalvalue={15}
                  />
                )}
              </div>
            </>
          )}
      </div>
    </div>
  );
};

export default CustomWidget;

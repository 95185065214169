import { AppContext } from "@/App.context";
import config from "@/config";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NOT_FOUND_ERROR,
  ERROR_STATUS_CODES,
} from "@/lib/errors/error-constants";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

/**
 * @typedef AllTableStatistics
 * @property {AlltableStatics[]} snapshots
 *
 */
/**
 * @typedef AlltableStatics
 * @property {String} subscription_id
 * @property {String} subscription_name
 * @property {String} snapshot_id
 * @property {String} snapshot_name
 * @property {String} region
 * @property {String} deleted_on
 * @property {Number} actual_saving
 * @property {String} status
 */

/**
 * @typedef AllBodyStatisticsRes
 * @property {AllTableStatistics} statistics
 */

/**
 * @typedef GetStatisticsReq
 * @property {'aws' | 'azure' |'gcp' } service
 * @property {number} page
 * @property {number} limit
 */

/**
 * @param {GetStatisticsReq} req
 * @returns {Promise<AllBodyStatisticsRes>}
 */

export const getStatisticsHistoryChart = (token) => {
  return async ({ service, page, recordsPerPage, orderBy, desc, ...data }) => {
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/snapshots/history-details?page=${page}&recordsPerPage=${recordsPerPage}&orderBy=${orderBy}&desc=${desc}`,
      {
        method: "POST",
        body: JSON.stringify({
          accounts: data?.accounts || [],
          regions: data?.regions || [],
          diskIds: data?.diskIds || [],
          resourceGroupNames:data?.resourceGroupNames || [],
          period: data?.period, 
          start_date:data?.fromDate , 
          end_date:data?.toDate ,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!owners.ok) {
      if (ERROR_STATUS_CODES.includes(owners.status)) {
        throw new Error(API_LOADING_ERROR);
      }
      if (owners.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR);
      }
      throw new Error((await owners.json()).message);
    }
    const res = await owners.json();
    if (res?.data?.data?.count === 0) {
      throw new Error(API_NO_DATA_FOUND_ERROR);
    }

    return res;
  };
};

/**
 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useHistoryTableStats = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getStatisticsHistoryChart", req, ...deps],
    queryFn: () => getStatisticsHistoryChart(appContext.idToken)(req),
    retry:false
  });
  return query;
};

/** @type {import('./global')} */
import React, { createContext, useState } from "react";

/**
 * @typedef {import('@/services/IdleResources/getAllStatics.repo').GetAllStatisticsFilters} TagItem
 */

/**
 * @typedef {import('@/services/IdleResources/addAcceptDismissIdle.repo').GetRecommendationsListRes} GetRecommendationsListRes
 */

/**
 * @typedef {import('@/services/IdleResources/addAcceptDismissIdle.repo').AddAcceptDismissIdleReq} AddAcceptDismissIdleReq
 */

/**
 * @typedef SavingContextType
 * @property {string[]} selectAccounts
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSelectAccounts
 * @property {string[]} selectRegions
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSelectRegions
 * @property {string[]} selectRg
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSelectRg
 * @property {TagItem[]} selectedTags
 * @property {React.Dispatch<React.SetStateAction<TagItem[]>>} setSelectedTags
 * @property {TagItem[]} showSelectDate
 * @property {React.Dispatch<React.SetStateAction<TagItem[]>>} setShowSelectDate
 */

/**
 * @type {React.Context<SavingContextType>}
 */
export const SavingContext = createContext();

/**
 * @returns {SavingContextType}
 */
export const useSavingContext = () => {
  /** @type {UseState<string[]>} */
  const [selectAccounts, setSelectAccounts] = useState([]);

  /** @type {UseState<string[]>} */
  const [selectRegions, setSelectRegions] = useState([]);

  /** @type {UseState<string[]>} */
  const [selectRg, setSelectRg] = useState([]);

  // /** @type {UseState<string[]>} */
  // const [showSelectDate, setShowSelectDate] = useState("");

  /** @type {UseState<string[]>} */
  const [showSelectDate, setShowSelectDate] = useState(() => {
    const currentDate = new Date();
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return `${months[currentDate.getMonth()]} ${currentDate.getFullYear()}`;
  });

  /** @type {UseState<TagItem[]>} */
  const [selectedTags, setSelectedTags] = useState([
    {
      filterOn: "",
      filterType: "include",
      values: [],
    },
  ]);

    const [selectedab, setselectedab] = useState("Idle Resources");
  
  return {
    selectedab,
    setselectedab,
    selectAccounts,
    setSelectAccounts,
    selectRegions,
    setSelectRegions,
    selectedTags,
    selectRg,
    setSelectRg,
    setSelectedTags,
    showSelectDate,
    setShowSelectDate,
  };
};

export const SavingProvider = ({ children }) => {
  const context = useSavingContext();
  return (
    <>
      <SavingContext.Provider value={context}>
        {children}
      </SavingContext.Provider>
    </>
  );
};


import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";


/**
 * @typedef AllBodyStatistics
 * @property {String} ticket_title
 * @property {String} ticket_id
 * @property {String} ticket_status
 * @property {String} comment
 * @property {Array} AllBodyStatisticsTable
 */

/**
 * @typedef AllBodyStatisticsTable
 * @property {String} account
 * @property {String} snapshotName
 * @property {String} snapshotCost
 * @property {String} status
 * @property {String} jiraStatus
 */
/**
 * @typedef AllBodyStatisticsRes
 * @property {AllBodyStatistics} statistics
 */
/**
 * @typedef GetStatisticsReq
 * @property {'aws' | 'azure' } service
 */

export const getStatisticsjira = (token) => {
  /**
   * @param {GetStatisticsReq} req
   * @returns {Promise<AllBodyStatisticsRes>}
   */
  return async ({ service, id }) => {
    // return datamock
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/cost-recommendations/ticket-status/${id}`,
      {
        method: "Get",
        headers: {
          Authorization: token,
        },
      }
    );
    if (!owners.ok) {
      throw new Error((await owners.json()).message);
    }
    return await owners.json();
  };
};

/**
 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useStatisticsjiraCostRecommendation = (req, deps = [],enabled=true) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getStatistics", req, ...deps],
    queryFn: () => getStatisticsjira(appContext.idToken)(req),
    retry:false,
    enabled
  });
  return query;
};
